import shortid from 'shortid';
import { NotificationManager } from 'react-notifications';
import _lodash from 'lodash';
import update from 'immutability-helper';
import {scroller} from 'react-scroll';
import formSerialize from 'form-serialize';
import { goToAnchor } from 'react-scrollable-anchor';
// import scrollToComponent from 'react-scroll-to-component';
import { clearNotificationMessage, setBreadcumbs, clearBreadcumbs } from '../redux/actions'; // authInProgress, authFailed
import { store } from '../redux/store';
import URLUtils from './URLUtils';
import { ACCESS_TOKEN, SELECTED_LANGUAGES, PRIMARY_LANGUAGE_CODE, UNAUTH_USER_LAST_ACTIVITY } from './localStorageConstant';
import {
    DELAY_BEFORE_REDUX_ACTION,
    DELAY_BEFORE_RELOAD_PAGE_AFTER_NOTIFICATION,
    ERROR_CODE_FROM_BACKEND,
    BREAKING_WIDTH_FOR_RESPONSIVE,
    DELAY_BEFORE_NEXT_ACTION,
    DELAY_BEFORE_RELOAD_PAGE_AFTER_CHANGE_LANGUAGE,
    BUTTONS,
    getBoardNameFromCode,
    getSubBoardNameFromCode,
    BOARD_NAME_QA,
    BOARD_NAME_COMMUNITY,
    BOARD_NAME_NOTICE,
    BOARD_NAME_RANK,
    INTERVAL_FOR_COUNT_AS_ONE_VISIT,
    ERROR_MESSAGE
} from '../config';
import i18n from '../locales/i18n';
import $ from 'jquery';
import { ApiUser } from '../api';

export default class MiscUtils {

    static initForFirstLoading(callback) {
        const languages = this.getFromLocalStorage(SELECTED_LANGUAGES, null);
        if (!languages){
            this.setToLocalStorage(SELECTED_LANGUAGES, MiscUtils.getDefaultLanguages())
        }
        URLUtils.setAuthorizationHeader(this.getFromLocalStorage(ACCESS_TOKEN));
        callback();
        // store.dispatch(authInProgress(false));
        // store.dispatch(authFailed(false));
    }

    static setToLocalStorage(key, val) {
        localStorage.setItem(key, JSON.stringify(val));
    }

    static getFromLocalStorage(key, defaultVal) {
        const valueA = localStorage.getItem(key) || null;

        // for IE
        // if (typeof valueA === 'undefined')
        //     return defaultVal;

        if ((typeof valueA !== 'undefined') && (valueA !== 'undefined') && (valueA !== null))
            return JSON.parse(valueA);
        else
            return defaultVal;
    }

    static removeFromLocalStorage(key) {
        localStorage.removeItem(key);
    }

    static clearLocalStorage() {
        localStorage.clear();
    }

    static generateId() {
        return shortid.generate();
    }

    static showErrorMessage(message) {
        this.showNotification(message, 'error');
    }

    static clearErrorMessage() {
        store.dispatch(clearNotificationMessage());
    }

    static showNotification(message, type) {
        switch (type) {
            // case 'info':
            //     NotificationManager.info('Info message');
            //     break;
            // case 'success':
            //     NotificationManager.success('Success message', 'Title here');
            //     break;
            // case 'warning':
            //     NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
            //     break;
            case 'error':
                if (message !== 'login_required')
                    NotificationManager.error(message);
                // NotificationManager.error(message, '', 5000, () => {
                //     alert('callback');
                // });
                break;
            default:
                NotificationManager.success(message);
        }
        if (type !== 'info')
            setTimeout(function (){
                store.dispatch(clearNotificationMessage());
            }, DELAY_BEFORE_REDUX_ACTION);

    }

    static showNotificationAndReload(message) {
        this.showNotification(message);
        setTimeout(() => URLUtils.reloadPage(), DELAY_BEFORE_RELOAD_PAGE_AFTER_NOTIFICATION);
    }

    // https://lodash.com/docs/4.17.4   find  _.get
    static safeget(obj, keyList, defaultVal) {
        var defaultValue = defaultVal ? defaultVal : null;
        return _lodash.get(obj, keyList, defaultValue);
    }

    static isEmptyObject(obj) {
        return _lodash.isEmpty(obj);
    }

    // https://github.com/kolodny/immutability-helper
    //static updateObject(obj, updatedDataDict) {
    //    return update(obj, updatedDataDict); // obj will not be changed
    //}
    static updateObject(obj, dict) {
        let data = {};
        for (let key in dict)
            data[key] = {$set: dict[key]};
        return update(obj, data); // obj will not be changed
    }

    static cloneObject(obj) {
        //return Object.assign({}, obj);
        return JSON.parse(JSON.stringify(obj));
    }

    static findElementInListByKey(lst, key, val) {
        let cond = {};
        cond[key] = val;
        return _lodash.find(lst, cond);
    }

    static removeElementFromArray(lst, element) {
        return _lodash.remove(lst, (ele) => {
            return ele !== element;
        })
    }

    static getClientBrowerTimezone() {
        var offset = new Date().getTimezoneOffset();
        offset = ((offset<0? '+':'-')+ // Note the reversed sign!
            parseInt(Math.abs(offset/60)));
        return offset
    }

    static getClientBrowerLocale() {
        // en-US
        const currentLocale = (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
        return currentLocale.split('-')[0];
    }




    // phai de o ComponentDidMount
    static scrollToObject(objectId) {
        // goToAnchor(objectId, true);
        scroller.scrollTo(objectId, {
            duration: 800,
            delay: 0,
            // offset: 300,
            smooth: 'easeInOutQuart'
        });
        // scrollToComponent(objectId, {
        //     offset: 1000,
        //     align: 'top',
        //     duration: 1500
        // });
    }


    static commonCallbackError(component, error) {
        component.setState({isProcessing: false});
        if (error.message === ERROR_CODE_FROM_BACKEND.LOGIN_REQUIRED)
            component.setState({redirectToLogin: true});
        if (error.message === ERROR_CODE_FROM_BACKEND.FORCE_LOGOUT)
            URLUtils.userLogout(component.props);
        else
            this.showErrorMessage(error.message);
    }


    static isMobileView() {
        const currentWidth = window.innerWidth;
        // let isMobileView = false;
        // if (currentWidth < BREAKING_WIDTH_FOR_RESPONSIVE) // small devices
        //     isMobileView = true;
        // return isMobileView;
        return (currentWidth < BREAKING_WIDTH_FOR_RESPONSIVE);
    }


    static handleEnterKeyPress(e, callback) {
        const KEY_ENTER = 13;
        if (e.charCode === KEY_ENTER) {
            callback();
        }
    };


    static serializeForm(formIdSelector) {
        const form = document.querySelector(formIdSelector);
        return formSerialize(form, { hash: true });
    };


    static changePrimaryLanguage(langCode, IS_CANCEL_RELOAD) {
        MiscUtils.setToLocalStorage(PRIMARY_LANGUAGE_CODE, langCode);
        i18n.changeLanguage(langCode);
        if (!IS_CANCEL_RELOAD)
            setTimeout(() => URLUtils.reloadPage(), DELAY_BEFORE_RELOAD_PAGE_AFTER_CHANGE_LANGUAGE);
    };


    static getDefaultLanguages() {
        const langCodes = [MiscUtils.getClientBrowerLocale(), 'en', 'cn', 'ja', 'kr'];
        let arr = [];
        langCodes.forEach((lang) => {
            if (arr.indexOf(lang) < 0)
                arr.push(lang);
        });
        return arr;
    };


    static updateBreadcumbs() {
        // da xu ly trong ArticleView
        if (URLUtils.isViewURL())
            return;
        //  /qa  ->  ['', 'qa']
        const lst = URLUtils.getCurrentPathname().split('/');
        const board = lst[1];
        const boardName = getBoardNameFromCode(board);
        let subBoard = '';
        let subBoardName = '';

        if (board) {
            if (lst.length >= 3){
                subBoard = lst[2];
                if (subBoard === 'write')
                    subBoardName = (board === BOARD_NAME_QA) ? i18n.t('Ask') : i18n.t('Write');
                // else if ((board === BOARD_NAME_NOTICE) && (['notice', ''].indexOf(subBoard) >= 0)) {
                else if ((subBoard === '') || ((board === BOARD_NAME_NOTICE) && (subBoard === 'notice'))){
                    subBoardName = 'null';
                } else
                    subBoardName = getSubBoardNameFromCode(lst[1], subBoard);
            } else {  //   /qa   /community  /notice   /ranking
                switch (board) {
                    case BOARD_NAME_NOTICE:
                        subBoardName = 'null';
                        break;
                    case BOARD_NAME_QA:
                    case BOARD_NAME_COMMUNITY:
                    case BOARD_NAME_RANK:
                        subBoardName = BUTTONS.LEFT_BUTTONS[board][0][0];
                        break;
                    default:
                        subBoardName = '';
                }
            }
        }

        if (boardName && subBoardName) {
            let breadcums = [
                {
                    text: boardName,
                    url: '/'+ board
                }
            ];
            if (subBoardName !== 'null')
                breadcums.push({
                    text: subBoardName,
                    url: '#'
                });
            store.dispatch(setBreadcumbs(breadcums)
            )
        } else {
            store.dispatch(clearBreadcumbs());
        }
    };


    static containsNonLatinCodepoints(s) {
        return /[^\u0000-\u0fff]/.test(s);
    }

    static isBlockedInTagText(char) {
        const regex = new RegExp("^[a-zA-Z0-9\-\b \.\(\)]"); //allowed keys
        return (!regex.test(char) && !this.containsNonLatinCodepoints(char));
    }


    static initElementArticleContent() {
        //click image in Viewing to see actual size : khong de o base.html duoc
        $('.content img').click(function() {
            //$('#show_actual_img').attr('src',$(this).attr('src')).bPopup();
            const t = $(this);
            if(t.parent()[0].tagName !== 'A')
                window.open(t.attr('src'));
        });

        //click URL in content will be opened in new tab
        $('.content a').attr('target','_newtab');
    }




    static setToCookie(key, value, expiredInUTCString) {
        let expires = "";
        if (!expiredInUTCString) {
            const expiredInDays = 1;
            let date = new Date();
            date.setTime(date.getTime() + expiredInDays * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toUTCString();
        } else
            expires = "; expires=" + expiredInUTCString;
        document.cookie = key + "=" + (value || "")  + expires + "; path=/";
    }


    static getFromCookie(key, defaultVal) {
        let nameEQ = key + "=";
        let ca = document.cookie.split(';');
        for(let i=0;i < ca.length;i++) {
            let c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return defaultVal;
    }


    static clearCookie(key) {
        // document.cookie = key+'=; Max-Age=-99999999;';
        this.setToCookie(key, null, '1970-01-00T00:00:00.000Z')
    }


    static updateLastActivityForUnauthenticatedUser() {
        const last_activity = this.getFromCookie(UNAUTH_USER_LAST_ACTIVITY);
        if (!last_activity) {
            const callbackSuccess = () => {
            };
            const callbackError = () => {
            };
            ApiUser.updateLastActivityForUnauthUser(callbackSuccess, callbackError);
        }

        let expiredInUTC = new Date();
        expiredInUTC.setTime(expiredInUTC.getTime() + INTERVAL_FOR_COUNT_AS_ONE_VISIT*24*3600*1000);
        this.setToCookie(UNAUTH_USER_LAST_ACTIVITY, new Date(), expiredInUTC);

    }


    static stripDoubleQuoteBeginAndEnd(sourceTxt) {
        return sourceTxt.replace(/^"+|"+$/g, '');
    }

}