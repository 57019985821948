import React from 'react';
import DatePicker from 'react-datepicker';
import i18n from '../../../locales/i18n';
import { DateUtils } from '../../../extras';

export default class DatetimePicker extends React.Component {

    render (){
        const { selectedDate, handleChange, timeFormat, dateFormat } = this.props;
        return (
            <DatePicker
                selected={selectedDate ? selectedDate : DateUtils.nowLegacyDate()}
                onChange={handleChange}
                showTimeSelect
                timeFormat={timeFormat ? timeFormat : "HH:mm"}
                timeIntervals={15}
                timeCaption={i18n.t("Time")}
                dateFormat={dateFormat ? dateFormat : "YYYY-MM-DD HH:mm:ss" } //"MMMM d, yyyy h:mm aa"  December 25, 2018 2:18 PM

                showMonthDropdown
                showYearDropdown

                // withPortal


                // dateFormat="YYYY-MM-DD"
                // selected={user.birthdate}
                // onChange={this.selectBirthdate}
                // maxDate={DateUtils.nowDate()}
                // withPortal
                // inline
                // showMonthDropdown
                // showYearDropdown
                //
            />
        );
    }
}
