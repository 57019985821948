import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import {UserAvatarInfo, HTMLContent, DropDownMenu, PopupConfirm} from '../element';
import CommentWrite from './CommentWrite';
import { BOARD_NAME_QA, BOARD_NAME_COMMUNITY, ARTICLE_TYPE, MESSAGE, DELAY_BEFORE_SCROLL_TO_HIGHLIGHTED_RESPONSE } from '../../../config';
import { MiscUtils } from '../../../extras';
import { ApiArticle } from '../../../api';
import { translate } from "react-i18next";


class Comment extends React.Component {

    state = {
        isShowDeleteConfirm: false,
        isDeleted: false,
        comment: this.props.comment,
        isEditing: false
    };


    replyComment = () => {
        this.props.replyToCommentCallback(this.props.comment);
    };


    deleteArticle = () => {
        const { comment, boardType, parentType } = this.props;
        let article_type = ARTICLE_TYPE.COMMENT_TO_Q;
        if (parentType === ARTICLE_TYPE.RESPONSE){
            if (boardType === BOARD_NAME_QA)
                article_type = ARTICLE_TYPE.COMMENT_TO_A;
            else if (boardType === BOARD_NAME_COMMUNITY)
                article_type = ARTICLE_TYPE.COMMENT_TO_R;
        } else if (parentType === ARTICLE_TYPE.FREE_TALK)
            article_type = ARTICLE_TYPE.COMMENT_TO_FT;
        const data = {
            article_id: comment.id,
            article_type: article_type
        };
        const callbackSuccess = (data) => {
            MiscUtils.showNotification(MESSAGE.DELETE_SUCCESS);
            this.toggleShowDeleteConfirm();
            this.setState({isDeleted: true});
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        ApiArticle.deleteArticle(callbackSuccess, callbackError, data);
    };


    toggleShowDeleteConfirm= () => {
        this.setState({isShowDeleteConfirm: !this.state.isShowDeleteConfirm});
    }

    generateCommentId = () => {
        const {comment, parentType, parentId} = this.props;
        let commentId = 'comment_question_' + comment.id; // parentType == ARTICLE_TYPE.ARTICLE
        if (parentType === ARTICLE_TYPE.RESPONSE)
            commentId = 'comments-of-'+parentId+'-'+comment.id;
        return commentId;
    };


    editArticle = () => {
        this.setState({isEditing: true});
    };


    cancelEditArticle = () => {
        this.setState({isEditing: false});
    };


    saveEditingArticleSuccessCallback = (data) => {
        this.setState({isEditing: false, comment: data});
    };


    componentDidMount() {
        const { highlightedCommentId } = this.props;
        if (highlightedCommentId && (this.generateCommentId() === highlightedCommentId)) {
            setTimeout(() => {
                MiscUtils.scrollToObject(highlightedCommentId);
            }, DELAY_BEFORE_SCROLL_TO_HIGHLIGHTED_RESPONSE);
        }
    }


    render (){
        const { currentUser, highlightedCommentId, t } = this.props;
        const { isDeleted, comment, isEditing, isShowDeleteConfirm } = this.state;

        const articleAction = (
            <div className="tooltipActionButton">
                {
                    (currentUser.user_id === comment.user.user_id) &&
                    <p onClick={this.editArticle}>{t('Edit')}</p>
                }
                <p onClick={this.toggleShowDeleteConfirm}>{t('Delete')}</p>
            </div>
        );

        const commentId = this.generateCommentId();

        return (
            <div >
                {
                    !isDeleted &&
                    <div className={classnames('commentView', {"highlighted": highlightedCommentId === commentId})}
                         id={commentId}>

                        {
                            !isEditing &&
                            <div>
                                <div className="row1">
                                    <UserAvatarInfo user={comment.user}/>
                                    <div className="rightInfo">
                                        <label>{comment.modified_date}</label>
                                    </div>
                                </div>
                                <div className="content dontBreakOut">
                                    <HTMLContent content={comment.content}/>
                                </div>

                                <div>
                                    {
                                        (currentUser.user_id !== comment.user.user_id) &&
                                        <button type="button" className="btn outlineBtn backgroundColorInherit" onClick={this.replyComment}>
                                            <FontAwesome name="reply" className="blankHyperlink" onClick={this.replyComment}
                                                         data-tip data-for={'replyToComment_'+comment.id} />&nbsp;
                                            {
                                                t('Reply')
                                            }
                                        </button>
                                    }


                                    {
                                        (currentUser.is_admin || (currentUser.user_id === comment.user.user_id)) &&
                                        <DropDownMenu htmlContent={articleAction} customClassName="pull-right">
                                            <Glyphicon glyph="option-horizontal"/>
                                        </DropDownMenu>
                                    }

                                </div>
                            </div>
                        }
                        {
                            isEditing &&
                            <CommentWrite {...this.props} editedComment={comment}
                                          addCommentSuccessCallback={this.saveEditingArticleSuccessCallback}
                                          cancelEditArticle={this.cancelEditArticle}
                            />
                        }



                        <hr/>
                    </div>
                }

                <PopupConfirm bodyText={t("Are you sure to delete?")} onConfirm={this.deleteArticle}
                              showModal={isShowDeleteConfirm} toggleShowModal={this.toggleShowDeleteConfirm}/>

            </div>
        )
    }
}

export default translate()(Comment);