import React from 'react';
import classnames from 'classnames';
import { MiscUtils } from '../../../extras';
import Comment from './Comment';
import {ARTICLE_TYPE, BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_FREETALK, CONTENT_BLOCKED_MESSAGE} from '../../../config';

import CommentWrite from './CommentWrite';
import CommentWriteInMobile from "./CommentWriteInMobile";


// import { TableSorterTransparent } from '../common';
// import { MiscUtils, URLUtils } from '../../extras';
// import Response from './Response';
// import { BOARD_NAME_QA, BOARD_NAME_COMMUNITY } from '../../config';

export default class Comments extends React.Component {

    state = {
        repliedComment: null
    };


    replyToCommentCallback = (comment) => {
        this.setState({repliedComment: comment});
    };


    addCommentSuccessCallback = (comment) => {
        this.setState({repliedComment: null});
        this.props.addCommentSuccessCallback(comment);
    };


    render (){
        const { isShowCommentList, comments, isBlocked, article, response, parentType, boardType, t } = this.props;
        const { repliedComment } = this.state;
        const isMobileView = MiscUtils.isMobileView();


        let placeholder = '';
        if (parentType === ARTICLE_TYPE.ARTICLE) {
            placeholder = t("Comment to {{author}}'s question here", { author: article.user.username });
        } else {  // response or Free Talk
            if (boardType === BOARD_NAME_QA)
                placeholder = t("Comment to {{author}}'s answer here", { author: response.user.username });
            else if(boardType === BOARD_NAME_COMMUNITY)
                placeholder = t("Comment to {{author}}'s reply here", { author: response.user.username });
            else if(boardType === BOARD_NAME_FREETALK)
                placeholder = t("Comment to {{author}}'s talk here", { author: response.user.username });
            else
                placeholder = t("Leave your thoughts here");
        }

        return (
            <div className={classnames("commentsList", {"shown": isShowCommentList}, {"hidden": !isShowCommentList})}>
                <div className="topArrow"></div>
                {
                    (comments.length > 0) &&
                    <div className="comments">
                        {
                            comments.map((comment) =>
                                <Comment comment={comment} {...this.props} key={MiscUtils.generateId()} replyToCommentCallback={this.replyToCommentCallback}/>
                            )
                        }
                    </div>
                }

                {
                    isBlocked &&
                    <div className='errorText alignCenter'>{CONTENT_BLOCKED_MESSAGE}</div>
                }

                {
                    !isBlocked &&
                    <div>
                        {!isMobileView && <CommentWrite {...this.props} repliedComment={repliedComment} addCommentSuccessCallback={this.addCommentSuccessCallback}
                                                        placeholder={placeholder}/>}
                        {isMobileView && <CommentWriteInMobile {...this.props} addCommentSuccessCallback={this.addCommentSuccessCallback}
                                                               placeholder={placeholder}/>}
                    </div>

                }
            </div>
        )
    }
}