import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import LanguageSelectionModal from './LanguageSelectionModal';
import { MAX_LANGUAGE_FILTERING, getLanguageTextFromCode } from "../../../config/common";
import { MiscUtils, SELECTED_LANGUAGES } from '../../../extras';
import { translate } from "react-i18next";


class LanguageFilteringButton extends React.Component {

    state = {
        showPopup: false,
        selectedLanguages: this.props.selectedLanguages
    };

    togglePopup = () => {
        this.setState({showPopup: !this.state.showPopup});
    };


    saveSelectLanguagesCallback = (selectedLanguages) => {
        this.setState({selectedLanguages}, () => {
            // khac voi LanguageUserSettingButton.js
            // de o day chu ko de upper parent la vi xai o nhieu View
            MiscUtils.setToLocalStorage(SELECTED_LANGUAGES, selectedLanguages);
            const { saveSelectLanguagesCallback } = this.props;
            if (saveSelectLanguagesCallback)
                saveSelectLanguagesCallback(selectedLanguages);
        });
    };


    render (){
        const { showPopup, selectedLanguages } = this.state;
        const { t } = this.props;
        return (
            <LanguageSelectionModal showPopup={showPopup} selectedLanguages={selectedLanguages} isMultiple={true}
                                    maxSelected={MAX_LANGUAGE_FILTERING}
                                    togglePopup={this.togglePopup}
                                    saveSelectLanguagesCallback={this.saveSelectLanguagesCallback} >

                <button type="button" className="btn" onClick={this.togglePopup}>
                    {t('Select language')} &nbsp;<Glyphicon glyph="globe" />
                </button>
                {
                    selectedLanguages.map(
                        (langCode) =>
                            (getLanguageTextFromCode(langCode) ? <label key={MiscUtils.generateId()}>{getLanguageTextFromCode(langCode)}</label> : '')

                    )
                }

            </LanguageSelectionModal>
        )
    }
}

export default translate()(LanguageFilteringButton);