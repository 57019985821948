import { GET_ARTICLES__FINISHED, GET_ARTICLES__PROCESSING,
    GET_POPULAR_ARTICLES__PROCESSING, GET_POPULAR_ARTICLES__FINISHED, SAVE_ARTICLE__FINISHED, SAVE_ARTICLE__PROCESSING } from './types';
import { DELAY_BEFORE_REDUX_ACTION } from '../../config';
import { actionInProgress, actionFinished } from './actionCommon';
import { ApiHttp, ApiArticle } from '../../api';




const saveArticle = (data, callback) => {
    return dispatch => {
        dispatch(actionInProgress(SAVE_ARTICLE__PROCESSING));
        setTimeout(() => {
            const callbackSuccess = (res) => {
                dispatch(actionFinished(SAVE_ARTICLE__FINISHED, null));
                callback(res.redirect_url);
            };
            const callbackError = (error) => {
                dispatch(actionFinished(SAVE_ARTICLE__FINISHED, error.error_msg));
            };
            ApiArticle.saveArticle(callbackSuccess, callbackError, data);
        }, DELAY_BEFORE_REDUX_ACTION);
    };
};


const getArticles = (url, callback) => {
    return dispatch => {
        dispatch(actionInProgress(GET_ARTICLES__PROCESSING));
        setTimeout(() => {
            const callbackSuccess = (res) => {
                dispatch(actionFinished(GET_ARTICLES__FINISHED, null));
                callback(res);
            };
            const callbackError = (error) => {
                dispatch(actionFinished(GET_ARTICLES__FINISHED, error.message));
            };
            ApiHttp.get(url, callbackSuccess, callbackError);
        }, DELAY_BEFORE_REDUX_ACTION);

    };
};


const getPopularArticles = (url, callback) => {
    return dispatch => {
        dispatch(actionInProgress(GET_POPULAR_ARTICLES__PROCESSING));
        setTimeout(() => {
            const callbackSuccess = (res) => {
                dispatch(actionFinished(GET_POPULAR_ARTICLES__FINISHED, null));
                callback(res.articles);
            };
            const callbackError = (error) => {
                dispatch(actionFinished(GET_POPULAR_ARTICLES__FINISHED, error.message));
            };
            ApiHttp.get(url, callbackSuccess, callbackError);
        }, DELAY_BEFORE_REDUX_ACTION);
    };
};


export { getArticles, getPopularArticles, saveArticle };