import { NOTIFICATION_GET_ALL, NOTIFICATION_GET_ALL__PROCESSING, NOTIFICATION_GET_ALL__FINISHED,
    NOTIFICATION_GET_UNREAD_COUNT, NOTIFICATION_GET_UNREAD_COUNT__FINISHED, NOTIFICATION_GET_UNREAD_COUNT__PROCESSING } from '../actions/types'
import { MiscUtils } from '../../extras';

const initialState = {
    notifications: [],
    isProcessing: false,

    unreadCount: 0,
    isUnreadProcessing: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type){
        case NOTIFICATION_GET_ALL:
            return MiscUtils.updateObject(state, {notifications: action.data});
        case NOTIFICATION_GET_ALL__PROCESSING:
            return MiscUtils.updateObject(state, {isProcessing: true});
        case NOTIFICATION_GET_ALL__FINISHED:
            return MiscUtils.updateObject(state, {isProcessing: false});

        case NOTIFICATION_GET_UNREAD_COUNT:
            return MiscUtils.updateObject(state, {unreadCount: action.data});
        case NOTIFICATION_GET_UNREAD_COUNT__PROCESSING:
            return MiscUtils.updateObject(state, {isUnreadProcessing: true});
        case NOTIFICATION_GET_UNREAD_COUNT__FINISHED:
            return MiscUtils.updateObject(state, {isUnreadProcessing: false});

        default:
            return state;
    }
}