import { URLUtils, URL_MAPPING } from '../extras';
import ApiHttp from './http';

export default class ApiAuth {

    static login = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.auth.login);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static signUp = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.auth.signUp);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static signUpResend = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.auth.signUpResend);
        ApiHttp.get(url, callbackSuccess, callbackError, data);
    };

    static signUpConfirm = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.auth.signUpConfirm);
        ApiHttp.get(url, callbackSuccess, callbackError, data);
    };

    static forgotPassword = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.auth.forgotPassword);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static forgotPasswordCheckCode = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.auth.forgotPasswordCheckCode);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static forgotPasswordSubmit = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.auth.forgotPasswordSubmit);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static connectSNS = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.auth.connectSNS);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static disconnectSNS = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.auth.disconnectSNS);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

}