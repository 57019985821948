import React from 'react';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import { UserAvatarInfo, TagList, } from '../element';
import { CLASS_HIDDEN_IN_MOBILE, CLASS_HIDDEN_IN_PC } from '../../../config';
import { translate } from "react-i18next";


class TableContentResponseElement extends React.Component {

    render() {
        const { response, t } = this.props;
        return (
            <div className="dataRow">

                <div className="row">
                    <div className="col-md-10">
                        <div>
                            <strong className="articleTitle">
                                <a href={response.article_view_url + response.anchor}>{response.article_title}</a>
                            </strong>
                        </div>

                        <div>
                            {response.response_content}
                        </div>
                    </div>
                    <div className="col-md-2">
                        <img src={response.article_thumbnail} alt="" className="img-rounded img-responsive"/>
                    </div>
                </div>

                <div className={"alignRight"+CLASS_HIDDEN_IN_PC}>{response.last_modified}</div>

                <div className="articleInfo marginTopMedium">

                    <div className={"paddingNone col-md-4"+CLASS_HIDDEN_IN_MOBILE}>
                        <label>{response.last_modified}</label>
                    </div>

                </div>

            </div>

        );
    }
}

export default translate()(TableContentResponseElement);