// to backend URL

export const URL_MAPPING = {

    //backend
    auth: {
        login: '/auth/sign_in',
        signUp: '/auth/sign_up',
        signUpResend: '/auth/sign_up_resend',
        signUpConfirm: '/auth/sign_up_confirm',
        forgotPassword: '/auth/forgot_password',
        forgotPasswordCheckCode: '/auth/forgot_password_check_code',
        forgotPasswordSubmit: '/auth/forgot_password_submit',
        connectSNS: '/auth/connect_sns',
        disconnectSNS: '/auth/disconnect_sns'
    },
    user: {
        getProfile: '/user/getProfile',
        getProfileForSetting: '/user/getProfileForSetting',
        saveSettingProfile: '/user/saveSettingProfile',
        saveGetInfo: '/user/saveGetInfo',
        settingNotification: '/user/settingNotification',
        getNameCard: '/user/getNameCard',
        deleteUser: '/user/deleteUser',
        sendFeedback: '/user/sendFeedback',
        changePassword: '/user/changePassword',
        changeEmail: '/user/changeEmail',
        changeEmailConfirm: '/user/changeEmailConfirm',
        saveLanguagesWhenWriting: '/user/saveLanguagesWhenWriting',
        getUserStatistic: '/user/getUserStatistic',
        getUserStatisticDetail: '/user/getUserStatisticDetail',
        updateLastActivityForUnauthUser: '/user/updateLastActivityForUnauthUser'
    },

    dashboard: {
        getReputation: '/dashboard/getReputation',
        getNotification: '/dashboard/getNotification',
        getNotificationInMenubar: '/dashboard/getNotificationInMenubar',
        getUnreadNotificationCountInMenubar: '/dashboard/getUnreadNotificationCountInMenubar',
        getBookmark: '/dashboard/getBookmark'
    },

    article: {
        getSettings: '/article/getSettings',
        getArticles: '/article/getArticles',
        getPopularArticles: '/article/getPopularArticles',
        saveArticle: '/article/saveArticle',
        voteArticle: '/article/voteArticle',
        voteDownArticle: '/article/voteDownArticle',
        bookmarkArticle: '/article/bookmarkArticle',
        deleteArticle: '/article/deleteArticle',
        blockArticle: '/article/blockArticle',
        reportArticle: '/article/reportArticle',
        getArticle: '/article/getArticle/:boardType/:articleId/',
        getArticleForEdit: '/article/getArticleForEdit/:boardType/:articleId/',
        getRecentResponses: '/article/getRecentResponses',
        getSortedResponses: '/article/getSortedResponses',
        saveResponse: '/article/saveResponse',
        saveComment: '/article/saveComment',
        search: '/article/search',
        getRelatedArticles: '/article/getRelatedArticles',
        validateThumbnailURL: '/article/validateThumbnailURL',
        setAcceptedResponse: '/article/setAcceptedResponse',
        attendEvent: '/article/attendEvent',
        cancelEvent: '/article/cancelEvent',
        shareArticle: '/article/shareArticle',
        changeLanguageWhenViewing: '/article/changeLanguageWhenViewing',
        updateNewsletterTracking: '/article/updateNewsletterTracking',
        reactArticle: '/article/reactArticle'
    },
    tag: {
        getTagsForSuggestion: '/tag/getTagsForSuggestion',
        getTagRanking: '/tag/getTagRanking',
        saveTagEdit: '/tag/saveTagEdit'
    },


    ranking: {
        people: '/ranking/people',
        articleAndResponse: '/ranking/articleAndResponse'
    },

    freeTalk: {
        saveTalk: '/freeTalk/saveTalk',
        getTalks: '/freeTalk/getTalks',
        deleteTalk: '/freeTalk/deleteTalk',
        saveComment: '/freeTalk/saveComment'
    },

    upload: 'upload_image',

    // frontend
    error: {
        notFound: '/error/notFound',
        forbidden: '/error/forbidden'
    }
};
