import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TableSorterTransparentWithLink } from '../common';
import ReputationView from './ReputationView';
import NotificationView from './NotificationView';
import BookmarkView from './BookmarkView';
import { URLUtils } from '../../extras';
import { translate } from "react-i18next";


class DashboardView extends React.Component {

    render() {
        const { path } = this.props.match;
        const { t } = this.props;
        const sortButtons = [
            {
                text: t('Reputation'),
                tab: 'reputation',
                url: path + '/reputation'
            },
            {
                text: t('Notification'),
                tab: 'notification',
                url: path + '/notification'
            },
            {
                text: t('Bookmark'),
                tab: 'bookmark',
                url: path + '/bookmark'
            }
        ];

        const arr = URLUtils.getCurrentURL(this).split('/');
        const selectedTab = ((arr.length === 3) && (arr[arr.length - 1] !== '')) ? arr[arr.length - 1] : 'reputation'; // default is reputation, else  /dashboard/notification

        return (
            <div className="col-md-9">
                <div className="row centerContent">
                    <h3 className="contentHeader">{t('Dashboard')}</h3>
                    <TableSorterTransparentWithLink sortButtons={sortButtons} selectedTab={selectedTab} />
                    <div id="dashboardView">
                        <Switch>
                            <Route exact path={path+"/notification"} component={ NotificationView } />
                            <Route exact path={path+"/bookmark"} component={ BookmarkView } />
                            <Route path={path} component={ ReputationView } />
                        </Switch>
                    </div>

                </div>
            </div>
        );
    }
}

export default translate()(DashboardView);