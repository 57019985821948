import React from 'react';
import { MiscUtils } from '../../extras';
import { TablePager } from '../common';
import { MESSAGE } from '../../config';

export default class ProfileStatsSpamView extends React.Component {

    render() {
        const { data, isProcessing, onPagerChange, boardType } = this.props;
        const spams = data.rows;
        const { currpage, totalpages } = data;
        return (
            <div id="profileStatsSpamView">

                {   !isProcessing && spams && spams.length > 0 &&
                        <div>

                            {
                                spams.map((spam) =>
                                    <div className="spam" key={MiscUtils.generateId()}>
                                        <span className="levelBadge"> {spam.reason} </span>
                                        <span className="numStatsCount"> {spam.category}</span>
                                        <span className="pull-right">{spam.date}</span>
                                    </div>
                                )
                            }

                            <TablePager page={parseInt(currpage)} total={parseInt(totalpages)} onPagerChange={onPagerChange}/>
                        </div>
                }

                { !isProcessing && spams && spams.length === 0 &&
                    <div>
                        <label>{MESSAGE.NO_SPAM}</label>
                    </div>
                }

            </div>
        );
    }
}