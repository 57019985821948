import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { MiscUtils } from '../../../extras';
import { getBoardNameFromCode, getSubBoardNameFromCode } from '../../../config';


class Breadcrumb extends React.Component {
    render (){
        const { breadcumbs, article } = this.props;
        let breadcumbsList = breadcumbs;
        if (article) {
            const { article_type, article_post_type_code } = article;
            breadcumbsList = [
                {
                    text: getBoardNameFromCode(article_type),
                    url: '/'+ article_type
                },
                {
                    text: getSubBoardNameFromCode(article_type, article_post_type_code),
                    url: '/'+ article_type + '/' + article_post_type_code
                }
            ];

            if (!breadcumbsList[1].text)
                breadcumbsList = [breadcumbsList[0]];
        }

        return (
            <div id="breadcrumbs">
                {
                    breadcumbsList &&
                    <ul className="breadcrumb">
                        {
                            breadcumbsList.map((breadcumb, idx) =>
                                <li key={ MiscUtils.generateId() } className={classnames({'active' : idx === (breadcumbsList.length-1) })}>
                                    <a href={breadcumb.url} >{ breadcumb.text }</a>
                                </li>
                            )
                        }
                    </ul>
                }
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        breadcumbs: state.reducerGlobal.breadcumbs
    }
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Breadcrumb));