import React from 'react';
import { MiscUtils } from '../../../extras';
import { ApiArticle } from '../../../api';
import { BlockingComponent } from '../element';
import { translate } from "react-i18next";


class RelatedArticles extends React.Component {

    state = {
        boardType: this.props.match.params.boardType,
        articleId: this.props.match.params.articleId,
        articles: [],
        isProcessing: true
    };


    componentDidMount (){
        let { boardType, articleId } = this.state;
        articleId = articleId.split('-')[0];
        articleId = articleId.endsWith("/") ? articleId.substring(0, articleId.length - 1) : articleId;

        const data = {
            board_type: boardType,
            article_id: articleId
        };
        const callbackSuccess = (data) => {
            this.setState({articles: data.articles, isProcessing: false});
        };
        const callbackError = (error) => {
            this.setState({isProcessing: false});
            // MiscUtils.showErrorMessage(ERROR_MESSAGE.CANNOT_CONNECT_TO_SERVER);
        };
        ApiArticle.getRelatedArticles(callbackSuccess, callbackError, data);
    }


    render (){
        const { articles, isProcessing } = this.state;
        const { t } = this.props;

        return (
            <div id="mostRelatedArticles">
                <h4>{t('Most related')}</h4>
                <BlockingComponent isProcessing={isProcessing}>
                    {
                        articles.map((article) =>
                            <div key={MiscUtils.generateId()} className="line">
                                <a href={article.url}>
                                    {article.title}&nbsp;
                                    {
                                        (article.count > 0) &&
                                            <span className="levelBadge">{article.count}</span>
                                    }
                                </a>
                            </div>
                        )
                    }
                    {
                        (!isProcessing && articles.length === 0) && t('No result')
                    }
                </BlockingComponent>
            </div>
        )
    }
}

export default translate()(RelatedArticles);