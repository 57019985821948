import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import bglogin from '../../static/images/bglogin.jpg';
import logo from '../../static/images/logo.png';
import { INDEX_PAGE } from '../../RouteURL';
import ActivationConfirm from './ActivationConfirm';
import ChangeEmailConfirm from './ChangeEmailConfirm';
import GetInfoForm from './GetInfoForm';
import LoginView from './LoginView';
import ResetPassword from './ResetPassword';
import { MiscUtils, CURRENT_BOARD, requireLogin } from '../../extras';
import { translate } from "react-i18next";


class AccountView extends React.Component {

    render() {
        // for translate, pass it to the Child
        // const { t } = this.props;
        MiscUtils.setToLocalStorage(CURRENT_BOARD, '');
        const { path } = this.props.match;
        const pageStyle = {
            logo: {
                textAlign: 'center',
                padding: '20px'
            },
            logoImage: {
                display: 'inline'
            },
            page: {
                //backgroundImage: "url(" + Background + ")"
                backgroundImage: "url("+bglogin+")",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                height: '100%',
                // paddingTop: formMarginTop
            }
        };

        return (
            <div id="accountView" style={pageStyle.page}>
                <NotificationContainer/>
                <div className="loginForm">
                    <div className="row" style={pageStyle.logo}>
                        <a href={INDEX_PAGE}><img className="img-responsive" src={logo} style={pageStyle.logoImage} alt="Joinusworld"/></a>
                    </div>
                    <Switch>
                        <Route path={path+"/confirm"} render={(props) => (<ActivationConfirm {...this.props}/>)} />
                        <Route path={path+"/changeEmailConfirm"} render={(props) => (<ChangeEmailConfirm {...this.props}/>)} />
                        <Route path={path+"/resetPassword"} render={(props) => (<ResetPassword {...this.props}/>)} />
                        <Route path={path+"/get_info"} component={ requireLogin(GetInfoForm) } />
                        <Route path={path+"/get_info"} render={(props) => (<LoginView {...this.props}/>)} />

                        {/*<Route path={`${path}/signup`} component={ LoginView } />*/}
                        <Route path={path+"/signup"} render={(props) => (<LoginView {...this.props}/>)} />
                        <Route exact path={path+"/login"} render={(props) => (<LoginView {...this.props}/>)} />
                    </Switch>
                </div>

            </div>
        )
    }
}

export default translate()(AccountView);