import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Navbar, NavItem, Nav, Glyphicon } from 'react-bootstrap';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { getAllNotification, getUnreadNotificationCount, setAllUnreadCount } from '../../redux/actions';
import { store } from '../../redux/store';
import logo from '../../static/images/logo.png';
import isNew from '../../static/images/icon/new.png';
import qa from '../../static/images/icon/qa.png';
import qa_active from '../../static/images/icon/qa_active.png';
import community from '../../static/images/icon/community.png';
import community_active from '../../static/images/icon/community_active.png';
import crown from '../../static/images/icon/crown.png';
import crown_active from '../../static/images/icon/crown_active.png';
import free from '../../static/images/icon/free.png';
import free_active from '../../static/images/icon/free_active.png';
import { SIGN_IN, INDEX_PAGE, MAINPAGE_COMMUNITY, MAINPAGE_QA, MAINPAGE_FREETALK, RANKING__DEFAULT,
    DEFAULT_INDEX, DASHBOARD_NOTIFICATION, SETTING_NOTIFICATION, PROFILE, DASHBOARD, SETTING } from '../../RouteURL';
import {MiscUtils, URLUtils, CURRENT_BOARD, ACCESS_TOKEN, PRIMARY_LANGUAGE_CODE} from '../../extras';
import { LANGUAGES } from "../../config";
import { DropDownMenu, LoadingSpinnerInline, NavigationBarSearchButton,ScrollContent, SideMenu } from './element';
import { BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_NOTICE, BOARD_NAME_RANK, BOARD_NAME_FREETALK, CLASS_HIDDEN_IN_MOBILE,
    CLASS_HIDDEN_IN_PC, NOTIFICATION_PULL_TIME } from '../../config';
import { getLanguageTextFromCode } from '../../config/common';
import { translate } from "react-i18next";
import NavigationBarInMobile from './NavigationBarInMobile';
import { Link } from "react-router-dom";

// raw html: https://codepen.io/zhaozhiming/pen/LNGyvR

const overrideStyle = {
    color: 'inherit',
    cursor: 'pointer',
    textDecoration: 'inherit'
};

class NavigationBar extends React.Component {


    state = {
        unreadNotificationCount: 0,
        currentShownLanguage: ''
    };


    userLogout = () => {
        URLUtils.userLogout(this.props);
    };


    getAllNotification = () => {
        // reset count of Unread, in server side, also remove all Unread count when getting Notifications
        const { setAllUnreadCount, getAllNotification } = this.props;
        setAllUnreadCount(0);
        getAllNotification();
    };


    componentDidMount = () => {
        const { getUnreadNotificationCount } = this.props;
        const token = MiscUtils.getFromLocalStorage(ACCESS_TOKEN);
        if (token) { // authenticated
            getUnreadNotificationCount();
            setInterval(() => getUnreadNotificationCount(), NOTIFICATION_PULL_TIME);
        } else {
            const currentLanguage = MiscUtils.getFromLocalStorage(PRIMARY_LANGUAGE_CODE, 'en');
            this.setState({currentShownLanguage: getLanguageTextFromCode(currentLanguage)})
        }
    };


    changeLanguage = (langCode) => {
        MiscUtils.changePrimaryLanguage(langCode);
    };


    render() {

        const { isAuthenticated, user, getNotificationIsProcessing, notifications, unreadCount, t } = this.props;
        const { currentShownLanguage } = this.state;

        // if (this.state.isLogout)
        //     return <Redirect to={DEFAULT_INDEX} />;
        const currentBoard = MiscUtils.getFromLocalStorage(CURRENT_BOARD);
        const isMobileView = MiscUtils.isMobileView();

        const noLanguages = LANGUAGES.length;
        const noColumn = 3;
        let arr = [];
        for (let idx=0; idx < (noLanguages/noColumn); idx++)
            arr.push(
                <tr key={MiscUtils.generateId()}>
                    <td onClick={() => this.changeLanguage(LANGUAGES[idx*noColumn].code)}>{ (idx*noColumn < noLanguages) && LANGUAGES[idx*noColumn].text }</td>
                    <td onClick={() => this.changeLanguage(LANGUAGES[idx*noColumn+1].code)}>{ ((idx*noColumn+1) < noLanguages) && LANGUAGES[idx*noColumn + 1].text }</td>
                    <td onClick={() => this.changeLanguage(LANGUAGES[idx*noColumn+2].code)}>{ ((idx*noColumn+2) < noLanguages) && LANGUAGES[idx*noColumn + 2].text }</td>
                </tr>
            );
        const languageSelectionHTML = (
            <div id="languageSelectionInNavBar">
                <p className="header">{t('CHOOSE LANGUAGE')}</p>
                <table>
                    {arr}
                </table>
            </div>
        );



        const notificationListStyle = {
            bar: {
                width: '400px'
            },
            // scrollBar: {
            //     width: 400,
            //     height: 400
            // }
        };

        const notificationHTML = (
            <div id="notificationListInNav" style={notificationListStyle.bar}>
                {
                    getNotificationIsProcessing &&
                    <div className="loadingSpinnerInline">
                        <LoadingSpinnerInline />
                    </div>
                }
                {
                    !getNotificationIsProcessing &&
                    <div>
                        <div className="header">
                            <label className="pull-left">{t('Notifications')}</label>
                            <Link to={SETTING_NOTIFICATION} className="pull-right">{t('Setting')}</Link>
                            {/*<span className="pull-right">Mark all as read</span>*/}
                        </div>
                        <div className="content">
                            <ScrollContent customHeight={(notifications.length === 0) ? 'auto !important' : '400'}>
                                { notifications.length === 0 && <label>{t('There is no notification')}</label>}
                                {
                                    notifications.length>0 &&
                                    notifications.map((notification) =>
                                        <div className={classnames('row notificationRow', {'isNew': notification.is_new})} key={MiscUtils.generateId()}>
                                            <div className="col-md-2 columnNoPaddingRight">
                                                <a href={PROFILE + '?user_id=' + notification.user.user_id}>
                                                    <img src={notification.user.avatar}  className="img-circle" alt="Avatar"/>
                                                </a>
                                            </div>
                                            <a href={notification.view_url}>
                                                <div className="col-md-10">
                                                    <div className="upper">
                                                        <strong>{notification.user.username}</strong>&nbsp;
                                                        {notification.acticle_action}&nbsp;

                                                        {
                                                            (notification.article_affected_title === '') &&
                                                            <strong>{notification.acticle_type}</strong>
                                                        }
                                                        {
                                                            (notification.article_affected_title !== '') &&
                                                            <span>{notification.acticle_type}&nbsp;</span>
                                                        }



                                                        <strong>{notification.article_affected_title}</strong>
                                                        {
                                                            notification.is_new &&
                                                                <img src={isNew} className="isNew" alt="isNew"/>
                                                        }
                                                    </div>
                                                    <div>{notification.datetime}</div>
                                                </div>
                                            </a>
                                        </div>

                                    )
                                }
                            </ScrollContent>

                        </div>
                        <div className="footer">
                            <a href={DASHBOARD_NOTIFICATION}>{t('See All')}</a>
                        </div>
                    </div>
                }
            </div>
        );


        const loginUserMenuHTML = (
            <div className="dropdownMenu">
                <a href={SETTING}><Glyphicon glyph="cog" /> {t('Setting')}</a>
                <a href={DASHBOARD}><Glyphicon glyph="dashboard" />{t('Dashboard')}</a>
                <a href={PROFILE}><Glyphicon glyph="briefcase" />{t('Profile')}</a>
                <a className="blankHyperlink" onClick={this.userLogout}><Glyphicon glyph="log-out" />{t('Logout')}</a>
            </div>
        );



        const navbarInstance = (
            <nav className="navbar-fixed-top navbar navbar-default">
                <div className="container">
                    <div className="navbar-header">
                        <a href={isAuthenticated ? DEFAULT_INDEX : INDEX_PAGE} style={overrideStyle} className="navbar-brand">
                            <img className="img-responsive topLeftLogo" src={logo} alt="Joinusworld"/>
                        </a>
                    </div>
                    <div>
                        <ul className={"nav navbar-nav navbar-left"+CLASS_HIDDEN_IN_MOBILE}>
                            <li className={classnames("navbarSectionIcon", {'active':currentBoard === BOARD_NAME_QA})}>
                                <a href={MAINPAGE_QA} data-tip data-for='qaBtn'>
                                    <img src={(currentBoard === BOARD_NAME_QA) ? qa_active : qa} alt="QA"/>
                                </a>
                                <ReactTooltip id='qaBtn' className="tooltipForBtn" effect='solid'>
                                    {t('Question')} & {t('Answer')}
                                </ReactTooltip>
                            </li>
                            <li className={classnames("navbarSectionIcon", {'active':currentBoard === BOARD_NAME_COMMUNITY})}>
                                <a href={MAINPAGE_COMMUNITY} data-tip data-for='communityBtn'>
                                    <img src={(currentBoard === BOARD_NAME_COMMUNITY) ? community_active : community}  alt="Community"/>
                                </a>
                                <ReactTooltip id='communityBtn' className="tooltipForBtn" effect='solid'>
                                    {t('Community')}
                                </ReactTooltip>
                            </li>
                            <li className={classnames("navbarSectionIcon", {'active':currentBoard === BOARD_NAME_RANK})}>
                                <a href={RANKING__DEFAULT} data-tip data-for='rankingBtn'>
                                    <img src={(currentBoard === BOARD_NAME_RANK) ? crown_active : crown}  alt="Ranking"/>
                                </a>
                                <ReactTooltip id='rankingBtn' className="tooltipForBtn" effect='solid'>
                                    {t('Ranking')}
                                </ReactTooltip>
                            </li>
                            <li className={classnames("navbarSectionIcon paddingTop5", {'active':currentBoard === BOARD_NAME_FREETALK})}>
                                <a href={MAINPAGE_FREETALK} data-tip data-for='freeBtn' style={{'padding-bottom': '10px'}}>
                                    <img src={(currentBoard === BOARD_NAME_FREETALK) ? free_active : free}  alt="Free Talk" style={{width: '35px', height: '35px'}}/>
                                </a>
                                <ReactTooltip id='freeBtn' className="tooltipForBtn" effect='solid'>
                                    {t('Free Talk')}
                                </ReactTooltip>
                            </li>
                        </ul>
                        <ul className={"myNavbarRight nav navbar-nav navbar-right" + CLASS_HIDDEN_IN_MOBILE}>
                            {
                                !isAuthenticated
                                &&
                                <li role="presentation" className="spanLanguage">
                                    <a role="button">
                                        {/*<Glyphicon glyph="globe"/>*/}
                                        {/*&nbsp;Site language:&nbsp;*/}
                                        <DropDownMenu htmlContent={languageSelectionHTML}>
                                            <a style={overrideStyle}>{currentShownLanguage}&nbsp;<Glyphicon
                                                glyph="menu-down"/></a> {/* chevron-down */}
                                        </DropDownMenu>
                                    </a>
                                </li>
                            }
                            {
                                !isAuthenticated
                                &&
                                <li role="presentation" className="spanLanguage">
                                    <a role="button" href={SIGN_IN}>
                                        <Glyphicon glyph="user"/>&nbsp;{t('Login')} / {t('Signup')}
                                    </a>
                                </li>

                            }

                            {
                                isAuthenticated
                                &&
                                <li className="notificationCount" onMouseEnter={this.getAllNotification}
                                    onClick={this.getAllNotification}>
                                    <a role="button">
                                        <DropDownMenu htmlContent={notificationHTML} position="bottom-end">
                                            <Glyphicon glyph="bell"/>
                                            {
                                                (unreadCount > 0) &&
                                                <span className="badge">{unreadCount}</span>
                                            }
                                        </DropDownMenu>
                                    </a>
                                </li>

                            }

                            {
                                isAuthenticated
                                &&
                                <li role="presentation" className="avatar">
                                    <a role="button">
                                        <DropDownMenu htmlContent={loginUserMenuHTML}>
                                            <div className="block">
                                                <img src={user.avatar_url} alt="avatar"
                                                     className="img-responsive img-rounded"/>
                                                <label>{user.username}</label>
                                            </div>
                                        </DropDownMenu>
                                    </a>
                                </li>

                            }

                            <NavigationBarSearchButton/>
                        </ul>
                    </div>
                </div>
            </nav>
        );

        return (
            <div id="topNavBar">
                {
                    isMobileView && <NavigationBarInMobile {...this.props} userLogout={this.userLogout}/>
                }
                { !isMobileView && navbarInstance }


            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.reducerAuth.isAuthenticated,
        user: state.reducerAuth.user,
        getNotificationIsProcessing: state.reducerNotification.isProcessing,
        notifications: state.reducerNotification.notifications,
        unreadCount: state.reducerNotification.unreadCount
    }
};

const mapDispatchToProps = {
    // logout,
    getAllNotification,
    getUnreadNotificationCount,
    setAllUnreadCount
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translate()(NavigationBar)));
