import React from 'react';
import classnames from 'classnames';
import { MiscUtils } from '../../../extras';
import { MAX_DATATABLE_PAGER_LENGTH } from '../../../config/common';

export default class TablePager extends React.Component {

    state = {
        pageList: [],
        currentPage: this.props.page
    };


    renderPager = () => {
        const { currentPage } = this.state;
        const { total } = this.props;
        let lst = [];
        let size = currentPage+MAX_DATATABLE_PAGER_LENGTH-1;
        if (size > total)
            size = total;
        for (let idx=currentPage; idx <= size; idx++)
            lst.push(idx);
        this.setState({
            pageList: lst
        });
    };

    changePage = (page) => {
        this.setState({currentPage: page}, () => {
            // never exe because componentDidMount always exec
            if (page % MAX_DATATABLE_PAGER_LENGTH === 0) {
                this.renderPager();
            }
        });
        if (this.props.onPagerChange)
            this.props.onPagerChange(page);

    };


    next = () => {
        const { total } = this.props;
        const { currentPage } = this.state;
        if ( currentPage < total)
            this.changePage(currentPage+1);
    };


    previous = () => {
        const { currentPage } = this.state;
        if ( currentPage >= 2)
            this.changePage(currentPage-1);
    };


    first = () => {
        this.changePage(1);
    };


    last = () => {
        const { total } = this.props;
        this.changePage(total);
    };


    componentDidMount = () => {
        this.renderPager();
    };


    render() {
        const { pageList, currentPage } = this.state;
        const { total } = this.props;
        return (
            <div id="tablePager" className="row text-center">
                {
                    total > 1 &&
                    <nav aria-label="Page navigation">
                        <ul className="pagination">
                            {
                                currentPage > 1 &&
                                <li>
                                    {
                                        currentPage !== 1 &&
                                        <a aria-label="First" onClick={this.first}>
                                            <span aria-hidden="true">&lt;</span>
                                            <span aria-hidden="true">&lt;</span>
                                        </a>
                                    }

                                    <a aria-label="Previous" onClick={this.previous}>
                                        <span aria-hidden="true">&lt;</span>
                                    </a>
                                </li>
                            }

                            {
                                pageList.map((page) => (
                                    <li onClick={() => {this.changePage(page)}} key={MiscUtils.generateId()}>
                                        <a className={classnames({selectedPage: page === currentPage})}>{page}</a>
                                    </li>
                                ))
                            }

                            {
                                currentPage < total &&
                                <li>
                                    <a aria-label="Next" onClick={this.next}>
                                        <span aria-hidden="true">&gt;</span>
                                    </a>
                                    {
                                        currentPage !== total &&
                                        <a aria-label="Last" onClick={this.last}>
                                            <span aria-hidden="true">&gt;</span>
                                            <span aria-hidden="true">&gt;</span>
                                        </a>
                                    }

                                </li>
                            }
                        </ul>
                    </nav>
                }

            </div>
        );
    }
}