import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LeftBarTagRanking, LeftBarProfileInfo } from './element';

export default class LeftBarOptional extends React.Component {

    render() {
        return (
            <div id="leftBarOptional">
                <Switch>
                    <Route exact path="/notice(|/notice)" component={ LeftBarTagRanking }/>
                    <Route exact path="/notice/:articleId(\d+.+)" component={ LeftBarTagRanking }/>
                    <Route path="/(qa|community)" component={ LeftBarTagRanking }/>
                    <Route path="/profile" component={ LeftBarProfileInfo }/>
                </Switch>
            </div>
        )
    }
}