import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { MiscUtils } from '../../extras';
import { ApiDashboard } from '../../api';
import { BlockingComponent, TablePager } from '../common';
import { CLASS_HIDDEN_IN_MOBILE, CLASS_HIDDEN_IN_PC, MESSAGE } from '../../config';
import { translate } from "react-i18next";


class BookmarkView extends React.Component {

    state = {
        isProcessing: false,
        bookmarks: [],
        page: 1,
        totalPages: 0
    };


    loadBookmarks = () => {
        const { page } = this.state;
        const data = {
            page
        };
        const callbackSuccess = (data) => {
            this.setState({
                isProcessing: false,
                bookmarks: data.rows,
                totalPages: parseInt(data.totalpages)
            });
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true, bookmarks: []}, () => ApiDashboard.getBookmark(callbackSuccess, callbackError, data));
    };


    onPagerChange = (page) => {
        this.setState({page}, () => this.loadBookmarks());
    };


    componentDidMount() {
        this.loadBookmarks();
    }



    render (){
        const { bookmarks, isProcessing, page, totalPages } = this.state;
        const { t } = this.props;
        return (
            <BlockingComponent isProcessing={isProcessing}>
                <div id="bookmarkView">
                    <div className="dataList">
                        {
                            bookmarks.map((bookmark) =>
                                <div key={MiscUtils.generateId()} className="row dataRow paddingVerticalMedium">
                                    <div className="col-md-8 fullInfo">
                                        {
                                            bookmark.boardName &&
                                            <span className={classnames(
                                                {"badgeQA": bookmark.board === 0},
                                                {"badgeCommunity": bookmark.board === 1},
                                                {"badgeNotice": bookmark.board === 2},
                                                {"badgeGuestbook": bookmark.board === 3}
                                            )} >{bookmark.boardName}</span>
                                        }
                                        <label className="action">{bookmark.author}</label>
                                        <div>
                                            <Link to={bookmark.article_view_url} style={{color: 'inherit'}}>{bookmark.article}</Link>
                                        </div>
                                    </div>
                                    <div className={"col-md-4"+CLASS_HIDDEN_IN_MOBILE}>
                                        <div className="alignRight">{bookmark.date}</div>
                                        <div className="alignRight">{t('View')}: <span className="numStatsCount">{bookmark.view} </span> </div>
                                    </div>
                                    <div className={"col-xs-12 col-sm-12"+CLASS_HIDDEN_IN_PC}>
                                        <span>{bookmark.date} </span>
                                        <span className="pull-right">{t('View')}: <span className="numStatsCount">{bookmark.view} </span> </span>
                                    </div>
                                </div>
                            )
                        }
                        {
                            !isProcessing && bookmarks && bookmarks.length === 0 &&
                            <div>{MESSAGE.NO_BOOKMARK}</div>
                        }
                    </div>
                    {
                        !isProcessing && bookmarks && bookmarks.length > 0 &&
                        <TablePager page={page} total={totalPages} onPagerChange={this.onPagerChange}/>
                    }
                </div>
            </BlockingComponent>
        )
    }
}

export default translate()(BookmarkView);