export const GLOBAL_NOTIFICATION_MESSAGE_ERROR = 'GLOBAL_NOTIFICATION_MESSAGE_ERROR';
export const GLOBAL_NOTIFICATION_MESSAGE_ERROR_CLEAR = 'GLOBAL_NOTIFICATION_MESSAGE_ERROR_CLEAR';
export const GLOBAL_SET_BREADCUMBS = 'GLOBAL_SET_BREADCUMBS';
export const GLOBAL_CLEAR_BREADCUMBS = 'GLOBAL_CLEAR_BREADCUMBS';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_AUTHENTICATING_USER = 'SET_AUTHENTICATING_USER';
export const AUTH__PROCESSING = 'AUTH__PROCESSING';
export const AUTH__FINISHED = 'AUTH__FINISHED';

export const GET_ARTICLES__PROCESSING = 'GET_ARTICLES__PROCESSING';
export const GET_ARTICLES__FINISHED = 'GET_ARTICLES__FINISHED';

export const GET_POPULAR_ARTICLES__PROCESSING = 'GET_POPULAR_ARTICLES__PROCESSING';
export const GET_POPULAR_ARTICLES__FINISHED = 'GET_POPULAR_ARTICLES__FINISHED';

export const GET_RESPONSES__PROCESSING = 'GET_RESPONSES__PROCESSING';
export const GET_RESPONSES__FINISHED = 'GET_RESPONSES__FINISHED';

export const SAVE_ARTICLE__PROCESSING = 'SAVE_ARTICLE__PROCESSING';
export const SAVE_ARTICLE__FINISHED = 'SAVE_ARTICLE__FINISHED';

export const NOTIFICATION_GET_ALL = 'NOTIFICATION_GET_ALL';
export const NOTIFICATION_GET_ALL__PROCESSING = 'NOTIFICATION_GET_ALL__PROCESSING';
export const NOTIFICATION_GET_ALL__FINISHED = 'NOTIFICATION_GET_ALL__FINISHED';

export const NOTIFICATION_GET_UNREAD_COUNT = 'NOTIFICATION_GET_UNREAD_COUNT';
export const NOTIFICATION_GET_UNREAD_COUNT__PROCESSING = 'NOTIFICATION_GET_UNREAD_COUNT__PROCESSING';
export const NOTIFICATION_GET_UNREAD_COUNT__FINISHED = 'NOTIFICATION_GET_UNREAD_COUNT__FINISHED';

