import React from 'react';
import { Link } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import CollapsibleButton from './CollapsibleButton';
import { translate } from "react-i18next";
import { MiscUtils, URLUtils } from '../../../extras';
import { ApiUser } from '../../../api';
import BlockingComponent from './BlockingComponent';


class LeftBarProfileInfo extends React.Component {

    state = {
        isProcessing: false,
        stats: null,
        user_id: ''
    };

    loadData = () => {
        const params = URLUtils.parseParamsInURL(this.props.location.search);
        const user_id = params.user_id;
        const data = {
            user_id
        };
        const callbackSuccess = (data) => {
            this.setState({
                isProcessing: false,
                stats: data.stats
            });
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };

        this.setState({isProcessing: true, user_id}, () => {
            ApiUser.getUserStatistic(callbackSuccess, callbackError, data);
        });
    };

    componentDidMount() {
        this.loadData();
    }


    render (){
        const { t } =  this.props;
        const { stats, isProcessing, user_id } = this.state;
        const params = user_id ? '?user_id='+user_id : '';
        return (

                <BlockingComponent isProcessing={isProcessing}>
                    {
                        !MiscUtils.isEmptyObject(stats) &&
                        <div id="leftBarProfileInfo">
                            <Collapsible trigger={<CollapsibleButton text="Q&A" isCollapsed={true} />} triggerWhenOpen={<CollapsibleButton text="Q&A" isCollapsed={false} />}>
                                <Link to={this.props.match.path+"/qa/question"+params}>
                                    <div className="innerButton">{t('Question')} <span className="pull-right">{stats.total_question}</span></div>
                                </Link>
                                <Link to={this.props.match.path+"/qa/answer"+params} >
                                    <div className="innerButton">{t('Answer')} <span className="pull-right">{stats.total_answer}</span></div>
                                </Link>
                                <Link to={this.props.match.path+"/qa/comment_to_q"+params}>
                                    <div className="innerButton">{t('Comment to Q')} <span className="pull-right">{stats.comment_to_q}</span></div>
                                </Link>
                                <Link to={this.props.match.path+"/qa/comment_to_a"+params}>
                                    <div className="innerButton">{t('Comment to A')} <span className="pull-right">{stats.comment_to_a}</span></div>
                                </Link>
                            </Collapsible>

                            <Collapsible trigger={<CollapsibleButton text={t('Community')} isCollapsed={true} />} triggerWhenOpen={<CollapsibleButton text={t('Community')} isCollapsed={false} />}>
                                <Link to={this.props.match.path+"/community/post"+params}>
                                    <div className="innerButton">{t('Post')} <span className="pull-right">{stats.post}</span></div>
                                </Link>
                                <Link to={this.props.match.path+"/community/reply"+params} >
                                    <div className="innerButton">{t('Reply')} <span className="pull-right">{stats.replies}</span></div>
                                </Link>
                                <Link to={this.props.match.path+"/community/comment_to_r"+params}>
                                    <div className="innerButton">{t('Comment to R')} <span className="pull-right">{stats.comment_to_r}</span></div>
                                </Link>
                            </Collapsible>

                            <Collapsible trigger={<CollapsibleButton text={('Tag')} isCollapsed={true} />} triggerWhenOpen={<CollapsibleButton text={('Tag')} isCollapsed={false} />}>
                                <Link to={this.props.match.path+"/tag/qa"+params}>
                                    <div className="innerButton">Q&A <span className="pull-right">{stats.tag_qa}</span></div>
                                </Link>
                                <Link to={this.props.match.path+"/tag/community"+params} >
                                    <div className="innerButton">{t('Community')} <span className="pull-right">{stats.tag_community}</span></div>
                                </Link>
                            </Collapsible>

                            <Collapsible trigger={<CollapsibleButton text={('Spam')} isCollapsed={true} />} triggerWhenOpen={<CollapsibleButton text={('Spam')} isCollapsed={false} />}>
                                <Link to={this.props.match.path+"/spam/qa"+params}>
                                    <div className="innerButton">Q&A <span className="pull-right">{stats.spam_qa}</span></div>
                                </Link>
                                <Link to={this.props.match.path+"/spam/community"+params} >
                                    <div className="innerButton">{t('Community')} <span className="pull-right">{stats.spam_community}</span></div>
                                </Link>
                                {/*<Link to={`${this.props.match.path}/spam/guestbook`}>*/}
                                    {/*<div className="innerButton">{t('Guestbook')} <span className="pull-right">{stats.spam_guestbook}</span></div>*/}
                                {/*</Link>*/}
                            </Collapsible>

                            {/*<Collapsible trigger={<CollapsibleButton text={('Guestbook')} isCollapsed={true} />} triggerWhenOpen={<CollapsibleButton text={('Guestbook')} isCollapsed={false} />}>*/}
                            {/*<Link to={`${this.props.match.path}/guestbook/guestbook`}>*/}
                            {/*<div className="innerButton">{t('Guestbook')} <span className="pull-right">10</span></div>*/}
                            {/*</Link>*/}
                            {/*<Link to={`${this.props.match.path}/guestbook/comment`} >*/}
                            {/*<div className="innerButton">{t('Comment')} <span className="pull-right">25</span></div>*/}
                            {/*</Link>*/}
                            {/*</Collapsible>*/}

                        </div>
                    }
                </BlockingComponent>
        );
    }
}

export default translate()(LeftBarProfileInfo);