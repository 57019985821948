import React from 'react';
export default  class ErrorNotFound extends React.Component {
    render (){
        return (
            <div>
                <div>Not found</div>
                <a href="/">Back</a>
            </div>
        )
    }
}