import React from 'react';
import { URLUtils, MiscUtils } from '../../extras';
import { TableSorterTransparent, TableContentArticle, TableContentResponse, TableContentComment, BlockingComponent } from '../../components';
import ProfileStatsTagView from './ProfileStatsTagView';
import ProfileStatsSpamView from './ProfileStatsSpamView';
import { BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_TAG, BOARD_NAME_SPAM } from '../../config';
import { ApiUser } from '../../api';
import i18n from '../../locales/i18n';

export default class ProfileStatsView extends React.Component {

    // tuong tu voi SearchView.js
    state = {
        isProcessing: false,
        currentSearchBoard: '',
        article_type: '',
        sortButtons: [],
        page: 1,
        result: {},
        user_id: ''
    };


    makeSearch = () => {
        const { currentSearchBoard, article_type, page, user_id } = this.state;

        const data = {
            board_type: currentSearchBoard,
            article_type,
            user_id,
            page: page,
            time: 3 // all
        };
        const callbackSuccess = (data) => {
            let sortButtons = [];
            const { statistic_info } = data;
            if (currentSearchBoard === BOARD_NAME_QA){
                sortButtons = [
                    [i18n.t('Question'), 'question'],
                    [i18n.t('Answer'), 'answer'],
                    [i18n.t('Comment to Q'), 'comment_to_q'],
                    [i18n.t('Comment to A'), 'comment_to_a']
                ];
                if (statistic_info.question_total > 0)
                    sortButtons[0][0] = i18n.t('Question') + ' ('+statistic_info.question_total+')';
                if (statistic_info.answer_total > 0)
                    sortButtons[1][0] = i18n.t('Answer') + ' ('+statistic_info.answer_total+')';
                if (statistic_info.comment_to_q_total > 0)
                    sortButtons[2][0] = i18n.t('Comment to Q') + ' ('+statistic_info.comment_to_q_total+')';
                if (statistic_info.comment_to_a_total > 0)
                    sortButtons[3][0] = i18n.t('Comment to A') + ' ('+statistic_info.comment_to_a_total+')';
            }
            else if (currentSearchBoard === BOARD_NAME_COMMUNITY){
                sortButtons = [
                    [i18n.t('Post'), 'post'],
                    [i18n.t('Reply'), 'reply'],
                    [i18n.t('Comment to R'), 'comment_to_r']
                ];
                if (statistic_info.post_total > 0)
                    sortButtons[0][0] = i18n.t('Post') +' ('+statistic_info.post_total+')';
                if (statistic_info.reply_total > 0)
                    sortButtons[1][0] = i18n.t('Reply') +' ('+statistic_info.reply_total+')';
                if (statistic_info.comment_total > 0)
                    sortButtons[2][0] = i18n.t('Comment to R') +' ('+statistic_info.comment_total+')';
            }
            else if (currentSearchBoard === BOARD_NAME_TAG){
                sortButtons = [
                    ['QA', BOARD_NAME_QA],
                    [i18n.t('Community'), BOARD_NAME_COMMUNITY],
                    // [i18n.t('Notice'), BOARD_NAME_NOTICE]
                ];
                if (statistic_info.qa_total > 0)
                    sortButtons[0][0] = 'QA ('+statistic_info.qa_total+')';
                if (statistic_info.community_total > 0)
                    sortButtons[1][0] = i18n.t('Community') +' ('+statistic_info.community_total+')';
                // if (statistic_info.notice_total > 0)
                //     sortButtons[2][0] = i18n.t('Notice') +' ('+statistic_info.notice_total+')';
            }
            else if (currentSearchBoard === BOARD_NAME_SPAM) {
                sortButtons = [
                    ['QA', BOARD_NAME_QA],
                    [i18n.t('Community'), BOARD_NAME_COMMUNITY],
                    // [i18n.t('Guestbook'), BOARD_NAME_GUESTBOOK]
                ];
                if (statistic_info.spam_qa > 0)
                    sortButtons[0][0] = 'QA ('+statistic_info.spam_qa+')';
                if (statistic_info.spam_community > 0)
                    sortButtons[1][0] = i18n.t('Community') +' ('+statistic_info.spam_community+')';
            }

            this.setState({
                isProcessing: false,
                result: data,
                sortButtons: sortButtons
            });
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true}, () => ApiUser.getUserStatisticDetail(callbackSuccess, callbackError, data));

    };



    initData = () => {
        const arr = URLUtils.getCurrentURL(this).split('/');
        const board = arr[arr.length -2];
        const article_type = arr[arr.length -1];
        const params = URLUtils.parseParamsInURL(this.props.location.search);
        const user_id = params.user_id;


        const sortButtonsDict = {
                qa: [
                    ['Question', 'question'],
                    ['Answer', 'answer'],
                    ['Comment to Q', 'comment_to_q'],
                    ['Comment to A', 'comment_to_a']
                ],
                community: [
                    ['Post', 'post'],
                    ['Reply', 'reply'],
                    ['Comment to R', 'comment_to_r']
                ],
                tag: [
                    ['QA', BOARD_NAME_QA],
                    ['Community', BOARD_NAME_COMMUNITY]
                ],
                spam: [
                    ['QA', BOARD_NAME_QA],
                    ['Community', BOARD_NAME_COMMUNITY],
                    // ['Guestbook', BOARD_NAME_GUESTBOOK]
                ],
                // guestbook: [
                //     ['Guestbook', BOARD_NAME_GUESTBOOK],
                //     ['Comment', 'comment']
                // ]
            };

        this.setState({
            currentSearchBoard: board,
            article_type,
            user_id,
            page: 1,
            result: {},
            sortButtons: sortButtonsDict[board]
        }, () => this.makeSearch());
    };


    selectSorter = (sorter) => {
        this.setState({
            article_type: sorter,
            result: {},
            page: 1
        }, () => this.makeSearch());
    };


    onPagerChange = (newPage) => {
        this.setState({page: newPage, result: {}}, () => this.makeSearch());
    };


    componentWillMount() {
        this.initData();
    }


    render() {
        const { isProcessing, currentSearchBoard, article_type, sortButtons, result } = this.state;

        return (
            <div id="profileStatsView">
                <TableSorterTransparent sortButtons={sortButtons} sortType={article_type} selectSorter={this.selectSorter}/>
                <BlockingComponent isProcessing={isProcessing}>
                    {
                        (['question', 'post', 'notice'].includes(article_type)) &&
                        <TableContentArticle boardType={ currentSearchBoard } data={result} isProcessing={isProcessing} onPagerChange={this.onPagerChange}/>
                    }
                    {
                        (['answer', 'reply'].includes(article_type)) &&
                        <TableContentResponse boardType={ currentSearchBoard } data={result} isProcessing={isProcessing} onPagerChange={this.onPagerChange} isCompact={true}/>
                    }
                    {
                        (['comment_to_q', 'comment_to_a', 'comment_to_r'].includes(article_type)) &&
                        <TableContentComment boardType={ currentSearchBoard } data={result} isProcessing={isProcessing} onPagerChange={this.onPagerChange} isCompact={true}/>
                    }
                    {
                        (currentSearchBoard === 'tag') &&
                        <ProfileStatsTagView data={result} boardType={article_type} isProcessing={isProcessing} onPagerChange={this.onPagerChange} />
                    }
                    {
                        (currentSearchBoard === 'spam') &&
                        <ProfileStatsSpamView data={result} boardType={article_type} isProcessing={isProcessing} onPagerChange={this.onPagerChange} />
                    }
                </BlockingComponent>
            </div>
        )
    }
}