import React from 'react';
import { TableContentResponseElement, LanguageFilteringButton, TableSorterTransparent } from '../common';
import { BOARD_NAME_QA, BOARD_NAME_COMMUNITY } from '../../config';
import { MiscUtils, SELECTED_LANGUAGES } from '../../extras';

export default class RankResponseView extends React.Component {

    state = {
        selectedLanguages: MiscUtils.getFromLocalStorage(SELECTED_LANGUAGES)
    };

    saveSelectLanguagesCallback = (selectedLanguages) => {
        const { saveSelectLanguagesCallback } = this.props;
        if (saveSelectLanguagesCallback)
            saveSelectLanguagesCallback(selectedLanguages);
    };


    render() {
        const { listObjects } = this.props;
        const { selectedLanguages } = this.state;
        return (
            <div>
                <LanguageFilteringButton
                    selectedLanguages={selectedLanguages}
                    saveSelectLanguagesCallback={this.saveSelectLanguagesCallback}
                />
                <TableSorterTransparent {...this.props}/>
                {
                    (listObjects.length > 0) &&
                    <div id="tableContentResponse">
                        {
                            listObjects.map((response, idx) => (
                                <TableContentResponseElement response={response} key={MiscUtils.generateId()}/>
                            ))
                        }
                    </div>
                }
            </div>
        );
    }
}