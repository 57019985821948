import { URLUtils, URL_MAPPING } from '../extras';
import ApiHttp from './http';

export default class ApiTag {

    static getTagsForSuggestion = (callbackSuccess, callbackError) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.tag.getTagsForSuggestion);
        ApiHttp.get(url, callbackSuccess, callbackError);
    };

    static getTagRanking = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.tag.getTagRanking);
        ApiHttp.get(url, callbackSuccess, callbackError, data);
    };

    static saveTagEdit = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.tag.saveTagEdit);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

}