import { GET_RESPONSES__PROCESSING, GET_RESPONSES__FINISHED } from './types';
import { DELAY_BEFORE_REDUX_ACTION } from '../../config';
import { actionFinished, actionInProgress } from './actionCommon';
import { ApiHttp } from '../../api';


const getResponses = (url, callback) => {
    return dispatch => {
        dispatch(actionInProgress(GET_RESPONSES__PROCESSING));
        setTimeout(() => {
            const callbackSuccess = (res) => {
                dispatch(actionFinished(GET_RESPONSES__FINISHED, null));
                callback(res);
            };
            const callbackError = (error) => {
                dispatch(actionFinished(GET_RESPONSES__FINISHED, error.message));
            };
            ApiHttp.get(url, callbackSuccess, callbackError);
        }, DELAY_BEFORE_REDUX_ACTION);

    };
};


export { getResponses };