import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import LanguageSelectionModal from './LanguageSelectionModal';
import { MAX_AVAILABLE_LANGUAGES, getLanguageTextFromCode } from "../../../config/common";
import { MiscUtils } from '../../../extras';


export default class LanguageUserSettingButton extends React.Component {

    state = {
        showPopup: false,
        selectedLanguages: this.props.selectedLanguages
    };

    togglePopup = () => {
        this.setState({showPopup: !this.state.showPopup});
    };


    saveSelectLanguagesCallback = (selectedLanguages) => {
        this.setState({selectedLanguages}, () => {
            const { saveSelectLanguagesCallback } = this.props;
            if (saveSelectLanguagesCallback)
                saveSelectLanguagesCallback(selectedLanguages);
        });
    };


    // when choosing Primary Language, then update Available
    componentWillReceiveProps = (nextProps) => {
        this.setState({
            showPopup: nextProps.showPopup,
            selectedLanguages: nextProps.selectedLanguages
        });
    };


    render (){
        const { showPopup, selectedLanguages } = this.state;
        const { isMultiple, disabledLanguages, setWidth } = this.props;
        return (
            <LanguageSelectionModal showPopup={showPopup}
                                    selectedLanguages={selectedLanguages}
                                    isMultiple={isMultiple}
                                    setWidth={setWidth}
                                    disabledLanguages={disabledLanguages}
                                    maxSelected={MAX_AVAILABLE_LANGUAGES}
                                    togglePopup={this.togglePopup}
                                    saveSelectLanguagesCallback={this.saveSelectLanguagesCallback} >
                {
                    selectedLanguages.map(
                        (langCode) =>
                            <span key={MiscUtils.generateId()} className="languageBadge">{getLanguageTextFromCode(langCode)}</span>
                    )
                }
                <Glyphicon glyph="pencil" className="blankHyperlink" onClick={this.togglePopup}/>
            </LanguageSelectionModal>
        )
    }
}