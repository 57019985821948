import { URLUtils, URL_MAPPING } from '../extras';
import ApiHttp from './http';

export default class ApiDashboard {

    static getReputation = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.dashboard.getReputation);
        ApiHttp.get(url, callbackSuccess, callbackError, data);
    };

    static getNotification = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.dashboard.getNotification);
        ApiHttp.get(url, callbackSuccess, callbackError, data);
    };

    static getNotificationInMenubar = (callbackSuccess, callbackError) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.dashboard.getNotificationInMenubar);
        ApiHttp.get(url, callbackSuccess, callbackError);
    };

    static getUnreadNotificationCountInMenubar = (callbackSuccess, callbackError) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.dashboard.getUnreadNotificationCountInMenubar);
        ApiHttp.get(url, callbackSuccess, callbackError);
    };

    static getBookmark = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.dashboard.getBookmark);
        ApiHttp.get(url, callbackSuccess, callbackError, data);
    };



}