import LanguageDetector from 'i18next-browser-languagedetector';
import { PRIMARY_LANGUAGE_CODE } from '../extras/localStorageConstant';
import i18n from "i18next";

import ar from './ar.json';
import bn from './bn.json';
import cn from './cn.json';
import de from './de.json';
import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import hi from './hi.json';
import id from './id.json';
import it from './it.json';
import ja from './ja.json';
import kk from './kk.json';
import km from './km.json';
import kr from './kr.json';
import ky from './ky.json';
import mn from './mn.json';
import ms from './ms.json';
import my from './my.json';
import ne from './ne.json';
import pl from './pl.json';
import pt from './pt.json';
import ru from './ru.json';
import si from './si.json';
import sv from './sv.json';
import th from './th.json';
import tl from './tl.json';
import tr from './tr.json';
import ur from './ur.json';
import uz from './uz.json';
import vi from './vi.json';









const initOptions = {
    // we init with resources
    resources: {
        ar, bn, cn, de, en, es, fr, hi, id, it, ja, kk, km, kr, ky, mn, ms, my, ne, pl, pt, ru, si, sv, th, tl, tr, ur, uz, vi
    },
    // lng: 'kr',
    fallbackLng: "en",
    // debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: '.ks.',
    nsSeparator: ':ns:',

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    }
};



// // MiscUtils.getFromLocalStorage()
// let lang = '';
// const currentPrimaryLangCode = localStorage.getItem(PRIMARY_LANGUAGE_CODE);
// console.log('-------------------- currentPrimaryLangCode: '+currentPrimaryLangCode);
// console.log('-------------------- ACCESS_TOKEN: '+localStorage.getItem(ACCESS_TOKEN));
// if (currentPrimaryLangCode)
//     lang = currentPrimaryLangCode;
// else {
//     // MiscUtils.getClientBrowerLocale()
//     // en-US
//     const currentLocale = (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
//     lang = currentLocale.split('-')[0];
// }

// se ra la  "kr"  can phai la  kr
let currentPrimaryLangCode = localStorage.getItem(PRIMARY_LANGUAGE_CODE);
if (currentPrimaryLangCode)
    currentPrimaryLangCode = currentPrimaryLangCode.replace(/^"+|"+$/g, '');
if (currentPrimaryLangCode)
    initOptions['lng'] = currentPrimaryLangCode;

i18n.use(LanguageDetector).init(initOptions);
// i18n.init(initOptions);

export default i18n;
