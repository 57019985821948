import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ProfileUserView from './ProfileUserView';
import ProfileStatsView from './ProfileStatsView';

export default class ProfileView extends React.Component {

    render() {
        return (
            <div className="col-md-10">
                <div id="profileView" className="row centerContent">
                    <h4>My Profile</h4>
                    <Switch> {/* only match only 1 */}
                        <Route exact path={this.props.match.path} component={ ProfileUserView } />
                        <Route path={this.props.match.path} component={ ProfileStatsView } />
                    </Switch>
                </div>
            </div>
        )
    }
}