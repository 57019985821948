import { URLUtils, URL_MAPPING } from '../extras';
import ApiHttp from './http';

export default class ApiArticle {

    static saveArticle = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.article.saveArticle);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static voteArticle = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.article.voteArticle);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static voteDownArticle = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.article.voteDownArticle);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static bookmarkArticle = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.article.bookmarkArticle);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static deleteArticle = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.article.deleteArticle);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static blockArticle = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.article.blockArticle);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static reportArticle = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.article.reportArticle);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static saveResponse = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.article.saveResponse);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static saveComment = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.article.saveComment);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static search = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.article.search);
        ApiHttp.get(url, callbackSuccess, callbackError, data);
    };

    static getRelatedArticles = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.article.getRelatedArticles);
        ApiHttp.get(url, callbackSuccess, callbackError, data);
    };

    static validateThumbnailURL = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.article.validateThumbnailURL);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static setAcceptedResponse = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.article.setAcceptedResponse);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static attendEvent = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.article.attendEvent);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static cancelEvent = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.article.cancelEvent);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static shareArticle = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.article.shareArticle);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static changeLanguageWhenViewing = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.article.changeLanguageWhenViewing);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static updateNewsletterTracking = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.article.updateNewsletterTracking);
        ApiHttp.get(url, callbackSuccess, callbackError, data);
    };


    static reactArticle = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.article.reactArticle);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };




}