// in frontend route

export const DEFAULT_INDEX = '/qa';
export const SIGN_IN = '/accounts/login';
export const SIGN_UP = '/accounts/signup';
export const SIGN_UP_SUCCESS = '/accounts/signup/success';
export const SIGN_UP_GET_INFO = '/accounts/get_info';
export const INDEX_PAGE = '/home';
export const MAINPAGE_QA = '/qa';
export const MAINPAGE_COMMUNITY = '/community';
export const MAINPAGE_NOTICE = '/notice';
export const MAINPAGE_FREETALK = '/freetalk';
export const RANKING__DEFAULT = '/ranking';
export const RANKING__PEOPLE = '/ranking/people';
export const RANKING__QUESTION = '/ranking/question';
export const RANKING__ANSWER = '/ranking/answer';
export const RANKING__POST = '/ranking/post';
export const RANKING__REPLY = '/ranking/reply';
export const NOTICE__ABOUTUS = '/notice/introduction';
export const NOTICE__USERGUIDE = '/notice/userguide';
//export const NOTICE__USERGUIDE_EN = '/notice/userguide/en';
export const NOTICE__CONTRIBUTOR = '/notice/translator';
export const NOTICE__PRIVACY = '/notice/privacy';
export const NOTICE__TOS = '/notice/termOfService';
export const NOTICE__FEEDBACK = '/notice/feedback';
export const NOTICE__DONATION = '/notice/donation';
export const SEARCH = '/search';
export const PROFILE = '/profile';
export const SETTING = '/setting';
export const SETTING_NOTIFICATION = '/setting/notification';
export const DASHBOARD = '/dashboard';
export const DASHBOARD_NOTIFICATION = '/dashboard/notification';
export const SEARCH_TAG = '/search?board=tag&query=';
