import React from 'react';
import _ from 'lodash';
import { MultiSelect} from 'react-selectize';
import { MiscUtils, URLUtils, URL_MAPPING } from '../../../extras';
import { ApiTag } from '../../../api';
import { ERROR_MESSAGE } from '../../../config';
import { translate } from "react-i18next";


const MIN_CHAR_TO_SHOW = 2;

class TagInput extends React.Component {

    state = {
        suggestionListCache: [],
        suggestionList: [],
        keyword: '',
        isShowDropDown: false
    };


    // checkLength = () => {
    //     alert('aaa')
    //     const { keyword } = this.state;
    //     // if (keyword && keyword.length > 3)
    //     this.setState({isShowDropDown: (keyword && keyword.length > 3)})
    // };

    isOpenDropDown = (isOpen) => {
        const { keyword } = this.state;
        if (isOpen) {
            this.refs.selectInstance.focus(); // focus the input text
            if (keyword && keyword.length >= MIN_CHAR_TO_SHOW)
                this.setState({isShowDropDown: true});
        }
    };


    componentDidMount() {
        const callbackSuccess = (res) => {
            const listTag = res;
            const suggestionListCache = listTag.map(function(tag){
                    return {label: tag, value: tag}
                });
            this.setState({suggestionListCache});
        };
        const callbackError = (error) => {
            MiscUtils.showErrorMessage(ERROR_MESSAGE.CANNOT_CONNECT_TO_SERVER);
        };
        ApiTag.getTagsForSuggestion(callbackSuccess, callbackError);
    }


    render (){
        const { suggestionList, keyword, isShowDropDown} = this.state;
        const { onChange, selectedTags, isHideErrorText, t } = this.props;
        const component = this;


        // Tags - Advance usage
        // http://furqanzafar.github.io/react-selectize/#/?category=multi
        // https://github.com/furqanZafar/react-selectize/blob/master/API.md
        return (
            <div id="tagInput">
                {
                    !isHideErrorText &&
                    <span className="errorText">*</span>
                }

                <MultiSelect
                    ref="selectInstance"
                    options = {suggestionList}
                    values = {selectedTags ? selectedTags : []}
                    // placeholder = "At least one tag such as (Korean food), max 5 tags"
                    placeholder={t('Enter or Commna (,) to enter tag')}

                    search={keyword}

                    // onFocus={this.checkLength}
                    open={isShowDropDown}
                    onOpenChange={this.isOpenDropDown}

                    // delimtiers :: [KeyCode]  comma
                    delimiters = {[188]}

                    // valuesFromPaste :: [Item] -> [Item] -> String -> [Item]
                    valuesFromPaste = {function(options, values, pastedText){
                        const DELIMITER = ",";
                        // const aaa ='123$^%^%456'
                        // console.log(aaa.split('').filter((char) => {
                        //     return !MiscUtils.isBlockedInTagText(char)
                        // }).join(''))
                        const newPastedText = pastedText.split('').filter((char) => {
                            return !MiscUtils.isBlockedInTagText(char) || (char === DELIMITER);
                        }).join('');

                        return newPastedText
                            .split(DELIMITER) // delimiters: 188 is ","
                            .filter(function(text){
                                const labels = values.map(function(item){
                                    return item.label;
                                });
                                return labels.indexOf(text) === -1;
                            })
                            .map(function(text){
                                return {label: text, value: text};
                            });
                    }}

                    // restoreOnBackspace :: Item -> String
                    restoreOnBackspace = {function(item){
                        return item.label;
                    }}


                    // when adding
                    // onValuesChange :: [Item] -> (a -> Void) -> Void
                    onValuesChange = {function(tags){
                        onChange(tags);
                        component.setState({keyword: ''});
                    }}


                    // when typing
                    onSearchChange = {function(search){
                        if (search) {
                            // const regex = new RegExp("^[a-zA-Z0-9\-\b \.\(\)]"); //allowed keys
                            // const lastChar = search.slice(-1);
                            // if (!regex.test(lastChar) && !MiscUtils.containsNonLatinCodepoints(lastChar)) {
                            //     search = search.slice(0, -1); // remove last char
                            // }
                            const lastChar = search.slice(-1);
                            if (MiscUtils.isBlockedInTagText(lastChar))
                                search = search.slice(0, -1); // remove last char
                        } else
                            search = "";

                        const trimSearch = search.trim();
                        let options = [];
                        if (trimSearch.length >= MIN_CHAR_TO_SHOW) {
                            options = component.state.suggestionListCache.filter((obj) => obj.value.indexOf(trimSearch) >= 0);
                        }
                        component.setState({keyword: search, isShowDropDown: false, suggestionList: options});
                    }}


                    // render dismiss button
                    // https://codepen.io/hiester/pen/BQQdWG <--
                    // https://github.com/furqanZafar/react-selectize/issues/16
                    renderValue={(item) => {
                                    return <div className="simple-value" style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: '0 0 0 8px',
                                                lineHeight: '22px'
                                            }}>
                                                <div onClick = {() => {
                                                                    const newItems = _.filter(component.props.selectedTags, function(d) {
                                                                        return item.label !== d.label
                                                                    });
                                                                    onChange(newItems);
                                                                }}
                                                     style={{
                                                         // padding: "0 5px",
                                                         color: "rgb(0, 136, 204)"
                                                     }}>
                                                    <i className="fa fa-times" aria-hidden="true"></i>
                                                </div>
                                                    <div style = {{
                                                        borderLeft: '1px solid #c9e6f2'
                                                    }}>
                                                        {item.label}
                                                    </div>
                                            </div>
                                }
                            }




                    // createFromSearch :: [Item] -> [Item] -> String -> Item?
                    createFromSearch = {function(options, values, search){
                        const trimSearch = search.trim();
                        // const labels = values.map(function(value){
                        //     return value.label;
                        // });
                        // if (!search || (trimSearch.length === 0 || labels.indexOf(trimSearch) !== -1))
                        //     return null;
                        return {label: trimSearch, value: trimSearch};
                    }}

                    // renderNoResultsFound :: [Item] -> String -> ReactElement
                    renderNoResultsFound = {function(values, search) {
                        return <div className = "no-results-found">
                            {function(){
                                if (search.trim().length === 0)
                                    return t('Type a few characters to create a tag');
                                else if (values.map(function(item){ return item.label; }).indexOf(search.trim()) !== -1)
                                    return t('Tag already exists');
                            }()}
                        </div>
                    }}

                    // focus hang dau tien, ko thi se auto select khi chi go vai character
                    firstOptionIndexToHighlight = {(optionIndex, options, value, search) => {
                        return 0;
                        // return (!value && optionIndex == 0 && search.length == 0) ? -1 : optionIndex
                    }}

                />








            </div>
        )
    }
}

export default translate()(TagInput);