import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { BlockingComponent, Breadcrumb } from '../common';
import Article from './Article';
import Responses from './Responses';
import { ERROR_MESSAGE } from '../../config';
import { MiscUtils, URLUtils, URL_MAPPING } from '../../extras';
import { ApiHttp, ApiArticle } from '../../api';
import { translate } from "react-i18next";




class ArticleView extends React.Component {

    state = {
        boardType: this.props.match.params.boardType,
        articleId: this.props.match.params.articleId.replace('/',''),  // in case   /qa/323/  chu ko phai  /qa/323-title/
        article: null,
        isLoading: true
    };


    loadArticle = () => {
        const params = URLUtils.parseParamsInURL(this.props.location.search);
        const newsletterTrackingId = params['tracking_id'] || null;
        if (newsletterTrackingId){
            const data = {tracking_id: newsletterTrackingId};
            const callbackSuccess = () => {
            };
            const callbackError = () => {
            };
            ApiArticle.updateNewsletterTracking(callbackSuccess, callbackError, data);
        }


        const { boardType, articleId } = this.state;
        let url = URLUtils.buildAPIURL(URL_MAPPING.article.getArticle);
        url = url.replace(':boardType', boardType);
        url = url.replace(':articleId', articleId.split('-')[0]);
        const callbackSuccess = (article) => {
            this.setState({article: article, isLoading: false});
        };
        const callbackError = (error) => {
            this.setState({isLoading: false});
            MiscUtils.showErrorMessage(ERROR_MESSAGE.CANNOT_CONNECT_TO_SERVER);
        };

        console.log(url)
        ApiHttp.get(url, callbackSuccess, callbackError);
    };


    componentDidMount (){
        this.loadArticle();
    }



    render() {

        const { isLoading, article } = this.state;
        const { user, t } = this.props;

        const sortButtons = [
            [t('Popular'), 'popular'],
            [t('Newest'), 'newest'],
            [t('Oldest'), 'oldest']
        ];

        let highlightedObjectId = null;
        // ?id=answer_392  ?id=comments-of-295-652   ?id=comment_question_652
        const params = URLUtils.parseParamsInURL(this.props.location.search);
        if (params.id) {
            highlightedObjectId = params.id;
        }

        return (
            <div className="col-md-7 centerContent" id="articleView">
                <BlockingComponent isProcessing={isLoading}>
                    <Breadcrumb article={article}/>
                    {
                        article &&
                        <div>
                            <Article currentUser={user} { ...this.state } highlightedObjectId={highlightedObjectId}/>
                            <Responses currentUser={user} sortButtons={sortButtons} {...this.state} highlightedObjectId={highlightedObjectId}/>
                        </div>
                    }
                </BlockingComponent>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.reducerAuth.user
        // article: state.reducerArticle.article,
        // boardType: state.reducerArticle.boardType
    }
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translate()(ArticleView)));
