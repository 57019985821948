import React from 'react';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import { UserAvatarInfo, TagList, } from '../element';
import { CLASS_HIDDEN_IN_MOBILE, CLASS_HIDDEN_IN_PC } from '../../../config';
import { translate } from "react-i18next";


class TableContentResponseElement extends React.Component {

    render() {
        const { response, t } = this.props;
        return (
            <div className="dataRow">
                <div className="row1">
                    <label className="languageBadge">{response.language}</label>
                    <TagList tags={response.tags} isHideEditButton={true} />
                </div>


                <div className="row">
                    <div className="col-md-10">
                        <div>
                            <strong className="articleTitle"><Link to={response.article_view_url}>{response.article_title}</Link></strong>
                        </div>
                        <UserAvatarInfo user={response.user} />

                        <div>
                            {response.response_content}
                        </div>
                    </div>
                    <div className="col-md-2">
                        <img src={response.article_thumbnail} alt="" className="img-rounded img-responsive"/>
                    </div>
                </div>

                <div className={"alignRight"+CLASS_HIDDEN_IN_PC}>{response.last_modified}</div>

                <div className="articleInfo marginTopMedium">

                    <div className={"paddingNone col-md-4"+CLASS_HIDDEN_IN_MOBILE}>
                        <label>{response.last_modified}</label>
                    </div>
                    <div className="paddingNone col-md-3 col-sm-4 col-xs-4">
                        <label>{t('Vote')} <span className="numStatsCount">{response.votes}</span></label>
                    </div>
                    <div className="paddingNone col-md-3 col-sm-5 col-xs-5">
                        <label>{t('Comment')} <span className="numStatsCount">{response.count_comment}</span></label>
                    </div>
                    <div className="paddingNone col-md-2 col-sm-3 col-xs-3 alignRight">
                        <label>
                            <FontAwesome name='facebook'/>
                            <FontAwesome name='twitter'/>
                        </label>
                    </div>




                </div>

            </div>

        );
    }
}

export default translate()(TableContentResponseElement);