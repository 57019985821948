import React from 'react';
import classnames from 'classnames';
import { BUTTONS } from '../../../config';
import { MiscUtils } from '../../../extras';

export default class TableSorter extends React.Component {
    state = {
        currentBoard: '',
        sortButtons: '',
        currentSelectSortButton: ''
    };

    componentWillMount (){
        this.initData(this.props.boardType);
    }


    initData = (boardType) => {
        const sortButtons = BUTTONS.SORT_BUTTONS[boardType];
        this.setState({
            currentBoard: boardType,
            sortButtons: sortButtons,
            currentSelectSortButton: sortButtons.length ? sortButtons[0][1] : ''
        });
    };


    selectSorter = (sorter) => {
        this.setState({currentSelectSortButton: sorter});
        if (this.props.selectSorter)
            this.props.selectSorter(sorter);
    };


    render() {
        const { sortButtons, currentSelectSortButton } = this.state;
        return (
            <div id="tableSorter" className="row">
                {
                    sortButtons.map((button, idx) => {
                        return (
                            <span key={MiscUtils.generateId()}>
                                <label
                                    className={classnames("blankHyperlink", {"activeSortButton": currentSelectSortButton === button[1]})}
                                    onClick={() => {this.selectSorter(button[1])}}
                                >
                                    {button[0]}
                                </label>
                                {/* https://brajeshwar.github.io/entities/ */}
                                {idx < sortButtons.length-1 && <span>&diams;</span>}
                            </span>
                        )
                    })
                }
            </div>
        );
    }
}