import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { MiscUtils, URLUtils, ACCESS_TOKEN } from '../extras';
import { SIGN_IN } from '../RouteURL';

export default function(ComposedComponent){

    class Authenticate extends React.Component {

        render(){
            if (!MiscUtils.getFromLocalStorage(ACCESS_TOKEN)) {
                // URLUtils.moveToURL(`${SIGN_IN}?next=${this.props.location.pathname}`);
                URLUtils.moveToURL(SIGN_IN + "?next=" + this.props.location.pathname);
                return;
            }
            return (
                <ComposedComponent {...this.props} />
            );
        }
    }


    const mapStateToProps = (state) => {
        return {
            // isAuthenticated: state.reducerAuth.isAuthenticated
        }
    };

    return connect(mapStateToProps, {})(withRouter(Authenticate));

}

