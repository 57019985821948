import React from 'react';
import { CLASS_HIDDEN_IN_MOBILE, CLASS_HIDDEN_IN_PC } from '../../../config';
import UserAvatar from './UserAvatar';
import { translate } from "react-i18next";



class UserAvatarInfo extends React.Component {
    render (){
        const { user, idx, isAnonymous, t } = this.props;
        const { activity_point, popularity_point, confidence_point, activity, popularity, confidence, username, level } = user;
        return (
            <div className="userAvatarInfo">
                <div className="leftColumn">
                    { idx && <div className={"badge userBadge userRank_"+idx}>{idx}</div> }
                    <UserAvatar {...this.props} />
                </div>
                <div className="rightColumn">
                    <p>
                        <strong>
                            {
                                !isAnonymous && username
                            }
                            {
                                isAnonymous &&
                                <span className="orangeColor">Anonymous</span>
                            }
                        </strong>
                        <label className="levelBadge">Lv {level}</label>
                        <span className={"reputationInfo"+CLASS_HIDDEN_IN_MOBILE}>
                            <span className="activityPoint">♥ { (activity_point || activity_point === 0) ? activity_point : activity }</span>&nbsp;
                            <span className="popularityPoint"> ♥ { (popularity_point || popularity_point === 0) ? popularity_point : popularity}</span>&nbsp;
                            <span className="confidencePoint"> ♥ { (confidence_point || confidence_point === 0) ? confidence_point : confidence}</span>
                        </span>
                    </p>
                    <div className={CLASS_HIDDEN_IN_PC}>
                        <span className="reputationInfo">
                            <span className="activityPoint">♥ { (activity_point || activity_point === 0) ? activity_point : activity }</span>&nbsp;
                            <span className="popularityPoint"> ♥ { (popularity_point || popularity_point === 0) ? popularity_point : popularity }</span>&nbsp;
                            <span className="confidencePoint"> ♥ { (confidence_point || confidence_point === 0)  ? confidence_point : confidence }</span>
                        </span>
                    </div>
                    {
                        !isAnonymous &&
                        <p className="career">{user.career ? user.career : user.job}</p>
                    }

                </div>
            </div>
        )

    }
}

export default translate()(UserAvatarInfo);