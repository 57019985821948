import React from 'react';
import { translate } from "react-i18next";
import TextareaAutosize from "react-autosize-textarea";
import { Glyphicon } from 'react-bootstrap';
import { MiscUtils, URLUtils } from "../../extras";
import { ApiFreeTalk } from '../../api';
import { MAX_CHARACTER_IN_FREETALK } from '../../config';
import {BlockingComponent, SwitchOnOff} from "../common";




class FreetalkWrite extends React.Component {

    state = {
        isProcessing: false,
        content: '',
        isPrivate: false
    };

    updateContent = (e) => {
        const content = e.target.value;
        if (content.length <= MAX_CHARACTER_IN_FREETALK)
            this.setState({content: e.target.value});
    };


    // onEnterPress = (e) => {
    //     const { editedTalk } = this.props;
    //     if(e.keyCode == 13 && e.shiftKey == false) { // neu shift + enter se la new line: default, ko can code
    //         e.preventDefault();
    //         if (editedTalk)
    //             this.saveEditTalk();
    //         else
    //             this.saveTalk();
    //     }
    // };


    toggleIsPrivate = () => {
        this.setState({isPrivate: !this.state.isPrivate});
    };


    saveTalk = () => {
        const { content, isPrivate } = this.state;
        const { editedTalk } = this.props;
        if (content.trim().length === 0)
            return;
        const data = { content, isPrivate };
        if (editedTalk)
            data['articleId'] = editedTalk.id;
        const callbackSuccess = (data) => {
            URLUtils.reloadPage();
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true}, () => {
            ApiFreeTalk.saveTalk(callbackSuccess, callbackError, data);
        });
    };



    componentWillReceiveProps(nextProps, nextContext) {
        console.log(nextProps.editedTalk)
        if (nextProps.editedTalk)
            this.setState({
                content: nextProps.editedTalk.content_original,
                isPrivate: nextProps.editedTalk.is_private,
            });
    }


    render() {

        const { isAuthenticated, editedTalk, t, toggleShowEditTalk } = this.props;
        const { isProcessing, content, isPrivate } = this.state;
        const placeHolder = isAuthenticated ? t('Leave your thoughts here') : t('Please login to leave your thoughts');

        // console.log(editedTalk)
        // console.log(content)

        return (
            <BlockingComponent isProcessing={isProcessing}>
                <div className="talkInput">

                    <TextareaAutosize
                        rows={4}
                        maxRows={8}
                        onChange={this.updateContent}
                        // onKeyDown={this.onEnterPress}
                        placeholder={placeHolder}
                        value={content}
                        innerRef={ref => this.textareaTalk = ref}
                        disabled={!isAuthenticated}
                    />

                    <div className="row">
                        <div className="col-md-6 col-xs-6 col-sm-6 alignLeft">
                            <SwitchOnOff checked={isPrivate} handleSwitchChange={this.toggleIsPrivate}
                                         publicPrivateStyle={true} preventPullRight={true}/>
                             &nbsp;
                            <span>
                                 { isPrivate && t('Board manager only')}
                                 { !isPrivate && t('Public to everyone')}
                             </span>
                        </div>

                        <div className="col-md-6 col-xs-6 col-sm-6 alignRight">
                            <span className={(content.length >= MAX_CHARACTER_IN_FREETALK) ? "errorText strongText italicText" : ""}>
                                { content.length }/{MAX_CHARACTER_IN_FREETALK}
                            </span>
                            &nbsp;&nbsp;
                            {
                                editedTalk &&
                                <button type="button" className="btn negativeBtn marginRight5" onClick={toggleShowEditTalk}>
                                    {t('Cancel')}
                                </button>
                            }
                            <button type="button" className="btn positiveBtn" onClick={this.saveTalk} disabled={(content.trim().length === 0) || !isAuthenticated}>
                                {t('Talk')} <Glyphicon glyph="send"/>
                            </button>
                        </div>
                    </div>
                    <hr/>
                </div>
            </BlockingComponent>
        )
    }
}

export default translate()(FreetalkWrite);