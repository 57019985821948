import { SET_CURRENT_USER, SET_AUTHENTICATING_USER, AUTH__PROCESSING, AUTH__FINISHED } from '../actions/types';
import { MiscUtils } from '../../extras';

const initialState = {
    isAuthenticated: false,
    isProcessing: false,
    error: null,
    user: {},
    userAuthenticating: {}
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                isAuthenticated: !MiscUtils.isEmptyObject(action.user),
                user: action.user
            };
        case AUTH__PROCESSING:
            return MiscUtils.updateObject(state, {isProcessing: true});
        case AUTH__FINISHED:
            return MiscUtils.updateObject(state, {isProcessing: false});
        case SET_AUTHENTICATING_USER:
            return MiscUtils.updateObject(state, {userAuthenticating: action.user});
        default:
            return state;
    }
}