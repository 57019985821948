import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import FacebookLogin from 'react-facebook-login/dist/facebook-login';
import GoogleLogin from 'react-google-login';
import KakaoLogin from 'react-kakao-login';
import NaverLogin from 'react-naver-login';
import { MAINPAGE_NOTICE, SIGN_UP, NOTICE__FEEDBACK, NOTICE__PRIVACY, NOTICE__TOS, NOTICE__ABOUTUS, NOTICE__CONTRIBUTOR, NOTICE__USERGUIDE, NOTICE__DONATION } from '../../RouteURL';
import { MiscUtils } from '../../extras';
import { FACEBOOK_APP_ID, GOOGLE_APP_ID, KAKAOTALK_APP_ID, NAVER_APP_ID, NAVER_CALLBACK } from '../../config';
import { PopupModal, BlockingComponent, HTMLContent } from "../common";
import Kakaotalk from '../../static/images/icon/kakaotalk.png';
import Naver from '../../static/images/icon/naver.png';


//https://www.npmjs.com/package/react-social-login-buttons
const styleLoginForm = {
    logo: {
        textAlign: 'center',
        padding: '20px'
    },
    logoImage: {
        display: 'inline'
    },
    signUp: {textAlign: 'justify', marginTop: "15px"},
    forgotPassword: {marginTop: '10px', cursor: 'pointer'}
};


export default class LoginForm extends React.Component {

    state = {
        showForgotPassword: false,
        showKakaotalkEmailError: false,
        forgotPasswordEmail: ''
    };

    snsLogin = (sns, email, data) => {
        this.props.login({
            sns,
            email,
            user_info: JSON.stringify(data),
            timezone: MiscUtils.getClientBrowerTimezone()
        });
    };





    facebookLoginCallback = (response) => {
        const { t } = this.props;
        // accessToken: "xxx"
        //email: "ngocthanh259@yahoo.com"
        //gender: "male"
        // timezone: 7
        // expiresIn: 4583
        // id: "631482439"
        // name: "Thanh Pham"
        // signedRequest: "aaa"
        // userID: "631482439"
        const { email } = response;
        if (!email)
            MiscUtils.showErrorMessage(t('Please give us permission to get your email address in order to continue'));
        else
            this.snsLogin('facebook', email, response);
    };





    googleLoginCallback = (response) => {
        // {
        //     "Ca": "104397063326813036660",
        //     "tc": {
        //         "token_type": "Bearer",
        //             "access_token": "xxxx",
        //             "scope": "email profile https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/userinfo.email",
        //             "login_hint": "xxx",
        //             "expires_in": 3599,
        //             "id_token": "xxxxx",
        //             "session_state": {
        //             "extraQueryParams": {
        //                 "authuser": "0"
        //             }
        //         },
        //         "first_issued_at": 1588738747200,
        //         "expires_at": 1588742346200,
        //         "idpId": "google"
        //     },
        //     "Pt": {
        //         "MU": "104397063326813036660",
        //             "Ad": "thanh ngoc",
        //             "pW": "thanh",
        //             "qU": "ngoc",
        //             "QK": "https://lh3.googleusercontent.com/a-/AOh14GgfPcg7yICNApzHSn3uHAulZgb_234FC05skxg2=s96-c",
        //             "yu": "email"
        //     },
        //     "googleId": "104397063326813036660",
        //     "tokenObj": {
        //         "token_type": "Bearer",
        //             "access_token": "xxxxx",
        //             "scope": "email profile https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/userinfo.email",
        //             "login_hint": "xxxx",
        //             "expires_in": 3599,
        //             "id_token": "xxxxx",
        //             "session_state": {
        //             "extraQueryParams": {
        //                 "authuser": "0"
        //             }
        //         },
        //         "first_issued_at": 1588738747200,
        //         "expires_at": 1588742346200,
        //         "idpId": "google"
        //     },
        //     "tokenId": "xxxx",
        //     "accessToken": "xxxx",
        //     "profileObj": {
        //         "googleId": "104397063326813036660",
        //         "imageUrl": "https://lh3.googleusercontent.com/a-/AOh14GgfPcg7yICNApzHSn3uHAulZgb_234FC05skxg2=s96-c",
        //         "email": "email",
        //         "name": "thanh ngoc",
        //         "givenName": "thanh",
        //         "familyName": "ngoc"
        //     }
        // }
        const { t } = this.props;
        const { email } = response.profileObj;
        if (!email)
            MiscUtils.showErrorMessage(t('Please give us permission to get your email address in order to continue'));
        else
            this.snsLogin('google', email, response);
    };

    googleLoginCallbackFailure = (response) => {
        const { t } = this.props;
        console.log(response);
        MiscUtils.showErrorMessage(t('Cannot login by Google'));
    };






    kakaotalkLoginCallback = (response) => {
        // {
        //     "response":{
        //         "access_token":"xx-bYw",
        //         "token_type":"bearer",
        //         "refresh_token":"xxx",
        //         "expires_in":7199,
        //         "scope":"account_email profile",
        //         "refresh_token_expires_in":5183999
        //     },
        //     "profile":{
        //         "id":xxx,
        //         "connected_at":"2020-05-06T07:18:20Z",
        //         "properties":{
        //             "nickname":"xxx",
        //             "profile_image":"xxx/img_640x640.jpg",
        //             "thumbnail_image":"xxx/img_110x110.jpg"
        //         },
        //         "kakao_account":{
        //             "profile_needs_agreement":false,
        //             "profile":{
        //                 "nickname":"xxx",
        //                 "thumbnail_image_url":"xxx/img_110x110.jpg",
        //                 "profile_image_url":"xxx/img_640x640.jpg"
        //             },
        //             "has_email":true,
        //             "email_needs_agreement":false,
        //             "is_email_valid":true,
        //             "is_email_verified":true,
        //             "email":"xxx"
        //         }
        //     }
        // }
        const { email } = response.profile.kakao_account;
        if (!email)
            this.toggleKakaotalkEmailError();
        else
            this.snsLogin('kakaotalk', email, response);
    };

    kakaotalkLoginCallbackFailure = (response) => {
        console.log(response);
        MiscUtils.showErrorMessage('Cannot login by Kakaotalk')
    };

    toggleKakaotalkEmailError = () => {
        this.setState({showKakaotalkEmailError: !this.state.showKakaotalkEmailError});
    };




    naverLoginCallback = (response) => {
        // age: "0-9"
        // birthday: undefined
        // email: "ngocthanh259@naver.com"
        // gender: "U"
        // id: "150328688"
        // name: "서용석"
        // nickname: "-"
        // profile_image: "https://ssl.pstatic.net/static/pwe/address/img_profile.png"
        const { t } = this.props;
        const { email } = response;
        if (!email)
            MiscUtils.showErrorMessage(t('Please give us permission to get your email address in order to continue'));
        else
            this.snsLogin('naver', email, response);
    };

    naverLoginCallbackFailure = (response) => {
        console.log(response);
        MiscUtils.showErrorMessage('Cannot login by Naver')
    };





    // showMessageSoon = () => {
    //     const { t } = this.props;
    //     MiscUtils.showNotification(t('Will be enabled!'));
    // };


    toggleForgotPassword = () => {
        this.setState({showForgotPassword: !this.state.showForgotPassword});
    };


    onChangeForgotPasswordEmail = (e) => {
        this.setState({forgotPasswordEmail: e.target.value})
    };


    sendVerificationCode = () => {
        const { forgotPasswordEmail } = this.state;
        const { forgotPassword } = this.props;
        forgotPassword(forgotPasswordEmail, () => this.toggleForgotPassword())
    };


    login = () => {
        const { t } = this.props;
        const { email, password } = this.refs;
        if (email.value && password.value)
            this.props.login({
                email: email.value,
                password: password.value
            });
        else
            this.setState({'status_message': t('Please fill username and password')});
    };


    handleEnterKeyPress = (e) => {
        MiscUtils.handleEnterKeyPress(e, this.login);
    };





    render() {
        const { showKakaotalkEmailError, showForgotPassword, forgotPasswordEmail } = this.state;

        const { isProcessing, t } = this.props;
        const isMobileView = MiscUtils.isMobileView();

        const leftSection = (
            <div className={classnames("col-md-6 leftSection", {"noRightBorder paddingVerticalMedium": isMobileView})}>
                {/*<button className="btn btn-block btn-social btn-facebook" onClick={this.facebookLoginClick}>*/}
                {/*<span className="fa fa-facebook"></span> Login with Facebook*/}
                {/*</button>*/}
                <FacebookLogin
                    appId={FACEBOOK_APP_ID}
                    autoLoad={false}
                    fields="name,email,picture,gender,timezone,birthday"
                    scope="public_profile,email"
                    callback={this.facebookLoginCallback}
                    cssClass="btn btn-block btn-social btn-facebook"
                    icon={<span className="fa fa-facebook"></span>}
                    textButton = {t('Login with Facebook')}
                />


                <div>
                    <span>or </span>

                    <GoogleLogin
                        clientId={GOOGLE_APP_ID}
                        scope="email profile https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/userinfo.email"
                        render={renderProps => (
                            //btn-block btn-social
                            <button className="btn btn-social-icon btn-google" onClick={renderProps.onClick} data-tip data-for='googleBtn'>
                              <span className="fa fa-google"></span>
                            </button>
                        )}
                        onSuccess={this.googleLoginCallback}
                        onFailure={this.googleLoginCallbackFailure}
                        cookiePolicy={'single_host_origin'}
                    />
                    <ReactTooltip id='googleBtn' className="tooltipForBtn" effect='solid'>
                        Google
                    </ReactTooltip>


                    &nbsp;
                    <KakaoLogin
                        jsKey={KAKAOTALK_APP_ID}
                        getProfile={true}
                        onSuccess={this.kakaotalkLoginCallback}
                        onFailure={this.kakaotalkLoginCallbackFailure}
                        render={renderProps => (
                            <button className="btn btn-social-icon btn-kakaotalk" data-tip data-for='kakaotalkBtn'
                            onClick={renderProps.onClick}>
                                <img src={Kakaotalk} />
                            </button>
                        )}
                    />
                    <ReactTooltip id='kakaotalkBtn' className="tooltipForBtn" effect='solid'>
                        Kakaotalk
                    </ReactTooltip>


                    &nbsp;
                    <NaverLogin
                        clientId={NAVER_APP_ID}
                        callbackUrl={NAVER_CALLBACK}
                        render={(props) => <button className="btn btn-social-icon btn-naver" data-tip data-for='naverBtn' onClick={props.onClick}>
                                                <img src={Naver} />
                                            </button>}
                        onSuccess={this.naverLoginCallback}
                        onFailure={this.naverLoginCallbackFailure}
                    />
                    <ReactTooltip id='naverBtn' className="tooltipForBtn" effect='solid'>
                        Naver
                    </ReactTooltip>

                </div>


                <div style={styleLoginForm.signUp}>
                    <HTMLContent content={t('{{sign_up_with_email}} By signing up you indicate that you have read and agree to the {{term_of_service}} and {{privacy_policy}}',
                                            {
                                                sign_up_with_email: "<a href="+SIGN_UP+" class=\"btn positiveBtn\"><strong>"+t('Sign up with email')+"</strong></a>",
                                                term_of_service: "<a href="+NOTICE__TOS+"><strong>"+t('Terms of Service')+"</strong></a>",
                                                privacy_policy: "<a href="+NOTICE__PRIVACY+"><strong>"+t('Privacy policy')+"</strong></a>"
                                            })
                    } />
                    {/*<Link to={SIGN_UP}><strong>Sign up</strong></Link> with email. By signing up you indicate that you have read and agree to the <Link to={NOTICE__TOS}><strong>Term of Service</strong></Link> and <Link to={NOTICE__PRIVACY}><strong>Privacy Policy</strong></Link>.*/}
                </div>

            </div>
        );

        return (
            <div id="loginForm">
                <div className="row mainSection">
                    { !isMobileView && leftSection}
                    <div className="col-md-6">
                        <form method="POST" name="login" acceptCharset="UTF-8" encType="application/x-www-form-urlencoded" autoComplete="on">
                            <div className="field-wrap">
                                <div className="form-group input-group">
                                    <span className="input-group-addon">
                                        <span className="glyphicon glyphicon-user"></span>
                                    </span>
                                    <input type="email" ref="email" placeholder={t("Email")} aria-describedby="basic-addon1" className="form-control" />
                                </div>
                            </div>

                            <div className="field-wrap">
                                <div className="form-group input-group">
                                    <span className="input-group-addon">
                                        <span className="glyphicon glyphicon-lock"></span>
                                    </span>
                                    <input type="password" ref="password" placeholder={t("Password")} aria-describedby="basic-addon2" autoComplete="off" className="form-control"
                                           onKeyPress={this.handleEnterKeyPress} />
                                </div>
                            </div>
                            <button type="button" className="btn btn-info btn-block" onClick={this.login}>{t('Login')}</button>
                        </form>

                        <label style={styleLoginForm.forgotPassword} onClick={this.toggleForgotPassword}>{t('Forgot password?')}</label>
                    </div>
                    { isMobileView && leftSection}
                </div>

                {
                    !isMobileView &&
                    <div>
                        <div className="row footerSection footerSection1">
                            <div className="col-md-2">
                                <Link to={NOTICE__ABOUTUS}>{t('About us')}</Link>
                            </div>
                            <div className="col-md-2">
                                <Link to={MAINPAGE_NOTICE}>{t('Notice')}</Link>
                            </div>
                            <div className="col-md-2">
                                <Link to={NOTICE__USERGUIDE}>{t('User guide')}</Link>
                            </div>
                            <div className="col-md-2">
                                <Link to={NOTICE__CONTRIBUTOR}>{t('Contributors')}</Link>
                            </div>
                            <div className="col-md-2">
                                <Link to={NOTICE__PRIVACY}>{t('Privacy')}</Link>
                            </div>
                            <div className="col-md-2">
                                <Link to={NOTICE__TOS}>TOS</Link>
                            </div>
                        </div>
                        <div className="row footerSection footerSection2">
                            <div className="col-md-offset-3 col-md-3">
                                <Link to={NOTICE__FEEDBACK}>{t('Feedback')}</Link>
                            </div>
                            <div className="col-md-3">
                                <Link to={NOTICE__DONATION}>{t('Donation')}</Link>
                            </div>
                        </div>
                    </div>
                }



                <PopupModal isVisible={showForgotPassword} onCloseClicked={this.toggleForgotPassword} setWidth={isMobileView ? 95 : 60}>
                    <BlockingComponent isProcessing={isProcessing}>
                        <div className="form-group" style={{marginBottom: '0px'}}>
                            <label>{t('Forgot password?')}</label>
                            <input type="email" className="form-control" value={forgotPasswordEmail}
                                   onChange={this.onChangeForgotPasswordEmail}
                                   placeholder={t('Recovery code will be sent to your email')}/>
                        </div>
                        <div className="actionBtn">
                            <button type="button" className="btn negativeBtn" onClick={this.toggleForgotPassword}>{t('Cancel')}</button>
                            <button type="button" className="btn positiveBtn" onClick={this.sendVerificationCode}>{t('Send')}</button>
                        </div>
                    </BlockingComponent>
                </PopupModal>


                <PopupModal isVisible={showKakaotalkEmailError} onCloseClicked={this.toggleKakaotalkEmailError} setWidth={isMobileView ? 95 : 60}>
                    <div className="form-group" style={{marginBottom: '0px'}}>
                        <span>We need your email to continue, please disconnect <strong>JOINUSWORLD</strong> app and try again, then select <strong>Accept All</strong> : <a target="_blank" href="https://accounts.kakao.com/weblogin/account/partner">disconnect</a></span>
                    </div>
                    <div className="actionBtn">
                        <button type="button" className="btn positiveBtn" onClick={this.toggleKakaotalkEmailError}>{t('OK')}</button>
                    </div>
                </PopupModal>

            </div>
        );
    }
}


