import React from 'react';
import { PopupModal} from '../../common';
import { ARTICLE_TYPE, BOARD_NAME_QA } from '../../../config';
import { MiscUtils } from '../../../extras';
import { translate } from "react-i18next";
import CommentWrite from "./CommentWrite";


class CommentWriteInMobile extends React.Component {

    state = {
        showWriteComment: false
    };


    toggleWriteComment = () => {
        this.setState({showWriteComment: !this.state.showWriteComment});
    };

    // state = {
    //     redirectToLogin: false,
    //     isProcessing: false,
    //     prefix: this.props.editedComment ? this.props.editedComment.comment_type : (this.props.commentPrefixButtons ? this.props.commentPrefixButtons[0][1] : ''),
    //     content: this.props.editedComment ? this.props.editedComment.original_content : ''
    // };
    //
    //
    // updatePrefix = (e) => {
    //     this.setState({prefix: e.target.value});
    // };
    //
    //
    // updateContent = (e) => {
    //     this.setState({content: e.target.value});
    // };
    //
    //
    // saveComment = () => {
    //     const { article, response, boardType, parentType, repliedComment } = this.props;
    //     let { content, prefix } = this.state;
    //     if (repliedComment)
    //         content = "<span class=\"quote_username_in_comment_content\" id=\"" + repliedComment.user.user_id + "\">" + repliedComment.user.username + "</span>&nbsp;" + content;
    //
    //     let articleType = ARTICLE_TYPE_IN_BACKEND.COMMENT_TO_Q;
    //     let parentId = article.article_id;
    //
    //     if (parentType === ARTICLE_TYPE.RESPONSE){
    //         parentId = response.id;
    //         if (article.article_type === BOARD_NAME_QA)
    //             articleType = ARTICLE_TYPE_IN_BACKEND.COMMENT_TO_A;
    //         else if (article.article_type === BOARD_NAME_COMMUNITY)
    //             articleType = ARTICLE_TYPE_IN_BACKEND.COMMENT_TO_R;
    //     }
    //
    //     const data = {
    //         parent_id: parentId,
    //         article_type: articleType,
    //         comment_type: prefix,
    //         article_content: content,
    //     };
    //     this.saveCommentSubmission(data);
    // };
    //
    //
    // cancelEditArticle = () => {
    //     this.props.cancelEditArticle();
    // };
    //
    //
    // saveEditArticle = () => {
    //     const { editedComment  } = this.props;
    //     const { prefix, content  } = this.state;
    //     const data = {
    //         comment_id: editedComment.id,
    //         comment_type: prefix,
    //         article_content: content,
    //     };
    //     this.saveCommentSubmission(data);
    // };
    //
    //
    // saveCommentSubmission = (data) => {
    //     const callbackSuccess = (res) => {
    //         this.setState({
    //             isProcessing: false,
    //             content: ''
    //         });
    //         MiscUtils.showNotification(MESSAGE.SAVE_SUCCESS);
    //         this.props.addCommentSuccessCallback(res.result_for_mobile_app);
    //     };
    //     const callbackError = (error) => {
    //         MiscUtils.commonCallbackError(this, error);
    //     };
    //     this.setState({isProcessing: true}, () => ApiArticle.saveComment(callbackSuccess, callbackError, data));
    // };


    addCommentInMobileSuccessCallback = (comment) => {
        this.toggleWriteComment();
        this.props.addCommentSuccessCallback(comment);
    };

    render (){
        // const { commentPrefixButtons, repliedComment, t, editedComment  } = this.props;
        // const { prefix, isProcessing, redirectToLogin, content } = this.state;
        // let placeHolder = t('Leave your comment');
        // if (repliedComment) {
        //     placeHolder = t('Reply to') + ': ' + repliedComment.user.username;
        //     this.textareaComment.focus();
        // }
        // if (redirectToLogin)
        //     return <Redirect to={SIGN_IN}/>;

        const isMobileView = MiscUtils.isMobileView();
        const { showWriteComment } = this.state;
        const { placeholder } = this.props;




        return (
            <div className="alignCenter marginVerticalMedium">
                <button type="button" className="outlineBtn" onClick={this.toggleWriteComment}>{placeholder}</button>

                <PopupModal isVisible={showWriteComment} onCloseClicked={this.toggleWriteComment} setWidth={isMobileView ? 95 : 50}>
                    <CommentWrite {...this.props} cancelWriteCommentInMobile={this.toggleWriteComment} addCommentSuccessCallback={this.addCommentInMobileSuccessCallback}
                                  placeholder={placeholder}/>
                </PopupModal>

            </div>
        )

    }
}

export default translate()(CommentWriteInMobile);