import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { findDOMNode } from 'react-dom';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
//import '../../static/css/loginButton.css';
import { SIGN_IN, SIGN_UP_SUCCESS } from '../../RouteURL';
import { BlockingComponent } from '../common';
import { MiscUtils, URLUtils } from '../../extras';
import { DELAY_BEFORE_NEXT_ACTION } from '../../config';
import { ApiAuth } from '../../api';
import { setAuthenticatingUser } from '../../redux/actions';


class SignupForm extends React.Component {

    state = {
        email: '',
        password1: '',
        password2: '',
        timezone: MiscUtils.getClientBrowerTimezone(),
        isProcessing: false,
        errorMessage: {}
    };


    onChangeInput = (name, e) => {
        let data = {};
        data[name] = e.target.value;
        this.setState(data);
    };


    showTooltip = () => {
        ['email', 'password1', 'password2'].forEach((refId) => ReactTooltip.show(this.refs[refId]));
    };


    hideTooltip = () => {
        ['email', 'password1', 'password2'].forEach((refId) => ReactTooltip.hide(this.refs[refId]));
    };


    signUp = () => {
        this.hideTooltip();
        const { email, password1, password2 } = this.state;
        const { setAuthenticatingUser } = this.props;
        const disabledSignUp = ((email === '') || (password1 === '') || (password2 === ''));
        if (disabledSignUp)
            return;
        const data = this.state;
        const callbackSuccess = (data) => {
            setAuthenticatingUser({
                email,
                resend_code: data.resend_code
            });
            setTimeout(() => URLUtils.moveToURL(SIGN_UP_SUCCESS), DELAY_BEFORE_NEXT_ACTION);
        };
        const callbackError = (error) => {
            this.setState({
                isProcessing: false,
                errorMessage: error.message
            }, () => {
                this.showTooltip();
            });

        };
        this.setState({
            isProcessing: true,
            errorMessage: {}
        }, () => ApiAuth.signUp(callbackSuccess, callbackError, data));
    };


    render() {
        const isMobileView = MiscUtils.isMobileView();
        const { t } = this.props;
        const { email, password1, password2, isProcessing, errorMessage } = this.state;

        const disabledSignUp = ((email === '') || (password1 === '') || (password2 === ''));
        return (
            <div id="signUpForm">
                <BlockingComponent isProcessing={isProcessing}>
                    <form>
                        <div className="form-group">
                            <label>{t('Email address')}</label>
                            <input type="email" className="form-control" placeholder={t("Email")} value={email}
                                   ref='email' data-tip={errorMessage.email} data-for="tooltipEmail"
                                   onChange={(e) => this.onChangeInput('email', e)}/>
                            <ReactTooltip place={isMobileView ? 'top' : 'right'} id="tooltipEmail"/>
                        </div>
                        <div className="form-group">
                            <label>{t('Password')}</label>
                            <input type="password" className="form-control"  placeholder={t("Password")} value={password1}
                                   ref='password1' data-tip={errorMessage.password1} data-for="tooltipPassword1"
                                   onChange={(e) => this.onChangeInput('password1', e)}/>
                            <ReactTooltip place={isMobileView ? 'top' : 'right'} id="tooltipPassword1"/>
                        </div>
                        <div className="form-group">
                            <label>{t('Confirm password')}</label>
                            <input type="password" className="form-control"  placeholder={t("Confirm password")} value={password2}
                                   ref='password2' data-tip={errorMessage.password2} data-for="tooltipPassword2"
                                   onChange={(e) => this.onChangeInput('password2', e)}/>
                            <ReactTooltip place={isMobileView ? 'top' : 'right'} id="tooltipPassword2"/>
                        </div>

                        <div className="actionBtn">
                            <button type="button" className="btn negativeBtn">
                                <Link to={SIGN_IN} style={{color: 'white'}}>{t('Login')}</Link>
                            </button>
                            <button type="button" className={classnames("btn positiveBtn", {'disabled': disabledSignUp})} onClick={this.signUp}>{t('Sign up')}</button>
                        </div>
                    </form>
                </BlockingComponent>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = {
    setAuthenticatingUser
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignupForm));