import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Glyphicon } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import TextareaAutosize from 'react-autosize-textarea';
import { PhonePrefixCode, Timezone, LanguageUserSettingButton,
    SwitchOnOff, BlockingComponent, DropdownListWithAutoComplete, PopupModal } from '../common';
import { MiscUtils, URLUtils, DateUtils, SELECTED_LANGUAGES } from '../../extras';
import { MESSAGE } from "../../config";
import { ApiUser } from '../../api';
import { translate } from "react-i18next";


class AccountSettingView extends React.Component {

    state = {
        isProcessing: false,
        user: null,
        isOpenDatePickerBirthdate: false,
        changeEmail: '',
        showDeleteUser: false,
        showChangePassword: false,
        showChangeEmail: false,
        originalPrimaryLanguageCode: null
    };

    updateStateUser = (obj, callback) => {
        this.setState({user: MiscUtils.updateObject(this.state.user, obj)});
    };

    updateContactPrefix = (prefix) => {
        this.updateStateUser({'contact_prefix': prefix});
    };

    updateTimezone = (timezone) => {
        this.updateStateUser({'timezone': timezone});
    };

    updatePublicBirthdate = (publicBirthdate) => {
        this.updateStateUser({'public_birth': publicBirthdate});
    };

    handleSwitchChange = (type, checked) => {
        let userTemp = Object.assign({}, this.state.user);
        userTemp.is_public[type] = checked;
        this.setState({ user: userTemp });
    };

    selectBirthdate = (dateObj) => {
        this.setState({user: MiscUtils.updateObject(this.state.user, {'birthdate': DateUtils.legacyDateToMoment(dateObj)})});
        this.toggleDatePickerBirthdate();
    };

    toggleDatePickerBirthdate = (e) => {
        e && e.preventDefault();
        this.setState({isOpenDatePickerBirthdate: !this.state.isOpenDatePickerBirthdate});
    };

    savePrimaryLanguageCallback = (languages) => {
        this.updateStateUser({
            'primary_language_code': languages[0],
            'available_languages_code_list': []
        });
    };

    saveAvailableLanguagesCallback = (languages) => {
        this.updateStateUser({'available_languages_code_list': languages});
    };

    saveSetting = () => {
        const { originalPrimaryLanguageCode } = this.state;
        let user = MiscUtils.cloneObject(this.state.user);
        user.contact = user.contact_prefix + '-' + user.contact_suffix;
        user.birthdate = DateUtils.dateToString(user.birthdate);
        user.is_public = JSON.stringify(user.is_public);
        user.primary_lang = user.primary_language_code;
        user.available_languages = user.available_languages_code_list.join(',');
        const callbackSuccess = (userNew) => {
            // set for language filtering
            MiscUtils.setToLocalStorage(SELECTED_LANGUAGES, [user.primary_language_code].concat(user.available_languages_code_list));
            this.setState({isProcessing: false});
            MiscUtils.showNotification(MESSAGE.SAVE_SUCCESS);
            if (originalPrimaryLanguageCode !== user.primary_language_code)
                MiscUtils.changePrimaryLanguage(user.primary_language_code, true);
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true}, () => ApiUser.saveSettingProfile(callbackSuccess, callbackError, user));
    };


    toggleDeleteUser = () => {
        this.setState({showDeleteUser: !this.state.showDeleteUser});
    };

    deleteUser = () => {
        const data = MiscUtils.serializeForm('#deleteUserForm');
        const callbackSuccess = () => {
            URLUtils.userLogout(this.props);
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true}, () => ApiUser.deleteUser(callbackSuccess, callbackError, data));
    };


    toggleChangePassword = () => {
        this.setState({showChangePassword: !this.state.showChangePassword});
    };

    changePassword = () => {
        const data = MiscUtils.serializeForm('#changePasswordForm');
        const callbackSuccess = () => {
            MiscUtils.showNotification(MESSAGE.SAVE_SUCCESS);
            this.setState({isProcessing: false});
            this.toggleChangePassword();
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true}, () => ApiUser.changePassword(callbackSuccess, callbackError, data));
    };


    toggleChangeEmail = () => {
        this.setState({showChangeEmail: !this.state.showChangeEmail});
    };

    changeEmail = () => {
        const {changeEmail} =  this.state;
        const data = {
            email: changeEmail
        };
        const callbackSuccess = () => {
            MiscUtils.showNotification(MESSAGE.SENT_SUCCESS);
            this.setState({isProcessing: false});
            this.toggleChangeEmail();
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true}, () => ApiUser.changeEmail(callbackSuccess, callbackError, data));
    };

    onChangeInput = (e) => {
        const data = {};
        data[e.target.name] = e.target.value;
        this.setState(data);
    };



    componentDidMount() {
        const callbackSuccess = (user) => {
            user.birthdate = DateUtils.dateFromText(user.birthdate);
            this.setState({
                isProcessing: false,
                user: user,
                originalPrimaryLanguageCode: user.primary_language_code
            });
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true}, () => ApiUser.getProfileForSetting(callbackSuccess, callbackError));
    }


    render() {
        const { t } = this.props;
        const isMobileView = MiscUtils.isMobileView();
        const { user, isOpenDatePickerBirthdate, isProcessing, showDeleteUser, showChangePassword, showChangeEmail, changeEmail } = this.state;
        const genderList = [
            {value: 'M', label: t('Male')},
            {value: 'F', label: t('Female')},
            {value: 'O', label: t('Group')},
        ];

        const publicBirthdateOptions = [
            {value: '2', label: t('Date only')},
            {value: '1', label: t('Public')},
            {value: '0', label: t('Private')}
        ];


        return (
            <BlockingComponent isProcessing={isProcessing}>
                {
                    user &&
                    <div id="accountSettingView">
                        <div className="row">
                            <div className="col-md-3 col-xs-4 col-sm-4">{t('Status')}</div>
                            <div className={classnames("col-md-9 col-xs-8 col-sm-8", {"alignRight": isMobileView})}>
                                <span className="languageBadge">{user.role}</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3 col-xs-4 col-sm-4">{t('Username')}</div>
                            <div className={classnames("col-md-9 col-xs-8 col-sm-8", {"alignRight": isMobileView})}>
                                {user.username}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">{t('Email')}</div>
                            <div className="col-md-9">
                                <span>{user.email}</span>
                                <Glyphicon glyph="pencil" onClick={this.toggleChangeEmail}/>
                                <SwitchOnOff
                                    publicPrivateStyle="true"
                                    checked={user.is_public.email}
                                    handleSwitchChange={(checked) => this.handleSwitchChange('email', checked)}
                                />
                                <PopupModal isVisible={showChangeEmail} onCloseClicked={this.toggleChangeEmail}>
                                    <BlockingComponent isProcessing={isProcessing}>
                                        <div className="form-group" style={{marginBottom: '0px'}}>
                                            {/*<label>Change email</label>*/}
                                            <input type="email" name="changeEmail" className="form-control" value={changeEmail}
                                                   onChange={this.onChangeInput}
                                                   placeholder={t('Enter new email address!')} autoFocus={true}/>
                                        </div>
                                        <div className="actionBtn">
                                            <button type="button" className="btn negativeBtn" onClick={this.toggleChangeEmail}>{t('Cancel')}</button>
                                            <button type="button" className="btn positiveBtn" onClick={this.changeEmail}>{t('Change')}</button>
                                        </div>
                                    </BlockingComponent>
                                </PopupModal>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3 col-xs-4 col-sm-4">{t('Password')}</div>
                            <div className={classnames("col-md-9 col-xs-8 col-sm-8", {"alignRight": isMobileView})}>
                                <span>******</span>
                                <Glyphicon glyph="pencil" onClick={this.toggleChangePassword} />
                            </div>
                            <PopupModal isVisible={showChangePassword}>
                                <form id="changePasswordForm" className="popupForm">
                                    {/*<div className="title">Change password</div>*/}
                                    <div className="form-group">
                                        <input type="password" className="form-control" name="old_password" placeholder={t('Old password')} />
                                    </div>
                                    <div className="form-group">
                                        <input type="password" className="form-control" name="new_password1" placeholder={t('New password')} />
                                    </div>
                                    <div className="form-group">
                                        <input type="password" className="form-control" name="new_password2" placeholder={t('Confirm password')} />
                                    </div>
                                    <div className="actionBtn">
                                        <button type="button" className="btn negativeBtn" onClick={this.toggleChangePassword}>{t('Cancel')}</button>
                                        <button type="button" className="btn positiveBtn" onClick={this.changePassword}>{t('Save')}</button>
                                    </div>
                                </form>
                            </PopupModal>
                        </div>

                        <div className="row birthdateRow">
                            <div className="col-md-3">{t('Birthdate')}</div>
                            <div className="col-md-9 birthdate">
                                <div className="col-md-6 col-xs-6 col-sm-6 columnNoPaddingLeft">
                                    <span className="languageBadge" onClick={this.toggleDatePickerBirthdate}>{user.birthdate && user.birthdate.format("YYYY-MM-DD")}</span>
                                    {
                                        isOpenDatePickerBirthdate &&
                                        <DatePicker
                                            dateFormat="YYYY-MM-DD"
                                            selected={DateUtils.momentToLegacyDate(user.birthdate)}
                                            onChange={this.selectBirthdate}
                                            maxDate={DateUtils.nowLegacyDate()}
                                            withPortal
                                            inline
                                            showMonthDropdown
                                            showYearDropdown
                                        />
                                    }
                                </div>
                                <div className="col-md-offset-3 col-md-3 col-xs-6 col-sm-6 columnNoPaddingRight">
                                    <DropdownListWithAutoComplete options={publicBirthdateOptions} value={user.public_birth}
                                        onChange={this.updatePublicBirthdate}/>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">{t('Contact')}</div>
                            <div className="col-md-9 contact">

                                <div className="col-md-6 col-xs-5 col-sm-5 columnNoPadding">
                                    <PhonePrefixCode prefix={user.contact_prefix} updateContactPrefix={this.updateContactPrefix}/>
                                </div>
                                <div className="col-md-6 col-xs-7 col-sm-5 columnNoPadding">
                                    - <input type="text" name="phone" value={user.contact_suffix} placeholder={t('Phone number')}
                                             onChange={(e) => {
                                                 this.updateStateUser({'contact_suffix': e.target.value});
                                             }}/>
                                    <SwitchOnOff
                                        publicPrivateStyle="true"
                                        checked={user.is_public.contact}
                                        handleSwitchChange={(checked) => this.handleSwitchChange('contact', checked)}
                                    />
                                </div>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">{t('Gender')}</div>
                            <div className="col-md-9 gender">
                                {
                                    genderList.map((obj) =>
                                        <label key={MiscUtils.generateId()}>
                                            <input type="radio" value={obj.value} checked={user.gender === obj.value} key={MiscUtils.generateId()}
                                                   onChange={(e) => {
                                                       this.updateStateUser({'gender': e.target.value});
                                                   }}/> {obj.label}
                                        </label>
                                    )
                                }
                                <SwitchOnOff
                                    publicPrivateStyle="true"
                                    checked={user.is_public.gender}
                                    handleSwitchChange={(checked) => this.handleSwitchChange('gender', checked)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3 col-xs-6 col-sm-6">{t('Primary language')}</div>
                            <div className={classnames("col-md-9 col-xs-6 col-sm-6", {"alignRight": isMobileView})}>
                                {/*<span className="languageBadge">{user.primary_language}</span>*/}
                                {/*<Glyphicon glyph="pencil" />*/}
                                <LanguageUserSettingButton selectedLanguages={[user.primary_language_code]}
                                                           isMultiple={false}
                                                           saveSelectLanguagesCallback={this.savePrimaryLanguageCallback} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">{t('Available languages')}</div>
                            <div className="col-md-9 availableLanguages">
                                <LanguageUserSettingButton selectedLanguages={user.available_languages_code_list}
                                                           disabledLanguages={[user.primary_language_code]}
                                                           isMultiple={true}
                                                           saveSelectLanguagesCallback={this.saveAvailableLanguagesCallback} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">{t('Timezone')}</div>
                            <div className="col-md-9">
                                <Timezone timezone={user.timezone} updateTimezone={this.updateTimezone}/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">{t('Nationality')}</div>
                            <div className="col-md-9">
                                <input type="text" name="nationality" value={user.nationality} className="inputText"
                                    onChange={(e) => {
                                        this.updateStateUser({'nationality': e.target.value});
                                    }}/>
                                <SwitchOnOff
                                    publicPrivateStyle="true"
                                    checked={user.is_public.nationality}
                                    handleSwitchChange={(checked) => this.handleSwitchChange('nationality', checked)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">{t('Location')}</div>
                            <div className="col-md-9">
                                <input type="text" name="location" value={user.location} className="inputText"
                                    onChange={(e) => {
                                        this.updateStateUser({'location': e.target.value});
                                    }} />
                                <SwitchOnOff
                                    publicPrivateStyle="true"
                                    checked={user.is_public.location}
                                    handleSwitchChange={(checked) => this.handleSwitchChange('location', checked)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">{t('Job')}</div>
                            <div className="col-md-9">
                                <input type="text" name="job" value={user.job} className="inputText"
                                    onChange={(e) => {
                                        this.updateStateUser({'job': e.target.value});
                                    }} />
                                <SwitchOnOff
                                    publicPrivateStyle="true"
                                    checked={user.is_public.job}
                                    handleSwitchChange={(checked) => this.handleSwitchChange('job', checked)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">{t('Homepage')}</div>
                            <div className="col-md-9">
                                <input type="text" name="homepage" value={user.homepage} className="inputText"
                                    onChange={(e) => {
                                        this.updateStateUser({'homepage': e.target.value});
                                    }} />
                                <SwitchOnOff
                                    publicPrivateStyle="true"
                                    checked={user.is_public.homepage}
                                    handleSwitchChange={(checked) => this.handleSwitchChange('homepage', checked)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">{t('About me')}</div>
                            <div className="col-md-9">
                                <TextareaAutosize
                                    rows={3}
                                    maxRows={6}
                                    defaultValue={user.introduction}
                                    placeholder={t('who are you?')}
                                    onChange={(e) => {
                                        this.updateStateUser({'introduction': e.target.value});
                                    }}
                                />

                                <SwitchOnOff
                                    publicPrivateStyle="true"
                                    checked={user.is_public.introduction}
                                    handleSwitchChange={(checked) => this.handleSwitchChange('introduction', checked)}
                                />
                            </div>
                        </div>


                        <div className="actionBtn alignCenter">
                            <button type="button" className="btn btn-danger" onClick={this.toggleDeleteUser}>
                                <Glyphicon glyph="user"/>&nbsp;{t('Delete')}
                            </button>
                            <button type="button" className="btn negativeBtn" onClick={URLUtils.backToPreviousPage}>{t('Cancel')}</button>
                            <button type="button" className="btn positiveBtn" onClick={this.saveSetting}>{t('Save')}</button>
                        </div>
                        <PopupModal isVisible={showDeleteUser}>
                            <form id="deleteUserForm" className="popupForm">
                                {/*<div className="title">Delete user</div>*/}
                                <div className="form-group">
                                    <input type="password" className="form-control" name="password1" placeholder={t('Password')}/>
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control" name="password2" placeholder={t('Confirm password')}/>
                                </div>
                                <div className="actionBtn">
                                    <button type="button" className="btn negativeBtn" onClick={this.toggleDeleteUser}>{t('Cancel')}</button>
                                    <button type="button" className="btn btn-danger" onClick={this.deleteUser}><Glyphicon glyph="user"/>&nbsp;{t('Delete')}</button>
                                </div>
                            </form>
                        </PopupModal>

                    </div>
                }

            </BlockingComponent>

        )
    }
}



const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translate()(AccountSettingView)));