import { GLOBAL_NOTIFICATION_MESSAGE_ERROR } from './types';
import { ERROR_MESSAGE } from '../../config';
// import i18n from '../../locales/i18n';

const actionInProgress = (type) => {
    return { type }
};

const actionFinished = (type, error) => {
    let actions = [{ type }];
    if (error){
        let translatedError = error;
        if ((error.indexOf('Tag length should be larger than') === 0) || (error.indexOf('Tag length should be smaller than') === 0)){
            const arr = error.split(' ');
            const no = arr[arr.length - 1];
            let message = ERROR_MESSAGE.TAG_MAXIMUM_EXCEEDED;
            if (error.indexOf('should be larger') >=0 )
                message = ERROR_MESSAGE.TAG_MINIMUM_EXCEEDED;
            translatedError = message.replace('N', no);
        }

        actions.push({
            type: GLOBAL_NOTIFICATION_MESSAGE_ERROR,
            message: translatedError
        });
    }
    return actions;
};


export { actionInProgress, actionFinished };