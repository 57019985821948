import React from 'react';

export default class UserGuideEnglishView extends React.Component {
    render (){
        const { iFrameHeight } = this.props;
        return (
                <iframe data-auto-height="false" data-aspect-ratio="1.33234859675037" scrolling="no" id="doc_15077" width="100%" height={iFrameHeight} frameborder="0"
                        allowFullScreen = "true"
                        mozallowfullscreen = "true"
                        webkitallowfullscreen = "true"
                        src="https://docs.google.com/presentation/d/e/2PACX-1vRganFGyi1GTdafhdwlIc_dpSrqZRR228bKCLIh3sv0wgWqFfp_WTOZ2hLuhZRSYrOxkn1FOzr-Hl0C/embed?start=false&loop=false&delayms=3000"  title="iframe_userguide_en"></iframe>
        )
    }
}