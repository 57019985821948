import React from 'react';
import Linkify from 'react-linkify';

// import { Anchorme } from 'react-anchorme';
// https://alexcorvi.github.io/anchorme.js
// https://github.com/potty/react-anchorme
// https://www.npmjs.com/package/react-linkify
// https://github.com/markdown-it/linkify-it
// http://markdown-it.github.io/linkify-it/
// ko work voi  dangerouslySetInnerHTML trong HTMLContent : https://github.com/tasti/react-linkify/issues/27
export default class AnchormeContent extends React.Component {

    render() {
        return (
            <Linkify>
                {this.props.children}
            </Linkify>
        );

    }
}