import { URLUtils, URL_MAPPING } from '../extras';
import ApiHttp from './http';

export default class ApiUser {

    static getProfile = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.user.getProfile);
        ApiHttp.get(url, callbackSuccess, callbackError, data);
    };

    static getProfileForSetting = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.user.getProfileForSetting);
        ApiHttp.get(url, callbackSuccess, callbackError, data);
    };

    static saveGetInfo = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.user.saveGetInfo);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static saveSettingProfile = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.user.saveSettingProfile);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static getSettingNotification = (callbackSuccess, callbackError) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.user.settingNotification);
        ApiHttp.get(url, callbackSuccess, callbackError);
    };

    static saveSettingNotification = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.user.settingNotification);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static getNameCard = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.user.getNameCard);
        ApiHttp.get(url, callbackSuccess, callbackError, data);
    };

    static deleteUser = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.user.deleteUser);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static changePassword = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.user.changePassword);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static changeEmail = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.user.changeEmail);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static changeEmailConfirm = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.user.changeEmailConfirm);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static saveLanguagesWhenWriting = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.user.saveLanguagesWhenWriting);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static getUserStatistic = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.user.getUserStatistic);
        ApiHttp.get(url, callbackSuccess, callbackError, data);
    };

    static getUserStatisticDetail = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.user.getUserStatisticDetail);
        ApiHttp.get(url, callbackSuccess, callbackError, data);
    };

    static updateLastActivityForUnauthUser = (callbackSuccess, callbackError) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.user.updateLastActivityForUnauthUser);
        ApiHttp.get(url, callbackSuccess, callbackError);
    };

}