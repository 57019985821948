import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';
import { DEFAULT_INDEX } from '../../RouteURL';
import { store } from '../../redux/store';
import { setCurrentUser } from '../../redux/actions';
import { Timezone } from '../common';
import { BlockingComponent, LanguageUserSettingButton } from '../common';
import { PASSWORD_DEFAULT, ERROR_CODE_FROM_BACKEND, DELAY_BEFORE_NEXT_ACTION } from '../../config';
import { MiscUtils, DateUtils, URLUtils, SELECTED_LANGUAGES, ACCESS_TOKEN, IS_USER_PROFILE_PROCESS } from '../../extras';
import { ApiUser } from '../../api';
import { translate } from "react-i18next";



class GetInfoForm extends React.Component {

    state = {
        isProcessing: false,
        isProcessingSaving: false,
        email: '',
        username: '',
        password1: '',
        password2: '',
        primary_lang: '',
        available_languages_code_list: [],
        timezone: '0',
        gender: 'F',
        birthday: null,

        isOpenDatePickerBirthdate: false,
        isRedirected: false,
        errorMessage: {}
    };


    showTooltip = () => {
        ['username', 'password1', 'password2'].forEach((refId) => ReactTooltip.show(this.refs[refId]));
    };


    hideTooltip = () => {
        ['username', 'password1', 'password2'].forEach((refId) => ReactTooltip.hide(this.refs[refId]));
    };


    onChangeInput = (name, e) => {
        let data = {};
        data[name] = e.target.value;
        this.setState(data);
    };


    updateTimezone = (timezone) => {
        this.setState({timezone})
    };


    updateGender = (e) => {
        this.setState({'gender': e.target.value})
    };


    selectBirthdate = (dateObj) => {
        this.setState({birthday: DateUtils.legacyDateToMoment(dateObj)});
        this.toggleDatePickerBirthdate();
    };

    toggleDatePickerBirthdate = (e) => {
        e && e.preventDefault();
        this.setState({isOpenDatePickerBirthdate: !this.state.isOpenDatePickerBirthdate});
    };


    initUser = () => {
        const callbackSuccess = (user) => {
            //user.birthday = DateUtils.dateFromText(user.birthday);
            user.birthday = DateUtils.nowDate();
            user.username = user.username === 'unnamed' ? '' : user.username;
            // moi sign up thi chua co birthday
            const { email, username, connected_to_facebook, timezone, gender, birthday } = user;
            const passwd = connected_to_facebook ? '' : PASSWORD_DEFAULT;
            this.setState({
                isProcessing: false,
                email,
                username,
                password1: passwd,
                password2: passwd,
                timezone,
                gender,
                birthday
            });
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true}, () => ApiUser.getProfileForSetting(callbackSuccess, callbackError));

    };


    saveSetting = () => {
        this.hideTooltip();
        const { username, password1, password2, primary_lang } = this.state;
        const { setCurrentUser } = this.props;
        const disabledSave = ((username === '') || (password1 === '') || (password2 === '') || (primary_lang == ''));

        if (disabledSave) return;
        let data = MiscUtils.cloneObject(this.state);
        data.birthday = DateUtils.dateToString(data.birthday);
        data.available_languages = data.available_languages_code_list.join(',');
        const callbackSuccess = () => {
            // set for language filtering
            MiscUtils.setToLocalStorage(SELECTED_LANGUAGES, [data.primary_lang].concat(data.available_languages_code_list));
            const callbackSuccessNew = (user) => {
                setCurrentUser(user).then(() => {

                    // google ad manager
                    // https://www.npmjs.com/package/react-google-tag-manager
                    // var callback = function () {
                    // };
                    // gtag('event', 'conversion', {
                    //     'send_to': 'AW-939777199/8Z3jCNmqr3QQr7mPwAM',
                    //     'event_callback': callback
                    // });

                    MiscUtils.setToLocalStorage(IS_USER_PROFILE_PROCESS, true);
                    MiscUtils.changePrimaryLanguage(user.language_primary_code, true);
                    // ko can ben duoi cung auto redirect
                    // setTimeout(() => this.setState({
                        // isProcessingSaving: false,
                        // isRedirected: true
                    // }), 2 * DELAY_BEFORE_NEXT_ACTION);
                });
            };
            const callbackErrorNew = (error) => {
                MiscUtils.commonCallbackError(this, error);
            };
            // setState o day la ko duoc, se reload lien
            // this.setState({isProcessingSaving: true}, () => ApiUser.getProfile(callbackSuccess1, callbackError1));
            ApiUser.getProfile(callbackSuccessNew, callbackErrorNew);
        };
        const callbackError = (error) => {
            if (error.message === ERROR_CODE_FROM_BACKEND.FORCE_LOGOUT)
                URLUtils.userLogout(this.props);
            else
                this.setState({
                    isProcessingSaving: false,
                    errorMessage: error.message
                }, () => {
                    this.showTooltip();
                });
        };
        this.setState({
            isProcessingSaving: true,
            errorMessage: {}
        }, () => ApiUser.saveGetInfo(callbackSuccess, callbackError, data));
    };


    savePrimaryLanguageCallback = (languages) => {
        this.setState({
            primary_lang: languages[0],
            available_languages_code_list: []
        });
    };

    saveAvailableLanguagesCallback = (languages) => {
        this.setState({available_languages_code_list: languages});
    };


    componentWillReceiveProps = (nextProps) => {
        //affect khi reload page   Get Info
        this.initUser();
    };


    componentDidMount = () => {
        //affect khi vua login xong
        this.initUser();
    };


    render() {
        const { t } = this.props;
        const { isProcessing, isProcessingSaving, email, username, password1, password2, primary_lang,
            available_languages_code_list, timezone, gender, isOpenDatePickerBirthdate, birthday, isRedirected, errorMessage } = this.state;


        if (isRedirected || (MiscUtils.getFromLocalStorage(ACCESS_TOKEN) && MiscUtils.getFromLocalStorage(IS_USER_PROFILE_PROCESS))) {
            URLUtils.moveToURL(DEFAULT_INDEX);
            return;
        }

        const genderList = [
            {value: 'M', label: t('Male')},
            {value: 'F', label: t('Female')},
            {value: 'O', label: t('Group')},
        ];

        const disabledSave = ((username === '') || (password1 === '') || (password2 === '') || (primary_lang == ''));
        const isMobileView = MiscUtils.isMobileView();

        return (
            <div id="getInfoForm">
                <BlockingComponent isProcessing={isProcessing || isProcessingSaving}>
                    {
                        !isProcessing &&
                        <form>
                            <div className="form-group">
                                <label>{t('Email')}</label>
                                <input type="text" className="form-control"  placeholder={t('Email')} value={email} disabled/>
                            </div>
                            <div className="form-group">
                                <label>{t('Username')}</label>
                                <input type="text" className="form-control"  placeholder={t('Unnamed')} value={username}
                                       ref='username' data-tip={errorMessage.username} data-for="tooltipUsername"
                                       onChange={(e) => this.onChangeInput('username', e)}/>
                                <ReactTooltip place="top" id="tooltipUsername"/>

                            </div>
                            <div className="form-group">
                                <label>{t('Password')}</label>
                                <input type="password" className="form-control"  placeholder={t('Password')} value={password1}
                                       ref='password1' data-tip={errorMessage.password1} data-for="tooltipPassword1"
                                       onChange={(e) => this.onChangeInput('password1', e)}/>
                                <ReactTooltip place="top" id="tooltipPassword1"/>

                            </div>
                            <div className="form-group">
                                <label>{t('Confirm password')}</label>
                                <input type="password" className="form-control"  placeholder={t('Confirm password')} value={password2}
                                       ref='password2' data-tip={errorMessage.password2} data-for="tooltipPassword2"
                                       onChange={(e) => this.onChangeInput('password2', e)}/>
                                <ReactTooltip place="top" id="tooltipPassword2"/>
                            </div>

                            <div className="form-group languages">
                                <label className="title">{t('Primary language')}</label>
                                <LanguageUserSettingButton selectedLanguages={[primary_lang]}
                                                           isMultiple={false}
                                                           saveSelectLanguagesCallback={this.savePrimaryLanguageCallback}
                                                           setWidth={isMobileView ? 95 : 80}/>
                            </div>

                            <div className="form-group">
                                <label className="title">{t('Available languages')}</label>
                                {/*<div>*/}
                                <LanguageUserSettingButton selectedLanguages={available_languages_code_list}
                                                           disabledLanguages={[primary_lang]}
                                                           isMultiple={true}
                                                           saveSelectLanguagesCallback={this.saveAvailableLanguagesCallback}
                                                           setWidth={isMobileView ? 95 : 80}/>
                                {/*</div>*/}
                            </div>

                            <div className="form-group">
                                <label>{t('Timezone')}</label>
                                <Timezone timezone={timezone} updateTimezone={this.updateTimezone}/>
                            </div>

                            <div className="form-group genders">
                                <label className="title">{t('Gender')}</label>
                                {
                                    genderList.map((obj) =>
                                        <label className="gender" key={MiscUtils.generateId()}>
                                            <input type="radio" value={obj.value} checked={gender === obj.value} key={MiscUtils.generateId()}
                                                   onChange={this.updateGender}/> {obj.label}
                                        </label>
                                    )
                                }
                            </div>

                            <div className="form-group languages">
                                <label className="title">{t('Birthdate')}</label>
                                <span className="languageBadge blankHyperlink" onClick={this.toggleDatePickerBirthdate}>{birthday && birthday.format("YYYY-MM-DD")}</span>
                                {
                                    isOpenDatePickerBirthdate &&
                                    <DatePicker
                                        dateFormat="YYYY-MM-DD"
                                        // selected={birthday}
                                        selected={DateUtils.momentToLegacyDate(birthday)}
                                        onChange={this.selectBirthdate}
                                        maxDate={DateUtils.nowLegacyDate()}
                                        withPortal
                                        inline
                                        showMonthDropdown
                                        showYearDropdown
                                    />
                                }
                            </div>

                            <div className="actionBtn">
                                <button type="button" className={classnames("btn positiveBtn", {'disabled': disabledSave})} onClick={this.saveSetting}>{t('Save')}</button>
                            </div>
                        </form>
                    }
                </BlockingComponent>
            </div>
        );
    }
}



const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = {
    setCurrentUser
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translate()(GetInfoForm)));
