import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RelatedArticles, PopularArticles, HowToAsk, NullComponent } from './element';
import RightBarOptional from './RightBarOptional';
import { CLASS_HIDDEN_IN_MOBILE } from '../../config';


export default class RightBar extends React.Component {

    render() {
        return (
            <div id="rightBar" className={"col-md-3"+CLASS_HIDDEN_IN_MOBILE}>
                <RightBarOptional />
            </div>
        )
    }
}
