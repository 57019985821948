import React from 'react';
import { MiscUtils } from '../../../extras';
import TableContentCommentElement from './TableContentCommentElement';
import TableContentCommentElementCompact from './TableContentCommentElementCompact';
import TablePager from './TablePager';
import { BOARD_NAME_QA, BOARD_NAME_COMMUNITY, MESSAGE } from '../../../config';


export default class TableContentComment extends React.Component {

    render() {
        const { data, isProcessing, onPagerChange, isCompact } = this.props;
        const comments = data.rows;
        const { currpage, totalpages } = data;

        return (
            <div id="tableContentComment">
                {   !isProcessing && comments && comments.length > 0 &&
                    <div>
                        {
                            comments.map((comment, idx) => {
                                if (isCompact)
                                    return <TableContentCommentElementCompact comment={comment} key={MiscUtils.generateId()}/>
                                else
                                    return <TableContentCommentElement comment={comment} key={MiscUtils.generateId()}/>

                            })
                        }
                        <TablePager page={parseInt(currpage)} total={parseInt(totalpages)} onPagerChange={onPagerChange}/>
                    </div>
                }

                { !isProcessing && comments && comments.length === 0 &&
                    <div>
                        <label>{MESSAGE.NO_COMMENT}</label>
                    </div>
                }

            </div>
        );
    }
}