import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { BlockingComponent } from '../element';
import { getPopularArticles } from '../../../redux/actions';
import { MiscUtils, URLUtils, CURRENT_BOARD, URL_MAPPING } from '../../../extras';
import { TableSorterTransparent } from '../dataTable';
import { ARTICLE_FILTER_WEEKLY, ARTICLE_FILTER_MONTHLY, BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_NOTICE } from '../../../config';
import { translate } from "react-i18next";


class PopularArticles extends React.Component {

    state = {
        currentBoard: MiscUtils.getFromLocalStorage(CURRENT_BOARD),
        currentSubBoard: '',
        articles: [],
        filterType: ARTICLE_FILTER_WEEKLY[1]
    };

    selectFilter = (filterType) => {
        this.setState({filterType: filterType}, () => this.loadArticles());

    };

    loadArticles = () => {
        const { currentBoard, filterType } = this.state;
        const subCategory = URLUtils.getSubCategoryOfBoardFromURL(this);
        let params = {
            type: currentBoard,
            category: subCategory,
            time: filterType
        };
        const getArticlesURL = URLUtils.buildAPIURL(URL_MAPPING.article.getPopularArticles, params);

        const component = this;
        const { getPopularArticles } = this.props;

        this.setState({articles: [], currentSubBoard: subCategory}, () => {
            getPopularArticles(getArticlesURL, function (articles){
                component.setState({articles: articles});
            });
        });
    };

    componentWillReceiveProps (nextProps){
        const subCategory = URLUtils.getSubCategoryOfBoardFromURL(this);
        if (subCategory !== this.state.currentSubBoard)
            this.loadArticles();
    }

    componentDidMount() {
        this.loadArticles();
    };

    render (){
        const sortButtons = [
            ARTICLE_FILTER_WEEKLY,
            ARTICLE_FILTER_MONTHLY
        ];
        const { currentBoard, articles, filterType} = this.state;
        const { getPopularArticlesProcessing, t } = this.props;
        return (
            <div id="mostRelatedArticles">
                { currentBoard === BOARD_NAME_QA && <h4>{t('Popular questions')}</h4>}
                { currentBoard === BOARD_NAME_COMMUNITY && <h4>{t('Popular posts')}</h4>}
                { currentBoard === BOARD_NAME_NOTICE && <h4>{t('Popular notices')}</h4>}
                <TableSorterTransparent sortButtons={sortButtons} sortType={filterType} selectSorter={this.selectFilter} />
                <BlockingComponent isProcessing={getPopularArticlesProcessing}>
                    {
                        articles.map((article) =>
                            <div key={MiscUtils.generateId()} className="line">
                                <a href={article.url}>{article.title}</a>
                            </div>
                        )
                    }
                    {
                        (!getPopularArticlesProcessing && articles.length === 0) &&
                        <div className="paddingHorizonMedium">{t('No result')}</div>
                    }
                </BlockingComponent>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        getPopularArticlesProcessing: state.reducerArticle.getPopularArticlesProcessing
    }
};

const mapDispatchToProps = {
    getPopularArticles
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translate()(PopularArticles)));