import React from 'react';
import pig from '../../static/images/donation_pig.png';

export default class DonationViewKoreanView extends React.Component {
    render() {
        return (
            <div>
                <p style={{padding: '15px 0px'}}>
                    <img src={pig} className="img-responsive center-block" alt="A" />
                </p>
                <p>조인어스 월드 는 비영리 NGO 네트워크, 조인어스코리아 (JOINUS FOUNDATION)에서 운영하는 웹사이트 입니다.</p>
                <p>조인어스월드는 지식 소통을 통해 '널리 세계인을 이롭게'라는 21세기형 '홍익인간' 정신을 구현하기 위한 웹사이트입니다. 해외 위키피디아가 온라인 백과사전 식의 정보공간이라면 조인어스월드는 보다 개인과 개인 간의 지식 교류를 통해 참여, 공유, 개방, 자율의 정신을 실현하는 공간이 되길 바랍니다. 조인어스월드 말 글대로, 여러분의 참여로 함게 좀 더 나은 세상을 열어 가고자 합니다. 함께 해주세요! 여러분의 관심과 후원을 호소 드립니다.</p>

                <div className="donationAmount">
                    <h5>후원금액 (USD)</h5>
                    <div className="row period">
                        <div className="col-md-3 col-xs-6 col-sm-6">
                            <label> <input type="radio" name="period" value="one" checked/> 한번만 </label>
                        </div>
                        <div className="col-md-3 col-xs-6 col-sm-6">
                            <label> <input type="radio" name="period" value="monthly"/> 매월 </label>
                        </div>
                    </div>
                    <div className="row amount">
                        <div className="col-md-3 col-xs-4 col-sm-4">
                            <label> <input type="radio" name="amount" value="5"/> $5 </label>
                        </div>
                        <div className="col-md-3 col-xs-4 col-sm-4">
                            <label> <input type="radio" name="amount" value="10"/> $10 </label>
                        </div>
                        <div className="col-md-3 col-xs-4 col-sm-4">
                            <label> <input type="radio" name="amount" value="20"/> $20 </label>
                        </div>
                        <div className="col-md-3 col-xs-4 col-sm-4">
                            <label> <input type="radio" name="amount" value="30"/> $30 </label>
                        </div>

                        <div className="col-md-3 col-xs-4 col-sm-4">
                            <label> <input type="radio" name="amount" value="50" checked/> $50 </label>
                        </div>
                        <div className="col-md-3 col-xs-4 col-sm-4">
                            <label> <input type="radio" name="amount" value="75"/> $75 </label>
                        </div>
                        <div className="col-md-3 col-xs-4 col-sm-4">
                            <label> <input type="radio" name="amount" value="100"/> $100 </label>
                        </div>
                        <div className="col-md-3 col-xs-4 col-sm-4">
                            <label> <input type="radio" name="amount" value="other"/> Other </label>
                        </div>
                    </div>

                    <div className="buttons">
                        <div className="col-md-offset-6 col-md-3 col-xs-6 col-sm-6"><button type="button" className="btn positiveBtn">Credit/debit card</button></div>
                        <div className="col-md-3 col-xs-6 col-sm-6"><button type="button" className="btn positiveBtn">PayPal account</button></div>
                    </div>

                    <h5>당신의 후원금은 다음에 사용됩니다.</h5>
                    <div>
                        <strong>설비 부문:</strong> 서버, 회선, 유지보수, 기자재 등.
                    </div>
                    <div>
                        <strong>인력 부문</strong> 웹개발/디자인, 기획, 행정, 세무 등.
                    </div>
                </div>

                <div>후원자의 정보는 조인어스 월드 프로젝트를 위해 저장되며, 누구에게도 개인 정보를 판매하거나 교환하지 않습니다.</div>
                <div style={{margin: '15px 0px', color: '#5cc5ca'}}>매월 기부는 중단을 알릴 때까지 조인어스 코리아에 의해 청구 됩니다.</div>

            </div>
        )
    }
}