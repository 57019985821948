import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { MiscUtils, URLUtils, CURRENT_SUB_BOARD, CURRENT_BOARD } from '../../../extras';
import { BUTTONS, BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_NOTICE } from '../../../config';
import { translate } from "react-i18next";


class LeftBarBoardSubCategoryButtons extends React.Component {

    state = {
        boardType: '',
        leftButtons: [],
        currentSelectButton: ''
    };


    initData = () => {
        const currentURL = URLUtils.getCurrentURL(this);
        let arr = currentURL.split('/');
        if (arr.length < 3)
            MiscUtils.removeFromLocalStorage(CURRENT_SUB_BOARD);
        else if (arr[2] && (arr[2] !== 'write')){
            MiscUtils.setToLocalStorage(CURRENT_SUB_BOARD, arr[2]);
        }

        const boardType = MiscUtils.getFromLocalStorage(CURRENT_BOARD);
        let subBoard = MiscUtils.getFromLocalStorage(CURRENT_SUB_BOARD);

        if (boardType === BOARD_NAME_NOTICE){
            if (subBoard === 'contact') //  /notice/introduction  and  /notice/contact in the same page
                subBoard = 'introduction';
        }
        const buttons = BUTTONS.LEFT_BUTTONS[boardType];

        let currentSelectButton = subBoard ? subBoard : buttons[0][1];
        if ((boardType === BOARD_NAME_NOTICE) && arr[2] && (arr[2] === 'write'))
            currentSelectButton = null;

        this.setState({
            boardType: boardType,
            leftButtons: buttons,
            currentSelectButton: currentSelectButton
        });
    };


    buttonClicked = (btnCode) => {
        this.setState({currentSelectButton: btnCode});
        MiscUtils.setToLocalStorage(CURRENT_SUB_BOARD, btnCode);
        // this.props.history.push('/' + this.state.boardType + '/' + btnCode);
        URLUtils.moveToURL('/' + this.state.boardType + '/' + btnCode);
    };


    // ko didMount duoc vi didMount la sau render, willMount se truoc render
    componentDidMount() {
        this.initData();
    }


    render() {
        const { boardType, leftButtons, currentSelectButton} = this.state;
        const { user, t } = this.props;
        return (
            <div className="list-group">
                {
                    leftButtons.map((button, idx) => (
                        <a key={MiscUtils.generateId()}
                           className={classnames("list-group-item", "blankHyperlink", {activeLeftButton : ((!currentSelectButton && (idx === 0) && (boardType !== BOARD_NAME_NOTICE)) || (currentSelectButton === button[1]))})}
                           onClick={
                                () => {this.buttonClicked(button[1])}
                             }
                        >{button[0]}</a>
                    ))
                }
                {
                    ([BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_NOTICE].indexOf(boardType) >=0) &&
                    (boardType !== BOARD_NAME_NOTICE || (user.is_admin)) &&
                    <a href={"/"+boardType+"/write"} className="list-group-item askButton">
                        { (boardType === BOARD_NAME_QA) ? t('Ask') : t('Write')}
                    </a>
                }
            </div>

        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.reducerAuth.user
    }
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translate()(LeftBarBoardSubCategoryButtons)));