import React from 'react';
import classnames from 'classnames';
import { Glyphicon } from 'react-bootstrap';
// import FontAwesome from 'react-fontawesome';
import ReactTooltip from 'react-tooltip';
import {
    UserAvatarInfo, NamecardInResponse, SourceList, HTMLContent, DropDownMenu, Comments,
    BlockingComponent, SNSSharingBtn, PopupModal, ReactionButton, PopupConfirm
} from '../common';
import ResponseWrite from './ResponseWrite';
import {
    BOARD_NAME_QA, ARTICLE_TYPE, CONTENT_BLOCKED_MESSAGE, MESSAGE, CLASS_HIDDEN_IN_MOBILE,
    CLASS_HIDDEN_IN_PC, DELAY_BEFORE_SCROLL_TO_HIGHLIGHTED_RESPONSE, MAX_WORDS_IN_CONTENT_SHOW_READ_MORE } from '../../config';
import { MiscUtils, URLUtils } from '../../extras';
import { ApiArticle } from '../../api';
import Check from '../../static/images/icon/check.png';
import Uncheck from '../../static/images/icon/uncheck.png';
import { translate } from "react-i18next";
import {BOARD_NAME_COMMUNITY} from "../../config/common";
import {SIGN_IN} from "../../RouteURL";



class Response extends React.Component {

    state = {
        isProcessing: false,
        isShowDeleteConfirm: false,
        isShowCommentList: false,
        isDeleted: false,
        comments: this.props.response.comments,
        isBlocked: this.props.response.is_blocked,
        isReported: this.props.response.is_reported,

        isVoted: this.props.response.is_voted,
        isDownVoted: this.props.response.is_down_voted,
        voteCount: this.props.response.count_vote,
        voteDownCount: this.props.response.count_down_vote,
        showEditResponse: false,
        isShowReadMore: this.props.response.content.split(" ").length > MAX_WORDS_IN_CONTENT_SHOW_READ_MORE,
        isShowActionList: false
    };


    toggleEditResponse = () => {

        if (this.state.showEditResponse) {
            let text = "If you leave now, you will lose all your data.";
            if (window.confirm(text) == true) {
                this.setState({showEditResponse: !this.state.showEditResponse});
                return;
            } else {
                text = "You canceled!";
                return;
            }
        }

        this.setState({showEditResponse: !this.state.showEditResponse});
    };


    toggleShowCommentList = () => {
        this.setState({isShowCommentList: !this.state.isShowCommentList});
    };


    toggleShowReadMore = () => {
        this.setState({isShowReadMore: !this.state.isShowReadMore});
        // const { currentUser } = this.props;
        // if (currentUser.user_id)
        //     this.setState({isShowReadMore: !this.state.isShowReadMore});
        // else {
        //     const currentURL = URLUtils.getCurrentPathname();
        //     URLUtils.moveToURL(SIGN_IN+"?next="+currentURL);
        // }
    };


    addCommentSuccessCallback = (comment) => {
        this.setState(prevState => ({
            comments: [...prevState.comments, comment]
        }));
    };


    setAcceptedResponse = () => {
        const { response } = this.props;
        const data = {
            response_id: response.id
        };
        const callbackSuccess = (data) => {
            URLUtils.reloadPage();
        };
        const callbackError = (error) => {
            this.setState({isProcessing: false});
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true}, () => ApiArticle.setAcceptedResponse(callbackSuccess, callbackError, data));
    };


    toggleShowActionList = () => {
        this.setState({isShowActionList: !this.state.isShowActionList});
    };


    editArticle = () => {
        this.toggleShowActionList();
        this.toggleEditResponse();
    };


    deleteArticle = () => {
        this.toggleShowActionList();
        const { response, boardType, t } = this.props;
        let article_type = ARTICLE_TYPE.REPLY;
        if (boardType === BOARD_NAME_QA)
            article_type = ARTICLE_TYPE.ANSWER;
        const data = {
            article_id: response.id,
            article_type: article_type
        };
        const callbackSuccess = (data) => {
            MiscUtils.showNotification(MESSAGE.DELETE_SUCCESS);
            this.toggleShowDeleteConfirm();
            this.setState({isProcessing: false, isDeleted: true});
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true}, () => ApiArticle.deleteArticle(callbackSuccess, callbackError, data));
    };


    toggleShowDeleteConfirm= () => {
        this.setState({isShowDeleteConfirm: !this.state.isShowDeleteConfirm});
    }


    blockArticle = () => {
        this.toggleShowActionList();
        const { response, boardType } = this.props;
        let article_type = ARTICLE_TYPE.REPLY;
        if (boardType === BOARD_NAME_QA)
            article_type = ARTICLE_TYPE.ANSWER;
        const data = {
            article_id: response.id,
            article_type: article_type,
            block_type: 2 // fix REDUNDANCY
        };
        const callbackSuccess = (data) => {
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        ApiArticle.blockArticle(callbackSuccess, callbackError, data);
        this.setState({isBlocked: !this.state.isBlocked});
    };


    reportArticle = () => {
        this.toggleShowActionList();
        const { response, boardType } = this.props;
        let article_type = ARTICLE_TYPE.REPLY;
        if (boardType === BOARD_NAME_QA)
            article_type = ARTICLE_TYPE.ANSWER;
        const data = {
            article_id: response.id,
            article_type: article_type,
            report_type: 2 // fix SPAM_TYPE_REDUNDANCY
        };
        const callbackSuccess = (data) => {
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        ApiArticle.reportArticle(callbackSuccess, callbackError, data);
        // made it instantly
        this.setState({isReported: !this.state.isReported});
    };



    voteArticle = () => {
        const { response, boardType, currentUser, t } = this.props;
        const { isVoted, voteCount, isDownVoted,  voteDownCount } = this.state;
        if (currentUser.user_id === response.user.user_id) {
            MiscUtils.showErrorMessage(t('You cannot vote your article'));
            return;
        }
        if (isDownVoted) {
            MiscUtils.showErrorMessage(t('You have already voted down. Un-vote down before voting up.'));
            return;
        }
        let article_type = ARTICLE_TYPE.REPLY;
        if (boardType === BOARD_NAME_QA)
            article_type = ARTICLE_TYPE.ANSWER;
        const data = {
            article_id: response.id,
            article_type: article_type
        };
        const callbackSuccess = (data) => {
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        ApiArticle.voteArticle(callbackSuccess, callbackError, data);

        // make it instantly
        let newCount = voteCount;
        if (isVoted)
            newCount -= 1;
        else
            newCount += 1;
        this.setState({
            isVoted: !this.state.isVoted,
            voteCount: newCount
        })
    };


    voteDownArticle = () => {
        const { response, boardType, currentUser, t } = this.props;
        const { isVoted, isDownVoted,  voteDownCount } = this.state;
        if (currentUser.user_id === response.user.user_id) {
            MiscUtils.showErrorMessage(t('You cannot vote your article'));
            return;
        }
        if (isVoted) {
            MiscUtils.showErrorMessage(t('You have already voted up. Un-vote up before voting down.'));
            return;
        }
        let article_type = ARTICLE_TYPE.REPLY;
        if (boardType === BOARD_NAME_QA)
            article_type = ARTICLE_TYPE.ANSWER;
        const data = {
            article_id: response.id,
            article_type: article_type
        };
        const callbackSuccess = (data) => {
            // let newCount = voteDownCount;
            // if (isDownVoted)
            //     newCount -= 1;
            // else
            //     newCount += 1;
            // this.setState({
            //     isDownVoted: !this.state.isDownVoted,
            //     voteDownCount: newCount
            // })
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        ApiArticle.voteDownArticle(callbackSuccess, callbackError, data);

        // make it instantly
        let newCount = voteDownCount;
        if (isDownVoted)
            newCount -= 1;
        else
            newCount += 1;
        this.setState({
            isDownVoted: !this.state.isDownVoted,
            voteDownCount: newCount
        })
    };


    componentDidMount() {
        MiscUtils.initElementArticleContent();
        const { highlightedResponseId, highlightedCommentId, response } = this.props;
        if (highlightedResponseId && (response.id === highlightedResponseId))
            setTimeout(() => {
                MiscUtils.scrollToObject('response_' + highlightedResponseId);
            }, DELAY_BEFORE_SCROLL_TO_HIGHLIGHTED_RESPONSE);
        if (highlightedCommentId){  // id=comments-of-295-652
            const arr = highlightedCommentId.split('-');
            const openedResponse = arr[2];
            if (response.id === parseInt(openedResponse)) {
                this.setState({isShowCommentList: true});
            }
        }
    }


    render (){
        const isMobileView = MiscUtils.isMobileView();
        const { isShowCommentList, comments, isDeleted, isBlocked, isVoted, voteCount, isDownVoted,
            voteDownCount, isReported, isProcessing, showEditResponse, isShowReadMore, isShowActionList, isShowDeleteConfirm } = this.state;
        const { article, response, boardType, currentUser, highlightedResponseId, order, t } = this.props;
        let responseURL = article.view_url_absolute;
        if (boardType === BOARD_NAME_QA )
            responseURL += '?id=answer_';
        else if (boardType === BOARD_NAME_COMMUNITY )
            responseURL += '?id=reply_';
        responseURL += response.id;

        const editBtn = (
            <p onClick={this.editArticle}>{t('Edit')}</p>
        );
        const blockBtn = (
            <p onClick={this.blockArticle}>{isBlocked ? t('Unblock') : t('Block')}</p>
        );
        const deleteBtn = (
            <p onClick={() => {
                this.toggleShowActionList();
                this.toggleShowDeleteConfirm();
            }}>{t('Delete')}</p>
        );
        const reportBtn = (
            <p onClick={this.reportArticle}>{isReported ? t('Unreport spam') : t('Report spam')}</p>
        );

        let articleAction = null;
        if (currentUser.user_id){
            if (currentUser.is_admin)
                articleAction = (
                    <div className="tooltipActionButton">
                        { blockBtn }
                        {
                            (currentUser.user_id === response.user.user_id) &&
                            editBtn
                        }
                        { deleteBtn }
                        { reportBtn }
                    </div>
                );
            else if (currentUser.user_id === response.user.user_id)
                articleAction = (
                    <div className="tooltipActionButton">
                        {
                            !isBlocked &&
                            editBtn
                        }
                        { deleteBtn }
                    </div>
                );
            else
                articleAction = (
                    <div className="tooltipActionButton">
                        { reportBtn }
                    </div>
                );

        }


        return (
            <BlockingComponent isProcessing={isProcessing}>
                {
                    !isDeleted &&
                    <div className={classnames('responseView', {"highlighted": highlightedResponseId === response.id})}
                         id={"response_"+response.id}>
                        <div className="row1" >
                            {
                                response.is_accepted && (boardType === BOARD_NAME_QA) &&
                                <span>
                                    {
                                        (article.user.user_id === currentUser.user_id) &&
                                        <img className="solved cursorPointer" src={Check} alt="Solved" onClick={this.setAcceptedResponse}/>
                                    }
                                    {
                                        (article.user.user_id !== currentUser.user_id) &&
                                        <img className="solved" src={Check} alt="Solved" />
                                    }

                                </span>
                            }
                            {
                                !response.is_accepted && (boardType === BOARD_NAME_QA) &&
                                (article.user.user_id === currentUser.user_id) &&
                                (response.user.user_id !== currentUser.user_id) &&
                                <span>
                                    {/*<FontAwesome name="check" className="setAcceptedAnswer" data-tip data-for={'setAcceptedAnswer_'+response.id}/>*/}
                                    <img className="solved cursorPointer" src={Uncheck} alt="Set accepted" data-tip data-for={'setAcceptedResponse_'+response.id}
                                        onClick={this.setAcceptedResponse}/>
                                    {
                                        !isMobileView &&
                                        <ReactTooltip id={'setAcceptedResponse_'+response.id} className="tooltipDefaultForBtn" effect='solid'>
                                            {t('Accept as the best answer')}
                                        </ReactTooltip>
                                    }

                                </span>

                            }
                            {
                                order && order <= 3 &&
                                <div className={"response_order response_order_"+order}></div>
                            }
                            <UserAvatarInfo user={response.user} />
                        </div>


                        <div className="content dontBreakOut">
                            <div className={classnames({"continueReading" : isShowReadMore})}>
                                <HTMLContent content={response.content}/>
                                {
                                    isShowReadMore &&
                                    <p className="readMore">
                                        <button className="outlineNegativeText" onClick={this.toggleShowReadMore}>{t('Read more')}</button>
                                    </p>
                                }
                            </div>
                        </div>


                        <div className={"row marginNoneLeftRight marginBottom10 "+CLASS_HIDDEN_IN_PC}>
                            <SNSSharingBtn customClass="pull-left" url={responseURL} author={response.author} boardType={boardType}/>
                            <span className="pull-right">{response.modified_date}</span>
                        </div>


                        {
                            isBlocked &&
                            <div className='errorText alignCenter'>{CONTENT_BLOCKED_MESSAGE}</div>
                        }

                        <div className={"row marginNoneLeftRight marginBottom10 "+CLASS_HIDDEN_IN_MOBILE}>
                            <SNSSharingBtn customClass="pull-left" url={responseURL} author={response.author} boardType={boardType}/>
                            <span className="pull-right">{response.modified_date}</span>
                        </div>


                        {   (boardType === BOARD_NAME_QA) && (response.sources.length > 0) &&
                            <SourceList sources={response.sources} />
                        }


                        <NamecardInResponse user={response.user} {...this.props} />


                        <div className="infoAction marginTopMedium">
                            <div className="item">
                                <ReactionButton boardType={boardType} response={response} />
                            </div>
                        </div>


                        <div className={classnames("infoAction", {'marginRight40': isMobileView})}>
                            <button type="button"
                                    className={classnames("btn outlineBtn", {"isVoted": isVoted})}
                                    data-rh={isVoted ? t("Unvote") : t("Vote")}
                                    onClick={this.voteArticle}
                            >
                                <Glyphicon glyph="thumbs-up" />
                                {
                                    (voteCount > 0) &&
                                    <span>&nbsp;({voteCount})</span>
                                }
                            </button>

                            <button type="button"
                                    className={classnames("btn outlineBtn item", {"isVoted": isDownVoted})}
                                    data-rh={isDownVoted ? t("Unvote down") : t("Vote down")}
                                    onClick={this.voteDownArticle}
                            >
                                <Glyphicon glyph="thumbs-down" />
                                {
                                    (voteDownCount > 0) &&
                                    <span>&nbsp;({voteDownCount})</span>
                                }
                            </button>


                            <span className="item collapsibleButton" onClick={this.toggleShowCommentList}>
                                {t('Comment')} <span className="numStatsCount">{response.comments.length}</span>
                            </span>
                            {
                                (articleAction) &&
                                <DropDownMenu htmlContent={articleAction} customClassName="pull-right" isShown={isShowActionList} clickOutsideWhenManual={this.toggleShowActionList}>
                                    <Glyphicon glyph="option-horizontal" className="cursorPointer" onClick={this.toggleShowActionList}/>
                                </DropDownMenu>
                            }
                        </div>

                        <Comments isShowCommentList={isShowCommentList}
                                  comments={comments}
                                  parentType={ARTICLE_TYPE.RESPONSE}
                                  parentId={response.id}
                                  addCommentSuccessCallback={this.addCommentSuccessCallback}
                                  isBlocked={isBlocked} {...this.props}/>

                        <hr/>
                    </div>
                }


                <PopupModal isVisible={showEditResponse} onCloseClicked={this.toggleEditResponse} setWidth={isMobileView ? 95 : 50} style={{ top: '30%' }}>
                    <ResponseWrite {...this.props} editedResponse={response} cancelEditResponse={this.toggleEditResponse}/>
                </PopupModal>

                <PopupConfirm bodyText={t("Are you sure to delete?")} onConfirm={this.deleteArticle}
                              showModal={isShowDeleteConfirm} toggleShowModal={this.toggleShowDeleteConfirm}/>

            </BlockingComponent>
        )
    }
}

export default translate()(Response);
