import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import classnames from 'classnames';
import LanguageSelectionModal from './LanguageSelectionModal';
import { MAX_LANGUAGE_FILTERING, getLanguageTextFromCode } from "../../../config/common";
import { MiscUtils, SELECTED_LANGUAGES } from '../../../extras';
import { translate } from "react-i18next";
import { ApiUser } from '../../../api';


class LanguageSelectionArticleWriteButton extends React.Component {

    state = {
        showPopup: false,
        selectedLanguages: this.props.selectedLanguages
    };


    componentWillReceiveProps(nextProps) {
        this.setState({selectedLanguages: nextProps.selectedLanguages});
    };


    togglePopup = () => {
        this.setState({showPopup: !this.state.showPopup});
    };


    saveSelectLanguagesCallback = (selectedLanguages) => {
        const {selectLanguagesCallback} = this.props;
        this.setState({selectedLanguages}, () => {
            // khac voi LanguageUserSettingButton.js
            // de o day chu ko de upper parent la vi xai o nhieu View
            // MiscUtils.setToLocalStorage(SELECTED_LANGUAGES, selectedLanguages);
            // const { saveSelectLanguagesCallback } = this.props;
            // if (saveSelectLanguagesCallback)
            //     saveSelectLanguagesCallback(selectedLanguages);

            const data = {
                languages: selectedLanguages.join(',')
            };
            const callbackSuccess = (data) => {
            };
            const callbackError = (error) => {
                MiscUtils.commonCallbackError(this, error);
            };
            ApiUser.saveLanguagesWhenWriting(callbackSuccess, callbackError, data);
        });
        selectLanguagesCallback(selectedLanguages);
    };


    render (){
        const { showPopup, selectedLanguages } = this.state;
        const { selectedLanguage, languageButtonClick, t } = this.props;
        return (
                <LanguageSelectionModal showPopup={showPopup} selectedLanguages={selectedLanguages} isMultiple={true}
                                        disabledLanguages={[selectedLanguage]}
                                        maxSelected={MAX_LANGUAGE_FILTERING}
                                        togglePopup={this.togglePopup}
                                        saveSelectLanguagesCallback={this.saveSelectLanguagesCallback} >
                    <div id="languageSelectionArticleWriteButton" className="languages">
                        {
                            selectedLanguages.map((langCode, idx) =>
                                <button type="button" key={MiscUtils.generateId()}
                                        className={classnames("btn outlineBtn", {"active": langCode === selectedLanguage})}
                                        onClick={() => languageButtonClick(langCode)}
                                >{getLanguageTextFromCode(langCode)}</button>
                            )
                        }
                        <button type="button" className="btn btn-default btn-circle editIcon" onClick={this.togglePopup}>
                            <Glyphicon glyph="pencil" />
                        </button>
                    </div>
                </LanguageSelectionModal>
        )
    }
}

export default translate()(LanguageSelectionArticleWriteButton);