import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import LanguageSelectionModal from './LanguageSelectionModal';
import { getLanguageTextFromCode } from "../../../config/common";


export default class LanguageArticleSettingButton extends React.Component {

    state = {
        showPopup: false,
        selectedLanguage: this.props.selectedLanguage
    };

    togglePopup = () => {
        this.setState({showPopup: !this.state.showPopup});
    };


    saveSelectLanguageCallback = (selectedLanguage) => {
        this.setState({selectedLanguage}, () => {
            const { saveSelectLanguageCallback } = this.props;
            if (saveSelectLanguageCallback)
                saveSelectLanguageCallback(selectedLanguage);
        });
    };

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            showPopup: nextProps.showPopup,
            selectedLanguage: nextProps.selectedLanguage
        });
    };


    render (){
        const { showPopup, selectedLanguage } = this.state;
        const { setWidth } = this.props;
        return (
            <LanguageSelectionModal showPopup={showPopup}
                                    selectedLanguages={[selectedLanguage]}
                                    isMultiple={false}
                                    setWidth={setWidth}
                                    togglePopup={this.togglePopup}
                                    saveSelectLanguagesCallback={this.saveSelectLanguageCallback} >

                <label className="languageBadge whiteColor">
                    {getLanguageTextFromCode(selectedLanguage)} &nbsp;
                    <Glyphicon glyph="chevron-down" className="blankHyperlink marginNoneLeftRight" onClick={this.togglePopup}/>
                </label>

            </LanguageSelectionModal>
        )
    }
}