import React from 'react';
import classnames from 'classnames';
import BlockingComponent from './BlockingComponent'
import { TableSorterTransparent } from '../dataTable';
import { MiscUtils, URLUtils } from '../../../extras';
import { ApiTag } from '../../../api';
import { SEARCH_TAG } from '../../../RouteURL';
import { translate } from "react-i18next";


class LeftBarTagRanking extends React.Component {

    state = {
        boardType: URLUtils.getCurrentPathname().split('/')[1], //  /qa/general -> qa
        isProcessing: false,
        period: 'weekly',
        listObjects: []
    };


    selectSorter = (period) => {
        this.setState({
            period: period
        }, () => this.loadData());
    };


    loadData = () => {
        const { period, boardType } = this.state;
        const data = {
            period,
            boardType
        };
        const callbackSuccess = (data) => {
            this.setState({
                isProcessing: false,
                listObjects: data
            });
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true, listObjects: []}, () => {
            ApiTag.getTagRanking(callbackSuccess, callbackError, data);
        });
    };


    componentDidMount() {
        this.loadData();
    }


    render (){
        const { boardType, listObjects, period, isProcessing } = this.state;
        const { t } = this.props;
        const sortButtons = [
            [t('Weekly'), 'weekly'],
            [t('Monthly'), 'monthly']
        ];

        return (
            <div id="topRanking">
                <h4>{t('Tag ranking')}</h4>
                <TableSorterTransparent sortButtons={sortButtons} sortType={period} selectSorter={this.selectSorter}/>
                <BlockingComponent isProcessing={isProcessing}>
                    {
                        listObjects.map((tag, idx) => (
                            <div key={MiscUtils.generateId()} className="articleTitle">
                                <button type="button"
                                    className={classnames("btn btn-default btn-circle", {'rank01':idx===0}, {'rank02':idx===1}, {'rank03':idx===2})}
                                >{idx+1}</button>
                                <a href={SEARCH_TAG+tag.tag}> {tag.tag} ({tag.count}) </a>
                            </div>
                        ))
                    }

                    {
                        (!isProcessing && listObjects.length === 0) &&
                        <div className="paddingHorizonMedium">{t('No result')}</div>
                    }

                </BlockingComponent>
            </div>
        );
    }

}

export default translate()(LeftBarTagRanking);