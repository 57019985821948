import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LeftBarBoardSubCategoryButtons, LeftBarTagRanking, LeftBarUserInfo, LeftBarProfileInfo } from './element';
import { CLASS_HIDDEN_IN_PC } from '../../config';
import RightBarOptional from './RightBarOptional';
import LeftBarOptional from './LeftBarOptional';


export default class BottomBar extends React.Component {

    render() {
        return (
            <div id="bottomBar" className={"myMainContent marginNone"+CLASS_HIDDEN_IN_PC}>
                <LeftBarOptional/>
                <RightBarOptional/>
            </div>
        )
    }
}