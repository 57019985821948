import React from 'react';
import { MultiSelect} from 'react-selectize';
import { translate } from "react-i18next";


class SourceInput extends React.Component {

    render() {
        const { onChange, selectedSources, t } = this.props;
        return (
            <div id="sourceInput">
                <MultiSelect
                    values = {selectedSources ? selectedSources : []}
                    placeholder={t('Enter or Comma(,) to enter source')}


                    // delimtiers :: [KeyCode]
                    delimiters = {[188]}

                    // valuesFromPaste :: [Item] -> [Item] -> String -> [Item]
                    valuesFromPaste = {function(options, values, pastedText){
                        return pastedText
                            .split(",")
                            .filter(function(text){
                                var labels = values.map(function(item){
                                    return item.label;
                                });
                                return labels.indexOf(text) == -1;
                            })
                            .map(function(text){
                                return {label: text, value: text};
                            });
                    }}

                    // restoreOnBackspace :: Item -> String
                    restoreOnBackspace = {function(item){
                        return item.label;
                    }}



                    // onValuesChange :: [Item] -> (a -> Void) -> Void
                    onValuesChange = {function(tags){
                        //self.setState({tags: tags}, () => onChange(tags));
                        onChange(tags);
                    }}

                    createFromSearch = {function(options, values, search){
                        const labels = values.map(function(value){
                            return value.label;
                        });
                        if (search.trim().length === 0 || labels.indexOf(search.trim()) !== -1)
                            return null;
                        return {label: search.trim(), value: search.trim()};
                    }}

                    // renderNoResultsFound :: [Item] -> String -> ReactElement
                    renderNoResultsFound = {function(values, search) {
                        return <div className = "no-results-found">
                            {function(){
                                if (search.trim().length === 0)
                                    return t('Type a few characters to create source');
                                else if (values.map(function(item){ return item.label; }).indexOf(search.trim()) !== -1)
                                    return t('Source already exists');
                            }()}
                        </div>
                    }}

                />
            </div>
        )
    }
    // render (){
    //     return (
    //         <div className="input-group">
    //             <input type="text" className="form-control" placeholder="Enter the source"></input>
    //            <span className="input-group-btn">
    //                <button type="button" className="btn bt-default outlineBtn" style={{padding: '6px 12px'}}><Glyphicon glyph="plus" /></button>
    //            </span>
    //         </div>
    //     )
    // }
}

export default translate()(SourceInput);