import React from 'react';
import { Glyphicon } from 'react-bootstrap';

export default class CollapsibleButton extends React.Component {
    render() {
        //triangle-bottom triangle-top
        const { text, isCollapsed} = this.props;
        return (
            <div className="collapsibleButton">
                <span>{text}</span>
                { isCollapsed && <Glyphicon glyph="menu-down" className="pull-right"/>}
                { !isCollapsed && <Glyphicon glyph="menu-up" className="pull-right"/>}
            </div>
        )
    }
}