import React from 'react';
import HTMLContent from './HTMLContent';
import ReactMarkdown from 'react-markdown'
import parse from 'html-react-parser';
import linkifyHtml from 'linkifyjs/html';

export default class PreviewContent extends React.Component {
    render () {
        const { htmlContent } = this.props;
        return (
            <div className="previewResponseContent previewArticleContent">
                <div dangerouslySetInnerHTML={ {__html: htmlContent} } />
                {/*<ReactMarkdown skipHtml={false} children={htmlContent}/>*/}
            </div>
        )
    }
}
