import { GET_RESPONSES__PROCESSING, GET_RESPONSES__FINISHED } from '../actions/types'
import { MiscUtils } from '../../extras';

const initialState = {
    // boardType: '',
    getResponsesProcessing: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type){
        case GET_RESPONSES__PROCESSING:
            return MiscUtils.updateObject(state, {getResponsesProcessing: true});
        case GET_RESPONSES__FINISHED:
            return MiscUtils.updateObject(state, {getResponsesProcessing: false});
        default:
            return state;
    }
}