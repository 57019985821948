import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Glyphicon } from 'react-bootstrap';
import { MiscUtils } from '../../../extras';
import TagInput from './TagInput';
import { ApiTag } from '../../../api';
import { MESSAGE } from "../../../config";
import { SEARCH_TAG } from '../../../RouteURL';


class TagList extends React.Component {

    state = {
        isShowTagInput: false,
        currentTags: this.props.tags,
        selectedTags: this.props.tags.map((tag) => {
            return {label: tag, value: tag};
        })
    };


    toggleTagInput = () => {
        this.setState({isShowTagInput: !this.state.isShowTagInput});
    };


    inputTag = (tags) => {
        this.setState({selectedTags: tags});
    };


    cancelTag = () => {
        this.toggleTagInput();
    };


    saveTag = () => {
        const { article } = this.props;
        const { selectedTags } = this.state;
        const tags = selectedTags.map((tagObj) => tagObj.value);
        const data = {
            article_id: article.article_id,
            article_type: article.article_type,
            tag: tags.join(',')
        };
        const callbackSuccess = (data) => {
            this.setState({currentTags: selectedTags.map((tag) => tag.value)});
            this.toggleTagInput();
            MiscUtils.showNotification(MESSAGE.SAVE_SUCCESS);
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        ApiTag.saveTagEdit(callbackSuccess, callbackError, data);
    };


    render (){
        const { user, isHideEditButton } = this.props;
        const { isShowTagInput, currentTags, selectedTags } = this.state;
        return (
            <div className="tagList">
                {
                    !isShowTagInput &&
                    <div>
                        {
                            currentTags.map((tag) => (
                                <label className="tagLabel" key={MiscUtils.generateId()}>
                                    <a href={SEARCH_TAG + tag} target="_blank">{tag}</a>
                                </label>
                            ))
                        }
                        {
                            user && user.is_show_edit_tag && !isHideEditButton &&
                            <button type="button" className="btn btn-default btn-circle" onClick={this.toggleTagInput}>
                                <Glyphicon glyph="pencil" />
                            </button>
                        }

                    </div>
                }
                {
                    isShowTagInput && !isHideEditButton &&
                    <div className="tagEdit">
                        <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                            <TagInput selectedTags={selectedTags} onChange={this.inputTag} isHideErrorText={true}/>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 displayFlex">
                            <Glyphicon glyph="remove" onClick={this.cancelTag}/>
                            <Glyphicon glyph="ok" onClick={this.saveTag}/>
                        </div>


                    </div>
                }

            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.reducerAuth.user
    }
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TagList));