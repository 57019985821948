import i18n from '../locales/i18n';

export const BUTTONS = {
    LEFT_BUTTONS: {
        qa: [
            ['FAQ', 'faq'],
            [i18n.t('Main'), 'main'],
            [i18n.t('General'), 'general'],
            [i18n.t('Life & Travel'), 'life_and_travel'],
            [i18n.t('Society & Culture'), 'society_and_culture'],
            [i18n.t('Medical'), 'medical'],
            [i18n.t('Law'), 'law'],
            [i18n.t('Local area'), 'local_area'],
            [i18n.t('Business & Finance'), 'business_and_finance'],
            [i18n.t('Language & Translation'), 'language_and_translation']
        ],
        community: [
            [i18n.t('Main'), 'main'],
            [i18n.t('News'), 'news'],
            [i18n.t('Talk & Info'), 'talk'],
            [i18n.t('Culture & Event'), 'culture'],
            [i18n.t('Entertainment'), 'entertainment'],
            [i18n.t('Education'), 'education'],
            [i18n.t('Column'), 'column'],
            [i18n.t('Find friends & Language Exchange'), 'find_friends'],
            [i18n.t('Meet up'), 'meetup'],
            [i18n.t('Housing'), 'housing'],
            [i18n.t('Job'), 'job'],
            [i18n.t('Business lounge'), 'business'],
            [i18n.t('Voice of members'), 'opinion']
        ],
        notice: [
            [i18n.t('Notice'), 'notice'],
            [i18n.t('About JOINUS WORLD'), 'introduction'],
            [i18n.t('User guide'), 'userguide'],
            [i18n.t('Contributors'), 'translator'],
            [i18n.t('Privacy'), 'privacy'],
            [i18n.t('Terms of Service'), 'termOfService'],
            [i18n.t('Inquiry & feedback'), 'feedback'],
            [i18n.t('Donation'), 'donation']
        ],
        ranking: [
            [i18n.t('People'), 'people'],
            [i18n.t('Best question'), 'question'],
            [i18n.t('Best answer'), 'answer'],
            [i18n.t('Best post'), 'post'],
            [i18n.t('Best reply'), 'reply']
        ]
    },

    SORT_BUTTONS: {
        // qa: [
        //     [i18n.t('NEWEST'), 'newest'],
        //     [i18n.t('VOTE'), 'vote'],
        //     [i18n.t('NO ANSWER'), 'no_answer']
        // ],
        // community: [
        //     [i18n.t('NEWEST'), 'newest'],
        //     [i18n.t('VOTE'), 'vote']
        // ],
        // notice: [
        // ],
        // ranking: [
        //     [i18n.t('WEEK'), 'week'],
        //     [i18n.t('MONTH'), 'month'],
        //     [i18n.t('YEAR'), 'year']
        // ],
        dashboard_reputation: [
            [i18n.t('All'), '0'],
            [i18n.t('Activity'), '1'],
            [i18n.t('Popularity'), '2'],
            [i18n.t('Confidence'), '3']
        ],
        dashboard_notification: [
            [i18n.t('All'), '0'],
            [i18n.t('Reply'), '3'],
            [i18n.t('Vote'), '6'],
            [i18n.t('Spam'), '16']
        ]
    },

    COMMENT_PREFIX_BUTTONS: {
        comment_to_qa: [
            [i18n.t('No Selection'), '0'],
            [i18n.t('Question Clarification'), '1'],
            [i18n.t('Additional Question'), '2']
        ],
        qa: [
            [i18n.t('No Selection'), '0'],
            [i18n.t('Thanks! :)'), '1'],
            [i18n.t('Unclear :('), '2']
        ],
        community: [
            [i18n.t('No Selection'), '0'],
            [i18n.t('Approval'), '1'],
            [i18n.t('Disapproval'), '2']
        ]
    }
};

export const BOARD_NAME_QA = 'qa';
export const BOARD_NAME_COMMUNITY = 'community';
export const BOARD_NAME_NOTICE = 'notice';
export const BOARD_NAME_RANK = 'ranking';
export const BOARD_NAME_FREETALK = 'freetalk';
export const BOARD_NAME_TAG = 'tag';
export const BOARD_NAME_SPAM = 'spam';
export const BOARD_NAME_GUESTBOOK = 'guestbook';
export const BOARD_QA = ['Q&A', BOARD_NAME_QA];
export const BOARD_COMMUNITY = [i18n.t('Community'), BOARD_NAME_COMMUNITY];
export const BOARD_NOTICE = [i18n.t('Notice'), BOARD_NAME_NOTICE];
export const BOARD_RANK = [i18n.t('Ranking'), BOARD_NAME_RANK];

export const ARTICLE_SORTER_NEWEST = [i18n.t('Newest'), 0];
export const ARTICLE_SORTER_VOTE = [i18n.t('Vote'), 1];
export const ARTICLE_SORTER_NO_ANSWER = [i18n.t('No answer'), 2];

export const ARTICLE_FILTER_WEEKLY = [i18n.t('Weekly'), 2];
export const ARTICLE_FILTER_MONTHLY = [i18n.t('Monthly'), 3];

export const RESPONSE_SORTER_NEWEST = [i18n.t('Newest'), 0];
export const RESPONSE_SORTER_DAILY_BEST = [i18n.t('Daily best'), 1];
export const RESPONSE_SORTER_WEEKLY_BEST = [i18n.t('Weekly best'), 2];

export const DELAY_BEFORE_SCROLL_TO_HIGHLIGHTED_RESPONSE = 3000;
export const DELAY_BEFORE_NEXT_ACTION = 300;
export const DELAY_BEFORE_REDUX_ACTION = 300;
export const DELAY_BEFORE_RELOAD_PAGE_AFTER_NOTIFICATION = 500;
export const DELAY_BEFORE_RELOAD_PAGE_AFTER_CHANGE_LANGUAGE = 1000;
export const MAX_DATATABLE_PAGER_LENGTH = 5;
export const PASSWORD_DEFAULT = '21L3LlKe8I7cj';
export const CONTENT_BLOCKED_MESSAGE = i18n.t('This was blocked');


export const RESPONSE_TYPE_IN_BACKEND = {
    QA: 5, // answer
    COMMUNITY: 3 // reply
};

export const ARTICLE_TYPE = {
    ARTICLE: 'ARTICLE',
    RESPONSE: 'RESPONSE',
    QUESTION: 'QUESTION',
    POST: 'POST',
    ANSWER: 'ANSWER',
    REPLY: 'REPLY',
    COMMENT_TO_Q: 'COMMENT_TO_Q',
    COMMENT_TO_A: 'COMMENT_TO_A',
    COMMENT_TO_R: 'COMMENT_TO_R',

    FREE_TALK: 'FREE_TALK',
    COMMENT_TO_FT: 'COMMENT_TO_FT',
};

export const ARTICLE_TYPE_IN_BACKEND = {
    QUESTION: 'question',
    POST: 'post',
    ANSWER: 'answer',
    REPLY: 'reply',
    FREE_TALK: 'free_talk',
    COMMENT_TO_Q: 'comment_to_q',
    COMMENT_TO_A: 'comment_to_a',
    COMMENT_TO_R: 'comment_to_r',
    COMMENT_TO_FT: 'comment_to_ft'
};

export const ERROR_CODE_FROM_BACKEND = {
    LOGIN_REQUIRED: 'login_required',
    FORCE_LOGOUT: 'force_logout'
};

export const ERROR_MESSAGE = {
    CANNOT_CONNECT_TO_SERVER: 'Cannot connect to server',
    TAG_MINIMUM_EXCEEDED: i18n.t('Tag length should be larger than N'),
    TAG_MAXIMUM_EXCEEDED: i18n.t('Tag length should be smaller than N')
};

export const MESSAGE = {
    SAVE_SUCCESS: i18n.t('Save successfully'),
    DELETE_SUCCESS: i18n.t('Delete successfully!'),
    SENT_SUCCESS: i18n.t('Sent successfully'),
    CONNECT_SUCCESS: i18n.t('Connect successfully'),
    DISCONNECT_SUCCESS: i18n.t('Disconnect successfully'),
    NO_QUESTION: i18n.t('There is no question'),
    NO_POST: i18n.t('There is no post'),
    NO_NOTICE: i18n.t('There is no notice'),
    NO_REPLY: i18n.t('There is no reply'),
    NO_ANSWER: i18n.t('There is no answer'),
    NO_COMMENT: i18n.t('There is no comment'),
    NO_BOOKMARK: i18n.t('There is no bookmark'),
    NO_NOTIFICATION: i18n.t('There is no notification'),
    NO_TAG: i18n.t('There is no tag'),
    NO_SPAM: i18n.t('There is no spam'),
};


export const MAX_LENGTH_ARTICLE_TITLE = 300;



export const getName = (obj) => {
    return obj[0];
};

export const getCode = (obj) => {
    return obj[1];
};

export const getBoardNameFromCode = (code) => {
    const lst = [BOARD_QA, BOARD_COMMUNITY, BOARD_NOTICE, BOARD_RANK];
    for (const idx in lst)
        if (getCode(lst[idx]) === code)
            return getName(lst[idx]);
    return '';
};

export const getSubBoardNameFromCode = (board, code) => {
    const lst = BUTTONS.LEFT_BUTTONS[board];
    for (const idx in lst)
        if (getCode(lst[idx]) === code)
            return getName(lst[idx]);
    return '';
};




export const LANGUAGES = [
    { code: 'en', text: 'English' },
    { code: 'kr', text: "한국어" },
    { code: 'cn', text: '中文' },
    { code: 'ja', text: '日本語' },
    { code: 'vi', text: "Tiếng Việt" },

    { code: 'fr', text: 'Français' },
    { code: 'de', text: 'Deutsch' },
    { code: 'it', text: 'Italiano' },
    { code: 'ru', text: 'Русский' },
    { code: 'th', text: 'ภาษาไทย' },
    { code: 'ar', text: "العربية" },

    { code: 'pl', text: 'Polski' },
    { code: 'si', text: "සිංහල" },
    { code: 'sv', text: 'Svenska' },
    { code: 'tr', text: "Türkçe" },
    { code: 'mn', text: "Монгол" },
    { code: 'ne', text: "नेपाली" },
    { code: 'bn', text: "বাংলা" },
    { code: 'uz', text: "O'zbek" },
    { code: 'pt', text: "Português" },
    { code: 'es', text: "Español" },

    { code: 'id', text: "Bahasa Indonesia" },
    { code: 'ms', text: "Bahasa Melayu" },
    { code: 'hi', text: "हिन्दी" },
    { code: 'kk', text: "Qazaq tili" },
    { code: 'my', text: "ဗမာစာ" },
    { code: 'km', text: "ភាសាខ្មែរ" },
    { code: 'tl', text: "Tagalog" },
    { code: 'ky', text: "кыргыз тили" },
    { code: 'ur', text: "اردو" }
];
export const getLanguageTextFromCode = (langCode) => {
    for (let idx in LANGUAGES)
        if (LANGUAGES[idx].code === langCode)
            return LANGUAGES[idx].text;

    return '';
};
export const MAX_LANGUAGE_FILTERING = 5;
export const MAX_AVAILABLE_LANGUAGES = 4; //  MAX_LANGUAGE_FILTERING - 1


export const BREAKING_WIDTH_FOR_RESPONSIVE = 992; /* Medium devices (desktops, 992px and up) */
export const RESPONSIVE_MODAL_WIDTH = {
    inPCView: 30,
    inMobileView: 80
};
// export const POPUP_SELECT_LANGUAGE_WIDTH = {
//     inPCView: 40,
//     inMobileView: 80
// };
export const CLASS_HIDDEN_IN_MOBILE = " hidden-xs hidden-sm ";
export const CLASS_HIDDEN_IN_PC = " hidden-md hidden-lg ";
export const NOTIFICATION_PULL_TIME = 15 * 1000; // 15 seconds
export const MAX_WORDS_IN_CONTENT_SHOW_READ_MORE = 20 * 5; // assume 20 words per line
export const MAX_RESPONSE_SHOWN_PER_PAGE = 5; // assume 20 words per line
export const MAX_CHARACTER_IN_FREETALK = 1000;
export const INTERVAL_FOR_COUNT_AS_ONE_VISIT = 1; // day