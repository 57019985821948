import React from 'react';
import classnames from 'classnames';
import { MiscUtils } from '../../../extras';

export default class TableSorterTransparent extends React.Component {

    state = {
        currentSortType: this.props.sortType
    };

    selectSorter = (sorter) => {
        const { selectSorter } = this.props;
        this.setState({currentSortType: sorter});
        if (selectSorter)
            selectSorter(sorter);
    };

    // componentDidMount() {
        // this.setState({currentSortType: this.props.sortType});
    // }

    componentWillReceiveProps(nextProps) {
        this.setState({currentSortType: nextProps.sortType});
    }


    render() {
        const { sortButtons } = this.props;
        return (
            <div id="tableSorterTransparent">
                {
                    sortButtons.map((button, idx) => (
                            <span key={MiscUtils.generateId()}>
                                <label
                                    className={classnames("blankHyperlink", {"activeSortTransparentButton": this.state.currentSortType === button[1]})}
                                    onClick={() => {this.selectSorter(button[1])}}
                                >
                                    {button[0]}
                                </label>
                            </span>
                    ))
                }
            </div>
        );
    }
}