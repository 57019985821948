import React from 'react';
import {translate} from "react-i18next";
import {BlockingComponent, Comments, DropDownMenu, HTMLContent, PopupConfirm, UserAvatarInfo} from "../common";
import {Glyphicon} from "react-bootstrap";
import {ARTICLE_TYPE, MESSAGE} from "../../config";
import {MiscUtils} from "../../extras";
import {ApiFreeTalk} from "../../api";
import classnames from 'classnames';


class Talk extends React.Component {

    state = {
        isShowDeleteConfirm: false,
        isShowActionList: false,
        isShowCommentList: false,
        comments: this.props.talk.comments,
        isBlocked: this.props.talk.is_blocked,
        isDeleted: false,
        isProcessing: false
    };


    toggleShowCommentList = () => {
        this.setState({isShowCommentList: !this.state.isShowCommentList});
    };


    toggleShowActionList = () => {
        this.setState({isShowActionList: !this.state.isShowActionList});
    };


    addCommentSuccessCallback = (comment) => {
        this.setState(prevState => ({
            comments: [...prevState.comments, comment]
        }));
    };


    deleteArticle = () => {
        const { talk } = this.props;
        const data = {
            article_id: talk.id
        };
        const callbackSuccess = (data) => {
            MiscUtils.showNotification(MESSAGE.DELETE_SUCCESS);
            this.toggleShowDeleteConfirm();
            this.setState({isProcessing: false, isDeleted: true});
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true}, () => ApiFreeTalk.deleteTalk(callbackSuccess, callbackError, data));
    };


    toggleShowDeleteConfirm= () => {
        this.setState({isShowDeleteConfirm: !this.state.isShowDeleteConfirm});
    }

    editArticle = () => {
        const {talk, editTalkCallback} = this.props;
        editTalkCallback(talk);
    };



    render() {

        const { isShowActionList, isShowCommentList, comments, isBlocked, isDeleted, isProcessing, isShowDeleteConfirm } = this.state;
        const { t, talk, currentUser } = this.props;
        const editBtn = (
            <p onClick={this.editArticle}>{t('Edit')}</p>
        );
        // const blockBtn = (
        //     <p onClick={this.blockArticle}>{isBlocked ? t('Unblock') : t('Block')}</p>
        // );
        const deleteBtn = (
            <p onClick={() => {
                this.toggleShowActionList();
                this.toggleShowDeleteConfirm();
            }}>{t('Delete')}</p>
        );

        let articleAction = null;
        if (currentUser.user_id){
            if (currentUser.is_admin)
                articleAction = (
                    <div className="tooltipActionButton">
                        {/*{ blockBtn }*/}
                        {
                            (currentUser.user_id === talk.user.user_id) &&
                            editBtn
                        }
                        { deleteBtn }
                    </div>
                );
            else if (currentUser.user_id === talk.user.user_id)
                articleAction = (
                    <div className="tooltipActionButton">
                        {
                            !isBlocked &&
                            editBtn
                        }
                        { deleteBtn }
                    </div>
                );
        }




        return (
            <BlockingComponent isProcessing={isProcessing}>
                {
                    !isDeleted &&
                    <div className={classnames("talkRow responseView", {"lightGreyBackground": talk.is_private})}>
                        <div className="row">
                            <div className="col-md-9">
                                <UserAvatarInfo user={talk.user} />

                            </div>
                            <div className="col-md-3">
                                <span className={classnames("pull-right", {"errorText": talk.is_private})}>
                                    { talk.last_modified }
                                    &nbsp;&nbsp;
                                    {
                                        talk.is_private &&
                                        <Glyphicon glyph="eye-close"/>
                                    }
                                </span>
                            </div>
                        </div>

                        <div className="content dontBreakOut">
                            <HTMLContent content={talk.content}/>
                        </div>

                        <div>
                                <span className="item collapsibleButton" onClick={this.toggleShowCommentList}>
                                    {t('Comment')} <span className="numStatsCount">{talk.comments.length}</span>
                                </span>
                            {
                                (articleAction) &&
                                <DropDownMenu htmlContent={articleAction} customClassName="pull-right" isShown={isShowActionList} clickOutsideWhenManual={this.toggleShowActionList}>
                                    <Glyphicon glyph="option-horizontal" className="cursorPointer" onClick={this.toggleShowActionList}/>
                                </DropDownMenu>
                            }
                        </div>


                        <Comments isShowCommentList={isShowCommentList}
                                  comments={comments}
                                  parentType={ARTICLE_TYPE.FREE_TALK}
                                  parentId={talk.id}
                                  addCommentSuccessCallback={this.addCommentSuccessCallback}
                                  isBlocked={isBlocked}
                                  // in order to adapt Comments component
                                  response={talk}
                                  {...this.props}/>
                    </div>
                }

                <PopupConfirm bodyText={t("Are you sure to delete?")} onConfirm={this.deleteArticle}
                              showModal={isShowDeleteConfirm} toggleShowModal={this.toggleShowDeleteConfirm}/>

            </BlockingComponent>
        )
    }
}

export default translate()(Talk);