import React from 'react';
import { TableSorterTransparent, Breadcrumb } from '../common';

export default class ContributorView extends React.Component {

    render() {
        const sortButtons = [
            ['Translation', 'translation']
        ];
        const leftClass = "col-md-3 col-xs-3 col-sm-3";
        const rightClass = "col-md-9 col-xs-9 col-sm-9";
        return (
            <div id="contributorView">
                {/*<h3 className="contentHeader">Contributors</h3>*/}
                <Breadcrumb />
                <TableSorterTransparent sortButtons={sortButtons} sortType={sortButtons[0][1]} />

                <div style={{paddingLeft: "10px"}}>
                    <div className="row">
                        <div className="col-md-3 col-xs-3 col-sm-3">
                            <label className="languageBadge">English</label>
                        </div>
                        <div className="col-md-9 col-xs-9 col-sm-9">
                            <ul>
                                <li>Soyeon Kim (김소연)</li>
                                <li>Yura Song (송유라)</li>
                                <li>Uhui Kim (김우희)</li>
                                <li>Jisu Kim (김지수)</li>
                                <li>Yoowon Kim (김유원)</li>
                                <li>Minkyeom Kim (김민겸)</li>
                                <li>Jiyoung Yi (이지영)</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className={leftClass}>
                            <label className="languageBadge">Deutsch</label>
                        </div>
                        <div className={rightClass}>
                            <ul>
                                <li>Seongyeon Kim (김성연)</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className={leftClass}>
                            <label className="languageBadge">Русский</label>
                        </div>
                        <div className={rightClass}>
                            <ul>
                                <li>Nahyeon Oh (오나현)</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className={leftClass}>
                            <label className="languageBadge">ဗမာစာ (Burmese)</label>
                        </div>
                        <div className={rightClass}>
                            <ul>
                                <li>YIN YIN AYE</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className={leftClass}>
                            <label className="languageBadge">日本語</label>
                        </div>
                        <div className={rightClass}>
                            <ul>
                                <li>Jeonghyo Kang (강정효)</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className={leftClass}>
                            <label className="languageBadge">中文</label>
                        </div>
                        <div className={rightClass}>
                            <ul>
                                <li>Sujin Ahn (안수진)</li>
                                <li>Hongan Jo (조홍안)</li>
                                <li>Yoomi Heo (허유미)</li>
                                <li>Youngah Cha (차영아)</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className={leftClass}>
                            <label className="languageBadge">Türkçe</label>
                        </div>
                        <div className={rightClass}>
                            <ul>
                                <li>Jaeyeon So (소재연)</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className={leftClass}>
                            <label className="languageBadge">Tiếng Việt</label>
                        </div>
                        <div className={rightClass}>
                            <ul>
                                <li>Hyejin, Han (한혜진)</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className={leftClass}>
                            <label className="languageBadge">ภาษาไทย</label>
                        </div>
                        <div className={rightClass}>
                            <ul>
                                <li>Nayeon Kim (김나연)</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className={leftClass}>
                            <label className="languageBadge">Polski</label>
                        </div>
                        <div className={rightClass}>
                            <ul>
                                <li>Jooyeon Yoo (유주연)</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className={leftClass}>
                            <label className="languageBadge">Français</label>
                        </div>
                        <div className={rightClass}>
                            <ul>
                                <li>Yeongeon Kim (김영언)</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className={leftClass}>
                            <label className="languageBadge">O'zbek</label>
                        </div>
                        <div className={rightClass}>
                            <ul>
                                <li>MAMATKABILOV SARVAR</li>
                            </ul>
                        </div>
                    </div>

                    <div className="more">
                        <p>ㆍ</p>
                        <p>ㆍ</p>
                        <p>ㆍ</p>
                    </div>
                    <p style={{marginBottom: "25px"}}>
                    <span className="contributorsBtn">
                        <a href="http://translate.joinusworld.org" target="blank">We are waiting for your translation...</a>  {/* http://j.mp/jk-trans */}
                    </span>
                    </p>
                </div>



            </div>
        )
    }
}