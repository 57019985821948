import React from 'react';
import { BlockingComponent, ButtonHyperlink } from '../../components';
import { URLUtils } from '../../extras';
import { SIGN_IN } from '../../RouteURL';
import { ApiUser } from '../../api';


export default class ChangeEmailConfirm extends React.Component {

    state = {
        isProcessing: true,
        isSuccess: null
    };


    componentDidMount() {
        const currentURL = URLUtils.getCurrentURL(this);
        const arr = currentURL.split('/');
        const hashCode = arr[arr.length-1];
        if (['', 'changeEmailConfirm'].indexOf(hashCode) >=0)
            this.setState({
                isProcessing: false,
                isSuccess: false
            });
        else {
            const data = {
                hashCode
            };
            const callbackSuccess = (data) => {
                this.setState({
                    isProcessing: false,
                    isSuccess: true
                });
            };
            const callbackError = (error) => {
                this.setState({
                    isProcessing: false,
                    isSuccess: false
                });
            };
            this.setState({isProcessing: true}, () => ApiUser.changeEmailConfirm(callbackSuccess, callbackError, data));
        }
    }

    render (){
        const { t } = this.props;
        const { isProcessing, isSuccess } = this.state;
        return (
            <div id="activationConfirm">
                <BlockingComponent isProcessing={isProcessing}>
                    {
                        isSuccess === true &&
                        <div>
                            {t('Congratulation, you changed to new email address successfully')}
                        </div>
                    }
                    {
                        isSuccess === false &&
                        <div>
                            {t('Invalid or Expired activation code')}
                        </div>
                    }
                    <ButtonHyperlink txt={t('Login')} url={SIGN_IN} />
                </BlockingComponent>
            </div>
        )
    }
}