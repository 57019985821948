import { compose, createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import multi from 'redux-multi';

import { persistStore, autoRehydrate } from 'redux-persist';
import { REHYDRATE } from 'redux-persist/constants';
import createActionBuffer from 'redux-action-buffer';

// https://availity.github.io/react-block-ui/components/reduxblockui/
//import reactBlockUIMiddleware from 'react-block-ui/reduxMiddleware';

import rootReducer from './reducers/rootReducer';



const middleware = [
    thunk,
    createActionBuffer(REHYDRATE), //make sure to apply this after redux-thunk et al.
    multi,
    //reactBlockUIMiddleware,
    // logger // need to be the last, if not when firing the action, it will has "undefied" action before every action
];

const enhancers = compose(
    applyMiddleware(...middleware),
    autoRehydrate(),
    window.devToolsExtension ? window.devToolsExtension() : f => f
);

//const initialState = {
//    'currentZone': 'qa'
//};

const store = createStore(
    rootReducer,
    enhancers
    //initialState
);

persistStore(store);

export { store };