import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { requireLogin, URL_MAPPING, URLUtils, MiscUtils, ACCESS_TOKEN, IS_USER_PROFILE_PROCESS } from '../../extras';
import { BoardView } from '../board';
import { RankingView } from '../ranking';
import { NoticeView } from '../notice';
import { ArticleView, ArticleWrite } from '../article';
import { SearchView } from '../search';
import { DashboardView } from '../dashboard';
import { ProfileView } from '../profile';
import { SettingView } from '../setting';
import { FreetalkView } from '../freetalk';
import { DEFAULT_INDEX, SIGN_UP_GET_INFO, MAINPAGE_QA, MAINPAGE_COMMUNITY, MAINPAGE_NOTICE, MAINPAGE_FREETALK, RANKING__DEFAULT } from '../../RouteURL';


export default class MainContent extends React.Component {

    render() {
        return (
            <div className="myMainContent">
                <Switch> {/* neu ko co switch thi se match all, co thi chi match cai dau  tien */}
                    {/*<Route exact path='/' component={ requireLogin(HomeView) }/>*/}
                    {/* <Route exact path="/" render={() => (isLoggedIn() ? <Redirect to="/mylink"/> : <Login/>)} /> */}
                    <Route exact path="/" render={() => {
                        if (MiscUtils.getFromLocalStorage(ACCESS_TOKEN) && !MiscUtils.getFromLocalStorage(IS_USER_PROFILE_PROCESS))
                            URLUtils.moveToURL(SIGN_UP_GET_INFO);
                        else
                            URLUtils.moveToURL(DEFAULT_INDEX)
                    }} />

                    {/* /qa/write   /qa/edit/123-aaa  */}
                    <Route exact path="/:boardType/:action(edit|write)/:articleId(\d+.+)?" component={ requireLogin(ArticleWrite) } />
                    <Route path="/:boardType/:articleId(\d+.+)" component={ ArticleView } />

                    <Route path="/(qa|community)" component={ BoardView } />
                    <Route exact path={MAINPAGE_NOTICE} component={ BoardView } />
                    <Route exact path="/notice/notice" component={ BoardView } />
                    <Route path={MAINPAGE_NOTICE} component={ NoticeView } />

                    <Route path={RANKING__DEFAULT} component={ RankingView } />
                    <Route path={MAINPAGE_FREETALK} component={ FreetalkView } />
                    <Route path="/search" component={ SearchView } />

                    <Route path="/dashboard" component={ requireLogin(DashboardView) } />
                    <Route path="/profile" component={ requireLogin(ProfileView) } />
                    <Route path="/setting" component={ requireLogin(SettingView) } />
                    <Route path='*' render={() => (<Redirect to={URL_MAPPING.error.notFound}/>)} />
                </Switch>
            </div>
        )
    }

}
