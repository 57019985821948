import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TableSorterTransparentWithLink } from '../common';
import { URLUtils } from '../../extras';
import AccountSettingView from './AccountSettingView';
import NotificationSettingView from './NotificationSettingView';
import PartyConnectionSettingView from './PartyConnectionSettingView';
import { translate } from "react-i18next";


class SettingView extends React.Component {

    render() {
        const { t } = this.props;
        const { path } = this.props.match;
        const sortButtons = [
            {
                text: t('Account setting'),
                tab: 'account',
                url: path + '/account'
            },
            {
                text: t('Notification setting'),
                tab: 'notification',
                url: path + '/notification'
            },
            {
                text: t('3rd party connection'),
                tab: 'party',
                url: path + '/party'
            }
        ];

        const arr = URLUtils.getCurrentURL(this).split('/');
        const selectedTab = (arr.length === 3) ? arr[arr.length - 1] : 'account'; // default is account, else  /setting/notification

        return (
            <div className="col-md-9">
                <div id="settingView" className="row centerContent">
                    {/*<h4 className="contentHeader">Setting</h4>*/}
                    <TableSorterTransparentWithLink sortButtons={sortButtons} selectedTab={selectedTab} />
                    <Switch>
                        <Route exact path={path + "/notification"} component={ NotificationSettingView } />
                        <Route exact path={path + "/party"} component={ PartyConnectionSettingView } />
                        <Route path={path} component={ AccountSettingView } />
                    </Switch>
                </div>
            </div>
        )
    }
}

export default translate()(SettingView);