import React from 'react';
import Select from 'react-select';

export default class DropdownListWithAutoComplete extends React.Component {

    state = {
        selectedValue: this.props.value
    };

    onChange = (obj) => {
        this.setState({
            selectedValue: obj ? obj.value : ''
        });
        const { onChange } = this.props;
        if (onChange && obj)
            onChange(obj.value);
    };

    render() {
        const { name, options } = this.props;
        return (
            <Select
                name={name}
                value={this.state.selectedValue}
                options={options}
                onChange={this.onChange}
            />
        )
    }
}