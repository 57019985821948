import React from 'react';
import { BOARD_NAME_COMMUNITY} from '../../config';
import { translate } from "react-i18next";
import ResponseWrite from "./ResponseWrite";
import {PopupModal} from "../common/element";
import {MiscUtils} from "../../extras";


class ResponseWriteInMobile extends React.Component {

    state = {
        showWriteResponse: false
    };


    toggleWriteResponse = () => {
        this.setState({showWriteResponse: !this.state.showWriteResponse});
    };



    render () {
        const isMobileView = MiscUtils.isMobileView();

        const {  article, t } = this.props;
        const { showWriteResponse } = this.state;
        // if (redirectToLogin)
        //     return <Redirect to={SIGN_IN + '?next=' + URLUtils.getCurrentPathname()}/>;

        let placeholder = t("Answer {{author}}'s question here", { author: article.user.username });
        if (article.article_type == BOARD_NAME_COMMUNITY)
            placeholder = t("Reply {{author}}'s post here", { author: article.user.username });

        return (
            <div className="alignCenter marginVerticalMedium">
                <button type="button" className="outlineBtn" onClick={this.toggleWriteResponse}>{placeholder}</button>

                <PopupModal isVisible={showWriteResponse} onCloseClicked={this.toggleWriteResponse} setWidth={isMobileView ? 95 : 50}>
                    <ResponseWrite {...this.props} cancelWriteResponseInMobile={this.toggleWriteResponse}/>
                </PopupModal>

            </div>
        )
    }
}

export default translate()(ResponseWriteInMobile);