import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { TableSorter, BlockingComponent, TablePager } from '../common';
import { CLASS_HIDDEN_IN_MOBILE, CLASS_HIDDEN_IN_PC } from '../../config';
import { MiscUtils } from '../../extras';
import { ApiDashboard } from '../../api';



class ReputationView extends React.Component {

    state = {
        isProcessing: false,
        reputations: [],
        page: 1,
        totalPages: 0,
        type: 0 // 0: all   1: reputation   2: popularity  3: confidence
    };


    loadReputations = () => {
        const { page, type } = this.state;
        const data = {
            page, type
        };
        const callbackSuccess = (data) => {
            this.setState({
                isProcessing: false,
                reputations: data.rows,
                totalPages: parseInt(data.totalpages)
            });
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true, reputations: []}, () => ApiDashboard.getReputation(callbackSuccess, callbackError, data));
    };


    onPagerChange = (page) => {
        this.setState({page}, () => this.loadReputations());
    };


    componentDidMount() {
        this.loadReputations();
    };


    selectSorter = (type) => {
        this.setState({
            type: type,
            page: 1,
            reputations: []
        }, () => this.loadReputations());
    };


    render (){
        const { reputations, isProcessing, page, totalPages } = this.state;
        console.log(this.state);
        const { user } = this.props;
        const isMobileView = MiscUtils.isMobileView();

        return (
            <BlockingComponent isProcessing={isProcessing}>
                <div>
                    <label className={"totalPoint" + (isMobileView ? ' totalPointInMobile' : '')}>Total: {user.total_point} point</label>
                    <TableSorter boardType="dashboard_reputation" selectSorter={this.selectSorter}/>
                </div>

                <div className="dataList">
                    {
                        reputations.map((reputation) =>
                            <div key={MiscUtils.generateId()} className="row dataRow paddingHorizonMedium paddingVerticalMedium">
                                <div className="col-md-1 col-xs-2 col-sm-2">
                                    <button type="button" className={classnames("btn btn-default btn-circle btn-lg noVote", {"negativeNoVote": reputation.point < 0})} >
                                        {reputation.point > 0 && "+"}{reputation.point}
                                    </button>
                                </div>
                                <div className={classnames("col-md-11 col-xs-10 col-sm-10", {"verticalMiddle": !reputation.article}, {"fullInfo": reputation.article})}>
                                    <span className="levelBadge">{reputation.index}</span>
                                    {
                                        reputation.board &&
                                        <span className={classnames(
                                            {"badgeQA": reputation.boardtype === 0},
                                            {"badgeCommunity": reputation.boardtype === 1},
                                            {"badgeNotice": reputation.boardtype === 2},
                                            {"badgeGuestbook": reputation.boardtype === 3}
                                        )} >{reputation.board}</span>
                                    }
                                    <span className="pull-right">{reputation.date}</span>

                                    {/*login*/}
                                    {
                                        !reputation.board &&
                                        <label className="action">{reputation.category}</label>
                                    }

                                    <div>
                                        {reputation.article}
                                    </div>

                                </div>
                            </div>
                        )
                    }
                </div>
                {
                    !isProcessing && reputations && reputations.length > 0 &&
                    <TablePager page={page} total={totalPages} onPagerChange={this.onPagerChange}/>
                }
            </BlockingComponent>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.reducerAuth.user
    }
};

export default connect(mapStateToProps, {})(withRouter(ReputationView));