import React from 'react';
import { Link } from 'react-router-dom';
import { Glyphicon } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import classnames from 'classnames';
import { TagList, HTMLContent } from '../element';
import { BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_NOTICE, CLASS_HIDDEN_IN_MOBILE, CLASS_HIDDEN_IN_PC } from '../../../config';
import { translate } from "react-i18next";


class TableContentArticleElement extends React.Component {

    render() {
        const {article, boardType, t} = this.props;
        return (
            <div className="dataRow">
                <div className="row">
                    <div className={"col-md-2 dataColumnLeft"+CLASS_HIDDEN_IN_MOBILE}>
                        <p>
                            <label className="languageBadge">{article.language}</label>
                        </p>
                        <button type="button" className="btn btn-default btn-circle btn-lg noVote">{article.vote}</button>
                        <p>{t('votes')}</p>

                        {/*{ boardType !== BOARD_NAME_NOTICE &&*/}
                            {/*<Link to={article.article_view_url+'?id=response_write'}>*/}
                                {/*<button type="button" className="btn outlineBtn">*/}
                                    {/*<Glyphicon glyph="pencil" />&nbsp;*/}
                                    {/*{ (boardType === BOARD_NAME_QA) && t('Answer')}*/}
                                    {/*{ (boardType === BOARD_NAME_COMMUNITY) && t('Reply')}*/}
                                {/*</button>*/}
                            {/*</Link>*/}
                        {/*}*/}

                    </div>
                    {/*<div className={classnames("dataColumnMiddle", {"col-md-8 col-xs-8 col-sm-8": (article.ma_thumbnail != ''), "col-md-10": (article.ma_thumbnail == '')})} >*/}
                    <div className="dataColumnMiddle col-md-10">
                        <div className="articleTags">
                            <div>
                                <label className={"languageBadge"+CLASS_HIDDEN_IN_PC}>{article.language}</label>
                            </div>
                            <TagList tags={article.ma_tags} isHideEditButton={true}/>
                        </div>

                        <p>
                            <strong className="articleTitle">
                                {/*<Link to={article.article_view_url}>*/}
                                <a href={article.article_view_url}>
                                    {
                                        article.is_anonymous &&
                                        <span className="orangeColor">{article.ma_title}</span>
                                    }
                                    {
                                        !article.is_anonymous &&
                                        article.ma_title
                                    }
                                </a>
                                {/*</Link>*/}
                            </strong>
                            <span className="icons">
                                { article.announcement && <FontAwesome name="bullhorn"/>}
                                { article.solved && <FontAwesome name="check"/>}
                            </span>

                        </p>

                        <div>
                            {/*<div className="articleContent">*/}
                            <div className={classnames("articleContent dontBreakOut", {"col-md-9 col-xs-9 col-sm-9": (article.ma_thumbnail !== '')})} >
                                <HTMLContent content={article.content}/>
                                {/*<a href="#" className="viewMore">View more</a>*/}
                            </div>
                            {
                                article.ma_thumbnail &&
                                <div className="col-md-3 col-xs-3 col-sm-3 thumbnail">
                                    <img src={article.ma_thumbnail} alt="" className="img-rounded img-responsive"/>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className={"alignRight"+CLASS_HIDDEN_IN_PC}>{article.last_modified}</div>

                <div className="row marginTopMedium">
                    <div className={'col-md-2 alignCenter' + CLASS_HIDDEN_IN_MOBILE}>
                        { boardType !== BOARD_NAME_NOTICE &&
                            <Link to={article.article_view_url+'?id=response_write'}>
                                <button type="button" className="btn outlineBtn">
                                    <Glyphicon glyph="pencil" />&nbsp;
                                    { (boardType === BOARD_NAME_QA) && t('Answer')}
                                    { (boardType === BOARD_NAME_COMMUNITY) && t('Reply')}
                                </button>
                            </Link>
                        }
                    </div>
                    <div className="paddingNone col-md-10 col-sm-12 col-xs-12 articleInfo">

                        <div className="paddingNone col-md-4 col-sm-4 col-xs-4">
                            <label className={CLASS_HIDDEN_IN_MOBILE}>{article.last_modified}</label>
                            <label className={CLASS_HIDDEN_IN_PC}>
                                {t('Vote')} <span className="numStatsCount">{article.vote}</span>
                            </label>
                        </div>
                        <div className="paddingNone col-md-3 col-sm-4 col-xs-4">
                            <label>
                                { (boardType === BOARD_NAME_QA) && t('Answer')+' '}
                                { (boardType === BOARD_NAME_COMMUNITY) && t('Reply')+' '}
                                { (boardType !== BOARD_NAME_NOTICE) && <span className="numStatsCount">{article.ma_response_count}</span> }
                            </label>
                        </div>



                        {
                            boardType !== BOARD_NAME_NOTICE &&
                            <div className="paddingNone col-md-3 col-sm-4 col-xs-4">
                                <label>
                                    {t('Comment')} <span className="numStatsCount">{article.count_comment}</span>
                                </label>
                            </div>
                        }

                        {
                            boardType !== BOARD_NAME_NOTICE &&
                            <div className={"paddingNone col-md-2"+CLASS_HIDDEN_IN_MOBILE}>
                                <label>
                                    {t('Follow')} <span className="numStatsCount">{article.count_bookmark}</span>
                                </label>
                            </div>
                        }



                    </div>
                </div>
            </div>
        );
    }
}

export default translate()(TableContentArticleElement);