import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import isNew from '../../static/images/icon/new.png';
import { TableSorter, BlockingComponent, TablePager } from '../common';
import { MESSAGE } from "../../config";
import { MiscUtils } from '../../extras';
import { ApiDashboard } from '../../api';


export default class NotificationView extends React.Component {

    state = {
        isProcessing: false,
        notifications: [],
        page: 1,
        totalPages: 0,
        type: 0 // 0: all   1: notification   2: popularity  3: confidence
    };


    loadNotifications = () => {
        const { page, type } = this.state;
        const data = {
            page, type
        };
        const callbackSuccess = (data) => {
            this.setState({
                isProcessing: false,
                notifications: data.rows,
                totalPages: parseInt(data.totalpages)
            });
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true, notifications: []}, () => ApiDashboard.getNotification(callbackSuccess, callbackError, data));
    };


    componentDidMount() {
        this.loadNotifications();
    }


    onPagerChange = (page) => {
        this.setState({page}, () => this.loadNotifications());
    };


    selectSorter = (type) => {
        this.setState({
            type: type,
            page: 1,
            notifications: []
        }, () => this.loadNotifications());
    };



    render (){
        const { notifications, isProcessing, page, totalPages } = this.state;
        return (
            <div id="notificationView">
                <BlockingComponent isProcessing={isProcessing}>
                    <div>
                        <TableSorter boardType="dashboard_notification" selectSorter={this.selectSorter}/>
                    </div>
                    <div className="dataList">
                        {
                            notifications.map((notification) =>
                                <div key={MiscUtils.generateId()} className="row dataRow paddingVerticalMedium paddingHorizonMedium">
                                    <div>
                                        {
                                            notification.board &&
                                            <span className={classnames(
                                                {"badgeQA": notification.boardtype === 0},
                                                {"badgeCommunity": notification.boardtype === 1},
                                                {"badgeNotice": notification.boardtype === 2},
                                                {"badgeGuestbook": notification.boardtype === 3}
                                            )} >{notification.board}</span>
                                        }
                                        {/*<label className="action">{notification.from}</label>*/}
                                        <span className="pull-right"> {notification.date}</span>
                                    </div>
                                    <div className="message">
                                        {
                                            (notification.notification_view_url) &&
                                            <a href={notification.notification_view_url} style={{color: 'inherit'}}>
                                                {/*<strong>[{notification.details}]</strong> {notification.article}*/}
                                                <strong>{notification.from}</strong>&nbsp;
                                                {notification.acticle_action}&nbsp;
                                                {notification.acticle_type}&nbsp;
                                                <strong>{notification.article_affected_title}</strong>
                                                {
                                                    (notification.isnew === 1) &&
                                                    <img src={isNew} className="isNew" alt="isNew"/>
                                                }
                                            </a>
                                        }
                                        {
                                            (!notification.notification_view_url) &&
                                            <span>
                                                {/*<strong>[{notification.details}]</strong> {notification.article}*/}
                                                <strong>{notification.from}</strong>&nbsp;
                                                {notification.acticle_action}&nbsp;
                                                {notification.acticle_type}&nbsp;
                                                <strong>{notification.article_affected_title}</strong>
                                                {
                                                    (notification.isnew === 1) &&
                                                    <img src={isNew} className="isNew" alt="isNew"/>
                                                }
                                            </span>
                                        }

                                    </div>
                                </div>
                            )
                        }
                        {
                            !isProcessing && notifications && notifications.length === 0 &&
                            <div>{MESSAGE.NO_NOTIFICATION}</div>
                        }
                    </div>
                    {
                        !isProcessing && notifications && notifications.length > 0 &&
                        <TablePager page={page} total={totalPages} onPagerChange={this.onPagerChange}/>
                    }
                </BlockingComponent>
            </div>
        )
    }
}