import React from 'react';
import { Tooltip } from 'react-tippy';
// https://github.com/tvkhoa/react-tippy

export default class DropDownMenu extends React.Component {

    render() {
        let { htmlContent, position, customClassName, hideArrow, isShown, clickOutsideWhenManual} = this.props;
        if (!position)
            position = 'bottom';
        if (typeof isShown !== 'undefined')
        return (
                    <Tooltip
                        position={position}
                        trigger="click"
                        // trigger="mouseenter"
                        arrow={hideArrow ? false : true}
                        size="big"
                        interactive={true}
                        html={htmlContent}
                        className={customClassName}
                        // hideDelay={10000}
                        // hideOnClick={true}
                        // open={this.props.isOpen}
                        // open={true}
                        open={isShown}
                        onRequestClose={() => {
                            if (clickOutsideWhenManual)
                                clickOutsideWhenManual();
                        }}
                    >
                        {this.props.children}
                    </Tooltip>
        );
        else
            return (
                    <Tooltip
                        position={position}
                        trigger="mouseenter"
                        arrow={hideArrow ? false : true}
                        size="big"
                        interactive={true}
                        html={htmlContent}
                        className={customClassName}
                    >
                        {this.props.children}
                    </Tooltip>
            );
    }
}