import React from 'react';
import PopupModal from './PopupModal';
import i18n from '../../../locales/i18n';

export default class PopupConfirm extends React.Component {

    render() {

        const { showModal, toggleShowModal, onConfirm, bodyText, titleText, confirmText } = this.props;
        return (
            <PopupModal isVisible={showModal} onCloseClicked={toggleShowModal}>
                <div className="alignLeft">
                    <h5>{bodyText}</h5>
                </div>
                <div className="actionBtn">
                    <button type="button" className="btn negativeBtn" onClick={toggleShowModal}>{i18n.t('No')}</button>
                    <button type="button" className="btn positiveBtn" onClick={onConfirm}>{confirmText ? confirmText : i18n.t('Yes')}</button>
                </div>
            </PopupModal>
        );
    }
}


// import Confirm from 'react-confirm-bootstrap';
// // https://github.com/gregthebusker/react-confirm-bootstrap#readme
// export default class PopupConfirm extends React.Component {
//     render() {
//         // confirmBtnStyle: 'primary', 'success', 'info', 'warning', 'danger', 'link'
//         const { onConfirm, bodyText, titleText, confirmText, confirmBtnStyle } = this.props;
//
//         return (
//             <Confirm
//                 onConfirm={onConfirm}
//                 body={bodyText}
//                 confirmText={confirmText ? confirmText : 'OK'}
//                 title={titleText}
//                 confirmBSStyle={confirmBtnStyle ? confirmBtnStyle : 'danger'}
//             >
//                 {this.props.children}
//             </Confirm>
//         );
//     }
// }