import moment from 'moment';
export default class DateUtils {

    static nowLegacyDate() {
        return new Date();
    }


    static nowDate() {
        return moment();
    }

    static yesterday() {
        return moment().subtract(1, 'days');
    }

    static dateFromText(txt, format) {
        return moment(new Date(txt));
        // if (!format)
        //     format = 'YYYY-MM-DD';
        // console.log(format);
        // return moment(txt).format(format);
    }

    static momentFromText(txt, format) {
        return moment(txt, format);
        // if (!format)
        //     format = 'YYYY-MM-DD';
        // console.log(format);
        // return moment(txt).format(format);
    }

    static momentToLegacyDate(momentObj) {
        return momentObj.toDate();
    }

    static legacyDateToMoment(dateObj) {
        return moment(dateObj);
    }


    static dateToString(dateObj, format) {
        if (!format)
            format = 'YYYY-MM-DD';
        return moment(dateObj).format(format);
    }

}