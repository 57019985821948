import React from 'react';
import Response from './Response';
import { MiscUtils } from '../../extras';

export default class ResponsesAccepted extends React.Component {

    render (){
        const { article } = this.props;

        const { highlightedObjectId } = this.props;
        let highlightedResponseId = null;
        let highlightedCommentId = null;
        // ?id=answer_392  ?id=comments-of-295-652   ?id=comment_question_652
        if (highlightedObjectId) {
            if ((highlightedObjectId.indexOf('answer') >=0) || (highlightedObjectId.indexOf('reply') >=0))
                highlightedResponseId = parseInt(highlightedObjectId.split('_')[1]);
            else if (highlightedObjectId.indexOf('comments-of-') >= 0)
                highlightedCommentId = highlightedObjectId;
        }

        return (
            <div id="responseAcceptedList">
                {
                    article.top_responses_data && article.top_responses_data.map((response) =>
                        <Response response={response} {...this.props} key={MiscUtils.generateId()}
                                  highlightedResponseId={highlightedResponseId}
                                  highlightedCommentId={highlightedCommentId}
                        />
                    )
                }
            </div>
        )
    }
}