import React from 'react';
import { TableSorterTransparent, Breadcrumb } from '../common';
import AboutUsIntroView from './AboutUsIntroView';
import AboutUsContactView from './AboutUsContactView';
import { URLUtils } from '../../extras';

export default class AboutUsView extends React.Component {

    state = {
        currentTab: 'introduction'
    };

    tabClick = (currentTab) => {
        this.setState({currentTab});
        this.props.history.push('/notice/' + currentTab);
    };

    componentWillMount() {
        const arr = URLUtils.getCurrentURL(this).split('/');
        this.tabClick(arr[arr.length-1]);
    }

    render() {
        const sortButtons = [
            ['Introduction', 'introduction'],
            ['Contact', 'contact']
        ];
        return (
            <div>
                {/*<h3 className="contentHeader">About us</h3>*/}
                <Breadcrumb />
                <TableSorterTransparent sortButtons={sortButtons} sortType={this.state.currentTab} selectSorter={this.tabClick}/>
                <div id="aboutUsView">
                    { this.state.currentTab === 'introduction' && <AboutUsIntroView /> }
                    { this.state.currentTab === 'contact' && <AboutUsContactView /> }
                </div>
            </div>
        )
    }
}