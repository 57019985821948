import { GET_ARTICLES__PROCESSING, GET_ARTICLES__FINISHED,
    GET_POPULAR_ARTICLES__FINISHED, GET_POPULAR_ARTICLES__PROCESSING,
    SAVE_ARTICLE__PROCESSING, SAVE_ARTICLE__FINISHED } from '../actions/types'
import { MiscUtils } from '../../extras';

const initialState = {
    article: null,
    boardType: '',
    getArticlesProcessing: false,
    getPopularArticlesProcessing: false,
    saveArticleProcessing: false
};

export default (state = initialState, action = {}) => {
    switch (action.type){

        case GET_ARTICLES__PROCESSING:
            return MiscUtils.updateObject(state, {getArticlesProcessing: true});
        case GET_ARTICLES__FINISHED:
            return MiscUtils.updateObject(state, {getArticlesProcessing: false,saveArticleProcessing: false});

        case GET_POPULAR_ARTICLES__PROCESSING:
            return MiscUtils.updateObject(state, {getPopularArticlesProcessing: true});
        case GET_POPULAR_ARTICLES__FINISHED:
            return MiscUtils.updateObject(state, {getPopularArticlesProcessing: false});

        case SAVE_ARTICLE__PROCESSING:
            return MiscUtils.updateObject(state, {saveArticleProcessing: true});
        case SAVE_ARTICLE__FINISHED:
            return MiscUtils.updateObject(state, {saveArticleProcessing: false});

        default:
            return state;
    }
}