import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
// import { Glyphicon } from 'react-bootstrap';
import { connect } from 'react-redux';
import { PopupboxManager } from 'react-popupbox';
import { MiscUtils, URLUtils, CURRENT_BOARD, CURRENT_SUB_BOARD, URL_MAPPING } from '../../extras';
import { Breadcrumb, TagInput, Editor, DropdownList, SourceInput, BlockingComponent, PreviewContent,
    LanguageSelectionArticleWriteButton, PopupConfirm, Timezone, DatetimePicker } from '../common';
import { ApiHttp, ApiArticle } from '../../api';
import { saveArticle } from '../../redux/actions';
import { ERROR_MESSAGE, MAX_LENGTH_ARTICLE_TITLE, BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_NOTICE, ERROR_CODE_FROM_BACKEND, LANGUAGES } from '../../config';
import { translate } from "react-i18next";
import DateUtils from "../../extras/DateUtils";


const ACTION_WRITE = 'write';
const ACTION_EDIT = 'edit';

class ArticleWrite extends React.Component {

    state = {
        isProcessing: false,
        action: this.props.match.params.action,
        articleId: this.props.match.params.articleId,
        boardType: this.props.match.params.boardType,
        //boardType: MiscUtils.getFromLocalStorage(CURRENT_BOARD),
        subBoard: MiscUtils.getFromLocalStorage(CURRENT_SUB_BOARD),
        category_list_exclusive_for_super_user: [],
        categoryList: [],
        languageObjectList: [],

        // article
        category: null,
        categoryLabel: null,
        titleLengthRemain: MAX_LENGTH_ARTICLE_TITLE,
        title: '',
        selectedLanguage: null,
        selectedTags: [],
        selectedSources: [],
        content: '',
        isAnnouncement: false,
        isAnonymous: false,
        isEvent: false,
        eventDatetime: DateUtils.nowLegacyDate(),
        eventTimeGMT: '',
        eventPlace: '',
        thumbnail_url: null,
        showSaveConfirm: false,

        // qa -> content will be optional
        isShowMoreContent: this.props.match.params.boardType !== BOARD_NAME_QA
    };


    chooseCategory = (e) => {
        this.setState({category: e.value, categoryLabel: e.label});
    };


    inputTag = (tags) => {
        this.setState({selectedTags: tags});
    };


    inputSource = (sources) => {
        this.setState({selectedSources: sources});
    };


    selectLanguage = (languageCode) => {
        this.setState({selectedLanguage: languageCode});
    };

    selectLanguages = (languages) => {
        this.setState({languageObjectList: languages});
    };


    changeAnnouncement = () => {
        this.setState({isAnnouncement: !this.state.isAnnouncement});
    };

    changeAnonymous = () => {
        this.setState({isAnonymous: !this.state.isAnonymous});
    };

    changeEvent = () => {
        this.setState({isEvent: !this.state.isEvent});
    };

    toggleShowSaveConfirm = () => {
        this.setState({showSaveConfirm: !this.state.showSaveConfirm});
    };

    handleChangeEventDatetime = (selectedDatetime) => {
        this.setState({eventDatetime: selectedDatetime});
    };

    updateEventTimezone = (timezone) => {
        this.setState({'eventTimeGMT': timezone});
    };

    updateEventPlace = (e) => {
        this.setState({'eventPlace': e.target.value});
    };


    validateThumbnailURL = (e) => {
        const url = e.target.value;
        const data = {
            url: url
        };
        const callbackSuccess = (res) => {
            this.setState({
                isProcessing: false,
                thumbnail_url: res.status === 'ok' ? url : 'invalid_thumbnail_url'
            });
        };
        const callbackError = (error) => {
            this.setState({isProcessing: false});
        };
        this.setState({isProcessing: true}, () => ApiArticle.validateThumbnailURL(callbackSuccess, callbackError, data));
    };


    titleTyping = (e) => {
        const currentTitle = e.target.value;
        const oldValue = this.state.title;
        const remain = MAX_LENGTH_ARTICLE_TITLE - currentTitle.length;
        if (remain >= 0)
            this.setState({title: currentTitle, titleLengthRemain: remain});
        else
            this.setState({title: oldValue, titleLengthRemain: 0});
    };


    updateContent = (newContent) => {
        this.setState({content: newContent});
    };


    previewArticleContent = () => {
        console.log(this.state.content)
        const content = (
            <PreviewContent htmlContent={this.state.content}/>
        );
        PopupboxManager.open({
            content,
            config: {
                className: 'col-md-5 previewContentInPopup',
                fadeIn: true,
                fadeInSpeed: 500,
            }
        });
    };


    toggleShowMoreContent = () => {
        this.setState({isShowMoreContent: !this.state.isShowMoreContent});
    };


    saveArticle = () => {
        window.removeEventListener("beforeunload", this.preventClose);
        const { t } = this.props;
        const { boardType, title, content, selectedSources, category, selectedTags} = this.state;

        let message = null;
        if (!title)
            message = t('Title is required!');
        else if (!content && boardType !== BOARD_NAME_QA)
            message = t('Content is required!');
        else if (!category && (boardType !== BOARD_NAME_NOTICE))
            message = t('Category is required!');
        else if ((boardType == BOARD_NAME_COMMUNITY) && (['news', 'culture'].includes(category)) && (selectedSources.length === 0))
            message = t('Source is required!');
        else if(selectedTags.length === 0)
            message = t('Tag is required!');
        if (message)
            MiscUtils.showErrorMessage(message);
        else
            this.toggleShowSaveConfirm();

    };


    saveArticleConfirm = () => {
        this.toggleShowSaveConfirm();

        const { articleId, boardType, title, content, selectedTags, selectedSources, selectedLanguage, category,
            categoryListWithCode, isAnnouncement, isAnonymous, isEvent, thumbnail_url, eventDatetime,
            eventTimeGMT, eventPlace, isShowMoreContent} = this.state;

        const tags = selectedTags.map((tagObj) => tagObj.value);
        const sources = selectedSources.map((sourceObj) => sourceObj.value);
        const data = {
            article_id: articleId,
            type: boardType,
            language: selectedLanguage,
            article_title: title,
            article_content: content,
            is_show_more_content: isShowMoreContent,
            post_type: (boardType !== BOARD_NAME_NOTICE) ? MiscUtils.findElementInListByKey(categoryListWithCode, 'code_text', category)['code'] : '',
            str_source: sources.join(','),
            tag: tags.join(','),

            article_thumbnail: thumbnail_url,
        };
        if (isAnnouncement)
            data['is_announcement'] = true;
        if (isAnonymous)
            data['is_anonymous'] = true;
        if (isEvent) {
            data['is_event'] = true;
            const arrTemp = DateUtils.dateToString(eventDatetime, 'MM/DD/YYYY h:mma').split(' ');
            data['event_date'] = arrTemp[0];
            data['event_time'] = arrTemp[1];
            data['event_time_gmt'] = eventTimeGMT;
            data['event_place'] = eventPlace;
        }

        this.props.saveArticle(data, (redirectURL) => {
            URLUtils.moveToURL(redirectURL);
        });
    };



    initData = (user) => {
        if (MiscUtils.isEmptyObject(user))
            return;

        const { boardType, subBoard, selectedLanguage, categoryList, category_list_exclusive_for_super_user, category } = this.state;
        // render Category list
        if (boardType !== BOARD_NAME_NOTICE) {
            // neu categoryList chua dc set thi se ko co select dc category
            let newList = MiscUtils.cloneObject(categoryList);
            if (!MiscUtils.isEmptyObject(user) && (!user.is_super_user)) {
                newList = newList.filter(
                    categoryObj => category_list_exclusive_for_super_user.indexOf(categoryObj.code) < 0
                );
            }
            let currentSubObj = MiscUtils.findElementInListByKey(newList, 'value', category ? category : subBoard);
            if (currentSubObj)
                this.setState({categoryList: newList, category: currentSubObj.value, categoryLabel: currentSubObj.label});
            else
                this.setState({categoryList: newList});
        }
        const languageList = user.languages_code.split(',');
        this.setState(
            {
                languageObjectList: languageList,
                selectedLanguage: selectedLanguage ? selectedLanguage : languageList[0]
            }
        );
    };


    initCategorySelect = (callback) => {
        const { t } = this.props;
        const { boardType } = this.state;
        // render Category list
        if (boardType !== BOARD_NAME_NOTICE) {
            const url = URLUtils.buildAPIURL(URL_MAPPING.article.getSettings);
            const callbackSuccess = (settings) => {
                let categoryList = settings['category_list'][boardType].map((obj) => {
                    return {
                        value: obj.code_text,
                        label: t(obj.value),
                        code: obj.code
                    }
                });

                // cai nay ko sync, dan den doi khi categoryList ko duoc set truoc khi  componentWillReceiveProps
                this.setState({
                    categoryList: categoryList,
                    categoryListWithCode: settings['category_list'][boardType],
                    category_list_exclusive_for_super_user: settings['category_list_exclusive_for_super_user'][boardType]
                }, () => {
                    const { user } = this.props;
                    // chi truong hop Sau khi login va next=write thi moi co user
                    this.initData(user);
                    callback();
                });
            };
            const callbackError = (error) => {
                MiscUtils.showErrorMessage(ERROR_MESSAGE.CANNOT_CONNECT_TO_SERVER);
            };
            ApiHttp.get(url, callbackSuccess, callbackError);
        } else
            callback();
    };


    // edit
    loadArticle = () => {
        const { boardType, articleId, categoryList } = this.state;
        let url = URLUtils.buildAPIURL(URL_MAPPING.article.getArticleForEdit);
        url = url.replace(':boardType', boardType);
        url = url.replace(':articleId', articleId.split('-')[0]);
        const callbackSuccess = (data) => {
            const { article } = data;
            const selectedCategory = categoryList.find((category) => category.code === article.article_post_type);
            this.setState({
                isProcessing: false,
                category: selectedCategory ? selectedCategory.value : '',
                categoryLabel: selectedCategory ? selectedCategory.label : '',
                titleLengthRemain: MAX_LENGTH_ARTICLE_TITLE - article.article_title.length,
                title: article.article_title,
                selectedLanguage: article.language_code,
                selectedTags: article.tags.map(function (tag) {
                        return {label: tag, value: tag}
                    }),
                selectedSources: article.sources.map(function (source) {
                    return {label: source, value: source}
                }),
                content: article.content,
                isAnnouncement: article.announcement,
                isAnonymous: article.is_anonymous,
                isEvent: article.is_event,
                eventDatetime: DateUtils.momentToLegacyDate(DateUtils.momentFromText(article.event_options.event_date+' '+article.event_options.event_time, 'MM/DD/YYYY h:mma')),
                eventTimeGMT: article.event_options.event_time_gmt,
                eventPlace: article.event_options.event_place,

                isShowMoreContent: article.content ? true : false

            });
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true}, () => ApiHttp.get(url, callbackSuccess, callbackError));
    };


    componentWillReceiveProps(nextProps){
        // o day thi moi lay duoc User
        const { user } = nextProps;
        this.initData(user);
        if (nextProps.message_error){
            if (nextProps.message_error === ERROR_CODE_FROM_BACKEND.FORCE_LOGOUT)
                URLUtils.userLogout(this.props);
            else
                MiscUtils.showErrorMessage(nextProps.message_error);
        }
    }

    preventClose = (e) => {
        e.preventDefault();
        e.returnValue = ""; //Chrome에서 동작하도록; deprecated
    };


    componentDidMount() {
        const { action }  = this.state;
        this.initCategorySelect(() => {
            if (action === ACTION_EDIT)
                this.loadArticle();
        });

        window.addEventListener("beforeunload", this.preventClose);
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.preventClose);
    }


    render() {
        let { user, saveArticleProcessing, t } = this.props;
        const { action, boardType, categoryList, categoryLabel, titleLengthRemain, title,
            languageObjectList, selectedLanguage, selectedTags, selectedSources, content,
            isProcessing, isAnnouncement, isAnonymous, isEvent, thumbnail_url, showSaveConfirm,
            eventDatetime, eventPlace, eventTimeGMT, isShowMoreContent } = this.state;
        if ((boardType === BOARD_NAME_NOTICE) && !MiscUtils.isEmptyObject(user) && (!user.is_admin)) {
            URLUtils.moveToURL(URL_MAPPING.error.forbidden);
            return;
        }
        const saveConfirmMessage = selectedLanguage ? t("Are you sure to save the article in")+" "+MiscUtils.findElementInListByKey(LANGUAGES, 'code', selectedLanguage)['text']+"?" : '';

        return (
            <div className="col-md-7" id="articleWrite">
                <BlockingComponent isProcessing={saveArticleProcessing || isProcessing}>
                    <Breadcrumb {...this.props} />
                        <h4>
                            { action === ACTION_WRITE && boardType === BOARD_NAME_QA && t('Ask your question')}
                            { action === ACTION_WRITE && boardType === BOARD_NAME_COMMUNITY && t('Write post')}
                            { action === ACTION_WRITE && boardType === BOARD_NAME_NOTICE && t('Write notice')}
                            { action === ACTION_EDIT && boardType === BOARD_NAME_QA && t('Edit question')}
                            { action === ACTION_EDIT && boardType === BOARD_NAME_COMMUNITY && t('Edit post')}
                            { action === ACTION_EDIT && boardType === BOARD_NAME_NOTICE && t('Edit notice')}
                        </h4>

                    <div className="title">
                        <span className="errorText">*</span>
                        {
                            (boardType === BOARD_NAME_QA) &&
                            <textarea rows="3" onChange={this.titleTyping} value={title}
                                      placeholder={t('What would you like to know? (The topic is currently limited to the area of Korea as beta service)')}></textarea>
                        }

                        {
                            (boardType !== BOARD_NAME_QA) &&
                            <input type="text" placeholder={t('Title')} onChange={this.titleTyping} value={title} className="inputTextBox"/>
                        }

                        <div className={classnames({"errorTextChangeColorOnly": titleLengthRemain === 0})}>
                            {
                                (boardType === BOARD_NAME_QA) &&
                                <a className="pull-left cursorPointer" onClick={this.toggleShowMoreContent}>
                                    {isShowMoreContent && t('Less')}
                                    {!isShowMoreContent && t('More description')}
                                </a>
                            }
                            {t('Characters remaining')}: {titleLengthRemain}
                        </div>
                    </div>

                    {
                        ((boardType !== BOARD_NAME_QA) || isShowMoreContent) &&
                        ((action === ACTION_WRITE) ||
                            ((action === ACTION_EDIT) && (!isProcessing))) &&
                        <Editor defaultContent={content} updateContent={this.updateContent}/>
                    }


                    <div className="paddingTopMedium articleOptions">
                        {
                            (boardType === BOARD_NAME_QA) &&
                            <label><input type="checkbox" checked={isAnonymous} onChange={this.changeAnonymous}/> &nbsp;{t('Anonymous')}</label>
                        }

                        {
                            (boardType === BOARD_NAME_COMMUNITY) &&
                            <label data-tip data-for='forEvent'>
                                <input type="checkbox" checked={isEvent} onChange={this.changeEvent}
                                       disabled={!user.is_able_to_create_event}
                                /> &nbsp;{t('Event')}
                                {
                                    !user.is_able_to_create_event &&
                                    <ReactTooltip id='forEvent' className="tooltipForBtn" effect='solid'>
                                        {t('Only available for user who has over 30 reputation')}
                                    </ReactTooltip>
                                }

                            </label>
                        }

                        {
                            user.is_super_user &&
                            <label><input type="checkbox" checked={isAnnouncement} onChange={this.changeAnnouncement}/> &nbsp;{t('Announcement')}</label>
                        }
                    </div>
                    {
                        isEvent &&
                            <div>
                                <div className="paddingVerticalMedium row eventSetting">
                                    <div className="col-md-6 datetimePicker">
                                        <DatetimePicker selectedDate={eventDatetime}
                                                        handleChange={this.handleChangeEventDatetime}
                                                        // dateFormat="MMMM d, YYYY - h:mm a"
                                                        dateFormat="MM/dd/YYYY  h:mma" // legacy date object: dd not DD (moment)
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Timezone timezone={eventTimeGMT} updateTimezone={this.updateEventTimezone}/>
                                    </div>
                                </div>
                                <div className="paddingVerticalMedium">
                                    <input type="text" placeholder={t('Place')} value={eventPlace} onChange={this.updateEventPlace} className="inputTextBox"/>
                                </div>
                            </div>
                    }



                    <div className="paddingVerticalMedium thumbnailURL">
                        <input type="text" placeholder={t('Thumbnail') + ' URL: '+ t('Optional')} onChange={this.validateThumbnailURL}
                               className={classnames({'invalid': thumbnail_url === 'invalid_thumbnail_url'}, {'valid': thumbnail_url && (thumbnail_url !== 'invalid_thumbnail_url')})}
                        />
                    </div>


                    <div className="paddingVerticalMedium">
                        <TagInput selectedTags={selectedTags} onChange={this.inputTag} />
                    </div>


                    {
                        (boardType !== BOARD_NAME_QA) &&
                        <div className="paddingVerticalMedium">
                            <SourceInput selectedSources={selectedSources} onChange={this.inputSource}/>
                        </div>
                    }


                    <hr className="hr-text" data-content={t('What language is this question written in?')} />
                    <div className="languageSelection">
                        <LanguageSelectionArticleWriteButton
                            selectedLanguages={languageObjectList}
                            selectedLanguage={selectedLanguage}
                            selectLanguagesCallback={this.selectLanguages}
                            languageButtonClick={this.selectLanguage}
                            // saveSelectLanguagesCallback={this.saveSelectLanguagesCallback}
                        />
                    </div>

                    {
                        boardType !== BOARD_NAME_NOTICE &&
                        <div className="category marginTop10">
                            <span className="errorText">*</span>
                            <DropdownList options={categoryList} value={categoryLabel}
                                          placeholder={t('Select category')} onChange={this.chooseCategory} />
                        </div>
                    }


                    {/*<div>*/}
                        {/*<button type="button" className="btn outlineBtn item">More options</button>*/}
                    {/*</div>*/}

                    <div className="actionBtn">
                        {
                            ((boardType !== BOARD_NAME_QA) || (isShowMoreContent)) &&
                            <button type="button" className="btn positiveBtn" onClick={this.previewArticleContent}>{t('Preview')}</button>
                        }
                        <a href={'/'+boardType}>
                            <button type="button" className="btn negativeBtn">{t('Cancel')}</button>
                        </a>
                        <button type="button" className="btn positiveBtn" onClick={this.saveArticle}>{t('Save')}</button>
                        <PopupConfirm bodyText={saveConfirmMessage} onConfirm={this.saveArticleConfirm}
                                      showModal={showSaveConfirm} toggleShowModal={this.toggleShowSaveConfirm}/>
                    </div>
                </BlockingComponent>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.reducerAuth.user,
        saveArticleProcessing: state.reducerArticle.saveArticleProcessing,
        message_error: state.reducerGlobal.message_error
    }
};

const mapDispatchToProps = {
    saveArticle
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translate()(ArticleWrite)));
