import React from 'react';
import defaultAvatar from '../../../static/images/default_avatar.jpg';
import NamecardInPopup from './NamecardInPopup';
import DropDownMenu from './DropDownMenu';
import { translate } from "react-i18next";



class UserAvatar extends React.Component {
    render (){
        const { user, isAnonymous, t } = this.props;
        const htmlContent = (
            <NamecardInPopup user={user} t={t} />
        );
        const { avatar, avatar_url } = user;
        return (
            <div className="UserAvatarView">
                {
                    !isAnonymous &&
                    <DropDownMenu htmlContent={htmlContent} position="top-start" hideArrow={true} isOpen={true}>
                        <img src={avatar ? avatar : avatar_url} alt="avatar" className="img-circle img-responsive userAvatarPhoto" />
                    </DropDownMenu>
                }
                {
                    isAnonymous &&
                    <img src={defaultAvatar} alt="avatar" className="img-circle img-responsive" />
                }
            </div>
        )

    }
}

export default translate()(UserAvatar);