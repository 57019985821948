import React from 'react';
import Switch from 'react-switch';
import { Glyphicon } from 'react-bootstrap';


export default class SwitchOnOff extends React.Component {

    render() {
        const { checked, handleSwitchChange, publicPrivateStyle, preventPullRight } = this.props;
        // onColor="#0ff"
        // onHandleColor="#08f"
        const customClass = preventPullRight ? '' : 'pull-right';

        if (publicPrivateStyle)
            return (
                <span className={customClass}>
                    <Switch
                        checked={checked}
                        onChange={handleSwitchChange}
                        offColor="#c3c3c3"
                        onColor="#68ddde"
                        offHandleColor="#9a9696"
                        onHandleColor="#19c8ca"
                        className="react-switch"
                        width={60}
                        uncheckedIcon={(
                            <div style={{ paddingTop: 5, paddingLeft: 3, fontSize: 14 }}>
                                <Glyphicon glyph="eye-close"/>
                            </div>
                        )}
                        checkedIcon={(
                            <div style={{ paddingTop: 5, paddingRight: 3, fontSize: 14 }}>
                                <Glyphicon glyph="eye-open"/>
                            </div>
                        )}
                    />
                </span>
            );
        else
            return (
                <span className={customClass}>
                    <Switch
                        checked={checked}
                        onChange={handleSwitchChange}
                        offColor="#c3c3c3"
                        onColor="#0ff"
                        offHandleColor="#9a9696"
                        onHandleColor="#08f"
                        className="react-switch"
                    />
                </span>
            )
    }
}