import React from 'react';
import { MiscUtils } from '../../extras';
import { TablePager } from '../common';
import { MESSAGE } from '../../config';
import { SEARCH } from '../../RouteURL';

export default class ProfileStatsTagView extends React.Component {

    render() {
        const { data, isProcessing, onPagerChange, boardType } = this.props;
        const tags = data.rows;
        const { currpage, totalpages } = data;
        return (
            <div id="profileStatsTagView">

                {   !isProcessing && tags && tags.length > 0 &&
                        <div>
                            <div className="row">
                                {
                                    tags.map((tag) =>
                                        <div className="col-md-3 col-sm-4 col-xs-6 tag" key={MiscUtils.generateId()}>
                                            <a href={SEARCH+'?board=tag&query='+tag.tag} target="_blank">
                                                <span className="levelBadge"> {tag.tag} </span>
                                                <span className="numStatsCount"> {tag.count} </span>
                                            </a>
                                        </div>
                                    )
                                }
                            </div>
                            <TablePager page={parseInt(currpage)} total={parseInt(totalpages)} onPagerChange={onPagerChange}/>
                        </div>
                }

                { !isProcessing && tags && tags.length === 0 &&
                    <div>
                        <label>{MESSAGE.NO_TAG}</label>
                    </div>
                }

            </div>
        );
    }
}