import React from 'react';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import emailImage from '../../static/images/email.png';
import Confirm from 'react-confirm-bootstrap';

import { SIGN_IN, INDEX_PAGE } from '../../RouteURL';
import { BlockingComponent, HTMLContent } from '../common';
import { MiscUtils } from '../../extras';
import { ApiAuth } from '../../api';


export default class SignupSuccess extends React.Component {

    state = {
        resendCode: '',
        email: '',
        isProcessing: false
    };

    // init: props is null, later: has props
    componentWillReceiveProps = (nextProps) => {
        const { email, resend_code } = nextProps.userAuthenticating;
        this.setState({
            email,
            resendCode: resend_code
        });
    };


    resendCode = () => {
        const { t } = this.props;
        const { resendCode } = this.state;
        const data = {
            hashCode: resendCode
        };
        const callbackSuccess = (data) => {
            this.setState({
                isProcessing: false,
                resendCode: data.resend_code
            });
            MiscUtils.showNotification(t('Email sent'));
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true}, () => ApiAuth.signUpResend(callbackSuccess, callbackError, data));
    };


    render() {
        const { isProcessing, email } = this.state;
        const { t } = this.props;
        return (
            <div id="signUpSuccess">
                <BlockingComponent isProcessing={isProcessing}>
                    <div>
                        <img className="img-responsive emailIcon" src={emailImage} alt="Email"/>
                    </div>
                    <p>
                        <HTMLContent content={t('Registration Email Sent to {{email}}',
                                                    {
                                                        email: "<label>"+email+"</label>"
                                                    })} />
                    </p>
                    <p>
                        {t('Check your email for the link to complete your registration. If you don\'t see this email in your inbox within 15 minutes, look for it in your spam-mail folder.')}

                    </p>
                    <p>
                        <HTMLContent content={t('If you find it there, please mark the email as Not spam and add to your address book: {{joinus_no_reply_email_address}}',
                            {
                                joinus_no_reply_email_address: "<label>Joinus.No.Reply (noreply@joinusworld.org) </label>"
                            })} />

                    </p>

                    <div className="actionBtn">
                        <Link to={SIGN_IN}>
                            <button type="button" className="btn btn-info">
                                <FontAwesome name="home"/> {t('Home')}
                            </button>
                        </Link>

                        <Confirm
                            onConfirm={this.resendCode}
                            body={t("Do you want to reset the activation code?")}
                            confirmText={t("Reset")}
                            title={t("Reset activation code")}>
                            <button type="button" className="btn btn-warning">
                                <FontAwesome name="retweet"/> {t('Reset')}
                            </button>
                        </Confirm>



                        {/*<button type="button" className="btn positiveBtn">*/}
                            {/*Change email*/}
                        {/*</button>*/}
                        {/*<button type="button" className="btn btn-danger">*/}
                            {/*Delete account*/}
                        {/*</button>*/}
                    </div>
                </BlockingComponent>

            </div>
        );
    }
}