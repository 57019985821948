import React from 'react';
import { Redirect } from 'react-router-dom';
import TextareaAutosize from 'react-autosize-textarea';
import { MESSAGE } from '../../../config';
import { BlockingComponent } from '../../common';
import { ARTICLE_TYPE, ARTICLE_TYPE_IN_BACKEND, BOARD_NAME_QA, BOARD_NAME_COMMUNITY, ERROR_CODE_FROM_BACKEND } from '../../../config';
import {MiscUtils, URLUtils} from '../../../extras';
import { SIGN_IN } from '../../../RouteURL';
import { ApiArticle, ApiFreeTalk } from '../../../api';
import { translate } from "react-i18next";



class CommentWrite extends React.Component {

    state = {
        redirectToLogin: false,
        isProcessing: false,
        prefix: this.props.editedComment ? this.props.editedComment.comment_type : (this.props.commentPrefixButtons ? this.props.commentPrefixButtons[0][1] : ''),
        content: this.props.editedComment ? this.props.editedComment.original_content : '',
        articleType: '',
        parentId: ''
    };


    updatePrefix = (e) => {
        this.setState({prefix: e.target.value});
    };


    updateContent = (e) => {
        this.setState({content: e.target.value});
    };


    onEnterPress = (e) => {
        const currentURL = URLUtils.getCurrentPathname();

        // in freetalk, not allow Enter to submit
        if (currentURL.indexOf('freetalk') >= 0)
            return;

        const { editedComment } = this.props;
        if(e.keyCode == 13 && e.shiftKey == false) { // neu shift + enter se la new line: default, ko can code
            e.preventDefault();
            if (editedComment)
                this.saveEditArticle();
            else
                this.saveComment();
        }
    };


    saveComment = () => {
        const arr = this.setArticleInfo();
        const { repliedComment } = this.props;
        const { articleType, parentId } = this.state;
        let { content, prefix } = this.state;
        if (repliedComment)
            content = "<span class=\"quote_username_in_comment_content\" id=\"" + repliedComment.user.user_id + "\">" + repliedComment.user.username + "</span>&nbsp;" + content;

        const data = {
            parent_id: arr[1],
            article_type: arr[0],
            comment_type: prefix,
            article_content: content,
        };
        this.saveCommentSubmission(data);

    };


    cancelEditArticle = () => {
        this.props.cancelEditArticle();
    };


    saveEditArticle = () => {
        const arr = this.setArticleInfo();
        const { editedComment  } = this.props;
        const { prefix, content, articleType } = this.state;
        const data = {
            article_type: arr[0],
            comment_id: editedComment.id,
            comment_type: prefix,
            article_content: content,
        };
        this.saveCommentSubmission(data);
    };


    saveCommentSubmission = (data) => {
        const { article_type } = data;
        const callbackSuccess = (res) => {
            this.setState({
                isProcessing: false,
                content: ''
            });
            MiscUtils.showNotification(MESSAGE.SAVE_SUCCESS);
            this.props.addCommentSuccessCallback(res.result_for_mobile_app);
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        const ApiObject = article_type === ARTICLE_TYPE_IN_BACKEND.COMMENT_TO_FT ? ApiFreeTalk : ApiArticle;
        this.setState({isProcessing: true}, () => ApiObject.saveComment(callbackSuccess, callbackError, data));
    };

    setArticleInfo = () => {
        const { article, response, parentType } = this.props;

        let articleType = null;
        let parentId = null;

        if (parentType === ARTICLE_TYPE.ARTICLE) {
            articleType = ARTICLE_TYPE_IN_BACKEND.COMMENT_TO_Q;
            parentId = article.article_id;
        } else {
            const responseType = response.object_type;
            parentId = response.id;
            if (responseType === ARTICLE_TYPE_IN_BACKEND.ANSWER)
                articleType = ARTICLE_TYPE_IN_BACKEND.COMMENT_TO_A;
            else if (responseType === ARTICLE_TYPE_IN_BACKEND.REPLY)
                articleType = ARTICLE_TYPE_IN_BACKEND.COMMENT_TO_R;
            else if (responseType === ARTICLE_TYPE_IN_BACKEND.FREE_TALK)
                articleType = ARTICLE_TYPE_IN_BACKEND.COMMENT_TO_FT;
        }
        return [articleType, parentId];
    }

    render (){
        const { commentPrefixButtons, repliedComment, t, editedComment, cancelWriteCommentInMobile, placeholder  } = this.props;
        const { prefix, isProcessing, redirectToLogin, content } = this.state;
        let placeHolder = placeholder;
        if (repliedComment) {
            placeHolder = t('Reply to') + ': ' + repliedComment.user.username;
            this.textareaComment.focus();
        }
        if (redirectToLogin)
            return <Redirect to={SIGN_IN}/>;

        return (
            <BlockingComponent isProcessing={isProcessing}>
                <div className="commentInput">
                    <div className="alignLeft">
                        {
                            commentPrefixButtons && commentPrefixButtons.map((button) =>
                                <label key={MiscUtils.generateId()}>
                                    <input type="radio" value={button[1]} checked={prefix === button[1]} key={MiscUtils.generateId()} onChange={this.updatePrefix}/> {button[0]}
                                </label>
                            )
                        }
                    </div>


                    <TextareaAutosize
                        rows={3}
                        maxRows={6}
                        onChange={this.updateContent}
                        onKeyDown={this.onEnterPress}
                        placeholder={placeHolder}
                        value={content}
                        innerRef={ref => this.textareaComment = ref}
                    />

                    <div className="actionBtn">
                        {
                            !editedComment &&
                            <div>
                                {
                                    cancelWriteCommentInMobile &&
                                    <button type="button" className="btn negativeBtn" onClick={cancelWriteCommentInMobile}>{t('Cancel')}</button>
                                }
                                <button type="button" className="btn positiveBtn" onClick={this.saveComment}>{t('Comment')}</button>
                            </div>

                        }
                        {
                            editedComment &&
                            <div>
                                <button type="button" className="btn negativeBtn" onClick={this.cancelEditArticle}>{t('Cancel')}</button>
                                <button type="button" className="btn positiveBtn" onClick={this.saveEditArticle}>{t('Save')}</button>
                            </div>
                        }

                    </div>
                </div>
            </BlockingComponent>

        )

    }
}

export default translate()(CommentWrite);