import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import FontAwesome from 'react-fontawesome';
import { ApiUser } from '../../api';
import { MiscUtils, URLUtils } from '../../extras';
import { CLASS_HIDDEN_IN_MOBILE } from '../../config';
import { TableSorterTransparent, BlockingComponent, HTMLContent } from '../common';
import {SETTING} from '../../RouteURL';
import { translate } from "react-i18next";


class ProfileUserView extends React.Component {

    state = {
        isProcessing: false,
        user: null
    };


    componentDidMount() {
        const params = URLUtils.parseParamsInURL(this.props.location.search);
        const data = {
            user_id: params.user_id
        };
        const callbackSuccess = (user) => {
            this.setState({
                isProcessing: false,
                user: user
            });
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true}, () => ApiUser.getProfileForSetting(callbackSuccess, callbackError, data));
    }


    render() {
        const { user, isProcessing } = this.state;
        const { current_user, t } = this.props;
        const sortButtons = [
            [t('Profile'), 'profile']
        ];

        const isMobileView = MiscUtils.isMobileView();
        const rightClass = isMobileView ? 'col-xs-9 col-sm-9 alignRight' : 'col-md-10';
        const leftClass = 'col-md-2 col-xs-3 col-sm-3';

        return (
            <BlockingComponent isProcessing={isProcessing}>
                {
                    user && !user.user_id &&
                    <div className='errorText alignCenter marginTopSmall'>{t('User not found')}</div>
                }

                {
                    user && (user.is_deleted === '1') &&
                        <div className='errorText'>{t('This account was deleted!')}</div>
                }

                {
                    user && user.user_id && (user.is_deleted !== '1') &&
                    <div>
                        {
                            user.introduction_display && (user.is_public.introduction || user.is_super_user || (current_user.id === user.user_id)) &&
                            <div>
                                <HTMLContent content={ user.introduction_display } />
                                { !user.is_public.introduction &&
                                    <FontAwesome name='lock' className="pull-right errorText icon"/>
                                }
                            </div>
                        }
                        <TableSorterTransparent sortButtons={sortButtons} sortType="profile"/>

                        <div id="profileUserView">

                            <div className={classnames({"marginVerticalMedium": isMobileView})}>
                                <span>{t('Last modified')}: { user.last_modified }</span>
                                <span className="pull-right">
                                    <span>
                                        <FontAwesome name="eye"/>&nbsp;{user.view_number}
                                    </span>
                                    {
                                        (current_user.user_id == user.user_id) &&
                                        <span>
                                            &nbsp;&nbsp;
                                            <a href={SETTING} className="btn-sm positiveBtn">{t('Edit')}</a>
                                        </span>
                                    }
                                </span>
                            </div>


                            <div className="row">
                                <div className={leftClass}><label>{t('Status')}</label></div>
                                <div className={rightClass}><span className="languageBadge">{ user.role }</span></div>
                            </div>

                            <div className="row">
                                <div className={leftClass}><label>{t('Language')}</label></div>
                                <div className={rightClass}>{ user.languages }</div>
                            </div>

                            <div className="row">
                                <div className={leftClass}><label>{t('Registration')}</label></div>
                                <div className={rightClass}>{ user.register_date }</div>
                            </div>

                            {
                                user.email && (user.is_public.email || user.is_super_user || (current_user.id === user.user_id)) &&
                                <div className="row">
                                    <div className={leftClass}><label>{t('Email')}</label></div>
                                    <div className={classnames(rightClass, {'private': !user.is_public.email })}>

                                        { user.email }
                                        { !user.is_public.email &&
                                            <FontAwesome name='lock' className="pull-right icon"/>
                                        }
                                    </div>
                                </div>
                            }

                            {
                                user.nationality && (user.is_public.nationality || user.is_super_user || (current_user.id === user.user_id)) &&
                                <div className="row">
                                    <div className={leftClass}><label>{t('Nationality')}</label></div>
                                    <div className={classnames(rightClass, {'private': !user.is_public.nationality })}>
                                        { user.nationality }
                                        { !user.is_public.nationality &&
                                            <FontAwesome name='lock' className="pull-right icon"/>
                                        }
                                    </div>
                                </div>
                            }

                            <div className="row">
                                <div className={leftClass}><label>{t('Timezone')}</label></div>
                                <div className={rightClass}>{ user.timezone_name }</div>
                            </div>

                            {
                                user.birthdate && (user.is_public.birth_year_month_day || user.is_public.birth_month_day || user.is_super_user || (current_user.id === user.user_id)) &&
                                <div className="row">
                                    <div className={leftClass}><label>{t('Birthdate')}</label></div>
                                    <div className={classnames(rightClass, {'private': (!user.is_public.birth_year_month_day && !user.is_public.birth_month_day) })}>
                                        { user.is_public.birth_year_month_day &&
                                            <span> { user.birthdate } (age: { user.age }) </span>
                                        }
                                        { user.is_public.birth_month_day &&
                                            <span> { user.birthdate_day_only }</span>
                                        }
                                        { !user.is_public.birth_month_day && !user.is_public.birth_year_month_day &&
                                            <div>
                                                { user.birthdate } ({t('age')}: { user.age })
                                                <FontAwesome name='lock' className="pull-right icon"/>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }

                            {
                                user.location && (user.is_public.location || user.is_super_user || (current_user.id === user.user_id)) &&
                                <div className="row">
                                    <div className={leftClass}><label>{t('Location')}</label></div>
                                    <div className={classnames(rightClass, {'private': !user.is_public.location })}>
                                        { user.location }
                                        { !user.is_public.location &&
                                            <FontAwesome name='lock' className="pull-right icon"/>
                                        }
                                    </div>
                                </div>
                            }

                            {
                                user.homepage && (user.is_public.homepage || user.is_super_user || (current_user.id === user.user_id)) &&
                                <div className="row">
                                    <div className={leftClass}><label>{t('Homepage')}</label></div>
                                    <div className={classnames(rightClass, {'private': !user.is_public.homepage })}>
                                        { user.homepage }
                                        { !user.is_public.homepage &&
                                            <FontAwesome name='lock' className="pull-right icon"/>
                                        }
                                    </div>
                                </div>
                            }

                            {
                                user.contact && (user.is_public.contact || user.is_super_user || (current_user.id === user.user_id)) &&
                                <div className="row">
                                    <div className={leftClass}><label>{t('Contact')}</label></div>
                                    <div className={classnames(rightClass, {'private': !user.is_public.contact })}>
                                        { user.contact }
                                        { !user.is_public.contact &&
                                            <FontAwesome name='lock' className="pull-right icon"/>
                                        }
                                    </div>
                                </div>
                            }

                            {
                                user.gender_display && (user.is_public.gender || user.is_super_user || (current_user.id === user.user_id)) &&
                                <div className="row">
                                    <div className={leftClass}><label>{t('Gender')}</label></div>
                                    <div className={classnames(rightClass, {'private': !user.is_public.gender })}>
                                        { user.gender_display }
                                        { !user.is_public.gender &&
                                            <FontAwesome name='lock' className="pull-right icon"/>
                                        }
                                    </div>
                                </div>
                            }

                            {
                                user.job && (user.is_public.job || user.is_super_user || (current_user.id === user.user_id)) &&
                                <div className="row">
                                    <div className={leftClass}><label>{t('Job')}</label></div>
                                    <div className={classnames(rightClass, {'private': !user.is_public.job })}>
                                        { user.job }
                                        { !user.is_public.job &&
                                            <FontAwesome name='lock' className="pull-right icon"/>
                                        }
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                }

            </BlockingComponent>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        current_user: state.reducerAuth.user
    }
};

export default connect(mapStateToProps, {})(withRouter(translate()(ProfileUserView)));