import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getArticles, getResponses } from '../../redux/actions';
import { TableContentArticle, TableContentResponse, LanguageFilteringButton, TableSorterTransparent, BlockingComponent, Breadcrumb } from '../common';
import { MiscUtils, URLUtils, CURRENT_BOARD, URL_MAPPING, SELECTED_LANGUAGES } from '../../extras';
import { ARTICLE_SORTER_NEWEST, ARTICLE_SORTER_VOTE, ARTICLE_SORTER_NO_ANSWER,
    RESPONSE_SORTER_NEWEST, RESPONSE_SORTER_DAILY_BEST, RESPONSE_SORTER_WEEKLY_BEST,
    BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_NOTICE, BOARD_NAME_RANK, ERROR_CODE_FROM_BACKEND } from '../../config';
import { translate } from "react-i18next";



class BoardView extends React.Component {

    state = {
        selectedLanguages: MiscUtils.getFromLocalStorage(SELECTED_LANGUAGES).filter(code => code !== ''),
        boardType: MiscUtils.getFromLocalStorage(CURRENT_BOARD),
        subCategory: URLUtils.getSubCategoryOfBoardFromURL(this),
        sortTypeArticle: ARTICLE_SORTER_NEWEST[1],
        sortTypeResponse: RESPONSE_SORTER_NEWEST[1],
        pagerArticle: 1,
        pagerResponse: 1,
        dataArticles: {},
        dataResponses: {}
    };

    initState = () => {
        this.setState({
            boardType: MiscUtils.getFromLocalStorage(CURRENT_BOARD),
            subCategory: URLUtils.getSubCategoryOfBoardFromURL(this),
            sortTypeArticle: ARTICLE_SORTER_NEWEST[1],
            sortTypeResponse: RESPONSE_SORTER_NEWEST[1],
            pagerArticle: 1,
            pagerResponse: 1,
            articles: [],
            responses: []
        }, () => {
            this.loadArticles();
            this.loadResponses();
        });
    };


    selectSorterArticle = (sorter) => {
        this.setState({sortTypeArticle: sorter}, () => this.loadArticles());
    };


    selectSorterResponse = (sorter) => {
        this.setState({sortTypeResponse: sorter}, () => this.loadResponses());
    };


    loadArticles = () => {
        const { boardType, subCategory, sortTypeArticle, pagerArticle, selectedLanguages } = this.state;
        const params = {
            board_type: boardType,
            left_category: subCategory,
            time: '3', //all
            tabid: sortTypeArticle,
            page: pagerArticle,
            languages: selectedLanguages.join(',')
        };
        const getArticlesURL = URLUtils.buildAPIURL(URL_MAPPING.article.getArticles, params);
        const component = this;
        const { getArticles } = this.props;
        this.setState({dataArticles: {}}, () => {
            getArticles(getArticlesURL, function (data){
                component.setState({dataArticles: data});
            });
        });
    };


    // recent answers
    loadResponses = () => {
        const { boardType, subCategory, sortTypeResponse, pagerResponse, selectedLanguages } = this.state;
        if ((boardType === BOARD_NAME_QA) && (subCategory === 'main')){
            const params = {
                sort: sortTypeResponse,
                page: pagerResponse,
                languages: selectedLanguages.join(',')
            };
            const getResponsesURL = URLUtils.buildAPIURL(URL_MAPPING.article.getRecentResponses, params);
            const component = this;
            const { getResponses } = this.props;
            this.setState({dataResponses: {}}, () => {
                getResponses(getResponsesURL, function (data){
                    component.setState({dataResponses: data});
                });
            });
        }
    };


    onPagerChangeArticle = (newPage) => {
        this.setState({pagerArticle: newPage}, () => this.loadArticles());
    };


    onPagerChangeResponse = (newPage) => {
        this.setState({pagerResponse: newPage}, () => this.loadResponses());
    };


    saveSelectLanguagesCallback = (selectedLanguages) => {
        this.setState({selectedLanguages}, () => {
            this.loadArticles();
            this.loadResponses();
        });
    };


    componentDidMount = () => {
        this.initState();
    };


    componentWillReceiveProps(nextProps){
        const {message_error} = nextProps;
        if (message_error){
            if (message_error === ERROR_CODE_FROM_BACKEND.FORCE_LOGOUT)
                URLUtils.userLogout(this.props);
            else
                MiscUtils.showErrorMessage(message_error);
        }
        // khi chon left bar category
        if (nextProps.location.pathname !== this.props.location.pathname) {
            this.initState();
        }
    };



    render() {
        const { boardType, subCategory, sortTypeArticle, sortTypeResponse, dataArticles, dataResponses, selectedLanguages } = this.state;
        const { getArticlesProcessing, getResponsesProcessing, t } = this.props;
        const sortButtonsResponse = [
            RESPONSE_SORTER_NEWEST,
            RESPONSE_SORTER_DAILY_BEST,
            RESPONSE_SORTER_WEEKLY_BEST
        ];
        let sortButtonsArticle = [
            ARTICLE_SORTER_NEWEST,
            ARTICLE_SORTER_VOTE
        ];
        if (boardType === BOARD_NAME_QA)
            sortButtonsArticle.push(ARTICLE_SORTER_NO_ANSWER);


        return (
            <div className="col-md-7" id="boardView">
                <div className="row selectLanguageComponentWrapper">
                    <LanguageFilteringButton
                        selectedLanguages={selectedLanguages}
                        saveSelectLanguagesCallback={this.saveSelectLanguagesCallback}
                    />
                </div>

                <div className="row centerContent">
                    <Breadcrumb />
                    {/*<h3 className="contentHeader">*/}
                        {/*{ (boardType === BOARD_NAME_QA) && t('Question') }*/}
                        {/*{ (boardType === BOARD_NAME_COMMUNITY) && t('Post') }*/}
                        {/*{ (boardType === BOARD_NAME_NOTICE) && t('Notice') }*/}
                    {/*</h3>*/}

                    {
                        (boardType !== BOARD_NAME_NOTICE) &&
                        <TableSorterTransparent sortButtons={sortButtonsArticle} sortType={sortTypeArticle} selectSorter={this.selectSorterArticle} />
                    }
                    <BlockingComponent isProcessing={getArticlesProcessing}>
                        <TableContentArticle boardType={boardType} data={dataArticles} isProcessing={getArticlesProcessing} onPagerChange={this.onPagerChangeArticle}/>
                    </BlockingComponent>
                </div>

                { boardType === BOARD_NAME_QA && (subCategory === 'main') &&
                    <div className="row centerContent" style={{marginTop: '20px'}} >
                        <h3 className="contentHeader">{t('Answers')}</h3>
                        <TableSorterTransparent sortButtons={sortButtonsResponse} sortType={sortTypeResponse} selectSorter={this.selectSorterResponse} />
                        <BlockingComponent isProcessing={getResponsesProcessing}>
                            <TableContentResponse boardType={boardType} data={dataResponses} isProcessing={getResponsesProcessing} onPagerChange={this.onPagerChangeResponse}/>
                        </BlockingComponent>
                    </div>
                }
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        message_error: state.reducerGlobal.message_error,
        getArticlesProcessing: state.reducerArticle.getArticlesProcessing,
        getResponsesProcessing: state.reducerResponse.getResponsesProcessing
    }
};

const mapDispatchToProps = {
    getArticles,
    getResponses
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translate()(BoardView)));
