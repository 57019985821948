import React from 'react';
import pig from '../../static/images/donation_pig.png';

export default class DonationViewEnglishView extends React.Component {
    render() {
        return (
            <div>
                <p style={{padding: '15px 0px'}}>
                    <img src={pig} className="img-responsive center-block" alt="A"/>
                </p>
                <p>JOINUS WORLD is a website administered by JOINUS KOREA,which is a nonprofit NGO network.</p>
                <p>Its headquarter is located in the Republic of Korea. We make efforts to build up a knowledge-exchange community with multi-language and culture connecting every knowledge with individuals all over the world based on four mottos-participation, sharing, openness, and autonomy - and to go forward with you. </p>

                <div className="donationAmount">
                    <h5>Donation amount (USD)</h5>
                    <div className="row period">
                        <div className="col-md-3 col-xs-6 col-sm-6">
                            <label> <input type="radio" name="period" value="one" checked/> One-time </label>
                        </div>
                        <div className="col-md-3 col-xs-6 col-sm-6">
                            <label> <input type="radio" name="period" value="monthly"/> Montly </label>
                        </div>
                    </div>
                    <div className="row amount">
                        <div className="col-md-3 col-xs-4 col-sm-4">
                            <label> <input type="radio" name="amount" value="5"/> $5 </label>
                        </div>
                        <div className="col-md-3 col-xs-4 col-sm-4">
                            <label> <input type="radio" name="amount" value="10"/> $10 </label>
                        </div>
                        <div className="col-md-3 col-xs-4 col-sm-4">
                            <label> <input type="radio" name="amount" value="20"/> $20 </label>
                        </div>
                        <div className="col-md-3 col-xs-4 col-sm-4">
                            <label> <input type="radio" name="amount" value="30"/> $30 </label>
                        </div>
                        <div className="col-md-3 col-xs-4 col-sm-4">
                            <label> <input type="radio" name="amount" value="50" checked/> $50 </label>
                        </div>
                        <div className="col-md-3 col-xs-4 col-sm-4">
                            <label> <input type="radio" name="amount" value="75"/> $75 </label>
                        </div>
                        <div className="col-md-3 col-xs-4 col-sm-4">
                            <label> <input type="radio" name="amount" value="100"/> $100 </label>
                        </div>
                        <div className="col-md-3 col-xs-4 col-sm-4">
                            <label> <input type="radio" name="amount" value="other"/> Other </label>
                        </div>
                    </div>

                    <div className="buttons">
                        <div className="col-md-offset-6 col-md-3 col-xs-6 col-sm-6"><button type="button" className="btn positiveBtn">Credit/debit card</button></div>
                        <div className="col-md-3 col-xs-6 col-sm-6"><button type="button" className="btn positiveBtn">PayPal account</button></div>
                    </div>

                    <h5>Where your donation goes</h5>
                    <div>
                        <strong>Technology:</strong> Servers, bandwidth, maintenance, development.
                    </div>
                    <div>
                        <strong>People and Projects:</strong> we have only a few engineers to support a wide variety of projects, making your donation a great investment in a highly-efficient non-profit organization.
                    </div>
                </div>

                <div>By donating, you are sharing your information with the Joinusworld Foundation, the nonprofit organization that hosts Joinusworld and other Joinusworld projects, and its service providers in Korea and elsewhere pursuant to our donor privacy policy. We do not sell or trade your information to anyone. For more information please read our donor policy</div>
                <div style={{margin: '15px 0px', color: '#5cc5ca'}}>Monthly payments will be debited by the Joinusworld Foundation until such time as you notify us to discontinue them.</div>

            </div>
        )
    }
}