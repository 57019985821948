import React from 'react';

export default class UserGuideKoreanView extends React.Component {
    render (){
        const { iFrameHeight } = this.props;
        return (
            <iframe data-auto-height="false" data-aspect-ratio="1.33234859675037" scrolling="no" id="doc_15077" width="100%" height={iFrameHeight} frameborder="0"
                    allowFullScreen = "true"
                    mozallowfullscreen = "true"
                    webkitallowfullscreen = "true"
                    src="https://docs.google.com/presentation/d/e/2PACX-1vSko0IpnpoIW5sDo0qvhTFYIFZ8c-AMzzI3Abxg_0I8TM47vO0xuG_Cy25lW3hyuBOZBEIV7xhP-4yO/embed?start=false&loop=false&delayms=3000"  title="iframe_userguide_kr"></iframe>
        )
    }
}