import React from 'react';
import Dropdown from 'react-dropdown';
// import { SimpleSelect } from 'react-selectize';

// http://fraserxu.me/react-dropdown/
// http://furqanzafar.github.io/react-selectize/#/?category=simple
export default class DropdownList extends React.Component {

    // render() {
    //     const { value, options, placeholder } = this.props;
    //     return (
    //         <SimpleSelect
    //             options = { options }
    //             placeholder = { placeholder }
    //             theme = "material" // can be one of "default" | "bootstrap3" | "material" | ...
    //         />
    //     )
    // }

    render() {
        const { value, options, placeholder, onChange } = this.props;
        return (
            <Dropdown options={options} value={value} placeholder={placeholder} onChange={onChange} />
        )
    }
}