import React from 'react';
import FontAwesome from 'react-fontawesome';
import fb from '../../../static/images/icon/facebook.png';
import { SEARCH, PROFILE } from '../../../RouteURL';
import BlockingComponent from './BlockingComponent';
import { ApiUser } from '../../../api';


export default class NamecardInPopup extends React.Component {

    state = {
        isProcessing: false,
        content: null
    };

    componentDidMount () {
        const { user } = this.props;
        const data = {
            user_id: user.user_id
        };
        const callbackSuccess = (res) => {
            this.setState({
                isProcessing: false,
                content: res
            });
        };
        const callbackError = (error) => {
            this.setState({isProcessing: false});
        };
        this.setState({isProcessing: true}, () => ApiUser.getNameCard(callbackSuccess, callbackError, data));
    };

    render (){
        const { user, t } = this.props;
        const { activity_point, popularity_point, confidence_point, activity, popularity, confidence,
            avatar, avatar_url, username, level } = user;
        const { isProcessing, content } = this.state;

        return (
            <div id="namecardInPopup">
                <BlockingComponent isProcessing={ isProcessing }>
                    {
                        !isProcessing && content &&
                        <div>
                            {/*  --- header --- */}
                            <div className="namecardHeader">

                                {/*<UserAvatarInfo user={user} />*/}
                                <div className="userAvatarInfo">
                                    <div className="leftColumn">
                                        <img src={avatar ? avatar : avatar_url} alt="avatar" className="img-circle img-responsive userAvatarPhoto" />
                                    </div>
                                    <div className="rightColumn">
                                        <p>
                                            <strong>{username}</strong>
                                            <label className="levelBadge">Lv {level}</label>
                                            <span className={"reputationInfo"}>
                                                <span className="activityPoint">♥ { (activity_point || activity_point === 0) ? activity_point : activity }</span>&nbsp;
                                                <span className="popularityPoint"> ♥ { (popularity_point || popularity_point === 0) ? popularity_point : popularity}</span>&nbsp;
                                                <span className="confidencePoint"> ♥ { (confidence_point || confidence_point === 0) ? confidence_point : confidence}</span>
                                            </span>
                                        </p>
                                        <p className="career">{user.career ? user.career : user.job}</p>
                                    </div>
                                </div>



                                {
                                    content.facebook &&
                                    <FontAwesome name="facebook-square" size="lg" className="snsLogo" />
                                }
                            </div>


                            {/*  --- content --- */}
                            <div className="row alignLeft">
                                <div className="col-md-12">
                                    <div>
                                        <strong>{t('Languages')}: </strong>
                                        <span>{ content.language }</span>
                                    </div>
                                    {
                                        content.location &&
                                        <div>
                                            <strong>{t('Location')}: </strong>
                                            <span>{content.location}</span>
                                        </div>
                                    }
                                </div>
                            </div>


                            {/*  --- footer --- */}
                            <div className="namecardFooter alignLeft">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>{t('Question')}</td>
                                                    <td><strong>{ content.question }</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{t('Answer')}</td>
                                                    <td><strong>{ content.answer }</strong> ({t('1st answer')}: <label>{ content.first_answer }</label> / {t('accepted')}: <label>{ content.accepted_answer }</label>)</td>
                                                </tr>
                                                <tr>
                                                    <td>{t('Post')}</td>
                                                    <td><strong>{ content.post }</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="namecardFooterAction">
                                    {/*<a type="button" className="btn outlineBtn">Guestbook</a>*/}
                                    <a href={PROFILE + '?user_id=' + content.user_id} target="_blank" type="button" className="btn outlineBtn">{t('More')}</a>
                                </div>
                            </div>


                        </div>
                    }
                </BlockingComponent>
            </div>
        )
    }
}
