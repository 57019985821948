import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import { MiscUtils } from '../../../extras';


export default class SourceList extends React.Component {
    render (){
        return (
            <div id="sourceList">
                {/*<label>Source:</label>*/}
                <Glyphicon glyph="list-alt"/>
                {
                    this.props.sources.map((source) =>
                        <span className="numStatsCount" key={MiscUtils.generateId()}>
                            <a href={source.source_url} target="_blank">{ source.source_text }</a>
                        </span>
                    )
                }
            </div>
        )
    }

}