import { URLUtils, URL_MAPPING } from '../extras';
import ApiHttp from './http';

export default class ApiFreeTalk {

    static saveTalk = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.freeTalk.saveTalk);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static getTalks = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.freeTalk.getTalks);
        ApiHttp.get(url, callbackSuccess, callbackError, data);
    };

    static saveComment = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.freeTalk.saveComment);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };

    static deleteTalk = (callbackSuccess, callbackError, data) => {
        const url = URLUtils.buildAPIURL(URL_MAPPING.freeTalk.deleteTalk);
        ApiHttp.post(url, callbackSuccess, callbackError, data);
    };



}