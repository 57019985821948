export * from './common';


// ----- PRODUCTION ----
export const FRONTEND_URL = 'https://www.joinusworld.org';
export const BACKEND_URL = 'https://backend.joinusworld.org';

export const FACEBOOK_APP_ID = '636535523987050'; // //Client, beta
export const KAKAOTALK_APP_ID = '1d01305d76069b82683d9115a3ed8fa4'; // ngocthanh19051986  shared by Joinus, appID: 114720
export const NAVER_APP_ID = 'u4uNQKB08jjQgOfrLGyY'; // ngocthanh259  / a..1..!...
export const NAVER_CALLBACK = 'https://www.joinusworld.org/accounts/login';