import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-i18next";


// import { Route, Switch } from 'react-router-dom';
// import { TableSorterTransparentWithLink } from '../common';
import { MiscUtils, URLUtils } from '../../extras';
import Talk from './Talk';
import { ApiFreeTalk } from "../../api";
import {BlockingComponent, PopupModal, TablePager} from "../common";
import { BOARD_NAME_FREETALK } from "../../config";
import FreetalkWrite from "./FreetalkWrite";




class FreetalkList extends React.Component {

    state = {
        isProcessing: false,
        talks: [],
        page: 1,
        totalPage: 1,
        isShowEditTalk: false,
        editedTalk: null
    };


    componentDidMount() {
        this.getTalks(this.state.page);
    }




    getTalks = (page) => {

        const data = { page };

        const callbackSuccess = (data) => {
            const { talks, total_page } = data;
            this.setState({
                isProcessing: false,
                talks,
                totalPage: total_page
            });
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true}, () => {
            ApiFreeTalk.getTalks(callbackSuccess, callbackError, data);
        });
    };



    onPagerChange = (newPage) => {
        this.setState({page: newPage}, () => {
            this.getTalks(newPage);
        });
    };



    editTalkCallback = (talk) => {
        this.setState({editedTalk: talk});
        this.toggleShowEditTalk();
    };


    toggleShowEditTalk = () => {
        this.setState({isShowEditTalk: !this.state.isShowEditTalk});
    };


    render() {

        const { user, t } = this.props;
        const { isProcessing, talks, page, totalPage, isShowEditTalk, editedTalk } = this.state;
        const isMobileView = MiscUtils.isMobileView();

        return (
            <BlockingComponent isProcessing={isProcessing}>
                {
                    !isProcessing &&
                    <div className="talkList">
                        <div className="talks">
                            {
                                talks.map((talk) => <Talk talk={talk} currentUser={user} boardType={BOARD_NAME_FREETALK} editTalkCallback={this.editTalkCallback}
                                                          key={MiscUtils.generateId()}/>)
                            }
                        </div>
                        <TablePager page={page} total={totalPage} onPagerChange={this.onPagerChange}/>
                    </div>

                }

                <PopupModal isVisible={isShowEditTalk} onCloseClicked={this.toggleShowEditTalk} setWidth={isMobileView ? 95 : 50}>
                    <FreetalkWrite {...this.props} editedTalk={editedTalk} toggleShowEditTalk={this.toggleShowEditTalk}/>
                </PopupModal>

            </BlockingComponent>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.reducerAuth.user
    }
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translate()(FreetalkList)));