import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import FacebookLogin from 'react-facebook-login/dist/facebook-login';
import GoogleLogin from 'react-google-login';
import KakaoLogin from 'react-kakao-login';
import NaverLogin from 'react-naver-login';
import { FACEBOOK_APP_ID, GOOGLE_APP_ID, KAKAOTALK_APP_ID, NAVER_APP_ID, NAVER_CALLBACK, MESSAGE} from '../../config';
import { MiscUtils } from '../../extras';
import { connectSNS, disconnectSNS } from '../../redux/actions';
import {BlockingComponent, PopupModal} from '../../components';
import { translate } from "react-i18next";
import Kakaotalk from "../../static/images/icon/kakaotalk.png";
import Naver from "../../static/images/icon/naver.png";



// xem LoginForm.js

class PartyConnectionSettingView extends React.Component {


    state = {
        showKakaotalkEmailError: false
    };


    facebookLoginCallback = (response) => {
        const { user, connectSNS } = this.props;
        const { t } = this.props;
        const { email } = response;
        if (!email)
            MiscUtils.showErrorMessage(t('Please give permission to get your email address in order to continue'));
        else {
            response['sns'] = 'facebook';
            connectSNS(user, response, () => MiscUtils.showNotification(MESSAGE.CONNECT_SUCCESS));
        }
    };





    googleLoginCallback = (response) => {
        const { user, connectSNS } = this.props;
        const { t } = this.props;
        const { email } = response.profileObj;
        if (!email)
            MiscUtils.showErrorMessage(t('Please give us permission to get your email address in order to continue'));
        else {
            response['sns'] = 'google';
            connectSNS(user, response, () => MiscUtils.showNotification(MESSAGE.CONNECT_SUCCESS));
        }
    };

    googleLoginCallbackFailure = (response) => {
        const { t } = this.props;
        console.log(response);
        MiscUtils.showErrorMessage(t('Cannot login by Google'));
    };







    kakaotalkLoginCallback = (response) => {
        const { user, connectSNS } = this.props;
        const { email } = response.profile.kakao_account;
        if (!email)
            this.toggleKakaotalkEmailError();
        else {
            response['sns'] = 'kakaotalk';
            connectSNS(user, response, () => MiscUtils.showNotification(MESSAGE.CONNECT_SUCCESS));
        }
    };

    kakaotalkLoginCallbackFailure = (response) => {
        console.log(response);
        MiscUtils.showErrorMessage('Cannot login by Kakaotalk')
    };

    toggleKakaotalkEmailError = () => {
        this.setState({showKakaotalkEmailError: !this.state.showKakaotalkEmailError});
    };






    naverLoginCallback = (response) => {
        const { user, connectSNS } = this.props;
        const { t } = this.props;
        const { email } = response;
        if (!email)
            MiscUtils.showErrorMessage(t('Please give us permission to get your email address in order to continue'));
        else {
            response['sns'] = 'naver';
            connectSNS(user, response, () => MiscUtils.showNotification(MESSAGE.CONNECT_SUCCESS));
        }
    };

    naverLoginCallbackFailure = (response) => {
        console.log(response);
        MiscUtils.showErrorMessage('Cannot login by Naver')
    };





    disconnectSNS = (sns) => {
        const { user, disconnectSNS } = this.props;
        disconnectSNS(user, sns, () => MiscUtils.showNotification(MESSAGE.DISCONNECT_SUCCESS));
    };




    render() {
        const { showKakaotalkEmailError } = this.state;
        const isMobileView = MiscUtils.isMobileView();
        const { user, isProcessing, t } = this.props;
        const classLeft = "col-md-4 col-xs-7 col-sm-7";
        const classRight = "col-md-8 col-xs-5 col-sm-5";
        const CONNECT = t('Connect');
        const DISCONNECT = t('Disconnect');
        // const COMING_SOON = t('Coming soon');
        return (
            <div id="partyConnectionSettingView">
                <BlockingComponent isProcessing={isProcessing} >
                    <div className="row">
                        <div className={classLeft}>
                            <span className="btn btn-block btn-social btn-facebook">
                                <span className="fa fa-facebook"></span> Facebook
                            </span>
                        </div>
                        <div className={classRight}>
                            {
                                user.connected_to_facebook &&
                                <button type="button" className="pull-right btn negativeBtn"
                                        onClick={() => this.disconnectSNS("facebook")}>
                                    {DISCONNECT}
                                </button>
                            }
                            {
                                !user.connected_to_facebook &&
                                <FacebookLogin
                                    appId={FACEBOOK_APP_ID}
                                    autoLoad={false}
                                    fields="name,email,picture,gender,timezone,birthday"
                                    scope="public_profile,email"
                                    callback={this.facebookLoginCallback}
                                    cssClass="pull-right btn positiveBtn"
                                    // icon={<span className="fa fa-facebook"></span>}
                                    textButton={CONNECT}
                                />
                            }
                        </div>
                    </div>
                    <hr/>


                    <div className="row">
                        <div className={classLeft}>
                            <span className="btn btn-block btn-social btn-google">
                                <span className="fa fa-google"></span> Google
                            </span>
                        </div>
                        <div className={classRight}>
                            {
                                user.connected_to_google &&
                                <button type="button" className="pull-right btn negativeBtn"
                                        onClick={() => this.disconnectSNS("google")}>
                                    {DISCONNECT}
                                </button>
                            }
                            {
                                !user.connected_to_google &&
                                <GoogleLogin
                                    clientId={GOOGLE_APP_ID}
                                    scope="email profile https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/userinfo.email"
                                    render={renderProps => (
                                        //btn-block btn-social
                                        <button className="pull-right btn positiveBtn" onClick={renderProps.onClick}>
                                            {CONNECT}
                                        </button>
                                    )}
                                    onSuccess={this.googleLoginCallback}
                                    onFailure={this.googleLoginCallbackFailure}
                                    cookiePolicy={'single_host_origin'}
                                />
                            }
                        </div>
                    </div>
                    <hr/>





                    {/*<div className="row">*/}
                    {/*    <div className={classLeft}>*/}
                    {/*        <span className="btn btn-block btn-social btn-linkedin">*/}
                    {/*            <span className="fa fa-linkedin"></span> Linkedin*/}
                    {/*        </span>*/}

                    {/*        <span className="btn btn-block btn-social btn-yahoo">*/}
                    {/*            <span className="fa fa-yahoo"></span> Yahoo*/}
                    {/*        </span>*/}

                    {/*        <span className="btn btn-block btn-social btn-microsoft">*/}
                    {/*            <span className="fa fa-windows"></span> Windows Live*/}
                    {/*        </span>*/}
                    {/*        */}
                    {/*    </div>*/}
                    {/*    <div className={classRight}>*/}
                    {/*        { /*<button type="button" className="pull-right btn negativeBtn disabled">Coming soon</button> */ }
                    {/*        <label className="pull-right">{COMING_SOON}</label>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<hr/>*/}




                    <div className="row">
                        <div className={classLeft}>
                            <span className="btn btn-block btn-social btn-social-kakaotalk">
                                <img src={Kakaotalk} /> Kakaotalk
                            </span>
                        </div>
                        <div className={classRight}>
                            {
                                user.connected_to_kakaotalk &&
                                <button type="button" className="pull-right btn negativeBtn"
                                        onClick={() => this.disconnectSNS("kakaotalk")}>
                                    {DISCONNECT}
                                </button>
                            }
                            {
                                !user.connected_to_kakaotalk &&
                                <KakaoLogin
                                    jsKey={KAKAOTALK_APP_ID}
                                    getProfile={true}
                                    onSuccess={this.kakaotalkLoginCallback}
                                    onFailure={this.kakaotalkLoginCallbackFailure}
                                    render={renderProps => (
                                        <button className="pull-right btn positiveBtn" onClick={renderProps.onClick}>
                                            {CONNECT}
                                        </button>
                                    )}
                                />

                            }
                        </div>
                    </div>
                    <hr/>


                    <div className="row">
                        <div className={classLeft}>
                            <span className="btn btn-block btn-social btn-social-naver">
                                <img src={Naver} /> Naver
                            </span>
                        </div>
                        <div className={classRight}>
                            {
                                user.connected_to_naver &&
                                <button type="button" className="pull-right btn negativeBtn"
                                        onClick={() => this.disconnectSNS("naver")}>
                                    {DISCONNECT}
                                </button>
                            }
                            {
                                !user.connected_to_naver &&
                                <NaverLogin
                                    clientId={NAVER_APP_ID}
                                    callbackUrl={NAVER_CALLBACK}
                                    render={(renderProps) => <button className="pull-right btn positiveBtn" onClick={renderProps.onClick}>
                                                        {CONNECT}
                                                    </button>
                                    }
                                    onSuccess={this.naverLoginCallback}
                                    onFailure={this.naverLoginCallbackFailure}
                                />
                            }
                        </div>
                    </div>


                </BlockingComponent>

                <PopupModal isVisible={showKakaotalkEmailError} onCloseClicked={this.toggleKakaotalkEmailError} setWidth={isMobileView ? 95 : 60}>
                    <div className="form-group" style={{marginBottom: '0px'}}>
                        <span>We need your email to continue, please disconnect <strong>JOINUSWORLD</strong> app and try again, then select <strong>Accept All</strong> : <a target="_blank" href="https://accounts.kakao.com/weblogin/account/partner">disconnect</a></span>
                    </div>
                    <div className="actionBtn">
                        <button type="button" className="btn positiveBtn" onClick={this.toggleKakaotalkEmailError}>{t('OK')}</button>
                    </div>
                </PopupModal>

            </div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        user: state.reducerAuth.user,
        isProcessing: state.reducerAuth.isProcessing
    }
};

const mapDispatchToProps = {
    connectSNS,
    disconnectSNS
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translate()(PartyConnectionSettingView)));