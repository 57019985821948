import React from 'react';
import {SkyLightStateless} from 'react-skylight';
import { RESPONSIVE_MODAL_WIDTH } from '../../../config';
import { MiscUtils } from '../../../extras';

// http://marcio.github.io/react-skylight/
export default class PopupModal extends React.Component {

    render() {
        const { isVisible, onCloseClicked, customStyle, setWidth } = this.props;
        let width = null;
        if (setWidth && (typeof setWidth !== 'undefined'))
            width = setWidth;
        else {
            //const isMobile = MiscUtils.getFromLocalStorage(IS_MOBILE_VIEW);
            // vi ko qua App (khi Get info page) nen ko cos IS_MOBILE_VIEW
            const isMobile = MiscUtils.isMobileView();
            width = isMobile ? RESPONSIVE_MODAL_WIDTH.inMobileView : RESPONSIVE_MODAL_WIDTH.inPCView;
        }
        const popupStyle = {
            //backgroundColor: '#00897B',
            //color: '#ffffff',
            height: 'auto',
            width: width + '%',
            left: ((100-width) / 2) + '%',
            marginLeft: '0'
        };

        return (
            <SkyLightStateless
                dialogStyles={customStyle ? customStyle : popupStyle}
                isVisible={isVisible}
                onCloseClicked={onCloseClicked}
                closeButtonStyle={{display: "none"}}  //no x button on top-right
            >
                {this.props.children}
            </SkyLightStateless>
        );
    }
}