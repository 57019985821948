import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RankPeopleView from './RankPeopleView';
import RankArticleView from './RankArticleView';
import RankResponseView from './RankResponseView';
import { RANKING__DEFAULT, RANKING__QUESTION, RANKING__ANSWER, RANKING__POST, RANKING__REPLY, RANKING__PEOPLE } from '../../RouteURL';
import { URLUtils, MiscUtils, URL_MAPPING, SELECTED_LANGUAGES } from '../../extras';
import { ApiHttp } from '../../api';
import { BOARD_NAME_QA, BOARD_NAME_COMMUNITY } from '../../config';
import { BlockingComponent, Breadcrumb } from '../common';
import { translate } from "react-i18next";



class RankingView extends React.Component {

    state = {
        isProcessing: false,
        period: 'weekly',
        listObjects: []
    };


    selectSorter = (period) => {
        this.setState({
            period: period,
            listObjects: []
        }, () => this.loadData(this.props.location.pathname));
    };

    initData = (props) => {
        this.setState({
            period: 'weekly',
            listObjects: []
        }, () => this.loadData(props.location.pathname));
    };



    loadData = (pathname) => {
      if(pathname.slice(-1) === '/')
        pathname = pathname.slice(0, -1);
      console.log(pathname);
        const { period } = this.state;
        const callbackSuccess = (data) => {
            this.setState({
                isProcessing: false,
                listObjects: data.data || data.rows
            });
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true, listObjects: []}, () => {
            let url = ((pathname === RANKING__DEFAULT) || (pathname === RANKING__PEOPLE)) ? URL_MAPPING.ranking.people : URL_MAPPING.ranking.articleAndResponse;
            let data = {};
            if ((pathname === RANKING__DEFAULT) || (pathname === RANKING__PEOPLE)) { // people
                data = { period };
            } else {
                let time = 2; // week
                if (period === 'monthly')
                    time = 3;
                else if (period === 'yearly')
                    time = 4;
                let category = 'question';
                if (pathname === RANKING__POST)
                    category = 'post';
                else if (pathname === RANKING__ANSWER)
                    category = 'answer';
                else if (pathname === RANKING__REPLY)
                    category = 'reply';
                data = { time, category };
            }
            data.language = MiscUtils.getFromLocalStorage(SELECTED_LANGUAGES).join(',');
            url = URLUtils.buildAPIURL(url);
            ApiHttp.get(url, callbackSuccess, callbackError, data);
        });
    };


    saveSelectLanguagesCallback = (selectedLanguages) => {
        // da luu selectedLanguages trong popup
        this.initData(this.props);
    };


    componentDidMount() {
        this.initData(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname){
            this.initData(nextProps);
        }
    }



    render() {
        const { isProcessing, listObjects, period } = this.state;
        const { pathname } = this.props.location;
        const { t } = this.props;
        const sortButtons = [
            [t('Week'), 'weekly'],
            [t('Month'), 'monthly'],
            [t('Year'), 'yearly']
        ];

        const currentURL = URLUtils.getCurrentURL(this);
        let boardType = null;
        if ((currentURL.indexOf('question') > 0) || (currentURL.indexOf('answer') > 0))
            boardType = BOARD_NAME_QA;
        else if ((currentURL.indexOf('post') > 0) || (currentURL.indexOf('reply') > 0))
            boardType = BOARD_NAME_COMMUNITY;


        return (
            <div className="col-md-10">
                <div id="rankingView" className="row centerContent">
                    <Breadcrumb />


                    <BlockingComponent isProcessing={isProcessing}>
                        <Switch>
                            {/*<Route exact path='/' component={ requireLogin(HomeView) }/>*/}
                            <Route exact path={RANKING__QUESTION} render={(props) => (<RankArticleView {...this.props}
                                listObjects={listObjects} sortButtons={sortButtons} boardType={boardType} selectSorter={this.selectSorter} sortType={period}
                                saveSelectLanguagesCallback={this.saveSelectLanguagesCallback} />)} />
                            <Route exact path={RANKING__POST} render={(props) => (<RankArticleView {...this.props}
                                listObjects={listObjects} sortButtons={sortButtons} boardType={boardType}  selectSorter={this.selectSorter} sortType={period}
                                saveSelectLanguagesCallback={this.saveSelectLanguagesCallback}/>)} />
                            <Route exact path={RANKING__ANSWER} render={(props) => (<RankResponseView {...this.props}
                                listObjects={listObjects} sortButtons={sortButtons} boardType={boardType} selectSorter={this.selectSorter} sortType={period}
                                saveSelectLanguagesCallback={this.saveSelectLanguagesCallback} />)}/>
                            <Route exact path={RANKING__REPLY} render={(props) => (<RankResponseView {...this.props}
                                listObjects={listObjects} sortButtons={sortButtons} boardType={boardType} selectSorter={this.selectSorter} sortType={period}
                                saveSelectLanguagesCallback={this.saveSelectLanguagesCallback} />)}/>
                            <Route path={RANKING__DEFAULT} render={(props) => (<RankPeopleView {...this.props}
                                listObjects={listObjects} sortButtons={sortButtons} boardType={boardType} selectSorter={this.selectSorter} sortType={period}
                                saveSelectLanguagesCallback={this.saveSelectLanguagesCallback} />)}/>
                        </Switch>
                    </BlockingComponent>


                </div>
            </div>
        );
    }
}

export default translate()(RankingView);