import React from 'react';
import { TableContentArticle, TableContentResponse, TableContentComment,
    TablePager, TableSorterTransparent, BlockingComponent } from '../common';
import { MiscUtils, URLUtils } from '../../extras';
import { ApiArticle } from '../../api';
import { BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_NOTICE, BOARD_NAME_RANK, BOARD_NAME_TAG, CLASS_HIDDEN_IN_MOBILE, CLASS_HIDDEN_IN_PC } from '../../config';
import { translate } from "react-i18next";


class SearchView extends React.Component {

    state = {
        isProcessing: false,
        currentSearchBoard: '',
        article_type: '',
        sortButtons: [],
        page: 1,
        searchTitle: '',
        searchQuery: '',
        searchAuthor: '',
        isSearchAuthor: false,
        result: {}
    };


    getInitArticleTYpe = (board) => {
        let article_type = 'notice';
        if (board === BOARD_NAME_QA)
            article_type = 'question';
        else if (board === BOARD_NAME_COMMUNITY)
            article_type = 'post';
        else if (board === BOARD_NAME_TAG)
            article_type = 'qa';
        return article_type;
    };


    isStopSubmitting = () => {
        const { searchAuthor, searchQuery, isSearchAuthor } = this.state;
        return ((!searchAuthor || !isSearchAuthor) && !searchQuery)
    };


    makeSearch = () => {
        if (this.isStopSubmitting())
            return;
        const { t } = this.props;
        const { currentSearchBoard, searchQuery, searchAuthor, page, article_type, isSearchAuthor } = this.state;
        const searchTitleDict = {
            qa: t('Question'),
            community: t('Community'),
            notice: t('Notice'),
            tag: t('Tag')
        };

        const data = {
            board_type: currentSearchBoard,
            article_type,
            time: 3, // all time
            keyword: searchQuery,
            author: isSearchAuthor ? searchAuthor : '',
            page: page
        };
        const callbackSuccess = (data) => {
            let sortButtons = [];
            const { statistic_info } = data;
            if (currentSearchBoard === BOARD_NAME_QA){
                sortButtons = [
                    [t('Question'), 'question'],
                    [t('Answer'), 'answer'],
                    [t('Comment to Q'), 'comment_to_q'],
                    [t('Comment to A'), 'comment_to_a']
                ];
                if (statistic_info.question_total > 0)
                    sortButtons[0][0] = t('Question') + ' ('+statistic_info.question_total+')';
                if (statistic_info.answer_total > 0)
                    sortButtons[1][0] = t('Answer') + ' ('+statistic_info.answer_total+')';
                if (statistic_info.comment_to_q_total > 0)
                    sortButtons[2][0] = t('Comment to Q') + ' ('+statistic_info.comment_to_q_total+')';
                if (statistic_info.comment_to_a_total > 0)
                    sortButtons[3][0] = t('Comment to A') + ' ('+statistic_info.comment_to_a_total+')';
            }
            if (currentSearchBoard === BOARD_NAME_COMMUNITY){
                sortButtons = [
                    [t('Post'), 'post'],
                    [t('Reply'), 'reply'],
                    [t('Comment to R'), 'comment_to_r']
                ];
                if (statistic_info.post_total > 0)
                    sortButtons[0][0] = t('Post') +' ('+statistic_info.post_total+')';
                if (statistic_info.reply_total > 0)
                    sortButtons[1][0] = t('Reply') +' ('+statistic_info.reply_total+')';
                if (statistic_info.comment_total > 0)
                    sortButtons[2][0] = t('Comment to R') +' ('+statistic_info.comment_total+')';
            }
            if (currentSearchBoard === BOARD_NAME_NOTICE)
                sortButtons = [];
            if (currentSearchBoard === BOARD_NAME_TAG){
                sortButtons = [
                    ['QA', BOARD_NAME_QA],
                    [t('Community'), BOARD_NAME_COMMUNITY],
                    [t('Notice'), BOARD_NAME_NOTICE]
                ];
                if (statistic_info.qa_total > 0)
                    sortButtons[0][0] = 'QA ('+statistic_info.qa_total+')';
                if (statistic_info.community_total > 0)
                    sortButtons[1][0] = t('Community') +' ('+statistic_info.community_total+')';
                if (statistic_info.notice_total > 0)
                    sortButtons[2][0] = t('Notice') +' ('+statistic_info.notice_total+')';
            }

            this.setState({
                isProcessing: false,
                result: data,
                sortButtons: sortButtons,
                searchTitle: MiscUtils.safeget(searchTitleDict, currentSearchBoard)
            });
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true}, () => ApiArticle.search(callbackSuccess, callbackError, data));
    };


    componentWillMount() {
        const params = URLUtils.parseParamsInURL(this.props.location.search);
        const { board, query } = params;

        this.setState({
            currentSearchBoard: board,
            searchQuery: query,
            article_type: this.getInitArticleTYpe(board),
            page: 1,
            result: []
        }, () => this.makeSearch());
    }


    changeSearchBoard = (event) => {
        const board = event.target.value;
        this.setState({
            currentSearchBoard: board,
            article_type: this.getInitArticleTYpe(board),
            page: 1,
            result: []
        }, () => this.makeSearch());
    };


    changeSearchQuery = (e) => {
        this.setState({searchQuery: e.target.value});
    };


    changeSearchAuthor = (e) => {
        this.setState({searchAuthor: e.target.value});
    };


    changeIsSearchAuthor = () => {
        this.setState({isSearchAuthor: !this.state.isSearchAuthor});
    };


    selectSorter = (sorter) => {
        this.setState({
            article_type: sorter,
            result: [],
            page: 1
        }, () => this.makeSearch());
    };


    searchButtonClick = () => {
        if (this.isStopSubmitting())
            return;
        const { currentSearchBoard } = this.state;
        this.setState({
            page: 1,
            article_type: this.getInitArticleTYpe(currentSearchBoard),
            result: []
        }, () => this.makeSearch());
    };


    handleEnterKeyPress = (e) => {
        MiscUtils.handleEnterKeyPress(e, () => this.makeSearch());
    };


    onPagerChange = (newPage) => {
        this.setState({page: newPage, result: {}}, () => this.makeSearch());
    };


    render() {
        const { sortButtons, searchTitle, currentSearchBoard, searchQuery, article_type, result, isProcessing, searchAuthor, isSearchAuthor } = this.state;
        const { t } = this.props;
        const isMobileView = MiscUtils.isMobileView();
        const selectBoardHTML = (
            <select onChange={this.changeSearchBoard} value={currentSearchBoard}>
                <option value={BOARD_NAME_QA}> QA </option>
                <option value={BOARD_NAME_COMMUNITY}> {t('Community')} </option>
                <option value={BOARD_NAME_NOTICE}> {t('Notice')} </option>
                <option value="tag"> {t('Tag')} </option>
            </select>
        );

        const searchQueryHTML = (
            <input type="text" className="inputText" value={searchQuery} onChange={this.changeSearchQuery} onKeyPress={this.handleEnterKeyPress}/>
        );

        const authorHTML = (
            <label>
                <input type="checkbox" defaultChecked={isSearchAuthor} onChange={this.changeIsSearchAuthor}/> {t('author')}
            </label>
        );

        const searchSubmitBTNHTML = (
            <button type="button" className="btn positiveBtn" onClick={this.searchButtonClick}>{t('Search')}</button>
        );


        return (
            <div className="col-md-12" id="searchView">

                {
                    !isMobileView &&
                    <div className={"row selectLanguageComponentWrapper"+CLASS_HIDDEN_IN_MOBILE}>
                        <div className="col-md-offset-1 col-md-10 searchHeaderBar">
                            <div className="row">
                                <div className="col-md-2">
                                    {selectBoardHTML}
                                </div>
                                <div className="col-md-4">
                                    {searchQueryHTML}
                                </div>
                                <div className="col-md-6">
                                    {authorHTML}
                                    <input type="text" className="inputTextAuthor" value={searchAuthor} onChange={this.changeSearchAuthor} onKeyPress={this.handleEnterKeyPress} />
                                    {searchSubmitBTNHTML}
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    isMobileView &&
                    <div className={"row selectLanguageComponentWrapper"+CLASS_HIDDEN_IN_PC}>
                        <div className="searchHeaderBar">
                            <div className="row">
                                <div className="col-xs-3 col-sm-3">
                                    {selectBoardHTML}
                                </div>
                                <div className="col-xs-9 col-sm-9">
                                    {searchQueryHTML}
                                </div>
                            </div>

                            <div className="row marginVerticalMedium">
                                <div className="col-xs-3 col-sm-3">
                                    {authorHTML}
                                </div>
                                <div className="col-xs-9 col-sm-9">
                                    <input type="text" className="inputText" />
                                </div>
                            </div>
                            <div className="alignCenter">
                                {searchSubmitBTNHTML}
                            </div>

                        </div>
                    </div>
                }


                <BlockingComponent isProcessing={isProcessing}>
                    {
                        !isProcessing &&
                        <div className="row centerContent">
                            <h3 className="contentHeader">{ searchTitle }</h3>
                            { sortButtons.length > 0 &&
                                <TableSorterTransparent sortButtons={sortButtons} sortType={article_type} selectSorter={this.selectSorter}/>
                            }
                            { currentSearchBoard !== BOARD_NAME_TAG &&
                                <div>
                                    {
                                        (['question', 'post', 'notice'].indexOf(article_type) >= 0) &&
                                        <TableContentArticle boardType={ currentSearchBoard } data={result} isProcessing={isProcessing} onPagerChange={this.onPagerChange}/>
                                    }
                                    {
                                        (['answer', 'reply'].indexOf(article_type) >= 0) &&
                                        <TableContentResponse boardType={ currentSearchBoard } data={result} isProcessing={isProcessing} onPagerChange={this.onPagerChange}/>
                                    }
                                    {
                                        (['comment_to_q', 'comment_to_a', 'comment_to_r'].indexOf(article_type) >= 0) &&
                                        <TableContentComment boardType={ currentSearchBoard } data={result} isProcessing={isProcessing} onPagerChange={this.onPagerChange}/>
                                    }
                                </div>

                            }
                            { currentSearchBoard === BOARD_NAME_TAG &&
                                <div>
                                    {
                                        (searchQuery === '') && (searchAuthor !== '') && (isSearchAuthor) &&
                                        t('TAG SEARCH AUTHOR')
                                    }
                                    {
                                        (searchQuery !== '') &&
                                        <TableContentArticle boardType={ article_type } data={result} isProcessing={isProcessing} onPagerChange={this.onPagerChange}/>
                                    }
                                </div>
                            }
                            <TablePager />
                        </div>
                    }

                </BlockingComponent>

            </div>
        );
    }
}

export default translate()(SearchView);