import React from 'react';
// import { Glyphicon } from 'react-bootstrap';
// import LanguageSelectionModal from './LanguageSelectionModal';
// import { MAX_LANGUAGE_FILTERING, getLanguageTextFromCode } from "../../../config/common";
import {BOARD_NAME_QA, BOARD_NAME_COMMUNITY, MESSAGE, ARTICLE_TYPE} from '../../../config';
import { MiscUtils } from '../../../extras';
import { translate } from "react-i18next";
import classnames from 'classnames';
import FontAwesome from "react-fontawesome";
// import {Glyphicon} from "react-bootstrap";
import {DropDownMenu} from "./DropDownMenu";
import { Tooltip } from 'react-tippy';
import image01 from '../../../static/images/reaction/1.not_clear_to_answer.png';
import image02 from '../../../static/images/reaction/2.curious_too.png';
import image03 from '../../../static/images/reaction/3.not_appropriate_here.png';
import image04 from '../../../static/images/reaction/4.so_difficult_to_answer.png';
import image05 from '../../../static/images/reaction/5.so_useful.png';
import image06 from '../../../static/images/reaction/6.cool.png';
import image07 from '../../../static/images/reaction/7.so_funny.png';
import image08 from '../../../static/images/reaction/8.very_sad.png';
import image09 from '../../../static/images/reaction/9.not_interesting.png';
import image10 from '../../../static/images/reaction/10.angry.png';
import {ApiArticle} from "../../../api";


const REACTION_MAPPING = {
    1: image01,
    2: image02,
    3: image03,
    4: image04,
    5: image05,
    6: image06,
    7: image07,
    8: image08,
    9: image09,
    10: image10
};
const DELAY_TO_SHOW = 100; // seconds
let timer = null;
// let BUTTONS = [];

class ReactionButton extends React.Component {

    state = {
        BUTTONS: [],
        isShown: undefined,
        currentReactedCode: this.props.article ? this.props.article.react_info.current_code : this.props.response.react_info.current_code,
        count: this.props.article ? this.props.article.react_info.stats.count : this.props.response.react_info.stats.count,
        stats: this.props.article ? this.props.article.react_info.stats.stats : this.props.response.react_info.stats.stats
    };


    componentDidMount = () => {
        const { boardType, article } = this.props;
        let BUTTONS = [5, 6, 7, 8, 9, 10];
        if (article && article.article_id && (boardType === BOARD_NAME_QA))
            BUTTONS = [1, 2, 3, 4];
        this.setState({BUTTONS});
    };


    hideActionList = () => {
        this.setState({isShown: false});

    };


    onMouseEnterButton = () => {
        timer = setTimeout(() => {
            this.setState({isShown: true});
        }, DELAY_TO_SHOW);

    };


    clickReactionButton = () => {
        clearTimeout(timer);  // tranh truong hop click roi ma van setState la true trong onMouseEnterButton
        const { BUTTONS, currentReactedCode } = this.state;
        this.makeReact(currentReactedCode ? currentReactedCode : BUTTONS[0]);
    };


    makeReact = (reactCode) => {
        this.hideActionList();
        const { currentReactedCode, count, stats } = this.state;
        const { boardType, article, response } = this.props;
        let statsTemp = MiscUtils.cloneObject(stats);
        let newCount = count;

        if (!(reactCode in statsTemp))
            statsTemp[reactCode] = 0;

        if (currentReactedCode && reactCode){
            statsTemp[currentReactedCode] -= 1;
            if (currentReactedCode === reactCode)
                newCount -= 1;
            else {
                statsTemp[reactCode] += 1;
            }
        } else if (!currentReactedCode && reactCode) {
            statsTemp[reactCode] += 1;
            newCount += 1;
        }
        const newCode = (currentReactedCode === reactCode) ? 0 : reactCode;

        // make it instant
        this.setState({
            currentReactedCode: newCode,
            count: newCount,
            stats: statsTemp
        });


        let article_type = null;
        let article_id = null;
        if (article && article.article_id){
            article_type = ARTICLE_TYPE.POST;
            article_id = article.article_id;
            if (boardType === BOARD_NAME_QA)
                article_type = ARTICLE_TYPE.QUESTION;
        }
        if (response && response.id) {
            article_type = ARTICLE_TYPE.REPLY;
            article_id = response.id;
            if (boardType === BOARD_NAME_QA)
                article_type = ARTICLE_TYPE.ANSWER;
        }
        const data = {
            article_type,
            article_id,
            code: newCode
        };
        const callbackSuccess = (data) => {
            // MiscUtils.showNotification(MESSAGE.SAVE_SUCCESS);
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        ApiArticle.reactArticle(callbackSuccess, callbackError, data);

    };


    render (){
        const { BUTTONS, isShown, currentReactedCode, count, stats } = this.state;
        const { t, boardType, article, response } = this.props;
        // const isMobileView = MiscUtils.isMobileView();

        // let buttons = [];
        // if (article && article.article_id) {
        //     if (boardType === BOARD_NAME_QA)
        //         buttons = [image01, image02, image03, image04];
        //     else if (boardType === BOARD_NAME_COMMUNITY)
        //         buttons = [image05, image06, image07, image08, image09, image10];
        // } else if (response && response.id){
        //     if (boardType === BOARD_NAME_QA)
        //         buttons = [image05, image06, image07, image08, image09, image10];
        //     else if (boardType === BOARD_NAME_COMMUNITY)
        //         buttons = [image05, image06, image07, image08, image09, image10];
        // }



        let preReactedCodes = [];
        for (let code in stats)
            if (!(code in preReactedCodes) && (stats[code] > 0))
                preReactedCodes.push(parseInt(code));

        const htmlContent = (
            <div className="tooltipActionImageButton displayFlex">
                {
                    BUTTONS.map((code) => (
                        <span className={classnames("photoReaction", {"selected": code === currentReactedCode})} onClick={() => this.makeReact(code)} key={MiscUtils.generateId()}>
                            <img src={REACTION_MAPPING[code]} className="img-responsive"/>
                            <span>
                                {stats[code] ? stats[code] : 0}
                            </span>
                        </span>
                    ))
                }
            </div>
        );
        return (
            <span className="actionButton reactButton" onClick={this.clickReactionButton} onMouseEnter={this.onMouseEnterButton}>

                <Tooltip
                    position="bottom"
                    // trigger="click"
                    trigger="mouseenter"
                    arrow={true}
                    size="big"
                    interactive={true}
                    html={htmlContent}
                    // className={customClassName}
                    delay={DELAY_TO_SHOW}
                    // hideDelay={10000}
                    hideOnClick={true}
                    // interactiveBorder={30}
                    // open={this.props.isOpen}
                    // open={true}
                    open={isShown}
                    onRequestClose={() => {
                        this.hideActionList();
                    }}
                    onShown={() => {
                        this.setState({isShown: true});
                    }}
                    onHidden={() => {
                        this.setState({isShown: undefined});
                    }}
                >


                    {
                        (currentReactedCode === 0) &&
                        <span>
                            <FontAwesome name="smile-o"/>
                            &nbsp;
                            <span>{t('Feeling')}</span>
                            &nbsp;
                            <span className="numStatsCount">{count}</span>
                            &nbsp;
                        </span>

                    }


                    {
                        preReactedCodes.map((code) => (
                            <img key={MiscUtils.generateId()} src={REACTION_MAPPING[code]} className="reactPhoto"/>
                        ))
                    }


                    {
                        (currentReactedCode !== 0) &&
                        <span>
                            {/*<img src={REACTION_MAPPING[currentReactedCode]} className="reactPhoto"/>*/}
                            &nbsp;
                            <span className="reacted">
                                {
                                    (count > 1) && <span>You and {count-1} others</span>
                                }
                                {
                                    (count == 1) && <span>You</span>
                                }

                            </span>

                        </span>

                    }





                </Tooltip>

            </span>
        )
    }
}

export default translate()(ReactionButton);