import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { MiscUtils } from '../../../extras';

export default class TableSorterTransparentWithLink extends React.Component {

    render() {
        const { sortButtons } = this.props;
        return (
            <div id="tableSorterTransparentWithLink">
                {
                    sortButtons.map((button, idx) => (
                            <span key={MiscUtils.generateId()}>
                                <label
                                    className={classnames({"activeSortTransparentButton": this.props.selectedTab === button.tab})}
                                >
                                    <Link to={button.url}>
                                        {button.text}
                                    </Link>
                                </label>
                            </span>
                    ))
                }
            </div>
        );
    }
}
