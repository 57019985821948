import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LeftBarBoardSubCategoryButtons, LeftBarUserInfo, LeftBarProfileInfo } from './element';
import LeftBarOptional from './LeftBarOptional';
import { CLASS_HIDDEN_IN_MOBILE } from '../../config';


export default class LeftBar extends React.Component {

    render() {
        return (
            <div id="leftBar" className={"col-md-2"+CLASS_HIDDEN_IN_MOBILE}>
                <Switch>
                    <Route path="/(qa|community|notice|ranking)" component={ LeftBarBoardSubCategoryButtons }/>
                    <Route path="/(dashboard|profile|setting)" component={ LeftBarUserInfo }/>
                </Switch>
                <LeftBarOptional />
            </div>
        )
    }
}