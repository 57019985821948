import React from 'react';
import { TableSorterTransparent, Breadcrumb } from '../common';
import UserGuideEnglishView from './UserGuideEnglishView';
import UserGuideKoreanView from './UserGuideKoreanView';
import {MiscUtils, URLUtils} from '../../extras';

export default class UserGuideView extends React.Component {

    state = {
        currentTab: 'kr'
    };

    tabClick = (currentTab) => {
        this.setState({currentTab});
        this.props.history.push('/notice/userguide/' + currentTab);
    };

    componentWillMount() {
        const arr = URLUtils.getCurrentURL(this).split('/');
        const tab = arr[arr.length-1];
        if (tab === 'en')
            this.tabClick(tab);
    }


    render() {
        const sortButtons = [
            ['Korean', 'kr'],
            ['English', 'en']
        ];
        const iFrameHeight = MiscUtils.isMobileView() ? 320 : 700;

        return (
            <div>
                {/*<h3 className="contentHeader">User guide</h3>*/}
                <Breadcrumb />
                <TableSorterTransparent sortButtons={sortButtons} sortType={this.state.currentTab} selectSorter={this.tabClick}/>
                { this.state.currentTab === 'kr' && <UserGuideKoreanView iFrameHeight={iFrameHeight}/>
                }

                { this.state.currentTab === 'en' && <UserGuideEnglishView iFrameHeight={iFrameHeight}/>
                }

            </div>
        )
    }
}