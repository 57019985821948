import React from 'react';
import { MiscUtils } from '../../../extras';
import TableContentResponseElement from './TableContentResponseElement';
import TableContentResponseElementCompact from './TableContentResponseElementCompact';
import TablePager from './TablePager';
import { BOARD_NAME_QA, BOARD_NAME_COMMUNITY, MESSAGE } from '../../../config';

export default class TableContentResponse extends React.Component {

    render() {
        const { boardType, data, isProcessing, onPagerChange, isCompact } = this.props;
        const responses = data.rows;
        const { currpage, totalpages } = data;
        return (
            <div id="tableContentResponse">
                {   !isProcessing && responses && responses.length > 0 &&
                    <div>
                        {
                            responses.map((response, idx) => {
                                if (isCompact)
                                    return <TableContentResponseElementCompact response={response} key={MiscUtils.generateId()}/>;
                                else
                                    return <TableContentResponseElement response={response} key={MiscUtils.generateId()}/>;
                            })
                        }
                        <TablePager page={parseInt(currpage)} total={parseInt(totalpages)} onPagerChange={onPagerChange}/>
                    </div>
                }

                { !isProcessing && responses && responses.length === 0 &&
                    <div>
                        { (boardType === BOARD_NAME_QA) && <label>{MESSAGE.NO_ANSWER}</label> }
                        { (boardType === BOARD_NAME_COMMUNITY) && <label>{MESSAGE.NO_REPLY}</label> }
                    </div>
                }

            </div>

        );
    }
}