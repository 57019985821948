import React from 'react';
import { translate } from "react-i18next";

class HowToAsk extends React.Component {
    render (){
        const { t } = this.props;
        return (
            <div className="selectLanguageComponentWrapper">
                <h4>{t('How to ask a good question')}</h4>
                <p>{t('To get the most helpful responses to your question, try including the following:')}</p>
                <p>{t('1. Include your question in the title.')}</p>
                <p>{t('2. Be clear and concise.')}</p>

            </div>
        );
    }
}

export default translate()(HowToAsk);