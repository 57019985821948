import React from 'react';
import classnames from 'classnames';
import FontAwesome from 'react-fontawesome';
import fb from '../../../static/images/icon/facebook.png';
import { SEARCH_TAG, PROFILE } from '../../../RouteURL';
import { BOARD_NAME_QA, BOARD_NAME_COMMUNITY, MESSAGE } from '../../../config';
import BlockingComponent from './BlockingComponent';
import UserAvatarInfo from './UserAvatarInfo';
import ScrollContent from './ScrollContent';
import { ApiUser } from '../../../api';
import { MiscUtils } from "../../../extras";
import { translate } from "react-i18next";


class NamecardInResponse extends React.Component {

    state = {
        isProcessing: false,
        content: null,
        isShowNamecard: false
    };

    componentDidMount () {
        const { user } = this.props;
        const data = {
            user_id: user.user_id
        };
        const callbackSuccess = (res) => {
            this.setState({
                isProcessing: false,
                content: res
            });
        };
        const callbackError = (error) => {
            this.setState({isProcessing: false});
        };
        this.setState({isProcessing: true}, () => ApiUser.getNameCard(callbackSuccess, callbackError, data));
    };


    toggleNamecard = () => {
        this.setState({isShowNamecard: !this.state.isShowNamecard});
    };


    render (){
        const isMobileView = MiscUtils.isMobileView();
        const { user, boardType, t } = this.props;
        const { isProcessing, content, isShowNamecard } = this.state;
        let recentObjects = [];
        if (content)
            if (boardType === BOARD_NAME_QA)
                recentObjects = content.recent_answers;
            else if (boardType === BOARD_NAME_COMMUNITY)
                recentObjects = content.recent_posts;

        return (
            <BlockingComponent isProcessing={ isProcessing }>
                {
                    content &&
                    <div id="namecardInResponse" className="boxWrapper">
                        {/*  --- header --- */}
                        <div className="namecardHeader">
                            <UserAvatarInfo user={user} />
                            {
                                content.facebook &&
                                <FontAwesome name="facebook-square" size="lg" className="snsLogo" />
                            }

                            <FontAwesome name={isShowNamecard ? "chevron-up" : "chevron-down"} size="lg" className={classnames("toggleIcon", {"greenColor": isShowNamecard})} onClick={this.toggleNamecard} />

                        </div>


                        {/*  --- content --- */}
                        {
                            isShowNamecard &&
                            <div>
                                <div className="row userActivityInfo">
                                    <div className="col-md-6 leftColumn">
                                        {
                                            content.mostly_used_tags && content.mostly_used_tags.length > 0 &&
                                            <div>
                                                <strong>{t('Mostly used tags')}:</strong>
                                                {
                                                    content.mostly_used_tags.map((tag) =>
                                                        <a href={SEARCH_TAG + tag} key={MiscUtils.generateId()}
                                                           target="_blank"> {tag} </a>
                                                    )
                                                }

                                            </div>
                                        }

                                        <div>
                                            <strong>{t('Languages')}: </strong>
                                            <span>{content.language}</span>
                                            {/*<span className="outlineText">English</span>*/}
                                            {/*<span className="outlineText">Tiếng Việt</span>*/}
                                        </div>

                                        {
                                            content.location &&
                                            <div>
                                                <strong>{t('Location')}: </strong>
                                                <span>{content.location}</span>
                                            </div>
                                        }
                                    </div>


                                    <div className={classnames("col-md-6 rightColumn", {"noLeftBorder": isMobileView})}>
                                        {
                                            boardType === BOARD_NAME_QA &&
                                            <strong>{t('RECENT ANSWERS')}</strong>
                                        }
                                        {
                                            boardType === BOARD_NAME_COMMUNITY &&
                                            <strong>{t('RECENT POSTS')}</strong>
                                        }
                                        {
                                            (recentObjects.length > 0) &&
                                            <ScrollContent customClassName='myScrollContent_namecard'>
                                                <ul>
                                                    {
                                                        recentObjects.map((obj) =>
                                                            <li key={MiscUtils.generateId()}>
                                                                <a href={obj.url} target="_blank">{obj.content}</a>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </ScrollContent>
                                        }
                                        {
                                            (recentObjects.length === 0) &&
                                            <div>
                                                {(boardType === BOARD_NAME_QA) && <span>{MESSAGE.NO_ANSWER}</span>}
                                                {(boardType === BOARD_NAME_COMMUNITY) && <span>{MESSAGE.NO_POST}</span>}
                                            </div>
                                        }
                                    </div>

                                </div>


                                {/*  --- footer --- */}
                                <div className="namecardFooter">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td>{t('Question')}</td>
                                                    <td><strong>{content.question}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{t('Answer')}</td>
                                                    <td>
                                                        <strong>{content.answer}</strong> ({t('1st answer')}: <label>{content.first_answer}</label> / {t('accepted')}: <label>{content.accepted_answer}</label>)
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{t('Post')}</td>
                                                    <td><strong>{content.post}</strong></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="namecardFooterAction">
                                        {/*<a type="button" className="btn outlineBtn">Guestbook</a>*/}
                                        <a href={PROFILE + '?user_id=' + content.user_id} target="_blank" type="button"
                                           className="btn outlineBtn">{t('More')}</a>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                }
            </BlockingComponent>
        )
    }
}

export default translate()(NamecardInResponse);