import React from 'react';

export default class AboutUsIntroView extends React.Component {
    render (){
        return (
            <div id="aboutUsIntroView">
                <p>JOINUS WORLD is a website administered by JOINUS Foundation (or JOINUS KOREA), which is a Nonprofit NGO network.</p>
                <p>
                    JOINUS Foundation is a Non-Governmental organization in Korea that focuses on exchange of multi-culture and languages. The main office is situated in Seoul, South Korea. The people who contributes their talents in this organization are called the JOKOER.
                </p>
                <p style={{textAlign: "center", padding: "20px 0px"}}>
                    <img src="http://cfile29.uf.tistory.com/image/236CA44751E5D96A249EC6" width="518" height="724" alt="mo"/>
                </p>

                <div className="section">
                    <h4>Mission</h4>
                    <p>JOINUS KOREA takes the lead of private diplomacy based on knowledge on foundation of 21st century ‘Benefit the world through knowledge exchange.</p>
                    <p>
                        <strong>1.</strong> It cultivates global multilingual knowledge exchange diplomat (Jokoer), and operate online Multilanguage & multi-cultural community for knowledge exchange with foreigners.
                    </p>
                    <p>
                        <strong>2.</strong> By promoting multilingual ‘language talents donation’ based on 4 spirits -‘participation’, “sharing’, ‘openness’, autonomy’, it implements communication free from language barrier, and by respecting the diversity of country, locales, culture, contributes to building a online community culture in which everyone can emphasize and communicate with each other.
                    </p>
                    <p>
                        <strong>3.</strong> By building a nonprofit global communication environment independent from specific capitals and powers, it endeavors to form undistorted public opinions and the online space to gather a consistent policy. And along with building a sound ecosystem with related industry, it moves ahead with rehabilitation of humanity and value of humanism which is waning on-line.
                    </p>
                </div>

                <div className="section">
                    <h4>History of development</h4>
                    <p>
                        <strong>2010. 12</strong> Established by founding members.
                    </p>
                    <p>
                        <strong>2011. 03</strong> For foreigner visiting Korea, this organization started up an online network to establish an online-based network for multicultural exchange.
                    </p>

                    <p>
                        <strong>2011. 05</strong> This organization was selected as a company focusing on public issues under the support of Korea Social Enterprise Agency.
                    </p>
                    <p>
                        <strong>2011. 12</strong> Recruiting JOKOER for the project of interviewing ambassador.
                    </p>
                    <p>
                        <strong>2011. 12</strong> MOU with SMBA(Small-medium business association) forum.
                    </p>
                    <p>
                        <strong>2012. 05</strong> Registration on the non-profit organization by local Government.
                    </p>
                    <p>
                        <strong>2013. 04</strong> Registration on Sharing NGO by Seoul metropolitan city.
                    </p>
                </div>

                <div className="section">
                    <h4>Principle activities</h4>
                    <p>
                        <strong>1.</strong> Setting up an online based community for multi-cultural exchange with foreigners.
                    </p>
                    <p>
                        <strong>2.</strong> Establishment of network for international exchange of information.
                    </p>
                    <p>
                        <strong>3.</strong> Training and instruction of JOKOER(so-called global cultural diplomats).
                    </p>
                    <p>
                        <strong>4.</strong> Online exchange of international education.
                    </p>
                    <p>
                        <strong>5.</strong> Development of search engine with various languages(R&D).
                    </p>
                    <p>
                        <strong>6.</strong> Establishment of search engine for publicity work of Korean culture and tourism.
                    </p>
                    <p>
                        <strong>7.</strong> Establishment of database concerning second-language information.
                    </p>
                    <p>
                        <strong>8.</strong> Supporting the education of language in developing countries.
                    </p>
                </div>

                <div className="section">
                    <h4>Activities of JOKOER</h4>
                    <p>JOKOER (as the member of JOINUS KOREA) is to implement the following :</p>
                    <p>
                        <strong>1.</strong> Regarding the question from visiting foreigners and multicultural residents in a variety of language region, JOKOERs contribute their talents mainly by responding to the questions within their knowledge scope or by contacting expert group network on a various field such as culture, tourism, medical, industry, labor, law, accounting.
                    </p>
                    <p>
                        <strong>2.</strong> For enhancing the access into website for foreigners, JOKOERs do the work of localizing the language of website UI menus, instructions and reporting errors.
                    </p>
                    <p>
                        <strong>3.</strong> Along with a language talent donation activity, they help people from domestic/ overseas communicate on a variety of issues, and contribute to settling down the desirable on-line community culture.
                    </p>
                </div>

            </div>
        )
    }
}