import React from 'react';
import { Redirect } from 'react-router-dom';
import classnames from 'classnames';
import { PopupboxManager } from 'react-popupbox';
import { BOARD_NAME_QA, BOARD_NAME_COMMUNITY, ERROR_MESSAGE, RESPONSE_TYPE_IN_BACKEND,
    DELAY_BEFORE_RELOAD_PAGE_AFTER_NOTIFICATION, ERROR_CODE_FROM_BACKEND, MESSAGE } from '../../config';
import { Editor, SourceInput, BlockingComponent, PreviewContent } from '../common';
import { MiscUtils, URLUtils } from '../../extras';
import { ApiArticle } from '../../api';
import { SIGN_IN } from '../../RouteURL';
import { translate } from "react-i18next";
// import {} from "../../config/common";


class ResponseWrite extends React.Component {

    state = {
        redirectToLogin: false,
        content: this.props.editedResponse ? this.props.editedResponse.content : '',
        isProcessing: false,
        selectedSources: (this.props.editedResponse && this.props.editedResponse.sources) ? this.props.editedResponse.sources.map((source) => ({
            label: source.source_url ? source.source_url : source.source_text,
            value: source.source_url ? source.source_url : source.source_text
        })) : []
    };




    updateContent = (newContent) => {
        this.setState({content: newContent});
    };


    previewResponseContent = () => {
        const content = (
            <PreviewContent htmlContent={this.state.content}/>
        );
        PopupboxManager.open({
            content,
            config: {
                className: 'col-md-5 previewContentInPopup',
                fadeIn: true,
                fadeInSpeed: 500
            }
        });
    };


    inputSource = (sources) => {
        this.setState({selectedSources: sources});
    };


    resetResponse = () => {
        const { editedResponse, cancelEditResponse, cancelWriteResponseInMobile } = this.props;
        if (cancelWriteResponseInMobile) // popup in mobile
            cancelWriteResponseInMobile();
        else if (editedResponse && cancelEditResponse)
            cancelEditResponse();
        else
            this.setState({content: ''});
    };


    saveResponse = () => {
        const { article, boardType, t, editedResponse } = this.props;
        const { content, selectedSources } = this.state;
        const sources = selectedSources.map((sourceObj) => sourceObj.value);
        if ((boardType === BOARD_NAME_QA) && (sources.length === 0)){
            MiscUtils.showErrorMessage(t('Please input source'));
            return;
        }

        const data = {
            parent_id: article.article_id,
            response_content: content,
            response_type: RESPONSE_TYPE_IN_BACKEND[boardType.toUpperCase()],
            str_source: sources.join(','),
            response_id: editedResponse ? editedResponse.id : null
        };


        const callbackSuccess = (res) => {
            MiscUtils.showNotificationAndReload(MESSAGE.SAVE_SUCCESS);
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true}, () => ApiArticle.saveResponse(callbackSuccess, callbackError, data));
    };


    componentDidMount() {
        // const { highlightedResponseWrite } = this.props;
        // if (highlightedResponseWrite)
        //     MiscUtils.scrollToObject(highlightedResponseWrite);
    }


    render () {
        const { boardType, article, t, editedResponse, cancelWriteResponseInMobile } = this.props;
        const { content, isProcessing, redirectToLogin, selectedSources } = this.state;

        if (redirectToLogin)
            return <Redirect to={SIGN_IN + '?next=' + URLUtils.getCurrentPathname()}/>;

        let placeholder = t("Answer {{author}}'s question here", { author: article.user.username });
        if (article.article_type == BOARD_NAME_COMMUNITY)
            placeholder = t("Reply {{author}}'s post here", { author: article.user.username });

        return (
            <BlockingComponent isProcessing={isProcessing}>
                <div id="response_write">
                    {
                        cancelWriteResponseInMobile &&
                        <div className="alignLeft">
                            <span className="greenColor">
                                {(boardType === BOARD_NAME_QA) && t('Answer')}
                                {(boardType === BOARD_NAME_COMMUNITY) && t('Reply')}
                                :&nbsp;
                            </span>
                            {article.article_title_with_new_line}
                        </div>
                    }
                    <Editor defaultContent={content} updateContent={this.updateContent} placeholder={placeholder}/>

                    {
                        (boardType === BOARD_NAME_QA) &&
                        <SourceInput selectedSources={selectedSources} onChange={this.inputSource}/>
                    }

                    <div className="actionBtn">
                        <button type="button" className="btn positiveBtn" onClick={this.previewResponseContent}>{t('Preview')}</button>
                        <button type="button" className="btn negativeBtn" onClick={this.resetResponse}>{t('Cancel')}</button>
                        <button type="button" className={classnames("btn positiveBtn", {'disabled': isProcessing})} onClick={this.saveResponse}>
                            {!editedResponse && (boardType === BOARD_NAME_QA) && t('Answer')}
                            {!editedResponse && (boardType === BOARD_NAME_COMMUNITY) && t('Reply')}
                            {editedResponse &&  t('Save')}
                        </button>
                    </div>
                </div>
            </BlockingComponent>
        )
    }
}

export default translate()(ResponseWrite);