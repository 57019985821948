import React from 'react';
import ReactTooltip from 'react-tooltip';
// import facebook from '../../../static/images/icon/facebook.png';
// import google from '../../../static/images/icon/googleplus.png';
// import twitter from '../../../static/images/icon/twitter.png';
import FontAwesome from 'react-fontawesome';
import { MiscUtils, URLUtils } from '../../../extras';
import { ApiArticle } from '../../../api';


export default class SNSSharingBtn extends React.Component {


    shareSNS = (sns_type) => {
        const { url, author, boardType, articleTitle, articleId, onShareCallback } = this.props;

        const isShareArticle = url.indexOf('?id=') < 0;
        if (isShareArticle) {
            // push to server
            const data = {
                article_id: articleId,
                article_type: boardType
            };
            const callbackSuccess = (data) => {
                if (onShareCallback)
                    onShareCallback();
            };
            const callbackError = (error) => {
                MiscUtils.commonCallbackError(this, error);
            };
            ApiArticle.shareArticle(callbackSuccess, callbackError, data);
        }


        let sharedURL = url;
        let width = 570;
        let height = 680;


        if (sns_type === 'facebook'){
            //url = 'http://www.joinusworld.org/joinus/qa/view/910';
            //http://www.joshuawinn.com/facebooks-sharer-php-longer-deprecated-2014/
            sharedURL = 'https://www.facebook.com/sharer/sharer.php?u=' + url;

        }  //end FB

        else if (sns_type === 'google'){
            //http://stackoverflow.com/questions/6536213/are-there-tags-to-specify-the-google-1-story-format-in-google-like-og-meta-for
            //url = 'http://www.joinusworld.org/joinus/community/view/1078/';
            sharedURL = 'https://plus.google.com/share?url=' + url;
        } //end google

        else if (sns_type === 'twitter'){
            //http://stackoverflow.com/questions/6208363/sharing-a-url-with-a-query-string-on-twitter
            let text = '';
            if (!isShareArticle) { //response
                if (boardType === 'qa')
                    text = author+"'s answer: ";
                else
                    text = author+"'s reply: ";
            } else {
                if (boardType === 'qa')
                    text = 'You are invited to answer this question "' + URLUtils.format_text_for_sns_forwarding(articleTitle, 40) + '": ';
                else
                    text = 'You are invited to view this post: "' + URLUtils.format_text_for_sns_forwarding(articleTitle, 40) + '": ';
            }
            //http://www.twitter.com/share
            sharedURL = 'http://twitter.com/intent/tweet?text='+text+'&url='  +url;

            width = 700;
            height = 260;
        }

        const x = window.innerWidth/2 - width/2;
        const y = window.innerHeight/2 - height/2;
        const params = 'menubar=yes,toolbar=yes,resizable=yes,scrollbars=yes,height='+height+',width='+width+',left='+x+',top='+y;

        window.open(sharedURL,'', params);
    };


    // https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
    // https://codepen.io/shaikmaqsood/pen/XmydxJ
    copyToClipboard = () => {
        const { url } = this.props;
        const el = document.createElement('textarea');
        el.value = url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        MiscUtils.showNotification('Copied')
    };


    render (){
        const { customClass } = this.props;
        const cls = customClass ? customClass : '';
        const isMobileView = MiscUtils.isMobileView();
        return (
            <div className={"snsSharingBtn "+cls}>
                <FontAwesome name="facebook-square" size="lg" className="marginRight5"
                             data-tip data-for='snsSharingFB'
                             onClick={() => this.shareSNS('facebook')}
                />
                {
                    !isMobileView &&
                    <ReactTooltip id='snsSharingFB' className="tooltipForBtn" effect='solid'>
                        Facebook
                    </ReactTooltip>
                }


                <FontAwesome name="google-plus-square" size="lg" className="marginRight5"
                             data-tip data-for='snsSharingGG'
                             onClick={() => this.shareSNS('google')}
                />
                {
                    !isMobileView &&
                    <ReactTooltip id='snsSharingGG' className="tooltipForBtn" effect='solid'>
                        Google
                    </ReactTooltip>
                }


                <FontAwesome name="twitter-square" size="lg" className="marginRight5"
                             data-tip data-for='snsSharingTW'
                             onClick={() => this.shareSNS('twitter')}
                />
                {
                    !isMobileView &&
                    <ReactTooltip id='snsSharingTW' className="tooltipForBtn" effect='solid'>
                        Twitter
                    </ReactTooltip>
                }



                <FontAwesome name="clone" size="lg" className="marginRight5"
                             data-tip data-for='snsSharingCopy' onClick={this.copyToClipboard}
                />
                {
                    !isMobileView &&
                    <ReactTooltip id='snsSharingCopy' className="tooltipForBtn" effect='solid'>
                        Copy URL
                    </ReactTooltip>
                }


                {/*<img src={facebook} className="marginRight5"/>*/}
                {/*<img src={google} className="marginRight5"/>*/}
                {/*<img src={twitter} className="marginRight5"/>*/}
            </div>
        );
    }
}

