import React from 'react';
import CKEditor from "@ckeditor/ckeditor5-react";
import BlockingComponent from './BlockingComponent';
import {DELAY_BEFORE_NEXT_ACTION, BACKEND_URL} from '../../../config';
import {MiscUtils} from '../../../extras';
import CustomEditor from 'ckeditor5-custom-build/build/ckeditor';
import './editor.css';

export default class Editor extends React.Component {

    state = {
        content: this.props.defaultContent,
        isProcessing: true,
        stats: { words: 0, characters: 0 }
    };

    editorRef = React.createRef();

    handleEditorReady(editor) {
        this.editorRef.current = editor;

        console.log(this.props.defaultContent)
        // Set the initial content of the editor
        editor.setData(this.props.defaultContent);
    }

    componentWillReceiveProps(nextProps) {
        const {defaultContent} = nextProps;
        const {content} = this.state;
        if ((defaultContent === '') && (content !== defaultContent))
            this.setState({content: nextProps.defaultContent}, () => this.ckeditor.editorInstance.setData(defaultContent));

    };

    updateContent = (editor) => {
        const newContent = editor.getData();
        this.setState({content: newContent});
        this.props.updateContent(newContent);
    };

    //constructor(props) {
    //    super(props);
    //this.updateContent = this.updateContent.bind(this);
    //this.state = {
    //    content: 'content'
    //}
    //}

    componentDidMount() {
        setTimeout(() => this.setState({isProcessing: false}), DELAY_BEFORE_NEXT_ACTION); //show editor after 1s
    }


    // <CKEditor activeClass="p10" content={this.state.content} onChange={this.updateContent} />
    render() {
        const {content, isProcessing, stats} = this.state;
        const {placeholder} = this.props;
        const isMobileView = MiscUtils.isMobileView();
        return (
            <div id="ckeditorContent">
                <BlockingComponent isProcessing={isProcessing}>
                    {
                        <CKEditor
                            editor={CustomEditor.Editor}
                            onChange={(event, editor) => {
                                this.updateContent(editor)
                            }}
                            content={'<p>content</p>'}
                            onReady={(editor) => {
                                document
                                    .querySelector('#ckeditorContent')
                                    .appendChild(editor.plugins.get('WordCount').wordCountContainer);
                                this.handleEditorReady.bind(this)
                            }}
                            style={{height: '400px'}}
                            config={{
                                initialData: this.props.defaultContent,
                                placeholder: placeholder ? placeholder : '',
                                simpleUpload: {
                                    uploadUrl: `${BACKEND_URL}/api/upload_image?ck&responseType=json`,
                                },
                                wordCount: {
                                    onUpdate: stats => {
                                        this.setState({stats: stats});
                                    }
                                }
                            }}
                        />
                    }
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', borderWidth: 1, border: '1px solid #ccced1', borderColor: '#ccced1', height: 40, width: '100%' }}>
                        <span style={{ marginRight: 10 }}>
                            Characters: { stats.characters }
                        </span>
                        <div style={{ width: 10 }}>

                        </div>
                        <span>
                            Words: { stats.words }
                        </span>
                    </div>
                </BlockingComponent>

            </div>

        )
    }
}


