import { combineReducers } from 'redux';
import reducerAuth from './reducerAuth';
import reducerArticle from './reducerArticle';
import reducerResponse from './reducerResponse';
import reducerGlobal from './reducerGlobal';
import reducerNotification from './reducerNotification';

export default combineReducers({
    reducerAuth,
    reducerArticle,
    reducerResponse,
    reducerGlobal,
    reducerNotification
});