import React from 'react';
import queryString from 'query-string';
import { TableSorterTransparent, Breadcrumb } from '../common';
import DonationEnglishView from './DonationEnglishView';
import DonationKoreanView from './DonationKoreanView';


export default class DonationView extends React.Component {

    state = {
        currentTab: 'ko'
    };

    tabClick = (currentTab) => {
        this.setState({currentTab});
        this.props.history.push('/notice/donation/?lang=' + currentTab);
    };

    componentWillMount() {
        //    /notice/donation?lang=ko
        const parsed = queryString.parse(this.props.location.search);
        let tab = 'ko';
        if (parsed && 'lang' in parsed)
            tab = parsed.lang;
        if (tab === 'en')
            this.tabClick(tab);
    }


    render() {
        const sortButtons = [
            ['Korean', 'ko'],
            ['English', 'en']
        ];
        return (
            <div>
                {/*<h3 className="contentHeader">Donation</h3>*/}
                <Breadcrumb />
                <TableSorterTransparent sortButtons={sortButtons} sortType={this.state.currentTab} selectSorter={this.tabClick}/>
                <div id="donationView">
                    { this.state.currentTab === 'ko' && <DonationKoreanView />
                    }

                    { this.state.currentTab === 'en' && <DonationEnglishView />
                    }
                </div>
            </div>
        )
    }
}