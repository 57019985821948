import 'bootstrap/dist/css/bootstrap.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-tippy/dist/tippy.css';
import 'react-notifications/lib/notifications.css';
import 'react-block-ui/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-dropdown/style.css';
import 'react-select/dist/react-select.css';
import 'react-popupbox/dist/react-popupbox.css';
import 'loaders.css/loaders.min.css';
import 'react-selectize/themes/index.css';
import 'react-selectize/themes/material.css';
import 'react-hint/css/index.css';
import './static/css/bootstrap-social.css';
import './static/css/App.css';
import './static/css/article.css';
import './static/css/content.css';



import React from 'react';
import { NotificationContainer } from 'react-notifications';
import { PopupboxContainer } from 'react-popupbox';
import ReactHintFactory from 'react-hint';
import { SIGN_UP_GET_INFO } from './RouteURL';
import { NavigationBar, LeftBar, RightBar, BottomBar, MainContent, Footer } from './components';
import { URLUtils, MiscUtils, CURRENT_BOARD, ACCESS_TOKEN, IS_USER_PROFILE_PROCESS } from './extras';
import { BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_NOTICE, BOARD_NAME_RANK, BOARD_NAME_FREETALK, BREAKING_WIDTH_FOR_RESPONSIVE } from './config';
import ReactGA from 'react-ga';


const ReactHint = ReactHintFactory(React);
export default class App extends React.Component {
    componentDidMount() {
        MiscUtils.updateLastActivityForUnauthenticatedUser();
        if (!window.location.href.includes("localhost")) {
            ReactGA.initialize('G-Z1YG0E2MRH', { debug: true });
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        // ReactGA.initialize('YOUR_MEASUREMENT_ID');
    }


    render() {
        // same in App.js HomeView.js
        if (MiscUtils.getFromLocalStorage(ACCESS_TOKEN) && !MiscUtils.getFromLocalStorage(IS_USER_PROFILE_PROCESS)) {
            URLUtils.moveToURL(SIGN_UP_GET_INFO);
            return;
        }


        let board = URLUtils.getCurrentURL(this).split('/')[1];
        if ([BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_NOTICE, BOARD_NAME_RANK, BOARD_NAME_FREETALK].indexOf(board) < 0)
            board = '';

        // can lam lai, ko the set localStorage duoc vi neu nhieu tab, thi se bi cai nay cai kia
        MiscUtils.setToLocalStorage(CURRENT_BOARD, board);
        const isMobileView = MiscUtils.isMobileView();

        MiscUtils.updateBreadcumbs();

        return (
            <div className="rootElement">

                <NotificationContainer/>
                <PopupboxContainer />
                <ReactHint events delay={100} />

                <NavigationBar />
                <div className="container myBody">
                    <div className="row">
                        {
                            !isMobileView &&
                            <LeftBar/>
                        }
                        <MainContent/>
                        {
                            !isMobileView &&
                            <RightBar/>
                        }

                        {
                            isMobileView &&
                            <BottomBar/>
                        }

                    </div>
                </div>

                <Footer/>

            </div>
        );
    }
}
