import React from 'react';
import ReactTooltip from 'react-tooltip';

import { NavigationBar, Footer, ImageSlider, ButtonHyperlink, HTMLContent } from '../common';
import { MiscUtils, URLUtils, CURRENT_BOARD, IS_USER_PROFILE_PROCESS, ACCESS_TOKEN } from '../../extras';

import bglogin from '../../static/images/bglogin.jpg';
import ico_ask from '../../static/images/ico_ask.png';
import ico_answer from '../../static/images/ico_answer.png';
import ico_choose from '../../static/images/ico_choose.png';
import ico_right_arrow from '../../static/images/ico_right_arrow.png';

import Argentina from '../../static/images/flag/Argentina-Flag_none.png';
import Austria from '../../static/images/flag/Austria-Flag_none.png';
import Brazil from '../../static/images/flag/Brazil-Flag_none.png';
import Chile from '../../static/images/flag/Chile-Flag_none.png';
import China from '../../static/images/flag/China-Flag_none.png';
import Finland from '../../static/images/flag/Finland-Flag_none.png';
import Germany from '../../static/images/flag/Germany-Flag.png';
import Indonesia from '../../static/images/flag/Indonesia-Flag_none.png';
import Vietnam from '../../static/images/flag/Vietnam-Flag.png';
import Italy from '../../static/images/flag/Italy-Flag_none.png';
import Japan from '../../static/images/flag/Japan-Flag_none.png';
import Korea from '../../static/images/flag/Korea-Flag_none.png';
import Malaysia from '../../static/images/flag/Malaysia-Flag.png';
import Norway from '../../static/images/flag/Norway-Flag_none.png';
import Poland from '../../static/images/flag/Poland-Flag_none.png';
import Portugal from '../../static/images/flag/Portugal-Flag_none.png';
import Spain from '../../static/images/flag/Spain-Flag.png';
import SriLanka from '../../static/images/flag/Sri-Lanka-Flag.png';
import Sweden from '../../static/images/flag/Sweden-Flag_none.png';
import Thailand from '../../static/images/flag/Thailand-Flag.png';
import Turkey from '../../static/images/flag/Turkey-Flag.png';
import Ukraine from '../../static/images/flag/Ukraine-Flag_none.png';
import USA from '../../static/images/flag/United-States-Flag_none.png';
import Ireland from '../../static/images/flag/Ireland-Flag_none.png';

import S1 from '../../static/images/sponsor/sponsor_01.png';
import S2 from '../../static/images/sponsor/sponsor_02.png';
import S3 from '../../static/images/sponsor/sponsor_03.png';
import S4 from '../../static/images/sponsor/sponsor_04.png';
import S5 from '../../static/images/sponsor/sponsor_05.png';
import S6 from '../../static/images/sponsor/sponsor_06.png';
import S7 from '../../static/images/sponsor/sponsor_07.png';
import S8 from '../../static/images/sponsor/sponsor_08.png';
import S9 from '../../static/images/sponsor/sponsor_09.png';
import S10 from '../../static/images/sponsor/sponsor_10.png';
import S11 from '../../static/images/sponsor/sponsor_11.png';
import S12 from '../../static/images/sponsor/sponsor_12.png';
import S13 from '../../static/images/sponsor/sponsor_13.png';
import S14 from '../../static/images/sponsor/sponsor_14.png';
import S15 from '../../static/images/sponsor/sponsor_15.jpg';
import S16 from '../../static/images/sponsor/sponsor_16.png';
import { SIGN_UP_GET_INFO, MAINPAGE_QA } from '../../RouteURL';
import { translate } from "react-i18next";




class HomeView extends React.Component {

    render() {
        const { t } = this.props;
        if (MiscUtils.getFromLocalStorage(ACCESS_TOKEN) && !MiscUtils.getFromLocalStorage(IS_USER_PROFILE_PROCESS)) {
            URLUtils.moveToURL(SIGN_UP_GET_INFO);
            return;
        }
        MiscUtils.setToLocalStorage(CURRENT_BOARD, '');

        const pageStyle= {
            thumbnail: {
                color: "white",
                textAlign: "center",

                backgroundImage: "url("+bglogin+")",
                height: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover'
                // /* The image used */
                // background-image: url("img_girl.jpg");
                //
                // /* Full height */
                // height: 100%;
                //
                // /* Center and scale the image nicely */
                // background-position: center;
                // background-repeat: no-repeat;
                // background-size: cover;
            }
        };

        const sponsors = [S1, S2, S5, S4, S3, S6, S7, S8, S9, S10, S11, S12, S13, S14, S15, S16];

        return (
            <div id="homeView">
                <ReactTooltip/> {/* place any where in this file */}
                <NavigationBar/>

                <div style={pageStyle.thumbnail}>
                    <div className="overlay">
                        <div className="homepageIntro">
                            <div className="row thumb_row1">JOINUS WORLD</div>
                            <div className="row thumb_row2">
                                {t('{{joinus_world}} is a multilingual question and answer website on a wide range of topics about Korea and its neighboring area. It is run by a non-profit network - JOINUS FOUNDATION (or JOINUS KOREA)',
                                    {
                                        joinus_world: ''
                                    })}
                            </div>
                            <div className="row">
                                <ButtonHyperlink url={MAINPAGE_QA} txt={t('Ask in your language')}/>
                            </div>
                            <div className="row thumb_row4">{t('or become a volunteer for the better world')}</div>
                        </div>
                    </div>
                </div>

                <div className="row howWork">
                    <label>{t('HOW IT WORKS?')}</label>
                    <hr className="hrFooter"/>
                </div>

                <div className="row howWorkIcon">
                    <div className="myItem col-md-2 col-md-offset-3 col-xs-4 col-sm-4">
                        <div>
                            <img className="img-responsive" src={ico_ask} alt="ASK"/>
                            <label>{t('You ask in your language')}</label>
                        </div>
                        <img className="img-responsive" src={ico_right_arrow} alt=">"/>

                    </div>
                    <div className="myItem col-md-2 col-xs-5 col-sm-5">
                        <div>
                            <img className="img-responsive" src={ico_answer} alt="Answer"/>
                            <label>{t('We answer in your language')}</label>
                        </div>
                        <img className="img-responsive" src={ico_right_arrow} alt=">"/>
                    </div>
                    <div className="col-md-2 col-xs-3 col-sm-3">
                        <img className="img-responsive" src={ico_choose} alt="Choose"/>
                        <label>{t('Choose the best answer')}</label>
                    </div>
                </div>

                <div className="introduction">
                    <div className="row">
                        <div className="introTitle">
                            <label>{t('INTRODUCTION')}</label>
                            <hr className="hrFooter"/>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                <iframe name="frame" src="https://www.youtube.com/embed/PnMUHhXhrMk" frameborder="0" scrolling="no" webkitAllowFullScreen mozallowfullscreen allowFullScreen title="intro"></iframe>
                            </div>
                            <div className="col-md-5">

                                <div className="row flag">
                                    <img src={Argentina} data-tip="Argentina" alt="Argentina"/>
                                    <img src={Austria} data-tip="Austria" alt="Austria"/>
                                    <img src={Brazil} data-tip="Brazil" alt="Brazil"/>
                                    <img src={Chile} data-tip="Chile" alt="Chile"/>
                                    <img src={China} data-tip="China" alt="China"/>
                                    <img src={Finland} data-tip="Finland" alt="Finland"/>
                                    <a href="https://www.youtube.com/embed/yBLrcEml-Rc" target="frame">
                                        <img src={Germany} data-tip="Germany" alt="A"/>
                                    </a>
                                    <img src={Indonesia} data-tip="Indonesia" alt="A"/>
                                    <a href="https://www.youtube.com/embed/b3f7oibGtuA" target="frame">
                                        <img src={Vietnam} data-tip="Vietnam" alt="A"/>
                                    </a>
                                    <img src={Italy} data-tip="Italy" alt="A"/>
                                    <img src={Japan} data-tip="Japan" alt="A"/>
                                    <img src={Korea} data-tip="Korea" alt="A"/>
                                    <a href="https://www.youtube.com/embed/PxSFx5jiYOs" target="frame">
                                        <img src={Malaysia} data-tip="Malaysia" alt="A"/>
                                    </a>
                                    <img src={Norway} data-tip="Norway" alt="A"/>
                                    <img src={Poland} data-tip="Poland" alt="A"/>
                                    <img src={Portugal} data-tip="Portugal" alt="A"/>
                                    <a href="https://www.youtube.com/embed/hQNu_9wNBF8" target="frame">
                                        <img src={Spain} data-tip="Spain" alt="A"/>
                                    </a>
                                    <a href="https://www.youtube.com/embed/9FDJoxlIDqw" target="frame">
                                        <img src={SriLanka} data-tip="Sri-Lanka" alt="A"/>
                                    </a>
                                    <img src={Sweden} data-tip="Norway" alt="A"/>
                                    <a href="https://www.youtube.com/embed/wXYo8c7h8qU" target="frame">
                                        <img src={Thailand} data-tip="Thailand" alt="A"/>
                                    </a>
                                    <a href="https://www.youtube.com/embed/bnMDUQnt5VI" target="frame">
                                        <img src={Turkey} data-tip="Turkey" alt="A"/>
                                    </a>
                                    <img src={Ukraine} data-tip="Ukraine" alt="A"/>
                                    <img src={USA} data-tip="USA" alt="A"/>
                                    <img src={Ireland} data-tip="Ireland" alt="A"/>
                                </div>

                                <div className="row fullIntro">
                                    <p>
                                        <HTMLContent content={t('{{joinus_world}} is a website administered by JOINUS KOREA, which is a nonprofit NGO network.',
                                            {
                                                joinus_world: "<strong>JOINUS WORLD</strong>"
                                            })} />
                                    </p>
                                    <p>
                                        {t('Its headquarter is located in the Republic of Korea. We make efforts to build up a knowledge-exchange comunity with multi-language and culture connecting every knowledge with individuals all over the world based on four mottos-participation, sharing, openness, and autonomy - and to go forward with you.')}
                                    </p>
                                    <p>
                                        {t('Thank you.')}
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <div className="container sponsorSlider">
                    <label>{t('SPONSOR')}</label>
                    <hr className="hrFooter"/>
                    <ImageSlider images={sponsors}/>
                </div>


                {/*<Footer/>*/}
            </div>

        );
    }
}


// export default translate(['common', 'home'])(HomeView);
export default translate()(HomeView);