import React from 'react';
// import Slider from 'react-image-slider';
import Slider from 'react-slick';
import { MiscUtils } from '../../../extras';


export default class ImageSlider extends React.Component {

    render (){
        const isMobile = MiscUtils.isMobileView();
        // https://react-slick.neostack.com/docs/api
        const settings = {
            dots: !isMobile,
            infinite: true,
            speed: 1000,
            autoplay: true,
            autoplaySpeed: 500,
            slidesToShow: isMobile ? 1 : 3,
            centerMode: true,
            slidesToScroll: 1
        };
        return (
            <Slider {...settings}>
                {
                    this.props.images.map((image) => <div key={MiscUtils.generateId()}><img src={image} alt="slider"/></div>)
                }
            </Slider>
        );
    }
}