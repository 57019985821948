import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ErrorNotFound from './ErrorNotFound';
import ErrorForbidden from './ErrorForbidden';

export default class ErrorView extends React.Component {
    render() {
        const { path } = this.props.match;
        return (
            <Switch>
                <Route exact path={path+"/notFound"} component={ ErrorNotFound } />
                <Route exact path={path+"/forbidden"} component={ ErrorForbidden } />
                {/*<Route path={path} component={ AccountSettingView } />*/}
            </Switch>
        )
    }
}