import React from 'react';
import {UserAvatarInfo, TableSorterTransparent} from '../common';
import {MiscUtils} from '../../extras';
import {CLASS_HIDDEN_IN_MOBILE, CLASS_HIDDEN_IN_PC} from '../../config';

export default class RankPeopleView extends React.Component {

  render() {
    const {listObjects, t} = this.props;
    return (
      <div>
        <TableSorterTransparent {...this.props}/>
        <table className="rankingDatalist">
          <tbody>
          {
            listObjects.map((user, idx) => (
              <tr className="dataRow row" key={MiscUtils.generateId()}>
                <td className={"dataColumnTitle col-md-7" + CLASS_HIDDEN_IN_MOBILE}>
                  <UserAvatarInfo user={user.user_info} idx={idx + 1}/>
                </td>
                <td className={"col-md-2" + CLASS_HIDDEN_IN_MOBILE}><label
                  className="languageBadge">{user.native_main_code}</label></td>
                <td className={"numStatRise col-md-1" + CLASS_HIDDEN_IN_MOBILE}>{user.rise}
                  <label>&#8593;</label></td>
                <td className={"numStat col-md-2" + CLASS_HIDDEN_IN_MOBILE}>{t('Total')} <label
                  className="numStatsCount">{user.total_point}</label></td>
                <td className={CLASS_HIDDEN_IN_PC}>
                  <div className="dataColumnTitle">
                    <UserAvatarInfo user={user.user_info} idx={idx + 1}/>
                  </div>
                  <div>
                    <label className="languageBadge">{user.native_main_code}</label>
                    <label className="numStat pull-right">{t('Total')} <label
                      className="numStatsCount">{user.total_point}</label></label>
                    <label className="numStatRise pull-right">{user.rise}
                      <label>&#8593;</label></label>
                  </div>
                </td>
              </tr>
            ))
          }
          </tbody>
        </table>
      </div>
    );
  }
}