import React from 'react';
import classnames from 'classnames';
import PopupModal from './PopupModal';
import { LANGUAGES } from "../../../config/common";
import { MiscUtils } from '../../../extras';
import { translate } from "react-i18next";


class LanguageSelectionModal extends React.Component {

    state = {
        showPopup: this.props.showPopup,
        languagesChecked: {},
        selectedLanguages: this.props.selectedLanguages
    };


    initLanguages = () => {
        const { selectedLanguages } = this.state;
        let languagesChecked = {};
        LANGUAGES.map(
            (languageObj) => languagesChecked[languageObj.code] = selectedLanguages ? (selectedLanguages.indexOf(languageObj.code) >=0) : false
        );
        this.setState({languagesChecked});
    };


    togglePopup = () => {
        this.props.togglePopup();
    };


    toggleLanguage = (langCode) => {
        const { isMultiple, maxSelected, disabledLanguages } = this.props;
        if (disabledLanguages && disabledLanguages.indexOf(langCode) >= 0)
            return;
        if (isMultiple) { // available languages or Language Filtering
            const {languagesChecked} = this.state;
            let data = {};
            data[langCode] = !languagesChecked[langCode];
            let count = 0;
            for (let lang in languagesChecked)
                if (languagesChecked[lang])
                    count += 1;
            if (data[langCode] && ((count+1) > maxSelected))
                return;
            this.setState({
                languagesChecked: MiscUtils.updateObject(this.state.languagesChecked, data),
            });
        } else { // primary
            let languagesChecked = {};
            LANGUAGES.map(
                (languageObj) => languagesChecked[languageObj.code] = false
            );
            languagesChecked[langCode] = true;
            this.setState({
                languagesChecked
            });
        }
    };


    cancelSelectedLanguages = () => {
        // reset
        this.initLanguages();
        this.togglePopup();
    };


    saveSelectedLanguages = () => {
        const { languagesChecked } = this.state;
        const { saveSelectLanguagesCallback } = this.props;
        let selectedLanguages = [];
        for (let langCode in languagesChecked)
            if (languagesChecked[langCode])
                selectedLanguages.push(langCode);
        this.setState({
            selectedLanguages
        }, () => {
            this.togglePopup();
            if (saveSelectLanguagesCallback)
                saveSelectLanguagesCallback(selectedLanguages);
        });

    };


    // when choosing Primary Language, then update Available
    componentWillReceiveProps = (nextProps) => {
        this.setState({
            showPopup: nextProps.showPopup,
            languagesChecked: {},
            selectedLanguages: nextProps.selectedLanguages
        }, () => this.initLanguages());
    };


    componentDidMount = () => {
        this.initLanguages();
    };


    render (){
        const { showPopup, languagesChecked } = this.state;
        const { disabledLanguages, setWidth, t } = this.props;
        return (
            <div id="selectLanguageComponent">
                {this.props.children}
                <PopupModal isVisible={showPopup} onCloseClicked={this.togglePopup} setWidth={setWidth}>
                    <div>
                        {
                            LANGUAGES.map(
                                (languageObj) =>
                                    <label key={MiscUtils.generateId()}
                                            className={classnames("blankHyperlink",
                                                    {"languageChecked": languagesChecked[languageObj.code]},
                                                    {'languageDisabled': disabledLanguages && disabledLanguages.indexOf(languageObj.code) >= 0}
                                                )}
                                            onClick={() => this.toggleLanguage(languageObj.code)}>
                                                {languageObj.text}
                                    </label>
                            )
                        }
                    </div>
                    <div className="actionBtn alignRight">
                        <button type="button" className="btn negativeBtn" onClick={this.cancelSelectedLanguages}>{t('Cancel')}</button>
                        <button type="button" className="btn positiveBtn" onClick={this.saveSelectedLanguages}>{t('Save')}</button>
                    </div>

                </PopupModal>

            </div>
        )


    }
}

export default translate()(LanguageSelectionModal);