import React from 'react';
import linkifyHtml from 'linkifyjs/html';
import ReactMarkdown from "react-markdown";
import parse from "html-react-parser";
// import Interweave from 'interweave';
// import AnchormeContent from './AnchormeContent';

export default class HTMLContent extends React.Component {
    renderedContent = this.props.content.split('\n').map((line, index) => {
        // If the line starts with a Markdown indicator, use ReactMarkdown
        if (line.trim().startsWith('#') || line.trim().startsWith('*') || line.trim().startsWith('[')) {
            console.log(line)
            return <ReactMarkdown key={index} children={line} />;
        }
        // Otherwise, treat it as HTML
        return parse(line);
    });
    render(){
        // interwave ko display iframe: https://milesj.gitbook.io/interweave/parser
        return (
            <div className={'html-content-body'} style={{ maxWidth: '100%'}}>
                <div dangerouslySetInnerHTML={ {__html: linkifyHtml(this.props.content)} } />
            </div>
        )
    }
}
