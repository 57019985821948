import React from 'react';
import { MiscUtils } from '../../extras';
import { Breadcrumb } from '../../components';

export default class PrivacyView extends React.Component {

    render() {
        const titles = ['Information Collection and Purpose of Usage', 'Items of Personal Information Collected and Method of Collection',
            'Duration of Retaining and Using Personal Information', 'Process and Method of Deleting Personal Information',
            'Providing and Sharing Personal Information', 'Installation and Operation of Device Designed to Collect Personal Information Automatically and Denial of such Device',
            'Technological and Managerial Measures for Protection of Personal Information', 'Rights of Member and Legal Guardian and How to Exercise the Rights',
            'Supplementary Provision'];
        return (
            <div id="privacyView">
                {/*<h3 className="contentHeader">Privacy Policy</h3>*/}
                <Breadcrumb />
                <p>We understand the critical importance of protecting your private information and are in full compliance with the Privacy Act, Information Protection Act and other such laws that protect privacy. In the following sections, the purpose and manner in which a member’s private information provided to us is used are explained in detail. Note that our organization policy regarding privacy may be amended to take account of the changes in the relevant privacy laws or for any other reasons. In such a case, we will notify the members of such changes via a notice on our website or by notifying each member individually.</p>
                <p>You may refuse to take part in our policies regarding the handing of your personal information (hereinafter “the Proceeding”). Without providing us with your consent to the following, you may be prevented from using a part or entirety of our services.</p>
                <p>Please check our websites often for any updates.</p>

                <div className="index">
                    {
                        titles.map((title, idx) =>
                            <div key={MiscUtils.generateId()}>
                                <a href={"#article_"+(idx+1)}><button type="button" className="btn btn-sm btn-default btn-circle positiveBtn">{ idx+1 }</button> {title}</a>
                            </div>
                        )
                    }
                </div>


                <div className="privacyContent">

                    <div className="section" id="article_1">
                        <h4>Information Collection and Purpose of Usage</h4>
                        <p>The private information is Personal Information of a living individual including but not limited to username and e-mail address, etc., which can be used to identify the owner of such information (including information which can be used to identify the owner of the information when such information is combined with other information with ease). The collected information may be utilized for the following purposes:</p>
                        <p>
                            <strong>1.</strong> To perform contractual obligations and process payment regarding service provision To provide contents; to send various bills; to verify identification for the financial transaction; to provide financial services; to purchase; to provide payment services; and, to collect fees.
                        </p>
                        <p>
                            <strong>2.</strong> To manage members To verify identification for membership services; to distinguish individual; to prevent misbehaving members from using services improperly and prevent unauthorized use of services; to confirm intention of membership application; to restrict membership application and the numbers of such application; to confirm legal guardian’s consent to collect information of minors under the age of fourteen (14) and verify identification of such legal guardian; to preserve records for arbitrating disputes; to provide customer services for processing customers’ claims; and, to send notifications.
                        </p>
                        <p>
                            <strong>3.</strong> To utilize for marketing and advertisement purposes To develop and specialize new service (goods); to provide services and advertisement according to demographic factors; to record frequency of using services; to compile statistics of service usage of members; and, to execute events (however, the Personal Information of members shall not be provided to the individual or organization who requests for advertisement).
                        </p>
                    </div>

                    <div className="section" id="article_2">
                        <h4>Items of Personal Information Collected and Method of Collection</h4>
                        <p>[Items of Personal Information Collected]</p>
                        <p>
                            <strong>1.</strong> The following information shall be collected to verify member’s application and provide optimized services:
                            <p className="subSection">
                                <strong>[General Members]</strong>
                                <p>
                                    - Required items: username; gender; region of application; password; e-mail address (address, contact information; date of birth; and, information regarding legal guardian in case of membership application by minor under the age of fourteen (14).
                                </p>
                            </p>
                        </p>
                        <p>
                            <strong>2.</strong> The following information may be collected in the course of using services or processing businesses :
                            <p className="subSection">
                                <div>
                                    - Service usage record; connection log; cookie; IP address, payment record; and, improper usage record.
                                </div>
                                <strong>[Method of Collection] </strong>
                                <div>We collect Personal Information by the following methods:</div>
                                <div>① Membership application through the homepage; consumer service board; and, participation in events.</div>
                                <div>② Tools which collect generated information.</div>
                            </p>
                        </p>
                    </div>

                    <div className="section" id="article_3">
                        <h4>Duration of Retaining and Using Personal Information</h4>
                        <p>We delete and destroy Personal Information immediately after the purpose of collection and use of such information is accomplished. However, we retain the following information for the specified period for the reason stated below:</p>
                        <p>
                            <strong>1.</strong> Personal Information retained upon cancellation of membership
                            <p className="subSection">
                                <div>- Items retained: username; gender; date of birth; e-mail address provided by member.</div>
                                <div>- Reason for retention: to prohibit misbehaving member from reapplying for membership; to deal with infringement of the rights, such as defamation; and, to cooperate with investigation.</div>
                                <div>- Duration of retention: one (1) year after cancellation of membership.</div>
                            </p>
                        </p>
                        <p>
                            <strong>2.</strong> Personal Information retained in relation to commercial transaction
                            <p className="subSection">
                                <div>- Items retained: commercial transaction record.</div>
                                <div>- Reason for retention: to comply with Commercial Act and the Consumer Protection Act.</div>
                                <div>- Duration of retention:</div>
                                <p className="subSection" style={{fontSize: 'smaller'}}>
                                    <div>■ records related to contract or cancellation of subscription – five (5) years</div>
                                    <div>■ records related to payment and supplying goods, etc. – five (5) years</div>
                                    <div>■ records related to customer complaints and resolution of disputes – three (3) years</div>
                                </p>
                            </p>
                        </p>
                        <p>
                            <strong>3.</strong> Collection of personal information related to log-in record
                            <p className="subSection">
                                <div>- Collected information: log-in record.</div>
                                <div>- Relevant act: Communication Privacy Protection Act.</div>
                                <div>- Storage period: 3 months.</div>
                            </p>
                        </p>
                    </div>

                    <div className="section" id="article_4">
                        <h4>Process and Method of Deleting Personal Information</h4>
                        <p>We, in principle, delete and destroy Personal Information immediately after the purpose of collection and use of such information is accomplished, or the duration of retaining and using Personal Information is expired by the following process and method:</p>
                        <p>
                            <strong>1.</strong> Method of deleting and destroying
                            <p className="subSection">The Personal Information provided by the member for the purpose of membership application, etc. shall be deleted and destroyed after the purpose of collection and use of such information is accomplished for further time period in accordance with the internal policies and relevant laws and regulations (please refer the Article 3 of this Policy). Unless otherwise provided in laws, Personal Information shall not be used for any purpose other than as specified herein.</p>
                        </p>
                        <p>
                            <strong>2.</strong> Method of deletion and destruction
                            <p className="subSection">Personal information that is documented in paper shall be shredded or incinerated; Personal Information that is electronically stored in file format shall be deleted using technological method in which the deleted data cannot be restored.</p>
                        </p>
                    </div>

                    <div className="section" id="article_5">
                        <h4>Providing and Sharing Personal Information</h4>
                        <p>We neither use the Personal Information beyond the scope set forth in this Privacy Policy nor provide the member’s Personal Information for third party individual, organization or organization unless otherwise provided as below:</p>
                        <p>
                            <strong>1.</strong> In case that the member gives prior consents
                            <p className="subSection">Prior to collect and provide Personal Information, we may ask the member to consent to such collection and provision notifying who the business partner is; what kind of information is required; what the purpose of collection and provision of such information is; and, how long and how such information is retained and protected/managed. We shall neither collect additional information nor share such information without member’s consent thereto.</p>
                        </p>
                        <p>
                            <strong>2.</strong>  In case that it is required by laws and regulations or that there are requests from investigation agency according to the process and method specified by laws and regulations for investigative purposes.
                        </p>
                    </div>

                    <div className="section" id="article_6">
                        <h4>Installation and Operation of Device Designed to Collect Personal Information Automatically and Denial of such Device</h4>
                        <p>We use cookies to save and recall Personal Information at any time in order to provide a personalized service to the member. A cookie is a small package of data sent by the server used in the operation of our website to the browser of the member and saved on the hard disk of the member’s computer.</p>
                        <p>
                            <strong>1.</strong> Purpose of using cookieTo use as standard for target marketing or personalized service by understanding preference and areas of interest of the member and non-member through analyzing frequencies and durations of visit, tracking usage, and degree of participation in various events.
                        </p>
                        <p>
                            <strong>2.</strong> Method of denying installation of cookieYou can install the cookie at your discretion. Therefore, you can grant all cookies, verify every time a cookie is saved, or refuse all cookies by adjusting settings of your internet browser.
                            <p className="subSection">
                                <div>You can block cookies by taking following steps (for internet explorers):</div>
                                <div>(1) Select [Internet Option] under [Tools] menu;</div>
                                <div>(2) Click [Privacy] tab; and,</div>
                                <div>(3) Adjust [Privacy] settings</div>
                                <div>However, you may experience some difficulties in using services which requires log-in.</div>
                            </p>
                        </p>

                    </div>

                    <div className="section" id="article_7">
                        <h4>Technological and Managerial Measures for Protection of Personal Information</h4>
                        <p>
                            <strong>1.</strong> Technological measures
                            <p className="subSection">
                                <div>- Personal information of member is protected by password, and important data are protected by separate protection technology by encrypting files and transferring data or file lock function.</div>
                                <div>- We use vaccine software to protect Personal Information from computer virus. Such vaccine software has been updated on regular basis and immediately after abrupt emergence of new computer virus in order to protect Personal Information from being compromised.</div>
                                <div>- We adopt security device (SSL or SET) which facilitates safe transfer of Personal Information via network by using encryption algorithm.</div>
                                <div>- We use the device which blocks outside intrusion, install intrusion detecting system for major servers, and monitor intrusion 24 hours for the purpose of preventing leakage of your Personal Information from hacking or other technological means.</div>
                            </p>
                        </p>
                        <p>
                            <strong>2.</strong> Managerial measures
                            <p className="subSection">
                                <div>- It is recommended that member take his/her own Personal Information seriously not to expose such information including but not limited to password to third parties besides our technological measures.</div>
                                <div>- We strictly specify that the employee who can handle Personal Information shall be the person who manages Personal Information, and who is necessarily required to handle Personal Information in the course of performing his/her duty. We educate such employee to comply with this Privacy Policy with emphasis. Our internal audit committee reviews the compliance with this Privacy Policy and the employee’s adherence to this Privacy policy. Upon finding problems through such internal reviews by the committee, we immediately make corrections to comply with this Privacy Policy.</div>
                            </p>

                        </p>
                    </div>

                    <div className="section" id="article_8">
                        <h4>Rights of Member and Legal Guardian and How to Exercise the Rights</h4>
                        <p>The user and his/her legal guardian may view and change any and all Personal Information and request for cancellation of membership. Members or minors under the age of fourteen (14) can view and change Personal Information by clicking [Managing my information] or can cancel membership by clicking [Helpdesk] on our website and go through the identify verification process for themselves, or please contact Personal Information manager via mail, telephone, or e-mail and we will immediately take actions accordingly.</p>
                        <p>We shall not use or provide your Personal Information unless your request to correct errors regarding your Personal Information is accomplished.</p>
                        <p>In addition, the result of correcting errors in Personal Information shall be immediately notified to the third party who has been already provided with defective Personal Information. We process Personal Information cancelled or deleted upon requests from users or legal guardians according to the Article 3 of this Privacy Policy and such information is managed not to be read or used for any purpose other than as specified in the Article 3 of this Privacy Policy.</p>
                    </div>

                    <div className="section" id="article_9">
                        <h4>Supplementary Provision</h4>
                        <p>
                            <strong>1.</strong> On our website, we will notify you of all changes in our organization privacy policy seven days before the changes take effect. Any such notification will include the reasons for amending the policy and the date on which the changes will take effect. Important or material policy changes regarding a member’s rights or obligations are notified thirty days before the changes take effect and such notification will be emailed to each member.
                        </p>
                        <p>
                            <strong>2.</strong> Unless you manifest your intention to the contrary, we will assume that you consented to the changes in our policy after we notify you of the changes in accordance with the stated procedural steps in <strong>"Information Collection and Purpose of Usage"</strong>.
                        </p>
                        <p>
                            <strong>3.</strong> Notwithstanding <strong>Items of Personal Information Collected and Method of Collection</strong>, we will take separate procedural steps for each member when we solicit additional information from a member or when we provide the collected information to a 3rd party.
                        </p>
                        <p>The above provisions will take effect in May. 20. 2013.</p>
                        <p>
                            <strong>Juvenile Protection Policy and Activities</strong>
                            <div>We established and execute the juvenile protection policies to prevent minors under the age of nineteen (19) from accessing harmful information according to the “Act on Promotion of Information and Communications Network Utilization and Information Protection, etc. and the Juvenile Protection Act” for the purpose of helping the juveniles to grow into sound person. We are engaging in the following activities to strictly restrict harmful information including but not limited to obscene and illegal information, and unethical, anti-social activities which discourage sound growth of the juveniles.</div>
                            <p className="subSection">
                                <div>- Establishing plan to protect juveniles from information harmful to minors.</div>
                                <div>- Restricting juveniles’ access to the information harmful to minors and managerial measure.</div>
                                <div>- Educating employees performing information and communication operation to protect juveniles from information harmful to minors.</div>
                                <div>- Providing consult and handling hardship regarding damage arising out of information harmful to minors.</div>
                                <div>- Other matters necessary to protect juveniles from information harmful to minors.</div>
                            </p>
                        </p>
                    </div>

                </div>
            </div>
        )
    }
}