import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Navbar, NavItem, Nav, Glyphicon } from 'react-bootstrap';
// import classnames from 'classnames';
// import ReactTooltip from 'react-tooltip';
// import { getAllNotification, getUnreadNotificationCount, setAllUnreadCount } from '../../redux/actions';
// import { store } from '../../redux/store';
// import logo from '../../static/images/logo.png';
// import isNew from '../../static/images/icon/new.png';
// import qa from '../../static/images/icon/qa.png';
// import qa_active from '../../static/images/icon/qa_active.png';
// import community from '../../static/images/icon/community.png';
// import community_active from '../../static/images/icon/community_active.png';
// import crown from '../../static/images/icon/crown.png';
// import crown_active from '../../static/images/icon/crown_active.png';
// import free from '../../static/images/icon/free.png';
// import free_active from '../../static/images/icon/free_active.png';
// import { SIGN_IN, INDEX_PAGE, MAINPAGE_COMMUNITY, MAINPAGE_QA, RANKING__DEFAULT,
//     DEFAULT_INDEX, DASHBOARD_NOTIFICATION, SETTING_NOTIFICATION, PROFILE, DASHBOARD, SETTING } from '../../RouteURL';
// import { MiscUtils, URLUtils, CURRENT_BOARD, ACCESS_TOKEN } from '../../extras';
// import { LANGUAGES } from "../../config";
// import { DropDownMenu, LoadingSpinnerInline, NavigationBarSearchButton,ScrollContent, SideMenu } from './element';
// import { BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_NOTICE, BOARD_NAME_RANK, BOARD_NAME_FREE, CLASS_HIDDEN_IN_MOBILE,
//     CLASS_HIDDEN_IN_PC, NOTIFICATION_PULL_TIME } from '../../config';
import { BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_NOTICE } from '../../config';
import { DASHBOARD_NOTIFICATION } from '../../RouteURL';
import { translate } from "react-i18next";
import { DEFAULT_INDEX, INDEX_PAGE, SEARCH, SIGN_IN } from "../../RouteURL";
import logo from "../../static/images/logo.png";
import {SideMenu} from "./element";
import { MiscUtils, URLUtils } from "../../extras";



class NavigationBarInMobile extends React.Component {


    state = {
        keyword: ''
    };



    changeSearchQuery = (e) => {
        this.setState({keyword: e.target.value});
    };


    onSearchEnter = (e) => {
        const {keyword} = this.state;
        MiscUtils.handleEnterKeyPress(e, () => URLUtils.moveToURL(SEARCH+'?board='+BOARD_NAME_QA+'&query='+keyword));
    };


    componentDidMount = () => {

    };



    render() {

        const { isAuthenticated, userLogout, unreadCount, user } = this.props;
        const { keyword } = this.state;

        const currentPathname = URLUtils.getCurrentPathname();
        let currentBoardType = currentPathname.split('/')[1];
        currentBoardType = ([BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_NOTICE].includes(currentBoardType)) ? currentBoardType : BOARD_NAME_QA;

        if (currentBoardType === BOARD_NAME_NOTICE && (!user.is_admin))
            currentBoardType = null;
        const writeArticleURL = currentBoardType ? ('/'+currentBoardType+'/write') : null;


        return (
                <nav id="mobileNavBar" className="navbar-fixed-top navbar navbar-default">
                    <div className="container">
                        <div className="navbar-header">

                            <div className="row marginNoneLeftRight">
                                <a href={isAuthenticated ? DEFAULT_INDEX : INDEX_PAGE} className="navbar-brand">
                                    <img className="img-responsive" src={logo} alt="Joinusworld"/>
                                </a>

                                <div className="pull-right buttons">
                                    {
                                        !isAuthenticated &&
                                        <a href={SIGN_IN} >
                                            <Glyphicon glyph="user"/>
                                        </a>
                                    }
                                    {
                                        isAuthenticated &&
                                        <div className="positionRelative">
                                            <a href={DASHBOARD_NOTIFICATION} >
                                                <Glyphicon glyph="bell"/>
                                                {
                                                    (unreadCount > 0) &&
                                                    <span className="badge">{unreadCount}</span>
                                                }
                                            </a>
                                            {
                                                writeArticleURL &&
                                                <a href={writeArticleURL} >
                                                    <Glyphicon glyph="pencil"/>
                                                </a>
                                            }

                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="row row2 marginNoneLeftRight">
                                <SideMenu userLogout={userLogout} {...this.props} />

                                <div className="search">
                                    <input className="inputSearch" type="text" value={keyword} placeholder="Search"
                                           onKeyPress={this.onSearchEnter}
                                           onChange={this.changeSearchQuery}
                                    />
                                </div>

                            </div>

                        </div>
                    </div>
                </nav>

        );
    }
}

const mapStateToProps = (state) => {
    return {

        // unreadCount: state.reducerNotification.unreadCount
    }
};

const mapDispatchToProps = {
    // logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translate()(NavigationBarInMobile)));