import React from 'react';
import ReactTooltip from 'react-tooltip';
import { PopupboxManager } from 'react-popupbox';
import { Editor, Breadcrumb, BlockingComponent,PreviewContent } from '../common';
import { MESSAGE } from "../../config";
import { MiscUtils, URLUtils, URL_MAPPING } from '../../extras';
import { translate } from "react-i18next";



class FeedbackView extends React.Component {

    state = {
        isProcessing: false,
        content: '',
        errorMessage: {},
        files: []
    };


    updateContent = (newContent) => {
        this.setState({content: newContent});
    };


    showTooltip = () => {
        ['email', 'subject'].forEach((refId) => ReactTooltip.show(this.refs[refId]));
    };


    hideTooltip = () => {
        ['email', 'subject'].forEach((refId) => ReactTooltip.hide(this.refs[refId]));
    };


    previewEmailContent = () => {
        const content = (
            <PreviewContent htmlContent={this.state.content}/>
        );
        PopupboxManager.open({
            content,
            config: {
                className: 'col-md-5 previewContentInPopup',
                fadeIn: true,
                fadeInSpeed: 500
            }
        });
    };


    changeAttachment = (e) => {
        this.setState({files: Array.from(e.target.files)});
    };

    sendFeedback = () => {
        const { content, files } = this.state;
        const form = MiscUtils.serializeForm('#feedbackForm');
        let formData = new FormData();
        files.forEach((file, idx) => {
            formData.append('attachment_'+idx, file);
        });
        formData.append('content', content ? content : '');
        formData.append('category', form.category ? form.category : '');
        formData.append('subject', form.subject ? form.subject : '');
        formData.append('from', form.from ? form.from : '');
        formData.append('from_name', form.from_name ? form.from_name : '');
        this.setState({isProcessing: true}, () => {
            fetch(URLUtils.buildAPIURL(URL_MAPPING.user.sendFeedback), {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*'
                },
                body:formData,
            }).then(res => res.json())
                .then((data) => {
                    this.setState({'isProcessing': false});
                    if (data.status === 'ok')
                        MiscUtils.showNotification(MESSAGE.SENT_SUCCESS);
                    else
                        this.setState({
                            errorMessage: data.message
                        }, () => {
                            this.showTooltip();
                        });
                })
                .catch(err => MiscUtils.commonCallbackError(this, 'Cannot send'));
        });
    };


    render() {
        const isMobileView = MiscUtils.isMobileView();
        const { content, errorMessage, isProcessing } = this.state;
        const { t } = this.props;
        return (
            <div id="feedbackView">
                {/*<h3 className="contentHeader">{t('Feedback')}</h3>*/}
                <Breadcrumb />


                <BlockingComponent isProcessing={isProcessing}>
                    <form className="form-horizontal" id="feedbackForm">
                        <div className="form-group">
                            <label className="col-sm-2 control-label">{t('Category')}</label>
                            <div className="col-sm-10">
                                <select className="form-control" name="category">
                                    <option value="Inquiry">{t('Inquiry')}</option>
                                    <option value="Suggestion">{t('Suggestion')}</option>
                                    <option value="Feedback">{t('Feedback')}</option>
                                    <option value="Error report">{t('Error report')}</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="col-sm-2 control-label">{t('Sender')}</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" name="from_name" placeholder={t('Your name (optional)')} /> <br/>
                                <input type="email" className="form-control" name="from" placeholder={t('Email address')}
                                       ref='email' data-tip={errorMessage.email} data-for="tooltipEmail"/>
                                <ReactTooltip place={isMobileView ? 'top' : 'right'} id="tooltipEmail"/>
                            </div>
                        </div>


                        <div className="form-group">
                            <label className="col-sm-2 control-label">{t('Subject')}</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" name="subject" placeholder={t('What do you want to tell us?')}
                                       ref='subject' data-tip={errorMessage.subject} data-for="tooltipSubject"/>
                                <ReactTooltip place={isMobileView ? 'top' : 'right'} id="tooltipSubject"/>
                            </div>
                        </div>

                        <div className="">
                            <Editor defaultContent={content} updateContent={this.updateContent}/>
                        </div>

                        {
                            !isMobileView &&
                            <div>
                                <input type="file" name="attachment" multiple onChange={this.changeAttachment}/>
                            </div>
                        }


                        <div className="actionBtn">
                            <button type="button" className="btn btn-success" onClick={this.previewEmailContent}>{t('Preview')}</button>
                            {/*<button type="button" className="btn negativeBtn">Cancel</button>*/}
                            <button type="button" className="btn positiveBtn" onClick={this.sendFeedback}>{t('Send')}</button>
                        </div>

                    </form>
                </BlockingComponent>

            </div>
        );
    }
}

export default translate()(FeedbackView);