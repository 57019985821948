import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getResponses } from '../../redux/actions';
import { TableSorterTransparent, BlockingComponent } from '../common';
import { MiscUtils, URLUtils, URL_MAPPING } from '../../extras';
import Response from './Response';
import { BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BUTTONS, MAX_RESPONSE_SHOWN_PER_PAGE } from '../../config';
import { translate } from "react-i18next";


class Responses extends React.Component {

    state = {
        responses: [],
        shown_responses: [],
        currentPage: 1,
        sortType: this.props.sortButtons[2][1] // oldest
    };


    selectSorterArticle = (sorter) => {
        this.setState({sortType: sorter}, () => this.loadResponses());
    };


    loadResponses = () => {
        const currentPathname = URLUtils.getCurrentPathname();
        const isViewURL = URLUtils.isViewURL();
        const isNotificationURL = URLUtils.getParamInCurrentURL('id'); // id=answer_  / reply_ / comment_...

        const { article_type, article_id } = this.props.article;
        const { sortType } = this.state;
        const params = {
            article_type,
            article_id,
            responses_sort_type: sortType
        };
        const getResponsesURL = URLUtils.buildAPIURL(URL_MAPPING.article.getSortedResponses, params);
        const component = this;
        const { getResponses } = this.props;
        this.setState({responses: []}, () => {
            getResponses(getResponsesURL, function (data){
                let shown_responses = data.slice(0, MAX_RESPONSE_SHOWN_PER_PAGE);
                let currentPage = 1;
                if (isViewURL && isNotificationURL)
                    shown_responses = data;
                component.setState({
                                            responses: data,
                                            shown_responses,
                                            currentPage
                                            });
            });
        });
    };


    loadMore = () => {
        const { currentPage, responses } = this.state;
        this.setState({
            currentPage: currentPage + 1,
            shown_responses: responses.slice(0, (currentPage+1)*MAX_RESPONSE_SHOWN_PER_PAGE)
        });
    };


    componentDidMount = () => {
        this.loadResponses();
    };


    render (){
        const { boardType, sortButtons, getResponsesProcessing, highlightedObjectId, t } = this.props;
        const { responses, shown_responses, sortType} = this.state;
        const commentPrefixButtons = BUTTONS['COMMENT_PREFIX_BUTTONS'][boardType];
        let highlightedResponseId = null;
        let highlightedCommentId = null;
        // ?id=answer_392  ?id=comments-of-295-652   ?id=comment_question_652
        if (highlightedObjectId) {
            if ((highlightedObjectId.indexOf('answer') >=0) || (highlightedObjectId.indexOf('reply') >=0))
                highlightedResponseId = parseInt(highlightedObjectId.split('_')[1]);
            else if (highlightedObjectId.indexOf('comments-of-') >= 0)
                highlightedCommentId = highlightedObjectId;
        }


        return (
            <BlockingComponent isProcessing={getResponsesProcessing} >
                {
                    responses.length > 0 &&
                    <div>
                        <div className="responseTitle">
                            { (boardType === BOARD_NAME_QA) && t("Answers") }
                            { (boardType === BOARD_NAME_COMMUNITY) && t("Replies") }
                            {
                                responses.length > 0 &&
                                <span>&nbsp;({responses.length})</span>
                            }
                        </div>
                        <TableSorterTransparent sortButtons={sortButtons} sortType={sortType} selectSorter={this.selectSorterArticle}/>
                    </div>
                }


                {
                    shown_responses.map((response, idx) =>
                        <Response response={response} {...this.props} key={MiscUtils.generateId()}
                                  order={idx+1}
                                  commentPrefixButtons={commentPrefixButtons}
                                  highlightedResponseId={highlightedResponseId}
                                  highlightedCommentId={highlightedCommentId}
                        />
                    )
                }

                {
                    (shown_responses.length < responses.length) &&
                    <div className="alignCenter">
                        <button type="button" className="btn positiveBtn" onClick={this.loadMore}>{t('Load more')}</button>
                    </div>
                }

            </BlockingComponent>

        )
    }
}


const mapStateToProps = (state) => {
    return {
        // message_error: state.reducerGlobal.message_error,
        getResponsesProcessing: state.reducerResponse.getResponsesProcessing
    }
};

const mapDispatchToProps = {
    getResponses
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translate()(Responses)));