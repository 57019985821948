import { actionFinished, actionInProgress } from './actionCommon';
import { NOTIFICATION_GET_ALL, NOTIFICATION_GET_ALL__PROCESSING, NOTIFICATION_GET_ALL__FINISHED,
    NOTIFICATION_GET_UNREAD_COUNT, NOTIFICATION_GET_UNREAD_COUNT__FINISHED, NOTIFICATION_GET_UNREAD_COUNT__PROCESSING} from './types';
import { DELAY_BEFORE_REDUX_ACTION } from '../../config';
import { ApiDashboard } from '../../api';


const actionGetAll = (data) => {
    return {
        type: NOTIFICATION_GET_ALL,
        data: data
    };
};


const getAllNotification = () => {
    return dispatch => {
        dispatch(actionInProgress(NOTIFICATION_GET_ALL__PROCESSING));
        setTimeout(() => {
            const callbackSuccess = (res) => {
                dispatch(actionFinished(NOTIFICATION_GET_ALL__FINISHED, null));
                // const notification = {
                //     user: {
                //         avatar: 'https://www.joinusworld.org/static/images/avatars/6749.jpg',
                //         username: 'Daisy',
                //         userID: 123
                //     },
                //     action: 'answered your question in',
                //     object: {
                //         title: 'How can I buy a mobile phone in Seoul?',
                //         objectID: 234
                //     },
                //     datetime: 'November 8 at 10:48am'
                // };
                // let notifications = [];
                // for (let idx=0; idx<30; idx++){
                //     notifications.push(notification);
                // }
                dispatch(actionGetAll(res));
            };
            const callbackError = (error) => {
                dispatch(actionFinished(NOTIFICATION_GET_ALL__FINISHED, error.message));
            };
            ApiDashboard.getNotificationInMenubar(callbackSuccess, callbackError);
        }, DELAY_BEFORE_REDUX_ACTION);
    };
};


const actionSetAllUnreadCount = (data) => {
    return {
        type: NOTIFICATION_GET_UNREAD_COUNT,
        data: data
    };
};


const getUnreadNotificationCount = () => {
    return dispatch => {
        dispatch(actionInProgress(NOTIFICATION_GET_UNREAD_COUNT__PROCESSING));
        setTimeout(() => {
            const callbackSuccess = (res) => {
                dispatch(actionFinished(NOTIFICATION_GET_UNREAD_COUNT__FINISHED, null));
                dispatch(actionSetAllUnreadCount(res.count));
            };
            const callbackError = (error) => {
                dispatch(actionFinished(NOTIFICATION_GET_UNREAD_COUNT__FINISHED, error.message));
            };
            ApiDashboard.getUnreadNotificationCountInMenubar(callbackSuccess, callbackError);
        }, DELAY_BEFORE_REDUX_ACTION);
    };
};


const setAllUnreadCount = (data) => {
    return dispatch => {
        dispatch(actionSetAllUnreadCount(data));
    };
};


export { getAllNotification, getUnreadNotificationCount, setAllUnreadCount };