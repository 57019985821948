import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AboutUsView from './AboutUsView';
import UserGuideView from './UserGuideView';
import ContributorView from './ContributorView';
import PrivacyView from './PrivacyView';
import TOSView from './TOSView';
import FeedbackView from './FeedbackView';
import DonationView from './DonationView';

export default class NoticeView extends React.Component {
    render (){
        //const arr = this.props.location.pathname.split('/');
        //let showLarge = true;
        //if (arr[arr.length-1] === 'feedback')
        //    showLarge = false;

        //<div className={classnames({'col-md-10': showLarge}, {'col-md-8': !showLarge})} >
        return (
            <div className="col-md-9">
                <div id="noticeView" className="row centerContent">
                    <Switch> {/* only match only 1 */}
                        <Route exact path={this.props.match.path + "/introduction"} component={ AboutUsView } />
                        <Route exact path={this.props.match.path + "/contact"} component={ AboutUsView } />
                        <Route path={this.props.match.path + "/userguide"} component={ UserGuideView } />
                        <Route exact path={this.props.match.path + "/translator"} component={ ContributorView } />
                        <Route exact path={this.props.match.path + "/privacy"} component={ PrivacyView } />
                        <Route exact path={this.props.match.path + "/termOfService"} component={ TOSView } />
                        <Route exact path={this.props.match.path + "/feedback"} component={ FeedbackView } />
                        <Route exact path={this.props.match.path + "/donation"} component={ DonationView } />
                    </Switch>
                </div>
            </div>
        )
    }
}