import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Switch, Route, Redirect} from 'react-router-dom';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import SignupSuccess from './SignupSuccess';
import { login, forgotPassword } from '../../redux/actions';
import { BlockingComponent } from '../../components';
import { DEFAULT_INDEX, SIGN_UP_GET_INFO, SIGN_IN, SIGN_UP, SIGN_UP_SUCCESS } from '../../RouteURL';
import { MiscUtils, URLUtils, ACCESS_TOKEN, IS_USER_PROFILE_PROCESS } from '../../extras';
import { DELAY_BEFORE_NEXT_ACTION } from '../../config';


class LoginView extends React.Component {


    componentWillReceiveProps (nextProps){
        const { message_error } = nextProps;
        if (message_error)
            if (message_error === 'redirect_to_email_sent'){
                MiscUtils.clearErrorMessage();
                const currentURL = URLUtils.getCurrentPathname();
                if (currentURL !== SIGN_UP_SUCCESS)
                    setTimeout(() => URLUtils.moveToURL(SIGN_UP_SUCCESS), DELAY_BEFORE_NEXT_ACTION);
            }
            else
                MiscUtils.showErrorMessage(message_error);
    }


    render() {
        const {isProcessing} = this.props;
        const accessToken = MiscUtils.getFromLocalStorage(ACCESS_TOKEN);
        if (accessToken && !MiscUtils.getFromLocalStorage(IS_USER_PROFILE_PROCESS)) {
            URLUtils.moveToURL(SIGN_UP_GET_INFO);
            return;
        }

        if (accessToken){
            let nextURL = DEFAULT_INDEX;
            const nextDict = URLUtils.parseParamsInURL(this.props.location.search);
            if ('next' in nextDict)
                nextURL = nextDict.next;
            URLUtils.moveToURL(nextURL);
            return;
        }

        return (
            <div>
                <BlockingComponent isProcessing={isProcessing}>
                    <Switch> {/* only match only 1 */}
                        <Route exact path={SIGN_IN} render={(props) => (<LoginForm {...this.props}/>)} />
                        <Route exact path={SIGN_UP_SUCCESS} render={(props) => (<SignupSuccess {...this.props}/>)} />
                        <Route exact path={SIGN_UP} render={(props) => (<SignupForm {...this.props}/>)} />
                    </Switch>
                </BlockingComponent>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        // isAuthenticated: state.reducerAuth.isAuthenticated,
        // user: state.reducerAuth.user,
        userAuthenticating: state.reducerAuth.userAuthenticating,
        isProcessing: state.reducerAuth.isProcessing,
        message_error: state.reducerGlobal.message_error
    }
};

const mapDispatchToProps = {
    login,
    forgotPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginView));