import React from 'react';
import { SwitchOnOff, BlockingComponent } from '../common';
import { MiscUtils } from '../../extras';
import { MESSAGE } from "../../config";
import { ApiUser } from '../../api';
import { translate } from "react-i18next";


class NotificationSettingView extends React.Component {

    state = {
        isProcessing: false,
        notification: {
            answer: false,
            reply: false,
            vote: false,
            accepted: false,
            spam: false,
            guestbook: false,
            newsletter: false
        }
    };


    handleSwitchChange = (type, checked) => {
        const data = {};
        data[type] = checked;
        this.setState({notification: MiscUtils.updateObject(this.state.notification, data)});
        const callbackSuccess = () => {
            MiscUtils.showNotification(MESSAGE.SAVE_SUCCESS);
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        ApiUser.saveSettingNotification(callbackSuccess, callbackError, {'notification': JSON.stringify(data)});
    };


    componentDidMount() {
        const callbackSuccess = (res) => {
            this.setState({notification: res.notificationSetting, isProcessing: false});
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true}, () => ApiUser.getSettingNotification(callbackSuccess, callbackError));
    };


    render() {
        const { notification, isProcessing } = this.state;
        const { t } = this.props;
        const classLeft = "col-md-3 col-xs-6 col-sm-6";
        const classRight = "col-md-9 col-xs-6 col-sm-6";
        return (
            <BlockingComponent isProcessing={isProcessing}>
                {
                    !isProcessing &&
                    <div id="notificationSettingView">
                        <div className="row">
                            <div className={classLeft}>{t('Answer')}</div>
                            <div className={classRight}>
                                <SwitchOnOff checked={notification.answer} handleSwitchChange={(checked) => this.handleSwitchChange('answer', checked)} />
                            </div>
                        </div>
                        <hr/>

                        <div className="row">
                            <div className={classLeft}>{t('Reply')} / {t('Comment')}</div>
                            <div className={classRight}>
                                <SwitchOnOff checked={notification.reply} handleSwitchChange={(checked) => this.handleSwitchChange('reply', checked)} />
                            </div>
                        </div>
                        <hr/>

                        <div className="row">
                            <div className={classLeft}>{t('Vote')} / {t('React')}</div>
                            <div className={classRight}>
                                <SwitchOnOff checked={notification.vote} handleSwitchChange={(checked) => this.handleSwitchChange('vote', checked)} />
                            </div>
                        </div>
                        <hr/>

                        <div className="row">
                            <div className={classLeft}>{t('Accepted')}</div>
                            <div className={classRight}>
                                <SwitchOnOff checked={notification.accepted} handleSwitchChange={(checked) => this.handleSwitchChange('accepted', checked)} />
                            </div>
                        </div>
                        <hr/>

                        <div className="row">
                            <div className={classLeft}>{t('Spam')} / {t('Block')}</div>
                            <div className={classRight}>
                                <SwitchOnOff checked={notification.spam} handleSwitchChange={(checked) => this.handleSwitchChange('spam', checked)} />
                            </div>
                        </div>
                        <hr/>

                        <div className="row">
                            <div className={classLeft}>{t('Guestbook')}</div>
                            <div className={classRight}>
                                <SwitchOnOff checked={notification.guestbook} handleSwitchChange={(checked) => this.handleSwitchChange('guestbook', checked)} />
                            </div>
                        </div>
                        <hr/>

                        <div className="row">
                            <div className={classLeft}>{t('Newsletter')} / {t('Group')}</div>
                            <div className={classRight}>
                                <SwitchOnOff checked={notification.newsletter} handleSwitchChange={(checked) => this.handleSwitchChange('newsletter', checked)} />
                            </div>
                        </div>
                        <hr/>
                        {
                            [t('Notice'), t('Share'), t('Bookmark'), t('Message')].map((text) =>
                                <div key={MiscUtils.generateId()}>
                                    <div className="row">
                                        <div className={classLeft}>{text}</div>
                                        <div className={classRight}>
                                            <label className="pull-right">{t('Coming soon')}</label>
                                        </div>
                                    </div>
                                    <hr/>
                                </div>
                            )
                        }
                    </div>
                }

            </BlockingComponent>
        )
    }
}

export default translate()(NotificationSettingView);