import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import manager from '../../../static/images/icon/manager.png';
import admin from '../../../static/images/icon/admin.png';
import facebook from '../../../static/images/icon/facebook.png';
import BlockingComponent from './BlockingComponent';
import { MiscUtils, URLUtils } from '../../../extras';
import { ApiUser } from '../../../api';
import { store } from '../../../redux/store';
import FontAwesome from 'react-fontawesome';
import { setCurrentUser } from '../../../redux/actions';
import { translate } from "react-i18next";


class LeftBarUserInfo extends React.Component {

    state = {
        isProcessing: false,
        user: null
    };


    componentDidMount() {
        const { setCurrentUser } = this.props;
        const params = URLUtils.parseParamsInURL(this.props.location.search);
        const data = {
            user_id: params.user_id
        };
        const callbackSuccess = (user) => {
            if (!data.user_id) {  // if has user_id, then it is just view profile page, so no set
                setCurrentUser(user).then(() => {
                    this.setState({
                        isProcessing: false,
                        user: user
                    });
                });
            } else
                this.setState({
                    isProcessing: false,
                    user: user
                });
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true}, () => ApiUser.getProfile(callbackSuccess, callbackError, data));
    }


    render (){
        const { user, isProcessing } = this.state;
        const { t } = this.props;
        return (
            <BlockingComponent isProcessing={isProcessing}>
                {
                    user && user.user_id &&
                    <div className="userLeftBarInfo">
                        <div>
                            <img src={user.avatar_url} alt="avatar" className="img-circle img-responsive avatar" />
                        </div>
                        <div className="userInfo">
                            <p>
                                <label>{user.username}</label>
                                <span className="levelBadge pull-right">Lv {user.level}</span>
                            </p>
                            <div>{t('Activity')} <span className="pull-right">{user.activity}</span></div>
                            <div>{t('Popularity')} <span className="pull-right">{user.popularity}</span></div>
                            <div>{t('Confidence')} <span className="pull-right">{user.confidence}</span></div>
                            <hr/>

                            {
                                user.is_admin &&
                                <p><img src={admin} alt="Admin"/> <label>{t('Admin')}</label></p>
                            }

                            {
                                !user.is_admin && user.is_super_user &&
                                <p><img src={manager} alt="Manager"/> <label>{t('Manager')}</label></p>
                            }

                            {
                                user.connected_to_facebook &&
                                <p>
                                    <FontAwesome name="facebook-square" size="lg" className="snsLogo" /> &nbsp;&nbsp;<label>{t('Verified')}</label>
                                </p>
                            }


                        </div>
                    </div>
                }

            </BlockingComponent>
        )
    }
}


const mapStateToProps = (state) => {
    return {
    }
};


const mapDispatchToProps = {
    setCurrentUser
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translate()(LeftBarUserInfo)));