import { actionFinished, actionInProgress } from './actionCommon';
import { SET_CURRENT_USER, SET_AUTHENTICATING_USER, AUTH__PROCESSING, AUTH__FINISHED } from './types';
import { DELAY_BEFORE_REDUX_ACTION, MESSAGE } from '../../config';
import { MiscUtils, URLUtils, ACCESS_TOKEN, IS_USER_PROFILE_PROCESS } from '../../extras';
import { ApiAuth } from '../../api';


const actionSetCurrentUser = (authResult) => {
    return [
        {
            type: SET_CURRENT_USER,
            user: authResult ? authResult : {}
        },
        {
            type: SET_AUTHENTICATING_USER,
            user: {}
        },
    ]
};


const setCurrentUser = (user) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(actionSetCurrentUser(user));
            resolve()
        });
    }
};


const actionSetAuthenticatingUser = (user) => {
    return {
        type: SET_AUTHENTICATING_USER,
        user: user
    };
};


const setAuthenticatingUser = (user) => {
    return dispatch => {
        dispatch(actionSetAuthenticatingUser(user));
    };
};





const logout = (callback) => {
    MiscUtils.clearLocalStorage();
    return dispatch => {
        dispatch(actionSetCurrentUser(null));
        dispatch(actionSetAuthenticatingUser({}));
        callback();
    };
};


const login = (data) => {
    return dispatch => {
        dispatch(actionInProgress(AUTH__PROCESSING));
        setTimeout(() => {
            const callbackSuccess = (res) => {
                dispatch(actionFinished(AUTH__FINISHED, null));
                MiscUtils.changePrimaryLanguage(res.language_primary_code, true);
                URLUtils.setAuthorizationHeader(res.ma_session_key);
                MiscUtils.setToLocalStorage(ACCESS_TOKEN, res.ma_session_key);
                MiscUtils.setToLocalStorage(IS_USER_PROFILE_PROCESS, res.is_processed);
                dispatch(actionSetCurrentUser(res));
            };
            const callbackError = (error) => {
                dispatch(actionFinished(AUTH__FINISHED, error.message));
                dispatch(actionSetAuthenticatingUser(error.user));
            };
            ApiAuth.login(callbackSuccess, callbackError, data);
        }, DELAY_BEFORE_REDUX_ACTION);

    };
};



const connectSNS = (user, data, successCallback) => {
    return dispatch => {
        dispatch(actionInProgress(AUTH__PROCESSING));
        setTimeout(() => {
            const callbackSuccess = (res) => {
                dispatch(actionFinished(AUTH__FINISHED, null));
                let tempUser = MiscUtils.cloneObject(user);
                tempUser['connected_to_'+data.sns] = true;
                dispatch(actionSetCurrentUser(tempUser));
                if (successCallback)
                    successCallback();
            };
            const callbackError = (error) => {
                dispatch(actionFinished(AUTH__FINISHED, error.message));
                MiscUtils.showErrorMessage(error.message);
            };
            ApiAuth.connectSNS(callbackSuccess, callbackError, {data: JSON.stringify(data)});
        }, DELAY_BEFORE_REDUX_ACTION);
    };
};


const disconnectSNS = (user, sns, successCallback) => {
    return dispatch => {
        dispatch(actionInProgress(AUTH__PROCESSING));
        setTimeout(() => {
            const callbackSuccess = (res) => {
                dispatch(actionFinished(AUTH__FINISHED, null));
                let tempUser = MiscUtils.cloneObject(user);
                tempUser['connected_to_'+sns] = false;
                dispatch(actionSetCurrentUser(tempUser));
                if (successCallback)
                    successCallback();
            };
            const callbackError = (error) => {
                dispatch(actionFinished(AUTH__FINISHED, error.message));
            };
            ApiAuth.disconnectSNS(callbackSuccess, callbackError, {sns});
        }, DELAY_BEFORE_REDUX_ACTION);

    };
};


const forgotPassword = (email, callback) => {
    return dispatch => {
        dispatch(actionInProgress(AUTH__PROCESSING));
        setTimeout(() => {
            const callbackSuccess = (data) => {
                dispatch(actionFinished(AUTH__FINISHED, null));
                MiscUtils.showNotification(MESSAGE.SENT_SUCCESS);
                if (callback)
                    callback();
            };
            const callbackError = (error) => {
                dispatch(actionFinished(AUTH__FINISHED, error.message));
            };
            ApiAuth.forgotPassword(callbackSuccess, callbackError, {email});
        }, DELAY_BEFORE_REDUX_ACTION);

    };
};


const forgotPasswordSubmit = (data, callback) => {
    return dispatch => {
        dispatch(actionInProgress(AUTH__PROCESSING));
        setTimeout(() => {
            const callbackSuccess = (res) => {
                dispatch(actionFinished(AUTH__FINISHED, null));
                MiscUtils.setToLocalStorage(ACCESS_TOKEN, res.ma_session_key);
                MiscUtils.setToLocalStorage(IS_USER_PROFILE_PROCESS, res.is_processed);
                URLUtils.setAuthorizationHeader(res.ma_session_key);
                dispatch(actionSetCurrentUser(res));
                setTimeout(() => {
                    MiscUtils.showNotification(MESSAGE.SAVE_SUCCESS);
                    if (callback)
                        callback()
                }, DELAY_BEFORE_REDUX_ACTION);
            };
            const callbackError = (error) => {
                dispatch(actionFinished(AUTH__FINISHED, error.message));
            };
            ApiAuth.forgotPasswordSubmit(callbackSuccess, callbackError, data)
        }, DELAY_BEFORE_REDUX_ACTION);

    };
};


export { login, logout, setCurrentUser, setAuthenticatingUser, connectSNS, disconnectSNS, forgotPassword, forgotPasswordSubmit };