import React from 'react';
import { MiscUtils } from '../../extras';
import { Breadcrumb } from '../../components';

export default class TOSView extends React.Component {

    render() {

        const titles = ['Purpose', 'Definition of Terms', 'Effectiveness and Revision of Service Agreement', 'Working Rules Besides Agreement', 'Formation of Agreement',
            'Application for Use', 'Acceptance Application for Use and Restriction', 'Modification of Membership Information', 'Service Hours', 'Modification and Suspension of Service',
            'Provision of Information and Advertisement', "Member's Responsibilities for Posting", 'Copyright of Postings', 'Profile', 'Community',
            'Obligations of organization', 'Obligations of Member', 'Notice to Member', 'Protection and Use of Membership Information', 'Cancellation of Contract and Restriction on Use',
            'Compensation', 'General Release', 'Jurisdiction and Governing Law'];

        return (
            <div id="privacyView">
                {/*<h3 className="contentHeader">Term of Use</h3>*/}
                <Breadcrumb />
                <div className="index">
                    {
                        titles.map((title, idx) =>
                            <div key={MiscUtils.generateId()}>
                                <a href={"#article_"+(idx+1)}><button type="button" className="btn btn-sm btn-default btn-circle positiveBtn">{ idx+1 }</button> {title}</a>
                            </div>
                        )
                    }
                </div>


                <div className="privacyContent">
                    <div className="section" id="article_1">
                        <h4>Purpose</h4>
                        <p>These Terms of Service User Agreement (hereinafter referred to as “Agreement”) have the purpose of stipulating the conditions and procedures for use of services provided by JOINUS FOUNDATION LIMITED, 8TH Floor, 322-11, Haenuri town, Shinjeongdong, Yangcheongu, Seoul, Korea (hereinafter referred to as “Organization”) between the Organization and the User (or member), and rights, obligations, responsibilities and other necessary issues between the Organization and the member.</p>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_2">
                        <h4>Definition of Terms</h4>
                        <p>The definitions of terms used in this Agreement are set forth in the following items, and terms not defined herein shall be construed in accordance with applicable laws and guide for each service.</p>
                        <div>
                            <strong>1.</strong> Service :all the Internet services provided by the Organization, available for the User or the member through PC, TV, mobile and various wire/wireless devices or program including the program or the service developed or established by a third party using API released by the organization.
                        </div>
                        <div>
                            <strong>2.</strong> Member :User whose ID and password has been authorized by accessing the Service and agreeing to this Agreement.
                        </div>
                        <div>
                            <strong>3.</strong> User :a person who tries to execute the usage contract with the organization to use the Service.
                        </div>
                        <div>
                            <strong>4.</strong> Membership Information :personal information of the User including username, e-mail address, gender, date of birth, country of application, etc. that is required to fill in on the membership application form ("application form").
                        </div>
                        <div>
                            <strong>5.</strong> ID :Member’s e-mail selected by the Member and authorized by the organization for the identification of the Member and Member’s use of the Service.
                        </div>
                        <div>
                            <strong>6.</strong> Password :combination of letters and numbers designated by the Member to protect Membership Information.
                        </div>
                        <div>
                            <strong>7.</strong> Posting :signs (including URL), letters, voice, sounds, pictures (including videos), images (including photographs), and files that are posted or registered by the Member on the Service provided by the organization.
                        </div>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_3">
                        <h4>Effectiveness and Revision of Service Agreement</h4>
                        <p>These Terms of Service User Agreement (hereinafter referred to as “Agreement”) have the purpose of stipulating the conditions and procedures for use of services provided by JOINUS FOUNDATION LIMITED, 8TH Floor, 322-11, Haenuri town, Shinjeongdong, Yangcheongu, Seoul, Korea (hereinafter referred to as “Organization”) between the Organization and the User (or member), and rights, obligations, responsibilities and other necessary issues between the Organization and the member.</p>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_4">
                        <h4>Working Rules Besides Agreement</h4>
                        <p>The matters, which are not stated in this Agreement, shall be governed by applicable laws and separate user agreement on the Service, detailed guidelines and regulations stipulated by the organization.</p>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_5">
                        <h4>Formation of Agreement</h4>
                        <p>
                            <strong>1.</strong> Service usage contract shall be formed when the User selects “I agree” to this Agreement and “Privacy Policy” and applies for use of the Service by filling in the application form provided by the organization and the organization approves such application.
                        </p>
                        <p>
                            <strong>2.</strong> The User who selects “I agree” to all the terms and fills in the application form shall be considered to be duly informed of the terms and conditions of this Agreement and “Privacy Policy” and agree to follow various policies (for instance, copyright policy, spam policy, detailed service guidelines) operated by the organization and notice frequently announced in the course of using the Service.
                        </p>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_6">
                        <h4>Application for Use</h4>
                        <p>
                            <strong>1.</strong> Membership information on the application form shall be regarded as true information of the User, and the Member who has provided false information cannot be protected under the law and may be subject to restricted use of the Service.
                        </p>
                        <p>
                            <strong>2.</strong> The organization may require the e-mail verification procedure to verify the applicant.
                        </p>
                        <p>
                            <strong>3.</strong> The User under the age 14 shall have his/her legal guardian’s consent as required by the organization to use the Service.
                        </p>
                        <p>
                            <strong>4.</strong> The organization may delete the ID of the User who applies with improper purpose and/or means, for example, by using false identification (i.e., e-mail), and such User may be subject to penalties in accordance with applicable laws, such as the Act on Promotion of Information and Communications Network Utilization and Information Protection, etc.
                        </p>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_7">
                        <h4>Acceptance Application for Use and Restriction</h4>
                        <p>The organization may not accept the following applications:</p>
                        <div>
                            <strong>1.</strong> In case that Service providing is impossible in terms of technology.
                        </div>
                        <div>
                            <strong>2.</strong> In case that the information provided in the application form has falsely written, for example, by using another person’s e-mail.
                        </div>
                        <div>
                            <strong>3.</strong> In case that the required information in the application form has been omitted or miswritten.
                        </div>
                        <div>
                            <strong>4.</strong> In case that the application is made to disrupt social well-being, order or public morals.
                        </div>
                        <div>
                            <strong>5.</strong> In case that the User’s membership has been disqualified for reasons attributable to him/her; however, this excludes the case wherein the User whose membership has been disqualified for more than 6 months and whose reapplication has been accepted by the organization.
                        </div>
                        <div>
                            <strong>6.</strong> In case that other requirements specified by the organization have not been satisfied.
                        </div>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_8">
                        <h4>Modification of Membership Information</h4>
                        <p>In case that change of Membership Information written in the application form has been made while using the Service, the Member shall immediately modify the Membership Information. All the responsibilities arising out of failure to modify the Membership Information shall be borne by the Member.</p>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_9">
                        <h4>Service Hours</h4>
                        <p>
                            <strong>1.</strong> The organization shall provide the Service upon acceptance of the Member’s application, provided that parts of the Service may be provided on the designated date.
                        </p>
                        <p>
                            <strong>2.</strong> In case that the organization is not able to provide the Service due to its business or technological problems, it shall announce such matter on the Service or give such notice to the Member.
                        </p>
                        <p>
                            <strong>3.</strong> The service is provided throughout the year and daily 24 hours available in principle; however, the service may be suspended during the periods designated by the organization due to system checkup, addition of the system, replacement of the system, systematic failure, etc. In such cases, the organization shall announce the suspension through the prior/post notice to the Member.
                        </p>
                        <p>
                            <strong>4.</strong> The organization may specify the service hours according to partition of the Service into a certain range. In such case, the organization shall announce the services hours for each partitioned service.
                        </p>
                        <p>
                            <strong>5.</strong> The organization may have separate terms of use relating to individual services in the Service, and in case that the Member uses individual services for the first time, the terms of use separately applicable to such service shall be provided for separate consent thereto.
                        </p>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_10">
                        <h4>Modification and Suspension of Service</h4>
                        <p>
                            <strong>1.</strong> In case that there is substantial reason to modify the Service (including individual service), the organization may provide the modified Service after giving notice to the Member regarding the contents and effective date of the modified Service in accordance with the <strong>Compensation</strong>
                        </p>
                        <p>
                            <strong>2.</strong> The organization may restrict or suspend all or parts of the Service for the following reason. In such cases, the organization shall announce the reason for restriction or suspension in advance. However, in case that the organization is unable to serve such notice in advance for uncontrollable reasons, the organization shall announce the reason after the restriction or suspension.
                            <p className="subSection">
                                <div>1. In case that unavoidable maintenance such as expansion or repair of the service facilities occurs.</div>
                                <div>2. In case that uncontrollable incidents due to the circumstances uncontrollable by the organization happen.</div>
                                <div>3. In case that the Service cannot be normally provided due to unexpectedly excessive connection to the Service.</div>
                                <div>4. In case that the organization decides that restriction or suspension of the service is necessary according to the service operating policy, for example, for replacement with new service or transfer of individual service to another site operated by the organization.</div>
                                <div>5. In case that the service cannot be maintained due to the circumstances of the organization, such as termination of the contract with its business partners (BP).</div>
                                <div>6. In case that force majeure, such as blackout, natural disaster or national emergency happens.</div>
                            </p>
                        </p>
                        <p>
                            <strong>3.</strong> The organization may restrict certain Service provided for the Member under the age of fourteen (14), the Member protected according to the Juvenile Protection Act, the Member under the age of twenty (20) and the corporate member, and such restriction shall be separately announced in individual services.
                        </p>
                        <p>
                            <strong>4.</strong> In case that the Member is not able to use the Paid Service already been paid (this excludes the case wherein identical Service on the different site operated by the organization is available) due to suspension of the service resulting from the reason described in the item 2, 4 and 5, the organization shall provide compensation by recharging “Acorn” in accordance with the Article 14(3). However this excludes the case wherein the payment is made by the payment method provided by the business partner for using the Paid Service.
                        </p>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_11">
                        <h4>Provision of Information and Advertisement</h4>
                        <p>
                            <strong>1.</strong> The organization in operating the service may provide various kinds of information through the posting on the service screen or though mail, SMS, etc.
                        </p>
                        <p>
                            <strong>2.</strong> The organization, as to the operation of the service, may post advertisements, etc. on the service screen, mail, SMS, etc.
                        </p>
                        <p>
                            <strong>3.</strong> The Member’s communication or trade using advertisements posted on the service or participating in the promotional activities of the advertiser through the service is entirely between the Member and the advertiser. Any problem occurring between the Member and the advertiser shall be directly resolved by them, and the organization shall not take any responsibility as to such problem.
                        </p>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_12">
                        <h4>Member's Responsibilities for Posting</h4>
                        <p>
                            <strong>1.</strong> In case that the posting put up on the Service by the Member is determined to come under the following items, the organization may take necessary measures, such as temporary measures without prior notice, deletion or refusal of such posting.
                            <p className="subSection">
                                <div>1. In case that its contents slander the organization, other Members or the third party, or damage their reputations.</div>
                                <div>2. In case that the contents disrupt public order and public morals.</div>
                                <div>3. In case that the contents is determined to be associated with a criminal behavior.</div>
                                <div>4. In case that the contents of the posting infringe on another person’s rights, such as the copyright of the organization and of the third party.</div>
                                <div>5. In case that the contents cause political or religious conflicts by violating the law or infringing on another person’s rights.</div>
                                <div>6. In case that unnecessary or unauthorized advertisements or promotional materials are posted.</div>
                                <div>7. In case that the contents have been posted by using personal information of others illegally or include forged or fortified information originally written by another person.</div>
                                <div>8. In case that the posting is determined to be unfit for the purpose of the posting, for example, for being repetitive of identical contents.</div>
                                <div>9. In case that the posting is designated to be a material harmful to minors or determined to be its equivalent under the Law on the Promotion of the Use of IT Network and the Protection of Information or the Juvenile Protection Act.</div>
                                <div>10. In case that the contents are determined to be in violation of other applicable laws or detailed guidelines for individual service of the organization.</div>
                            </p>
                        </p>
                        <p>
                            <strong>2.</strong> The organization may separately establish and implement detailed guidelines for individual service, and the Member shall put up postings (including delivery among Members) in accordance with the guidelines.
                        </p>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_13">
                        <h4>Copyright of Postings</h4>
                        <p>
                            <strong>1.</strong> The copyrights to the posting put up by the Member are protected under the Copyright Act. The copyrights to the works prepared by the organization belong to the organization.
                        </p>
                        <p>
                            <strong>2.</strong> The Member shall allow the organization to use his/ her posting inside/outside the Republic of Korea for the purpose set forth in the following items.
                            <p className="subSection">
                                <p>1. To copy, transmit, or display the posting within the Service (including the case that the Service is provided by sites operated by the third party or located in a certain part of media) and to modify the posting by changing its size or simplifying it in order to show the best posting on the service screen.</p>
                                <p>2. To copy, transmit or display the posting on another site operated by the organization. However, this excludes the case wherein the Member has not given his consent.</p>
                                <p>3. To have media, press agencies, etc. report or televise all or parts of the posting to promote the service of the organization. However, in this case, the organization shall not provide Membership Information to media, press agencies, etc. without the Member’s individual consent.</p>
                            </p>
                        </p>
                        <p>
                            <strong>3.</strong> Notwithstanding the stipulation in the previous section, in case that the organization uses the posting for commercial purposes (for example, providing the posting to the third party in exchange of monetary consideration) and not for the purpose stated in each item of the previous section, it shall obtain prior consents from the Member through telephone, mail, etc. In this case, the organization shall provide separate compensation to the Member.
                        </p>
                        <p>
                            <strong>4.</strong> The Member by putting up the posting on the Service is considered to have consented to other Members’ use of the posting within the Service or the organization’s use of the posting as search results. However, the Member may take actions, such as keeping the posting private or excluding it from search results through management functions in individual service.
                        </p>
                        <p>
                            <strong>5.</strong> In case that the Member cancels the usage contract or the usage contract is cancelled in accordance with the Article 23(3), the posting recorded on such Member’s account shall be deleted. However, the posting necessary for other Members’ normal use of the Service, such as those which have been put up by scrapping or those which have been posted in Communitys, shared board, comments, etc. within the shared Service shall not be deleted.
                        </p>
                        <p>
                            <strong>6.</strong> As a matter of operational policy of the Service or in case of merge among sites operated by the organization (including the case of merge of individual service among sites) or transfer of individual service to another site operated by the organization, the organization may provide the service by changing/moving the location of the posting or sharing among sites without modifying the contents of the posting, and in case of modification, relocation or sharing of the posting, prior announcement shall be notified.
                        </p>
                        <p>
                            <strong>7.</strong> In case that the Member reposts his/her posting originally posted on his/her Profile (hereinafter referred to as “original posting”), the reposted materials and the original posting are considered different, and in this case, although the original posting is deleted, the reposted materials are subject to the provision of the <strong>(5)</strong>.
                        </p>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_14">
                        <h4>Profile</h4>
                        <p>
                            <strong>1.</strong> The Member discloses his/her username, gender information to other Members or Users through Profile Service. In addition, the Member, at his/her own discretion, may disclose additional information, such as his/her date of birth, blood type, etc.
                        </p>
                        <p>
                            <strong>2.</strong> The Member may request that the Member or the User easily visit his/her Profile using certain domain. However, the organization shall not be obliged to individually confirm whether the requesting Member has rights to use the words set forth in the following items, and the Member shall not request to use domain comprised of the words in the following items.
                            <p className="subSection">
                                <div>1. organization name of others.</div>
                                <div>2. trademark and service mark of others.</div>
                                <div>3. well-known name of the third party.</div>
                                <div>4. words against social order and public morals.</div>
                                <div>5. trademark unable to be registered under the Trademark Act.</div>
                                <div>6. words or combination of words associated with the service provided by the organization.</div>
                                <div>7. The case that it is judged to violate applicable laws or to infringe rights of the third party.</div>
                            </p>
                        </p>
                        <p>
                            <strong>3.</strong> In case that the organization recognizes that certain domain requested by the Member and issued by the organization comes under the item provided in the Article 14(2) after the fact, it may suspend use of the concerned domain or provide the Service to the concerned Member by modifying the domain name to the one similar to the original one. The domain originally used by the Member can be reissued to the third party.
                        </p>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_15">
                        <h4>Community</h4>
                        <p>
                            <strong>1.</strong> Responsibilities for the Community created through the Community service and posting on the Community put up by the Community member shall be borne by the Community or the Community member.
                        </p>
                        <p>
                            <strong>2.</strong> In case that the category designated by each Community is determined to be an inappropriate one, the organization may move such category to an appropriate one for the efficient provision of the service.
                        </p>
                        <p>
                            <strong>3.</strong> The Member may freely apply for the Membership to the Community, and acceptance of such application may be determined in accordance with the policy and bylaws of each Community.
                        </p>
                        <p>
                            <strong>4.</strong> The Community manager (the person who manages the Community and who has been designated by the organization in accordance with due procedure by the organization) shall represent the Community regardless of the bylaws or regulation of the Community and have rights to permit the Community membership of the Member and may restrict the posting unfit for the purpose of creation of the Community or operation of the service and may refuse or delete registration of such posting. In addition, the Community manager may delegate management of the Community member to operating team of the Community including vice manager of the Community.
                        </p>
                        <p>
                            <strong>5.</strong> The Community manager and operating team managing the Community member upon delegation from the Community manager shall not disclose personal information of the Community member without his/her consent in any case, and the Community manager shall take all the responsibilities resulting from the leakage of personal information of the Community Member.
                        </p>
                        <p>
                            <strong>6.</strong> In case that the Community member engages in illegal act by using the Community service, or puts up or shares postings described in the Article 12(1) and (2), the organization may take temporary measures on the concerned posting and request that the Community manager take necessary measures, such as suspension, restriction or deletion as to such act. In case that the Community manager does not immediately accept such request from the organization, the organization at its discretion may delete the concerned posting or suspend the illegal act and shall notify the Community manager after the fact.
                        </p>
                        <p>
                            <strong>7.</strong> Responsibilities for management and operation of the board and the PDS within the Community shall be borne by the Community manager and the vice manager designated by the Community manager.
                        </p>
                        <p>
                            <strong>8.</strong> As soon as the Community manager recognizes or is notified that the posting coming under the Article 12(1) and (2) is put up or shared within the Community, he/she shall delete the concerned posting.
                        </p>
                        <p>
                            <strong>9.</strong> In case that the Community comes under each of the following items, the organization may suspend operation of the Community or close the Community.
                            <p className="subSection">
                                <div>1. The case that it shares the posting that runs counter to morals or contains obscene and vulgar contents, or has been created for the purpose of sharing such posting.</div>
                                <div>2. The case that it is operated for the purpose of sharing contents against or in violation of the current law.</div>
                                <div>3. The case that its activity clearly gives disadvantages to the organization or Members.</div>
                                <div>4. The case that the problem arising out of identical cause is not resolved despite warnings or corrective measures.</div>
                                <div>5. The case that it is required to delete or suspend transmission of the posting which infringes the copyrights more than two times.</div>
                                <div>6. The case that it engages in commercial activities for the purpose of making profit through the Community without permission from the organization.</div>
                                <div>7. The case that the organization plays the role of the arbitrator due to dispute within the Community.</div>
                                <div>8. the case that new posting has not been posted for more than three (3) consecutive months or the number of Community member remains less than one (1) for more than three (3) consecutive months.</div>
                                <div>9. The case that it is designated or notified to be designated as harmful media to minors according to the Act on Promotion of Information and Communications Network Utilization and Information Protection, etc. or the Juvenile Protection Act (the case that the board within the Community is subject to this item, only the concerned board is subject to application).</div>
                            </p>
                        </p>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_16">
                        <h4>Obligations of organization</h4>
                        <p>
                            <strong>1.</strong> The organization, in order to provide the stable service, shall repair or restore any obstacle or damage occurring to the facilities without undue delay unless there are reasons for not doing so.
                        </p>
                        <p>
                            <strong>2.</strong> The organization, in order to protect Membership Information of the Member, shall establish and operate security system and announce and follow “Privacy Policy.” In addition, the organization, in dealing with Membership Information in accordance with “Privacy Policy,” shall establish and operate technological and managerial measures.
                        </p>
                        <p>
                            <strong>3.</strong> The organization shall deal with any complaints received from the Member expeditiously, and in case that it is hard for the organization to handle the matter promptly, it shall serve notice to the Member as to the reasons and the process schedule on the service screen or through mail.
                        </p>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_17">
                        <h4>Obligations of Member</h4>
                        <p>
                            <strong>1.</strong> The Member shall comply with applicable laws, this Agreement, information for use of the Service, the matter publicly announced on the Service, and the matter notified by the organization regarding use of Service and shall not engage in behaviors interrupting the organization’s business.
                        </p>
                        <p>
                            <strong>2.</strong> The Member, without organization’s explicit consent, shall not transfer or give a Member’s rights to use the Service to others, shall not make such rights available for the use by others, or shall not offer such rights as security.
                        </p>
                        <p>
                            <strong>3.</strong> The Member shall not engage in the behavior set forth in the following items.
                            <p className="subSection">
                                <div>1. The behavior writing false information when applying for use of the Service or for alteration, or using the personal information of others.</div>
                                <div>2. The behavior using the posting or information obtained while using the Service for the purpose of making profits or exposing such posting or information to the third party through publication and broadcasting, etc. However, in case that there is need for the public interests, prior consent from the organization is required.</div>
                                <div>3. The commercial activity, such as sales of products or Service using the Service (including hacking, profits through advertisement, commercial activities through obscene sites, illegal distribution of commercial software). However, this excludes the case wherein the organization officially permitted such activities.</div>
                                <div>4. The behavior hindering the organization’s operation of the Service, disrupting other Members’ use of the Service and impersonating the organization’s operating team, employee or person concerned.</div>
                                <div>5. The behavior dealing the Member’s Acorn perceptually with the third party or cashing the Member’s Acorn.</div>
                                <div>6. The behavior putting up the posting coming under the Article 12(1) and (2) or transmitting such posting.</div>
                            </p>
                        </p>
                        <p>
                            <strong>4.</strong> The Member shall thoroughly manage his/her ID and password and take responsibilities for all the results occurring due to the negligence or illicit use, and the organization shall not take any responsibility as to such matter.
                        </p>
                        <p>
                            <strong>5.</strong> The Member shall not have the third part use his/her ID or password, and in case that the ID and password are stolen or the Member recognizes the third party’s use of his/her ID or password, he/she shall notify the organization of such matter immediately and follow the organization’s guide.
                        </p>
                        <p>
                            <strong>6.</strong> The Member’s ID may interwork with the ID used by the Member in another site or the Service of the organization with consent from the Member.
                        </p>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_18">
                        <h4>Notice to Member</h4>
                        <p>
                            <strong>1.</strong> The organization may serve notice on the matter regarding rights and obligations necessary for use of the organization’s Service through the mail either issued by the organization or designated by the Member, SMS, etc.
                        </p>
                        <p>
                            <strong>2.</strong> The notice served to unspecified individual Members may be substituted with notice posted on the Service.
                        </p>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_19">
                        <h4>Protection and Use of Membership Information</h4>
                        <p>
                            <strong>1.</strong> The organization shall collect the User’s Membership Information within the minimum extent necessary to establish and implement the usage contract with lawful and fair means in accordance with “Privacy Policy”.
                        </p>
                        <p>
                            <strong>2.</strong> “Privacy Policy” of the organization shall not apply to other websites (websites not operated by the organization) linked to the organization.
                        </p>
                        <p>
                            <strong>3.</strong> In case that the administration agency, investigation agency, etc. request for the reading of the Member’s Membership information or the organization’s submission of such information in accordance with the Protection of the Communications Secrets Act, the Law on the Promotion of the Use of IT Network and the Protection of Information, etc., the organization may provide such information.
                        </p>
                        <p>
                            <strong>4.</strong> The organization shall not take any responsibility for the Membership Information exposed due to reasons attributable to the Member.
                        </p>
                        <p>
                            <strong>5.</strong> The organization, in principle, performs its duty including process and management of the Membership Information, but it may delegate all or parts of such works to another organization selected by the organization if necessary. In case of delegation of the work as to the process and management of the Membership Information, notice shall be put on “Privacy Policy”.
                        </p>
                        <p>
                            <strong>6.</strong> In case that the Member wants to withdraw his/her consent to use or provision of Membership Information given to the organization in the process of executing the Agreement, “Privacy Policy” shall apply, and in case that the Member withdraws such consent, the contract for use of Service shall be automatically cancelled.
                        </p>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_20">
                        <h4>Cancellation of Contract and Restriction on Use</h4>
                        <p>
                            <strong>1.</strong> The Member who wants to cancel the Agreement shall apply for cancellation to “Help Desk” operated by the organization.
                        </p>
                        <p>
                            <strong>2.</strong> Since in case that the Member cancels the Agreement, there is a chance that the postings are not deleted in accordance with the provision of the Article 13(5), the postings shall be deleted prior to the cancellation of the contract for use.
                        </p>
                        <p>
                            <strong>3.</strong> In case that the Member violates this Agreement and individual service agreement, the organization may restrict his/her use of the Service by giving warning, suspending and stopping the use permanently in stages or may cancel the usage contract.
                        </p>
                        <p>
                            <strong>4.</strong> The Member may file an objection to the suspension of use of service and restriction on use of other services according to the procedures stipulated by the organization, and the organization shall reopen the Service for use in case that the Member’s objection is determined be proper.
                        </p>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_21">
                        <h4>Compensation</h4>
                        <p>
                            <strong>1.</strong> In case that the damage has occurred to the Member due to the Service, the organization shall take responsibility as long as the damage occurred due to the intention or gross negligence on the organization, and the scope of responsibilities shall be limited to the ordinarily expected damage.
                        </p>
                        <p>
                            <strong>2.</strong> In case that the causes stated in the following items occur due to the intention or gross negligence of the organization and damage occurs to the Member due to such causes, the organization shall compensate for such damage as described in the following items.
                            <p className="subSection">
                                <p>1. In case of suspension of the whole service without prior notice, the organization shall compensate by extending the duration of using the limited period items to three times the amount of time the obstacles has continued. However, this excludes the case wherein the organization put prior notice on suspension of the Service due to the system checkup or addition of the system. </p>
                                <p>2. In case of damage, harm or fault on the contents purchased by the Member, he/ she will be compensated by means of restoration of the contents purchased or of refund.</p>
                            </p>
                        </p>
                        <p>
                            <strong>3.</strong> In case that the Member’s illegal act or violation of this Agreement in the course of using the Service damages the organization or that the third party claims damages against the organization or make various objection to the organization, the concerned Member with his own responsibility and at his own expenses shall indemnify the organization, and in case that the organization is not indemnified, the concerned Member shall compensate for all the damage occurred to the organization.
                        </p>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_22">
                        <h4>General Release</h4>
                        <p>
                            <strong>1.</strong> The organization shall be exempted from the responsibilities for offering the Service which is unable to be provided due to the act of God or equivalent force majeure.
                        </p>
                        <p>
                            <strong>2.</strong> The organization shall not take responsibilities for the Service interruption occurring due to reasons attributable to the Member.
                        </p>
                        <p>
                            <strong>3.</strong> The organization shall not be responsible for the Member’s inability to make profits expected from the Service provided by the organization or for damages arising out of selecting or using the postings put on the Service. In addition, the organization shall not take responsibilities for the contents in terms of accuracy of the posting put up on the site by the Member.
                        </p>
                        <p>
                            <strong>4.</strong> The organization shall not have obligation to intervene in the dispute occurring due to the Service among the Members or between the Member and the third party and shall not be liable for the damage resulting from such dispute.
                        </p>
                    </div>
                </div>

                <div className="privacyContent">
                    <div className="section" id="article_23">
                        <h4>Jurisdiction and Governing Law</h4>
                        <p>
                            <strong>1.</strong> The Member shall deal with the suggestions or solutions regarding copyright infringement as to the posting, defamation, or use of personal information and the Service through Helpdesk or Rights Protection Center.
                        </p>
                        <p>
                            <strong>2.</strong> In case of filing a suit as to the dispute arising out of use of the Service, the court stipulated by the Civil Procedure Act of the Republic of Korea shall be the competent court.
                        </p>
                        <p>
                            <div>Supplementary Provision</div>
                            <div>This Agreement shall enter into force on May, 20, 2013.</div>
                        </p>
                    </div>
                </div>


            </div>
        )
    }
}