import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RelatedArticles, PopularArticles, HowToAsk, NullComponent } from './element';


export default class RightBarOptional extends React.Component {

    render() {
        return (
            <div id="rightBarOptional">
                <Switch>
                    {/*<Route exact path="/:boardType/view/:articleId" render={(props) => (<RelatedArticles {...this.props}/>)} />*/}
                    <Route exact path="/:boardType/:articleId(\d+.+)" component={ RelatedArticles }/>

                    <Route exact path="/qa/(write|edit)/:articleId(\d+.+)?" component={ HowToAsk } />
                    <Route exact path="/(community|notice)/write" render={(props) => (<NullComponent />)} />

                    <Route exact path="/(qa|community|notice)(|/notice)" component={ PopularArticles } />
                    <Route exact path="/(qa|community)/:boardSubType" component={ PopularArticles } />
                </Switch>
            </div>
        )
    }
}
