import axios from 'axios';
import queryString from 'query-string';
import { BACKEND_URL, DELAY_BEFORE_NEXT_ACTION } from '../config';
import { DEFAULT_INDEX } from '../RouteURL';
import { store } from '../redux/store';
import { logout } from "../redux/actions";
import { MiscUtils, SELECTED_LANGUAGES } from '../extras';



export default class URLUtils {

    static getCurrentURL(obj) {
        return obj.props.history.location.pathname;
    }

    // if not has props
    static getCurrentPathname() {
        return window.location.pathname;
        // host: "localhost:3000"
        // hostname: "localhost"
        // href: "http://localhost:3000/accounts/login?id=abc"
        // search: "id=abc"
        // origin: "http://localhost:3000"
        // pathname: "/accounts/login"
        // port: "3000"
        // protocol: "http:"
    }


    // if not has props
    static getFullURL() {
        return window.location.href;
    }


    static getParamInCurrentURL(id) {
        const paramsDict = this.parseParamsInURL(window.location.search);
        return paramsDict[id] ? paramsDict[id] : null;
    }


    static setAuthorizationHeader(token) {
        //axios.defaults.headers.common['is_api'] = true;
        //axios.defaults.headers.common['Isapi'] = true;
        // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        if (token)
            axios.defaults.headers.common['Authorization'] = token;
        else
            delete axios.defaults.headers.common['Authorization'];
    }

    static makeHttpRequest(methodOriginal, urlOriginal, callbackSuccess, callbackError, data, customHeaders) {
        const method = methodOriginal.toLowerCase();
        let url = urlOriginal;
        let config = {
            method: method,
            url: url
        };
        // https://timonweb.com/posts/make-drf-and-axios-work-nicely-together/
        if (data) {
            const paramsTxt = queryString.stringify(data);
            if (method === 'get')
                config['url'] = url + '?' + paramsTxt;
            else if (method === 'post')
                config['data'] = paramsTxt; // for django backend
        }
        let headers = {'Content-type': 'application/x-www-form-urlencoded', 'Accept': 'application/json'};
        if (customHeaders)
            headers = customHeaders;
        config['headers'] = headers;
        axios(config).then(
            (res) => {
                const { data } = res;
                if (Array.isArray(data)) {
                    callbackSuccess(data);
                    return;
                }
                if ((['ok', 'success'].indexOf(data.message) >=0) || (['ok', 'success'].indexOf(data.status) >=0))
                    callbackSuccess(data);
                else
                    callbackError(data);
            },
            (err) => {callbackError(err); }
        );
    }

    static buildAPIURL(url, paramsDict) {
        let tempURL = BACKEND_URL + '/api' + url;
        if (paramsDict)
            tempURL = tempURL + '?' + queryString.stringify(paramsDict);
        return tempURL;
    }

    // static isShowRightBar(url) {
    //     const listURLShowRightBar = ['qa', 'community', 'notice', 'ranking'];
    //     const listSubURLShowRightBar = {
    //         'qa' : ['/write'],
    //         'community' : ['/write'],
    //         'notice' : ['/write'],
    //         'ranking' : ['/write']
    //     };
    //
    //     if (this.isViewURL(url))
    //         return true;
    //
    //     //  /qa/write
    //     const arr = url.split('/');
    //
    //     const boardType = arr[1];
    //     if ((listURLShowRightBar.indexOf(boardType) < 0) || (arr.length < 3))
    //         return false;
    //
    //     const subURL = arr.slice(2, arr.length-1).join('/');
    //     return (listSubURLShowRightBar[boardType].indexOf(subURL) >=0);
    //
    // }


    static parseParamsInURL(url) {
        return queryString.parse(url);
    }

    static reloadPage() {
        window.location.reload();
    }

    static backToPreviousPage() {
        window.history.back();
    }

    static getSubCategoryOfBoardFromURL(component) {
        const arr = this.getCurrentURL(component).split('/');
        let subCategory = arr[arr.length - 1];
        if (subCategory == 'qa')
            subCategory = 'faq';
        else if ((subCategory == 'community') || (subCategory == 'notice'))
            subCategory = 'main';
        return subCategory;
    }

    static moveToURL(url) {
        window.location.href = url;
    }

    static userLogout(props, nextURL) {
        store.dispatch(logout(() => {
            MiscUtils.setToLocalStorage(SELECTED_LANGUAGES, MiscUtils.getDefaultLanguages());
            if (URLUtils.getCurrentPathname() !== DEFAULT_INDEX){
                props.history.push(nextURL ? nextURL : DEFAULT_INDEX);
                // URLUtils.moveToURL(DEFAULT_INDEX); : ko xai, vi se show login form roi moi move
            }
            setTimeout(() => URLUtils.reloadPage(), DELAY_BEFORE_NEXT_ACTION);
        }));
        MiscUtils.clearErrorMessage();
    }

    static isViewURL() {
        const re = new RegExp("(qa|community|notice)\/[0-9]+.*");
        return re.test(URLUtils.getCurrentPathname());
    }


    static format_text_for_sns_forwarding(text, len) {
        const temp = text.replace(/(<([^>]+)>)/ig,"");
        if (temp.length > len)
            return temp.substring(0, len) + '...';
        else
            return temp;
    }


}
