import { GLOBAL_NOTIFICATION_MESSAGE_ERROR, GLOBAL_NOTIFICATION_MESSAGE_ERROR_CLEAR,
    GLOBAL_SET_BREADCUMBS, GLOBAL_CLEAR_BREADCUMBS} from '../actions/types';
import { MiscUtils } from '../../extras';

const initialState = {
    message_error: null,
    breadcumbs: null
};

export default (state = initialState, action = {}) => {
    switch (action.type){
        case GLOBAL_NOTIFICATION_MESSAGE_ERROR:
            return MiscUtils.updateObject(state, {message_error: action.message});
        case GLOBAL_NOTIFICATION_MESSAGE_ERROR_CLEAR:
            return MiscUtils.updateObject(state, {message_error: null});
        case GLOBAL_SET_BREADCUMBS:
        case GLOBAL_CLEAR_BREADCUMBS:
            return MiscUtils.updateObject(state, {breadcumbs: action.breadcumbs});
        default:
            return state;
    }
}