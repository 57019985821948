import React from 'react';
import { Link } from 'react-router-dom';
import { UserAvatarInfo, TagList, } from '../element';

export default class TableContentCommentElement extends React.Component {

    render() {
        const { comment } = this.props;
        return (
            <div className="dataRow">
                <div className="row1">
                    <label className="languageBadge">{comment.language}</label>
                    <TagList tags={comment.tags} isHideEditButton={true}/>
                </div>

                <div className="row">
                    <div className="col-md-10">
                        <div>
                            {/*<strong className="articleTitle"><Link to={'/'+boardType+'/view/'+comment.article_id}>{comment.article_title}</Link></strong>*/}
                            <strong className="articleTitle"><Link to={comment.article_view_url}>{comment.article_title}</Link></strong>
                        </div>
                        <UserAvatarInfo user={comment.user} />


                        <div>
                            {comment.comment_content}
                        </div>
                        <div>
                            {comment.parent_content}
                        </div>
                    </div>
                    {/*<div className="col-md-2">*/}
                        {/*<img src={comment.article_thumbnail} alt="" className="img-rounded img-responsive"/>*/}
                    {/*</div>*/}
                </div>

                <div className="articleInfo marginTopMedium">
                    <div className="leftInfo">
                        <label>{comment.last_modified}</label>
                        {/*<label>Vote <span className="numStatsCount">{comment.votes}</span></label>*/}
                    </div>
                    {/*<div className="rightInfo">*/}
                        {/*<label>Comment <span className="numStatsCount">{comment.count_comment}</span></label>*/}
                        {/*<label>*/}
                            {/*<FontAwesome name='facebook'/>*/}
                            {/*<FontAwesome name='twitter'/>*/}
                        {/*</label>*/}
                    {/*</div>*/}

                </div>

            </div>

        );
    }
}