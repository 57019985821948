import React from 'react';
import { Redirect } from 'react-router-dom';
import { Glyphicon } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import { UserAvatarInfo, UserAvatar, TagList, SourceList, HTMLContent, DropDownMenu, Comments, NamecardInResponse,
            PopupConfirm, SNSSharingBtn, LanguageArticleSettingButton, ReactionButton} from '../common';
import { BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_NOTICE, BUTTONS, ARTICLE_TYPE,
    ERROR_MESSAGE, CONTENT_BLOCKED_MESSAGE, CLASS_HIDDEN_IN_MOBILE, RESPONSE_TYPE_IN_BACKEND,
    DELAY_BEFORE_RELOAD_PAGE_AFTER_NOTIFICATION, MESSAGE, MAX_WORDS_IN_CONTENT_SHOW_READ_MORE } from '../../config';
import ResponseWrite from './ResponseWrite';
import ResponsesAccepted from './ResponsesAccepted';
import ResponseWriteInMobile from './ResponseWriteInMobile';

import { ApiArticle } from '../../api';
import { MiscUtils, URLUtils } from '../../extras';
import { translate } from "react-i18next";
import calendar from '../../static/images/icon/calendar.png';
import { SETTING, SIGN_IN } from '../../RouteURL';


class Article extends React.Component {

    state = {
        isShowDeleteConfirm: false,
        redirectToLogin: false,
        isShowCommentList: false,
        comments: this.props.article.comment_to_q_data,
        isVoted: this.props.article.is_voted,
        voteCount: this.props.article.count_vote,
        isBookmarked: this.props.article.is_bookmarked,
        bookmarkCount: this.props.article.count_bookmark,
        shareCount: this.props.article.count_share,
        isBlocked: this.props.article.is_blocked,
        isReported: this.props.article.is_reported,
        isEvent: this.props.article.is_event,
        isAttendedByUser: this.props.article.is_attended_by_user,
        isShowAttendEventConfirm: false,
        isShowCancelEventConfirm: false,
        languageCode: this.props.article.language_code,
        isShowReadMore: this.props.article.content.split(" ").length > MAX_WORDS_IN_CONTENT_SHOW_READ_MORE,
        isShowActionList: false
        // neu login roi ko hide content thi xai cai nay
        //isShowReadMore: (!this.props.currentUser.user_id) && (this.props.article.content.split(" ").length > MAX_WORDS_IN_CONTENT_SHOW_READ_MORE)
    };


    toggleShowCommentList = () => {
        this.setState({isShowCommentList: !this.state.isShowCommentList});
    };


    toggleShowAttendEventConfirm = () => {
        this.setState({isShowAttendEventConfirm: !this.state.isShowAttendEventConfirm});
    };


    // https://css-tricks.com/text-fade-read-more/
    toggleShowReadMore = () => {
        // neu login roi ko hide content thi xai cai nay
        // const currentURL = URLUtils.getCurrentPathname();
        // URLUtils.moveToURL(`${SIGN_IN}?next=${currentURL}`);

        // neu login roi van hide content thi dung cai nay
        this.setState({isShowReadMore: !this.state.isShowReadMore});
        // const { currentUser } = this.props;
        // if (currentUser.user_id)
        //     this.setState({isShowReadMore: !this.state.isShowReadMore});
        // else {
        //     const currentURL = URLUtils.getCurrentPathname();
        //     URLUtils.moveToURL(SIGN_IN+"?next="+currentURL);
        // }
    };


    addCommentSuccessCallback = (comment) => {
        this.setState(prevState => ({
            comments: [...prevState.comments, comment]
        }));
    };


    onShareCallback = () => {
        this.setState({shareCount: this.state.shareCount + 1});
    };


    toggleShowActionList = () => {
        this.setState({isShowActionList: !this.state.isShowActionList});
    };


    voteArticle = () => {
        const { article, boardType, currentUser, t } = this.props;
        if (currentUser.user_id === article.user.user_id) {
            MiscUtils.showErrorMessage(t('You cannot vote your article'));
            return;
        }
        let article_type = ARTICLE_TYPE.POST;
        if (boardType === BOARD_NAME_QA)
            article_type = ARTICLE_TYPE.QUESTION;
        const data = {
            article_id: article.article_id,
            article_type: article_type
        };
        const callbackSuccess = (data) => {
            // const { isVoted, voteCount } = this.state;
            // let newCount = voteCount;
            // if (isVoted)
            //     newCount -= 1;
            // else
            //     newCount += 1;
            // this.setState({
            //     isVoted: !this.state.isVoted,
            //     voteCount: newCount
            // })
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        ApiArticle.voteArticle(callbackSuccess, callbackError, data);

        // made it instantly
        const { isVoted, voteCount } = this.state;
        let newCount = voteCount;
        if (isVoted)
            newCount -= 1;
        else
            newCount += 1;
        this.setState({
            isVoted: !this.state.isVoted,
            voteCount: newCount
        })
    };


    bookmarkArticle = () => {
        const { article, boardType, currentUser, t } = this.props;
        if (currentUser.user_id === article.user.user_id) {
            MiscUtils.showErrorMessage(t('You cannot bookmark your article'));
            return;
        }
        let article_type = ARTICLE_TYPE.POST;
        if (boardType === BOARD_NAME_QA)
            article_type = ARTICLE_TYPE.QUESTION;
        const data = {
            article_id: article.article_id,
            article_type: article_type
        };
        const callbackSuccess = (data) => {
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        ApiArticle.bookmarkArticle(callbackSuccess, callbackError, data);

        // made it instantly
        const { isBookmarked, bookmarkCount } = this.state;
        let newCount = bookmarkCount;
        if (isBookmarked)
            newCount -= 1;
        else
            newCount += 1;
        this.setState({
            isBookmarked: !this.state.isBookmarked,
            bookmarkCount: newCount
        })

    };


    editArticle = () => {
        const { article, boardType } = this.props;
        URLUtils.moveToURL('/'+boardType+'/edit/'+article.article_id);
    };


    deleteArticle = () => {
        const { article, boardType } = this.props;
        let article_type = ARTICLE_TYPE.POST;
        if (boardType === BOARD_NAME_QA)
            article_type = ARTICLE_TYPE.QUESTION;
        const data = {
            article_id: article.article_id,
            article_type: article_type
        };
        const callbackSuccess = (data) => {
            URLUtils.moveToURL('/'+boardType);
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        ApiArticle.deleteArticle(callbackSuccess, callbackError, data);
    };


    toggleShowDeleteConfirm= () => {
        this.setState({isShowDeleteConfirm: !this.state.isShowDeleteConfirm});
    }

    blockArticle = () => {
        this.toggleShowActionList();
        const { article, boardType } = this.props;
        let article_type = ARTICLE_TYPE.POST;
        if (boardType === BOARD_NAME_QA)
            article_type = ARTICLE_TYPE.QUESTION;
        const data = {
            article_id: article.article_id,
            article_type: article_type,
            block_type: 2 // fix REDUNDANCY
        };
        const callbackSuccess = (data) => {
            // this.setState({isBlocked: !this.state.isBlocked});
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        ApiArticle.blockArticle(callbackSuccess, callbackError, data);
        // made it instantly
        this.setState({isBlocked: !this.state.isBlocked});
    };


    reportArticle = () => {
        this.toggleShowActionList();
        const { article, boardType } = this.props;
        let article_type = ARTICLE_TYPE.POST;
        if (boardType === BOARD_NAME_QA)
            article_type = ARTICLE_TYPE.QUESTION;
        const data = {
            article_id: article.article_id,
            article_type: article_type,
            report_type: 2 // fix SPAM_TYPE_REDUNDANCY
        };
        const callbackSuccess = (data) => {
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        ApiArticle.reportArticle(callbackSuccess, callbackError, data);
        // made it instantly
        this.setState({isReported: !this.state.isReported});
    };



    // for Event
    saveResponse = (isJoined) => {
        const txtContent = isJoined ? " has joined here!" : " has cancelled!";
        const txtClass = isJoined ? "viewing_attend_event_auto_reply" : "viewing_cancel_event_auto_reply";
        const { article, boardType, currentUser} = this.props;
        const data = {
            parent_id: article.article_id,
            response_content: "<span class='"+txtClass+"'>"+ currentUser.username + txtContent +"</span>",
            response_type: RESPONSE_TYPE_IN_BACKEND[boardType.toUpperCase()]
        };
        if (isJoined)
            data['is_attended'] = '1';
        else
            data['is_cancelled'] = '1';
        const callbackSuccess = (res) => {
            setTimeout(() => URLUtils.reloadPage(), DELAY_BEFORE_RELOAD_PAGE_AFTER_NOTIFICATION);
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        this.setState({isProcessing: true}, () => ApiArticle.saveResponse(callbackSuccess, callbackError, data));
    };


    attendEvent = () => {
        const { currentUser, t } = this.props;
        if (!currentUser.user_id) {
            MiscUtils.showErrorMessage(t('Please login to continue!'));
            return;
        }
        this.toggleShowAttendEventConfirm();
    };


    attendEventConfirm = () => {
        const { article, t } = this.props;
        const data = {
            article_id: article.article_id
        };
        const callbackSuccess = (data) => {
            this.saveResponse(true);
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        ApiArticle.attendEvent(callbackSuccess, callbackError, data);
        // made it instantly
        MiscUtils.showNotification(t('Thank you for your attending. See you soon in the event :)'));
        this.setState({
            isAttendedByUser: true,
            isShowAttendEventConfirm: false
        });
    };




    toggleShowCancelEventConfirm = () => {
        this.setState({isShowCancelEventConfirm: !this.state.isShowCancelEventConfirm});
    };


    cancelEvent = () => {
        const { currentUser, t } = this.props;
        if (!currentUser.user_id) {
            MiscUtils.showErrorMessage(t('Please login to continue!'));
            return;
        }
        this.toggleShowCancelEventConfirm();
    };


    cancelEventConfirm = () => {
        const { article, t } = this.props;
        const data = {
            article_id: article.article_id
        };
        const callbackSuccess = (data) => {
            this.saveResponse(false);
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        ApiArticle.cancelEvent(callbackSuccess, callbackError, data);
        // made it instantly
        MiscUtils.showNotification(t('Opps, it is so sorry that you cannot join with us. See you next time :)'));
        this.setState({
            isAttendedByUser: false,
            isShowCancelEventConfirm: false
        });
    };

    // for Event : END



    saveSelectLanguageCallback = (languages) => {
        const { article, boardType} = this.props;
        const newLanguageCode = languages[0];
        const data = {
            article_id: article.article_id,
            type: boardType,
            lang_code: newLanguageCode
        };
        const callbackSuccess = (data) => {
            this.setState({languageCode: newLanguageCode});
            MiscUtils.showNotification(MESSAGE.SAVE_SUCCESS);
        };
        const callbackError = (error) => {
            MiscUtils.commonCallbackError(this, error);
        };
        ApiArticle.changeLanguageWhenViewing(callbackSuccess, callbackError, data);
    };


    componentDidMount() {
        MiscUtils.initElementArticleContent();
        const { highlightedObjectId, response } = this.props;
        if (highlightedObjectId && (highlightedObjectId.indexOf('comment_question_') >=0)){  // id=comment_question_xxx
            this.setState({isShowCommentList: true});
        }
    }


    render (){
        const { boardType, article, currentUser, highlightedObjectId, t } = this.props;
        const { isShowCommentList, comments, redirectToLogin, isVoted, voteCount, isBookmarked, bookmarkCount, isBlocked, isReported,
            isEvent, isAttendedByUser, isShowAttendEventConfirm, isShowCancelEventConfirm, languageCode, isShowReadMore, shareCount,
            isShowActionList, isShowDeleteConfirm} = this.state;
        const isMobileView = MiscUtils.isMobileView();
        if (redirectToLogin)
            return <Redirect to={SIGN_IN + '?next=' + URLUtils.getCurrentPathname()}/>;

        const commentPrefixButtons = BUTTONS['COMMENT_PREFIX_BUTTONS']['comment_to_qa'];

        const editBtn = (
            <p onClick={this.editArticle}>{t('Edit')}</p>
        );
        const blockBtn = (
            <p onClick={this.blockArticle}>{isBlocked ? t('Unblock') : t('Block')}</p>
        );
        const deleteBtn = (
            <p onClick={() => {
                this.toggleShowActionList();
                this.toggleShowDeleteConfirm();
            }}>{t('Delete')}</p>
        );
        const reportBtn = (
            <p onClick={this.reportArticle}>{isReported ? t('Unreport spam') : t('Report spam')}</p>
        );

        let articleAction = null;
        if (currentUser.user_id){
            if (currentUser.is_admin)
                articleAction = (
                    <div className="tooltipActionButton">
                        { blockBtn }
                        {
                            (currentUser.user_id === article.user.user_id) &&
                            editBtn
                        }
                        { deleteBtn }
                        {
                            (currentUser.user_id !== article.user.user_id) &&
                            reportBtn
                        }
                    </div>
                );
            else if (currentUser.user_id === article.user.user_id)
                articleAction = (
                    <div className="tooltipActionButton">
                        {
                            !isBlocked &&
                            editBtn
                        }
                        { deleteBtn }
                    </div>
                );
            else
                articleAction = (
                    <div className="tooltipActionButton">
                        { reportBtn }
                    </div>
                );
        }


        let highlightedCommentId = null;
        if (highlightedObjectId && (highlightedObjectId.indexOf('comment_question_') >= 0))
            highlightedCommentId = highlightedObjectId;

        const showAttendEventMessage = t('Are you sure to join this event?');
        const showCancelEventMessage = t('Are you sure to cancel?');

        return (
            <div id="article">

                <h1 className="title">
                    <strong style={{display: "flex"}}>
                        { article.announcement && <FontAwesome name="bullhorn"/>}
                        { article.solved && <FontAwesome name="check"/>}
                        <HTMLContent content={article.article_title_with_new_line}/>
                    </strong>

                </h1>

                <div className="infoDate">
                    <span className={CLASS_HIDDEN_IN_MOBILE}>{article.created_date} &nbsp;|&nbsp; </span><span>{t('Last modified')}: {article.modified_date}</span>
                    <span className="pull-right">{t('View')} <span className="numStatsCount">{article.count_view}</span></span>
                </div>

                <div className="alignRight marginNoneLeftRight marginTop10">
                    <SNSSharingBtn url={article.view_url_absolute} boardType={boardType}
                                   articleTitle={article.article_title} articleId={article.article_id}
                                   onShareCallback={this.onShareCallback}
                    />
                </div>

                <hr/>
                <div className="row1 displayFlex">

                    {
                        !currentUser.is_super_user &&
                        <label className="languageBadge">{article.language}</label>
                    }
                    {
                        currentUser.is_super_user &&
                        <LanguageArticleSettingButton selectedLanguage={languageCode}
                                                      saveSelectLanguageCallback={this.saveSelectLanguageCallback} />
                    }

                    <div className="tagListWrapper">
                        <TagList tags={article.tags} article={article} />
                    </div>
                </div>
                <div className="row1 userInfo" style={{display: 'flex'}}>
                    <div className="col-xs-10 col-sm-10 col-md-10 section">
                        <UserAvatarInfo user={article.user} isAnonymous={article.is_anonymous}/>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 section">
                        <div className="voteNum">
                            <button type="button"
                                    className={classnames("btn btn-default btn-circle btn-lg actionButton noVote", {"isVoted": isVoted})}
                                    data-rh={isVoted ? t("Unvote") : t("Vote")}
                                    onClick={this.voteArticle}>
                                {voteCount}
                            </button>
                            <p>{t('votes')}</p>
                        </div>
                    </div>


                </div>
                <div className="content dontBreakOut">
                    {
                        article.is_event &&
                        <div className="eventOptions marginBottom20">
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="eventTitle">Date</td>
                                        <td className="eventContent">{article.event_options.event_date} {article.event_options.event_time} GMT:{article.event_options.event_time_gmt}</td>
                                    </tr>
                                    <tr>
                                        <td className="eventTitle">Place</td>
                                        <td className="eventContent">{article.event_options.event_place}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                    <div className={classnames({"continueReading" : isShowReadMore})}>
                        <HTMLContent content={article.content}/>
                        {
                            isShowReadMore &&
                            <p className="readMore">
                                <button className="outlineNegativeText" onClick={this.toggleShowReadMore}>{t('Read more')}</button>
                            </p>
                        }
                    </div>
                </div>

                {
                    !article.is_anonymous &&
                    <NamecardInResponse user={article.user} {...this.props} />
                }


                {
                    isBlocked &&
                    <div className='errorText alignCenter'>{CONTENT_BLOCKED_MESSAGE}</div>
                }


                {
                    isEvent && !currentUser.is_able_to_attend_event &&
                    <div className="info_required_for_event marginTop20">
                        <img src={calendar}></img>
                        <span>{t('In order to attend this event, you must fill in the following information at your profile')}:
                            <strong>
                                <a href={SETTING} target="_blank">{t('contact')}</a>
                                <span>({t('Only accessible by the event manager')})</span>
                            </strong>.
                        </span>
                    </div>
                }


                {   (boardType !== BOARD_NAME_QA) && (article.sources.length > 0) &&
                    <SourceList sources={article.sources}/>
                }


                {/*<div className={classnames("infoAction marginTopMedium", {'marginLeft30 marginRight40': isMobileView})}>*/}

                <div className="infoAction marginTopMedium">
                    <div className="item">
                        <ReactionButton boardType={boardType} article={article} />
                    </div>
                </div>




                <div className="infoAction marginTopMedium row">

                    {/*{*/}
                        {/*(boardType !== BOARD_NAME_NOTICE) &&*/}
                        {/*<button type="button" className="btn outlineBtn">*/}
                            {/*<Glyphicon glyph="pencil" />&nbsp;*/}
                            {/*{ (boardType === BOARD_NAME_QA) && "Answer" }*/}
                            {/*{ (boardType === BOARD_NAME_COMMUNITY) && "Reply" }*/}
                        {/*</button>*/}
                    {/*}*/}


                    <div className={classnames({"col-md-3 col-xs-3": boardType === BOARD_NAME_QA}, {"col-md-4": boardType !== BOARD_NAME_QA})}>
                        <span className="item">
                            <FontAwesome name="heart-o"/> {!isMobileView && t('Follow')} <span className="numStatsCount">{bookmarkCount}</span>
                        </span>
                    </div>

                    {   (boardType === BOARD_NAME_QA) &&
                        <div className="col-md-3  col-xs-3 alignCenter">
                            <span className="item actionButton collapsibleButton" onClick={this.toggleShowCommentList}>
                                <FontAwesome name="comment-o"/> {!isMobileView && t('Comment')} <span className="numStatsCount">{comments.length}</span>
                            </span>
                        </div>
                    }


                    <div className={classnames({"alignRight col-md-3 col-xs-3": boardType === BOARD_NAME_QA}, {"alignCenter col-md-4": boardType !== BOARD_NAME_QA})}>
                        <span className="item">
                            <FontAwesome name="share-square-o"/> {!isMobileView && t('Share')} <span className="numStatsCount">{shareCount}</span>
                        </span>
                    </div>




                    <div className={classnames("alignRight", {"col-md-3 col-xs-3": boardType === BOARD_NAME_QA}, {"col-md-4": boardType !== BOARD_NAME_QA})}>
                    {/*<div className="alignRight col-md-6">*/}
                        <button type="button"
                                className={classnames("btn outlineBtn item", {"isBookmarked": isBookmarked})}
                                data-rh={isBookmarked ? t("Unbookmark") : t("Bookmark")} onClick={this.bookmarkArticle}>
                            <Glyphicon glyph="heart-empty" />
                        </button>

                        {
                            (articleAction) &&
                            <DropDownMenu htmlContent={articleAction} customClassName="pull-right" isShown={isShowActionList} clickOutsideWhenManual={this.toggleShowActionList}>
                                <Glyphicon glyph="option-horizontal"  onClick={this.toggleShowActionList}/>
                            </DropDownMenu>
                        }
                    </div>

                </div>



                {
                    (boardType === BOARD_NAME_COMMUNITY) && isEvent && currentUser.is_able_to_attend_event &&
                    <div>
                        {
                            !isAttendedByUser &&
                            <div className="alignCenter">
                                <button type="button" className="btn positiveBtn" onClick={this.attendEvent}
                                        data-tip data-for='attendEventBtn'
                                >
                                    <Glyphicon glyph="plus-sign"/> &nbsp;
                                    {t('Join Us')}
                                </button>
                                <ReactTooltip id='attendEventBtn' className="tooltipForBtn" effect='solid'>
                                    {t('I will attend this event')}
                                </ReactTooltip>
                                <PopupConfirm bodyText={showAttendEventMessage} onConfirm={this.attendEventConfirm}
                                              showModal={isShowAttendEventConfirm} toggleShowModal={this.toggleShowAttendEventConfirm}/>
                            </div>
                        }
                        {
                            isAttendedByUser &&
                            <div className="alignCenter">
                                <button type="button" className="btn negativeBtn" onClick={this.cancelEvent}
                                        data-tip data-for='cancelEventBtn'
                                >
                                    <Glyphicon glyph="remove"/> &nbsp;
                                    {t('Cancel')}
                                </button>
                                <ReactTooltip id='cancelEventBtn' className="tooltipForBtn" effect='solid'>
                                    {t('I cannot attend this event')}
                                </ReactTooltip>
                                <PopupConfirm bodyText={showCancelEventMessage} onConfirm={this.cancelEventConfirm}
                                              showModal={isShowCancelEventConfirm} toggleShowModal={this.toggleShowCancelEventConfirm}/>
                            </div>
                        }
                        {
                            (article.event_attendee_user_list.length > 0) &&
                            <div className="boxWrapper marginTop20 eventAttendeeList">
                                <h5><strong>{t('Attendees')}</strong></h5>
                                <div className="displayFlex">
                                    {
                                        article.event_attendee_user_list && article.event_attendee_user_list.map((user) =>
                                            <UserAvatar user={user} key={MiscUtils.generateId()}/>
                                        )
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }


                <div className="commentToQuestion responseView">
                    <Comments isShowCommentList={isShowCommentList}
                              highlightedCommentId={highlightedCommentId}
                              comments={comments}
                              commentPrefixButtons={commentPrefixButtons}
                              parentType={ARTICLE_TYPE.ARTICLE}
                              parentId={article.article_id}
                              addCommentSuccessCallback={this.addCommentSuccessCallback}
                              isBlocked={isBlocked}
                              {...this.props} />
                </div>

                {
                    (boardType === BOARD_NAME_QA) && (article.top_responses_data.length >0) &&
                    <ResponsesAccepted {...this.props} />
                }


                {
                    (boardType !== BOARD_NAME_NOTICE) && (!isBlocked) &&
                    <div>
                        {!isMobileView && <ResponseWrite {...this.props} />}
                        {isMobileView && <ResponseWriteInMobile {...this.props} />}
                    </div>
                }

                <PopupConfirm bodyText={t("Are you sure to delete?")} onConfirm={this.deleteArticle}
                              showModal={isShowDeleteConfirm} toggleShowModal={this.toggleShowDeleteConfirm}/>

            </div>
        )
    }
}


export default translate()(Article);
