import React from 'react';
import classnames from 'classnames';
import { Glyphicon } from 'react-bootstrap';
import { slide as Menu } from 'react-burger-menu';
import { CLASS_HIDDEN_IN_PC, BUTTONS, BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_NOTICE, BOARD_NAME_RANK } from '../../../config';
import { SIGN_IN, DASHBOARD_NOTIFICATION, PROFILE, DASHBOARD, SETTING, SEARCH, MAINPAGE_FREETALK } from '../../../RouteURL';
import { MiscUtils, URLUtils, CURRENT_SUB_BOARD, CURRENT_BOARD } from '../../../extras';
import Collapsible from 'react-collapsible';
import CollapsibleButton from './CollapsibleButton';
import { translate } from "react-i18next";
import i18n from "../../../locales/i18n";


let MENU_STATE = {};
MENU_STATE[BOARD_NAME_NOTICE] = false;
MENU_STATE[BOARD_NAME_QA] = false;
MENU_STATE[BOARD_NAME_COMMUNITY] = false;
MENU_STATE[BOARD_NAME_RANK] = false;



class SideMenu extends React.Component {

    state = {
        keyword: '',
        isOpen: false,
        board: null,
        subBoard: null,
        isSectionMenuOpen: MiscUtils.cloneObject(MENU_STATE)
    };


    userLogout = (e) => {
        this.setState({isOpen: false}, () => this.props.userLogout(e));
    };


    handleStateChange = (state) => {
        this.setState({isOpen: state.isOpen});
    };


    boardSubtypeButtonClick = (board, subBoard) => {
        this.setState({board, subBoard, isOpen: false});
        MiscUtils.setToLocalStorage(CURRENT_BOARD, board);
        MiscUtils.setToLocalStorage(CURRENT_SUB_BOARD, subBoard);
        // this.props.history.push('/'+board+'/'+subBoard);
        URLUtils.moveToURL('/'+board+'/'+subBoard);
    };


    toggleSectionMenuOpen = (board) => {
        // let data = MiscUtils.cloneObject(this.state.isSectionMenuOpen); // can open multiple section
        let data = MiscUtils.cloneObject(MENU_STATE); // close all
        data[board] = !this.state.isSectionMenuOpen[board];
        this.setState({isSectionMenuOpen: data});
    };


    // changeSearchQuery = (e) => {
    //     this.setState({keyword: e.target.value});
    // };
    //
    //
    // onSearchEnter = (e) => {
    //     const {keyword} = this.state;
    //     MiscUtils.handleEnterKeyPress(e, () => URLUtils.moveToURL(SEARCH+'?board='+BOARD_NAME_QA+'&query='+keyword));
    // };


    render (){
        const {isAuthenticated, user, unreadCount, t} = this.props;
        const { isOpen, isSectionMenuOpen, subBoard, keyword } = this.state;

        // let boardType = MiscUtils.getFromLocalStorage(CURRENT_BOARD);
        // if ([BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_NOTICE].indexOf(boardType) < 0)
        //     boardType = BOARD_NAME_QA; //default, ask question
        // if (boardType === BOARD_NAME_NOTICE && (!user.is_admin))
        //     boardType = null;
        // const writeArticleURL = boardType ? ('/'+boardType+'/write') : null;

        {/*<Menu left id="sideMenu" side="right" width='80%'*/}
        // muon set left/right position thi edit .bm-burger-button
        return (
            <Menu id="sideMenu" left width='80%'
                  isOpen={isOpen}
                  onStateChange={(state) => this.handleStateChange(state)}
                  burgerButtonClassName={"sideMenuIcon"+CLASS_HIDDEN_IN_PC}
                  menuClassName="sideMenuPanel"
                  burgerBarClassName="sideMenuBurgerBar"
                  crossClassName="sideMenuCross"
            >

                {/*<div className="search">*/}
                {/*    <input className="inputSearch" type="text" value={keyword} placeholder="Search"*/}
                {/*           onKeyPress={this.onSearchEnter}*/}
                {/*           onChange={this.changeSearchQuery}*/}
                {/*    />*/}
                {/*</div>*/}

                {
                    isAuthenticated &&
                    <div className="userAvatarInfo marginVerticalMedium">

                        {/*<UserAvatarInfo user={user} />*/}
                        <div className="col-xs-3 col-sm-3" style={{padding: '0px'}}>
                            <img src={ user.avatar_url } alt="avatar" className="img-circle img-responsive avatar" />
                        </div>
                        <div className="col-xs-9 col-sm-9 rightColumn">
                            <p>
                                <strong>{user.username}</strong>
                                <label className="levelBadge">Lv {user.level}</label>
                            </p>

                            <span className="reputationInfo">
                                <span className="activityPoint">♥ { user.activity }</span>&nbsp;
                                <span className="popularityPoint"> ♥ { user.popularity }</span>&nbsp;
                                <span className="confidencePoint"> ♥ { user.confidence + '1' }</span>
                            </span>
                            <p className="career">{ user.career }</p>
                        </div>
                    </div>
                }
                {
                    isAuthenticated &&
                    <div className="buttons">
                        {/*<div className="button notificationCount">*/}
                        {/*    <a href={DASHBOARD_NOTIFICATION}>*/}
                        {/*        <Glyphicon glyph="bell"/>*/}
                        {/*        {*/}
                        {/*            (unreadCount > 0) &&*/}
                        {/*            <span className="badge">{unreadCount}</span>*/}
                        {/*        }*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                        <div className="button">
                            <a href={SETTING}> <Glyphicon glyph="cog"/> </a>
                        </div>
                        <div className="button">
                            <a href={DASHBOARD}> <Glyphicon glyph="dashboard"/> </a>
                        </div>
                        <div className="button">
                            <a href={PROFILE}> <Glyphicon glyph="briefcase"/> </a>
                        </div>
                        {/*<div className="button">*/}
                        {/*    <a href="#" onClick={this.userLogout}> <Glyphicon glyph="log-out"/> </a>*/}
                        {/*</div>*/}
                    </div>
                }

                {/*{*/}
                {/*    isAuthenticated && writeArticleURL &&*/}
                {/*    <a className="writeBtn" href={'/'+boardType+'/write'}> <Glyphicon glyph="pencil"/> </a>*/}
                {/*}*/}

                {/*{*/}
                {/*    !isAuthenticated &&*/}
                {/*    <div className="buttons marginVerticalMedium">*/}
                {/*        <div className="button signInButton">*/}
                {/*            <a href={SIGN_IN} > <Glyphicon glyph="log-in"/> </a>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}



                <Collapsible open={isSectionMenuOpen[BOARD_NAME_QA]} handleTriggerClick={() => this.toggleSectionMenuOpen(BOARD_NAME_QA)}
                             trigger={<CollapsibleButton text="Q&A" isCollapsed={true} />} triggerWhenOpen={<CollapsibleButton text="Q&A" isCollapsed={false} />}
                >
                    {
                        BUTTONS.LEFT_BUTTONS[BOARD_NAME_QA].map((button, idx) => (
                            <a key={MiscUtils.generateId()}
                               onClick={
                                   () => {this.boardSubtypeButtonClick(BOARD_NAME_QA, button[1])}
                               }
                            >
                                <div className={classnames("innerButton", {'active': subBoard === button[1]})}>{button[0]}</div>
                            </a>
                        ))
                    }
                </Collapsible>


                <Collapsible open={isSectionMenuOpen[BOARD_NAME_COMMUNITY]} handleTriggerClick={() => this.toggleSectionMenuOpen(BOARD_NAME_COMMUNITY)}
                             trigger={<CollapsibleButton text={t('Community')} isCollapsed={true} />} triggerWhenOpen={<CollapsibleButton text={t('Community')} isCollapsed={false} />}
                >
                    {
                        BUTTONS.LEFT_BUTTONS[BOARD_NAME_COMMUNITY].map((button, idx) => (
                            <a key={MiscUtils.generateId()}
                               onClick={
                                   () => {this.boardSubtypeButtonClick(BOARD_NAME_COMMUNITY, button[1])}
                               }
                            >
                                <div className={classnames("innerButton", {'active': subBoard === button[1]})}>{button[0]}</div>
                            </a>
                        ))
                    }
                </Collapsible>


                <Collapsible open={isSectionMenuOpen[BOARD_NAME_RANK]} handleTriggerClick={() => this.toggleSectionMenuOpen(BOARD_NAME_RANK)}
                             trigger={<CollapsibleButton text={t('Ranking')} isCollapsed={true} />} triggerWhenOpen={<CollapsibleButton text={t('Ranking')} isCollapsed={false} />}
                >
                    {
                        BUTTONS.LEFT_BUTTONS[BOARD_NAME_RANK].map((button, idx) => (
                            <a key={MiscUtils.generateId()}
                               onClick={
                                   () => {this.boardSubtypeButtonClick(BOARD_NAME_RANK, button[1])}
                               }
                            >
                                <div className={classnames("innerButton", {'active': subBoard === button[1]})}>{button[0]}</div>
                            </a>
                        ))
                    }
                </Collapsible>


                <a className="sideMenuHyperlink" href="/notice">{t('Notice')}</a>
                <a className="sideMenuHyperlink" href={MAINPAGE_FREETALK}>{t('Free Talk')}</a>


                <Collapsible open={isSectionMenuOpen[BOARD_NAME_NOTICE]}
                             handleTriggerClick={() => this.toggleSectionMenuOpen(BOARD_NAME_NOTICE)}
                             trigger={<CollapsibleButton text={t('About us')} isCollapsed={true} />}
                             triggerWhenOpen={<CollapsibleButton text={t('About us')} isCollapsed={false} />}
                >
                    {
                        BUTTONS.LEFT_BUTTONS[BOARD_NAME_NOTICE].map((button, idx) => {
                            if (!['notice', 'feedback', 'donation'].includes(button[1]))
                                return (
                                    <a key={MiscUtils.generateId()}
                                       onClick={
                                           () => {this.boardSubtypeButtonClick(BOARD_NAME_NOTICE, button[1])}
                                       }
                                    >
                                        <div className={classnames("innerButton", {'active': subBoard === button[1]})}>
                                            { (button[1] !== 'introduction') && button[0] }
                                            { (button[1] === 'introduction') && t('Organization') }
                                        </div>
                                    </a>
                                )
                        })

                    }
                </Collapsible>

                {/*<Collapsible open={isSectionMenuOpen[BOARD_NAME_NOTICE]}*/}
                {/*             handleTriggerClick={() => this.toggleSectionMenuOpen(BOARD_NAME_NOTICE)}*/}
                {/*             trigger={<CollapsibleButton text={t('Notice')} isCollapsed={true} />}*/}
                {/*             triggerWhenOpen={<CollapsibleButton text={t('Notice')} isCollapsed={false} />}*/}
                {/*>*/}
                {/*    {*/}
                {/*        BUTTONS.LEFT_BUTTONS[BOARD_NAME_NOTICE].map((button, idx) => {*/}
                {/*            if (button[1] !== 'introduction')*/}
                {/*                return (*/}
                {/*                    <a key={MiscUtils.generateId()}*/}
                {/*                       onClick={*/}
                {/*                           () => {this.boardSubtypeButtonClick(BOARD_NAME_NOTICE, button[1])}*/}
                {/*                       }*/}
                {/*                    >*/}
                {/*                        <div className={classnames("innerButton", {'active': subBoard === button[1]})}>{button[0]}</div>*/}
                {/*                    </a>*/}
                {/*                )*/}
                {/*        })*/}

                {/*    }*/}
                {/*</Collapsible>*/}

                <div className="bottomButtons">
                    <div className="alignCenter">
                        <a href="#" onClick={this.userLogout}>
                            <Glyphicon glyph="log-out"/> &nbsp; {t('Logout')}
                        </a>
                    </div>
                    <div className="paddingTop15">
                        <div className="col-xs-6 alignCenter left">
                            <a onClick={
                                () => {this.boardSubtypeButtonClick(BOARD_NAME_NOTICE, 'donation')}
                            }>{t('Donation')}</a>
                        </div>
                        <div className="col-xs-6 alignCenter">
                            <a onClick={
                                () => {this.boardSubtypeButtonClick(BOARD_NAME_NOTICE, 'feedback')}
                            }>{t('Inquiry')}</a>
                        </div>
                    </div>
                </div>


            </Menu>
        );
    }
}

export default translate()(SideMenu);


// const mapStateToProps = (state) => {
//     return {
//         isAuthenticated: state.reducerAuth.isAuthenticated,
//         user: state.reducerAuth.user,
//         getNotificationIsProcessing: state.reducerNotification.isProcessing,
//         notifications: state.reducerNotification.notifications,
//         unreadCount: state.reducerNotification.unreadCount
//     }
// };
//
// const mapDispatchToProps = {
//     // logout,
//     // getAllNotification,
//     // getUnreadNotificationCount
// };
//
// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideMenu));