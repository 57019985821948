import React from 'react';
import { connect } from 'react-redux';
import { withRouter} from 'react-router-dom';
import { BlockingComponent, ButtonHyperlink } from '../../components';
import { MiscUtils, URLUtils } from '../../extras';
import { SIGN_IN, DEFAULT_INDEX } from '../../RouteURL';
import { forgotPasswordSubmit } from '../../redux/actions';
import { ApiAuth } from '../../api';


class ResetPassword extends React.Component {

    state = {
        isProcessingLocal: true,
        isSuccess: null,
        hashCode: ''
    };


    componentWillReceiveProps (nextProps){
        const { message_error } = nextProps;
        if (message_error)
            MiscUtils.showErrorMessage(message_error);
    }


    savePassword = () => {
        const data = MiscUtils.serializeForm('#resetPasswordForm');
        this.props.forgotPasswordSubmit(data, () => {
            URLUtils.moveToURL(DEFAULT_INDEX);
        })
    };


    componentDidMount() {
        const currentURL = URLUtils.getCurrentURL(this);
        const arr = currentURL.split('/');
        const hashCode = arr[arr.length-1];
        if (['', 'resetPassword'].indexOf(hashCode) >=0)
            this.setState({
                isProcessingLocal: false,
                isSuccess: false
            });
        else {
            const data = {
                hashCode
            };
            const callbackSuccess = (data) => {
                this.setState({
                    isProcessingLocal: false,
                    isSuccess: true,
                    hashCode
                });
            };
            const callbackError = (error) => {
                this.setState({
                    isProcessingLocal: false,
                    isSuccess: false
                });
            };
            this.setState({isProcessingLocal: true}, () => ApiAuth.forgotPasswordCheckCode(callbackSuccess, callbackError, data));
        }
    }

    render (){
        const { isProcessingLocal, isSuccess, hashCode } = this.state;
        const { isProcessing, t } = this.props;
        return (
            <div id="resetPasswordView">
                <BlockingComponent isProcessing={isProcessing || isProcessingLocal}>
                    {
                        isSuccess === true &&
                        <form id="resetPasswordForm" className="popupForm">
                            <input type="hidden" name="hashCode" value={hashCode} />
                            <div className="title">{t('Reset password')}</div>
                            <div className="form-group">
                                <input type="password" className="form-control" name="password1" placeholder={t("Password")}/>
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control" name="password2" placeholder={t("Confirm password")}/>
                            </div>
                            <div className="actionBtn">
                                <button type="button" className="btn positiveBtn" onClick={this.savePassword}>{t("Save")}</button>
                            </div>
                        </form>
                    }
                    {
                        isSuccess === false &&
                        <div className="alignCenter">
                            <p>{t('Invalid or Expired reset password code')}</p>
                            <ButtonHyperlink txt={t('Login')} url={SIGN_IN} />
                        </div>
                    }
                </BlockingComponent>
            </div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        isProcessing: state.reducerAuth.isProcessing,
        message_error: state.reducerGlobal.message_error
    }
};

const mapDispatchToProps = {
    forgotPasswordSubmit
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPassword));