import React from 'react';
import { MAINPAGE_NOTICE, NOTICE__ABOUTUS, NOTICE__USERGUIDE, NOTICE__CONTRIBUTOR, NOTICE__PRIVACY, NOTICE__TOS, NOTICE__FEEDBACK, NOTICE__DONATION } from '../../RouteURL';
import { CLASS_HIDDEN_IN_MOBILE, CLASS_HIDDEN_IN_PC } from '../../config';
import { translate } from "react-i18next";


const overrideStyle = {
    color: 'inherit',
    cursor: 'pointer',
    textDecoration: 'inherit'
};



class Footer extends React.Component {

    render() {
        const { t } = this.props;
        const navbarInstance = (
            <footer className={"navbar-default myFooter"}>
                <div className="container">
                    <div className="row sectionButton">
                        <label>
                            <a href={ NOTICE__ABOUTUS } style={overrideStyle}>{t('ABOUT US')}</a>
                        </label>
                        <label>
                            <a href={ MAINPAGE_NOTICE } style={overrideStyle}>{t('NOTICE')}</a>
                        </label>
                        <label>
                            <a href={ NOTICE__USERGUIDE } style={overrideStyle}>{t('USER GUIDE')}</a>
                        </label>
                        <label>
                            <a href={ NOTICE__CONTRIBUTOR } style={overrideStyle}>{t('CONTRIBUTORS')}</a>
                        </label>
                        <label>
                            <a href={ NOTICE__PRIVACY } style={overrideStyle}>{t('PRIVACY')}</a>
                        </label>
                        <label>
                            <a href={ NOTICE__TOS } style={overrideStyle}>{t('TERMS OF SERVICE')}</a>
                        </label>
                        <label>
                            <a href={ NOTICE__FEEDBACK } style={overrideStyle}>{t('INQUIRY')}</a>
                        </label>
                        <label>
                            <a href={ NOTICE__DONATION } style={overrideStyle}>{t('DONATION')}</a>
                        </label>
                    </div>
                    <div className={"row "+CLASS_HIDDEN_IN_MOBILE}>
                        <div className="col-md-offset-1 col-md-10 intro">
                            <p>© 2013 JOINUS WORLD Limited.
                                {t('Content created and posted by JOINUS WORLD users is licensed under a Creative Commons Attribution/Share-Alike License.')}
                            </p>
                            <p>
                                {t('{{joinus_world}} is a registered trademark of the JOINUS Foundation, Inc., a non-profit organization.',
                                    {
                                        joinus_world: "JOINUS WORLD®"
                                    })
                                }
                            </p>
                        </div>
                    </div>
                    <div className={"row "+CLASS_HIDDEN_IN_PC}>
                        <div className="alignCenter">
                            <p>© 2013 JOINUS WORLD Limited</p>
                        </div>
                    </div>

                </div>
            </footer>
        );
        return navbarInstance;
    }
}

export default translate()(Footer);