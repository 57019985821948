// import 'babel-polyfill'; // fix IE bug : da co cua Naver Login
// import 'idempotent-babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import App from './App';
import { HomeView, ErrorView, AccountView } from './components'
import { MiscUtils } from './extras';

import { I18nextProvider } from "react-i18next";
import i18n from "./locales/i18n";

// import registerServiceWorker from './registerServiceWorker';

MiscUtils.initForFirstLoading(() => {
    ReactDOM.render(
        // vi Login va App tach biet nen phai xu ly global_message rieng le
        <Provider store={ store }>
            <I18nextProvider i18n={i18n}>
                <BrowserRouter>
                    <Switch>
                        {/* Nhung truong hop nhu HomeView thi phai check get_info page, tuong tu nhu App.js */}
                        <Route exact path="/home" component={ HomeView } />
                        <Route path="/accounts" component={ AccountView } />
                        <Route path="/error" component={ ErrorView } />
                        <Route path="/" component={ App } />
                    </Switch>
                </BrowserRouter>
            </I18nextProvider>
        </Provider>,
        document.getElementById('root')
    );
});


// co cai nay thi bi cache, khi Server side rendering, thi reload la ko hit vao server, nen ko render dc page o server
// registerServiceWorker();
