import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import { translate } from "react-i18next";
import FreetalkWrite from './FreetalkWrite';
import FreetalkList from './FreetalkList';

// import { TableSorterTransparentWithLink } from '../common';
// import { URLUtils } from '../../extras';



class FreetalkView extends React.Component {

    render() {
        const { t } = this.props;

        return (
            <div className="col-md-8">

                <div id="freetalkView" className="row centerContent">
                    <h3>Free talk</h3>
                    <FreetalkWrite {...this.props}/>
                    <FreetalkList  {...this.props}/>

                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.reducerAuth.isAuthenticated,
        user: state.reducerAuth.user,
    }
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translate()(FreetalkView)));

