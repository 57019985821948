import { GLOBAL_NOTIFICATION_MESSAGE_ERROR_CLEAR, GLOBAL_SET_BREADCUMBS, GLOBAL_CLEAR_BREADCUMBS } from './types';


const actionClearNotificationMessage = (bool) => {
    return {
        type: GLOBAL_NOTIFICATION_MESSAGE_ERROR_CLEAR
    };
};

const clearNotificationMessage = () => {
    return dispatch => {
        dispatch(actionClearNotificationMessage());
    }
};



const actionSetBreadcumbs = (breadcumbs) => {
    return {
        type: breadcumbs ? GLOBAL_SET_BREADCUMBS : GLOBAL_CLEAR_BREADCUMBS,
        breadcumbs: breadcumbs
    };
};

const setBreadcumbs = (breadcumbs) => {
    return dispatch => {
        dispatch(actionSetBreadcumbs(breadcumbs));
    }
};

const clearBreadcumbs = () => {
    return dispatch => {
        dispatch(actionSetBreadcumbs(null));
    }
};



export { clearNotificationMessage, setBreadcumbs, clearBreadcumbs };