import React from 'react';
import { MiscUtils } from '../../../extras';
import { BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_NOTICE, BOARD_NAME_TAG, MESSAGE } from '../../../config';
import TableContentArticleElement from './TableContentArticleElement';
import TablePager from './TablePager';


export default class TableContentArticle extends React.Component {
    render() {
        const { boardType, data, isProcessing, onPagerChange } = this.props;
        const articles = data.rows;
        const { currpage, totalpages } = data;
        // MiscUtils.showNotification('Current page: '+currpage)
        // MiscUtils.showNotification('Total: '+totalpages)
        return (
            <div id="tableContentArticle">
                {   !isProcessing && articles && articles.length > 0 &&
                    <div>
                        {
                            articles.map((article, idx) => (
                                <TableContentArticleElement boardType={boardType} article={article}
                                                            key={MiscUtils.generateId()}/>
                            ))
                        }

                        <TablePager page={parseInt(currpage)} total={parseInt(totalpages)} onPagerChange={onPagerChange}/>

                    </div>
                }

                { !isProcessing && articles && articles.length === 0 &&
                    <div>
                        { (boardType === BOARD_NAME_QA) && <label>{MESSAGE.NO_QUESTION}</label> }
                        { (boardType === BOARD_NAME_COMMUNITY) && <label>{MESSAGE.NO_POST}</label> }
                        { (boardType === BOARD_NAME_NOTICE) && <label>{MESSAGE.NO_NOTICE}</label> }
                    </div>
                }
            </div>
        );
    }
}