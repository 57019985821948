import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem, Glyphicon } from 'react-bootstrap';
import { SEARCH } from '../../../RouteURL';
import { MiscUtils, URLUtils, CURRENT_BOARD } from '../../../extras';
import { BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_NOTICE } from '../../../config';
import { translate } from "react-i18next";


class NavigationBarSearchButton extends React.Component {

    state = {
        searchQuery: '',
        searchSubmitURL: ''
    };


    changeSearchQuery = (e) => {
        const searchQuery = e.target.value;
        let boardType = MiscUtils.getFromLocalStorage(CURRENT_BOARD);
        if ([BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_NOTICE].indexOf(boardType) < 0)
            boardType = BOARD_NAME_QA;
        const searchSubmitURL = SEARCH+'?board='+boardType+'&query='+searchQuery;
        this.setState({searchQuery, searchSubmitURL });
    };


    handleEnterKeyPress = (e) => {
        const { searchSubmitURL } = this.state;
        MiscUtils.handleEnterKeyPress(e, () => URLUtils.moveToURL(searchSubmitURL));
    };


    render (){
        const { searchQuery, searchSubmitURL } = this.state;
        const { t } = this.props;

        // let boardType = MiscUtils.getFromLocalStorage(CURRENT_BOARD);
        // if ([BOARD_NAME_QA, BOARD_NAME_COMMUNITY, BOARD_NAME_NOTICE].indexOf(boardType) < 0)
        //     boardType = BOARD_NAME_QA;
        // const url = SEARCH+'?board='+boardType+'&query='+searchQuery;

        return (
            <NavItem className="searchBox">
                <Link to={searchSubmitURL}>
                    <Glyphicon glyph="search"/>
                </Link>
                &nbsp;<input type="text" placeholder={t('Search')} value={searchQuery} onChange={this.changeSearchQuery} onKeyPress={this.handleEnterKeyPress}/>
            </NavItem>
        );
    }
}

export default translate()(NavigationBarSearchButton);