import React from 'react';
import { Loader } from 'react-loaders';
//import '../../../static/css/loadingSpinner.css';

export default class LoadingSpinner extends React.Component {
    render (){
        return <Loader active type="line-scale" color="#02a17c"/>;
    }
}

