import React from 'react';
import { Glyphicon } from 'react-bootstrap';

export default class AboutUsContactView extends React.Component {
    render (){
        return (
            <div id="aboutUsContactView">
                <div className="location">
                    <iframe width="100%" height="380" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=ko&amp;geocode=&amp;q=%EB%8C%80%ED%95%9C%EB%AF%BC%EA%B5%AD+%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C+%EC%96%91%EC%B2%9C%EA%B5%AC+%EC%8B%A0%EC%A0%95%EB%8F%99+%ED%95%B4%EB%88%84%EB%A6%AC%ED%83%80%EC%9A%B4&amp;aq=0&amp;oq=+%ED%95%B4%EB%88%84%EB%A6%AC+%ED%83%80%EC%9A%B4&amp;sll=37.515928,126.863815&amp;sspn=0.105523,0.154324&amp;ie=UTF8&amp;hq=%ED%95%B4%EB%88%84%EB%A6%AC%ED%83%80%EC%9A%B4&amp;hnear=%EB%8C%80%ED%95%9C%EB%AF%BC%EA%B5%AD+%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C+%EC%96%91%EC%B2%9C%EA%B5%AC+%EC%8B%A0%EC%A0%95%EB%8F%99&amp;t=m&amp;ll=37.515928,126.863815&amp;spn=0.006295,0.006295&amp;output=embed" title="iframe_aboutus_contact"></iframe>
                    <br/>
                    <small>
                        <a target="blank" href="https://maps.google.com/maps?f=q&amp;source=embed&amp;hl=ko&amp;geocode=&amp;q=%EB%8C%80%ED%95%9C%EB%AF%BC%EA%B5%AD+%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C+%EC%96%91%EC%B2%9C%EA%B5%AC+%EC%8B%A0%EC%A0%95%EB%8F%99+%ED%95%B4%EB%88%84%EB%A6%AC%ED%83%80%EC%9A%B4&amp;aq=0&amp;oq=+%ED%95%B4%EB%88%84%EB%A6%AC+%ED%83%80%EC%9A%B4&amp;sll=37.515928,126.863815&amp;sspn=0.105523,0.154324&amp;ie=UTF8&amp;hq=%ED%95%B4%EB%88%84%EB%A6%AC%ED%83%80%EC%9A%B4&amp;hnear=%EB%8C%80%ED%95%9C%EB%AF%BC%EA%B5%AD+%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C+%EC%96%91%EC%B2%9C%EA%B5%AC+%EC%8B%A0%EC%A0%95%EB%8F%99&amp;t=m&amp;ll=37.515928,126.863815&amp;spn=0.006295,0.006295" style={{color:"#0000FF",textAlign: "left"}}>View Larger Map</a>
                    </small>
                </div>

                <div className="contactPoint">
                    <p>
                        <Glyphicon glyph="home"/> (158-885) 8F, Haenuri town, 322-11, Sinjeong-dong, Yangcheon-gu, Seoul, Korea
                    </p>
                    <p>
                        <Glyphicon glyph="phone-alt"/> + 82-70-7839-5200
                    </p>
                    <p>
                        <Glyphicon glyph="print"/> + 82-2-2654-7697
                    </p>
                </div>

                <div className="contactSNS">
                    <h5>See us more at</h5>
                    <a className="btn btn-social-icon btn-facebook" href="https://www.facebook.com/JOINUSKOREA4U" target="blank">
                        <span className="fa fa-facebook"></span>
                    </a>
                    <a className="btn btn-social-icon btn-twitter" href="https://twitter.com/Joinuskorea" target="blank">
                        <span className="fa fa-twitter"></span>
                    </a>
                    <a className="btn btn-social-icon btn-google" href="https://plus.google.com/u/0/b/118254046775405488381/118254046775405488381/posts" target="blank">
                        <span className="fa fa-google"></span>
                    </a>

                    <a className="btn btn-social-icon btn-openid" href="http://blog.joinuskorea.org/" target="blank">
                        <span className="fa fa-rss"></span>
                    </a>


                </div>
            </div>
        )
    }
}