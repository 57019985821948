import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'

export default class ScrollContent extends React.Component {

    render() {
        const { children, customClassName } = this.props;
        return (
            <PerfectScrollbar className={customClassName ? customClassName : "myScrollContent_notification_drop_list"}>
                {children}
            </PerfectScrollbar>
        );
    }
}