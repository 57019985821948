import React from 'react';
import { getBoardNameFromCode, getSubBoardNameFromCode } from '../../../config';

export default class ButtonHyperlink extends React.Component {
    render (){
        const { url, txt, customClass} = this.props;
        let cls = customClass;
        if (!cls)
            cls = 'btn-info';
        return (
            <a href={url}>
                <button type="button" className={"btn "+cls}>{ txt }</button>
            </a>
        );
    }
}