import React from 'react';
import { TableContentArticleElement, TableSorterTransparent, LanguageFilteringButton } from '../common';
import { BOARD_NAME_QA, BOARD_NAME_COMMUNITY } from '../../config';
import { MiscUtils, SELECTED_LANGUAGES } from '../../extras';


export default class RankArticleView extends React.Component {

    state = {
        selectedLanguages: MiscUtils.getFromLocalStorage(SELECTED_LANGUAGES)
    };


    saveSelectLanguagesCallback = (selectedLanguages) => {
        const { saveSelectLanguagesCallback } = this.props;
        if (saveSelectLanguagesCallback)
            saveSelectLanguagesCallback(selectedLanguages);
    };


    render() {
        const { listObjects, boardType } = this.props;
        const { selectedLanguages } = this.state;
        return (
            <div>
                <LanguageFilteringButton
                    selectedLanguages={selectedLanguages}
                    saveSelectLanguagesCallback={this.saveSelectLanguagesCallback}
                />
                <TableSorterTransparent {...this.props}/>
                {
                    (listObjects.length > 0) &&
                    <div id="tableContentArticle">
                        {
                            listObjects.map((article, idx) => (
                                <TableContentArticleElement boardType={boardType} article={article} key={MiscUtils.generateId()}/>
                            ))
                        }
                    </div>
                }
            </div>
        );
    }
}